/* eslint-disable react/jsx-no-bind */
import Immutable, { type Map, type List, type OrderedSet, type RecordOf } from 'immutable'
import * as React from 'react'
import { ThemeProvider } from 'styled-components'

import { Separator } from 'components/app/custom-data/custom-data.styles'
import { BarChart } from 'components/charts/bar-chart'
import {
  DataSetFactory,
  DataPointFactory,
  type DataSetRecord,
} from 'components/charts/chart-helper'
import { Box, BoxHeader, HeaderBoxTitle, HeaderBoxActions, BoxBody } from 'components/common/box'
import { Button, Dropdown } from 'components/common/button'
import { Wrapper } from 'components/common/empty-states'
import { FlexLine, FlexLineItem } from 'components/common/flexline'
import Hint from 'com.batch/shared/ui/component/hint'
import { Icon, type availableIcons } from 'components/common/svg-icon'
import {
  Table,
  TableCellOrder,
  TableHeader,
  TableRow,
  TableCell,
  TableBody,
} from 'components/common/table'
import { TableToggle, TableToggleItem } from 'components/common/tabletoggle'
import { Tooltip } from 'com.batch/shared/ui/component/tooltip'
import { DateRangePicker } from 'components/form'

import { type DateRange, type Dayjs, dayjs } from 'com.batch.common/dayjs.custom'
import { numberFormat, kformat, percentage } from 'com.batch.common/utils'

import {
  MetricsFlexLine,
  MetricsColumn,
  ChartTitle,
  ChartMetric,
  ChartAverage,
} from './recurring.styles'

import { type AppRecord, type CampaignRecord } from 'com.batch.redux/_records'
import { fetchCampaignDetailAnalytics } from 'com.batch.redux/dataCampaign.api'
import { type DayDataRecord } from 'com.batch.redux/dataCampaign.records'
import { OrchestrationState } from 'constants/common'

type serieKeys =
  | 'sent'
  | 'supplied'
  | 'clickedSecondary'
  | 'clickedMain'
  | 'sentNotifOn'
  | 'openRate'
  | 'reengageRate'
  | 'clicked'
  | 'open'
  | 'influencedOpen'
  | 'reengaged'
  | 'errors'
  | 'skipped'
  | 'display'
  | 'skipped'

type ChartDataProps = {
  total: number
  average: number
  label: string
  hint: string
  sets: List<DataSetRecord>
  serie: serieKeys
}
export const ChartDataFactory = Immutable.Record<ChartDataProps>({
  total: 0,
  average: 0,
  label: '',
  hint: '',
  sets: Immutable.List(),
  serie: 'sent',
} as ChartDataProps)
export type ChartDataRecord = RecordOf<ChartDataProps>

type RecurrenceDisplayMode = 'table' | 'chart'
type RecurringDataProps = {
  app: AppRecord
  campaign: CampaignRecord
}

const reduceSum = (curr: number, acc: number) => curr + acc
const modes: Array<{
  label: string
  value: RecurrenceDisplayMode
  icon: availableIcons
}> = [
  { label: 'Charts', value: 'chart', icon: 'graphic' },
  { label: 'Table', value: 'table', icon: 'table' },
]
const buildSerie = (label: string, color: string, key: serieKeys, tooltip: string) => {
  return {
    label,
    tooltip,
    color,
    key,
  }
}
const shortName = {
  sent: 'Sent',
  sentNotifOn: 'Opt-ins',
  open: 'Open',
  display: 'Displayed',
  openRate: '%',
  reengageRate: '%',
  influencedOpen: 'Influence',
  reengaged: 'Reengage',
  supplied: 'Supplied',
  clicked: 'Clicked',
  clickedSecondary: 'Secondary button',
  clickedMain: 'Main button',
  errors: 'err',
  skipped: 'sk',
}

const predefRangesAmplitudes = (range: DateRange): Array<[number, 'week' | 'month' | 'year']> => {
  const duration = dayjs.duration(range.to.diff(range.from))
  if (duration.months() <= 1) {
    return [
      [1, 'week'],
      [2, 'week'],
      [1, 'month'],
    ]
  }
  if (duration.months() < 7) {
    return [
      [1, 'week'],
      [2, 'week'],
      [1, 'month'],
      [3, 'month'],
      [6, 'month'],
    ]
  }

  return [
    [1, 'week'],
    [1, 'month'],
    [3, 'month'],
    [6, 'month'],
    [1, 'year'],
  ]
}

export const RecurringData = ({ app, campaign }: RecurringDataProps): React.ReactElement => {
  // ====================== LOCAL STATE
  const [loading, setLoading] = React.useState(false)
  const [mode, setMode] = React.useState<RecurrenceDisplayMode | null | undefined>(null)
  const [range, setRange] = React.useState<DateRange | null | undefined>(null)
  const [overIndex, setOverIndex] = React.useState<null | number>(null)
  const [dayData, setDayData] = React.useState<List<DayDataRecord>>(Immutable.List())
  const [minDay, setMinDay] = React.useState(dayjs.utc())
  const [maxDay, setMaxDay] = React.useState(dayjs.utc())
  const [sortBy, setSortBy] = React.useState('period')
  const [sortOrder, setSortOrder] = React.useState<'dsc' | 'asc'>('asc')
  const [pickedSeries, setPickedSeries] = React.useState<OrderedSet<serieKeys>>(
    Immutable.OrderedSet(
      campaign.type === 'push' ? ['sentNotifOn', 'open', 'influencedOpen'] : ['display', 'clicked']
    )
  )

  // ====================== EFFECTS
  React.useEffect(() => {
    setLoading(true)
    fetchCampaignDetailAnalytics({ app, token: campaign.token }).then(
      data => {
        const sorted = data.sort((a, b) => (a.period.isBefore(b.period) ? -1 : 1))
        const reasonnableFist = sorted.slice(-40).first()?.period ?? dayjs()
        setDayData(sorted)
        const first = sorted.first()?.period ?? dayjs.utc()
        const last = sorted.last()?.period ?? dayjs.utc()
        setMinDay(first)
        setMaxDay(last)
        setRange({ from: dayjs(reasonnableFist.toDate()), to: dayjs(last.toDate()) })
        setMode(sorted.size < 4 ? 'table' : 'chart')
        setLoading(false)
      },
      error => {
        console.log(error)
        setLoading(false)
      }
    )
  }, [app, campaign.token])

  // ====================== DERIVED

  // callback to update sort order
  const updateSort = React.useCallback(
    field => {
      let newSortOrder: 'asc' | 'dsc' = sortOrder === 'asc' ? 'dsc' : 'asc'
      if (sortBy !== field) newSortOrder = 'dsc'
      setSortBy(field)
      setSortOrder(newSortOrder)
    },
    [sortBy, sortOrder]
  )

  // range filtered data
  const filteredData = React.useMemo(
    () =>
      dayData.filter(
        day =>
          range &&
          day.period.hour(4).isSameOrAfter(range.from, 'day') &&
          day.period.hour(4).isSameOrBefore(range.to, 'day')
      ),
    [dayData, range]
  )

  // list of displayable data series for the current campaign type
  const series = React.useMemo(
    () =>
      Immutable.List(
        campaign.type === 'push'
          ? [
              buildSerie('Sent', '#98CE6F ', 'sent', 'Notification sent.'),
              buildSerie(
                'Sent Opt-ins',
                '#79B34D',
                'sentNotifOn',
                'Notifcation sent to opt-ins users.'
              ),
              buildSerie(
                'Direct Open',
                '#F7D451',
                'open',
                'Users who tapped or clicked on the notification.'
              ),
              buildSerie('Open rate', '#F7D451', 'openRate', ''),
              buildSerie(
                'Influenced open',
                '#E9B87B',
                'influencedOpen',
                'Users who opened the app in a 3 hours range afterwards.'
              ),
              buildSerie(
                'Reengaged',
                '#595D92',
                'reengaged',
                'Users who received the notification and became engaged 3 days after receiving it.'
              ),
              buildSerie('Reengage rate', '#E9B87B', 'reengageRate', ''),
              buildSerie(
                'Error',
                '#DE475E',
                'errors',
                'Sent to the push provider but not to the end device because of errors or uninstallations.'
              ),
              buildSerie(
                'Skipped',
                '#C860A5',
                'skipped',
                'The notification was skipped because of capping limit.'
              ),
            ]
          : [
              buildSerie(
                'Supplied',
                '#242548',
                'supplied',
                'Number of devices the campaign was supplied to.'
              ),
              buildSerie(
                'Displayed',
                '#79B34D',
                'display',
                'Number of times the campaign was displayed.'
              ),
              buildSerie(
                'Clicked',
                '#F7D451',
                'clicked',
                "Number of clicks on one of the campaign's buttons."
              ),
              buildSerie(
                'Main button',
                '#088157',
                'clickedMain',
                'Number of click on the first button.'
              ),
              buildSerie(
                'Secondary button',
                '#D5B571',
                'clickedSecondary',
                'Number of click on the second button.'
              ),
            ]
      ),
    [campaign.type]
  )

  const isEmptyForRange = React.useMemo(() => filteredData.size === 0, [filteredData.size])

  // sorted data
  const sortedData = React.useMemo(() => {
    return filteredData.sort((a, b) => {
      const sv = sortOrder === 'asc' ? -1 : 1
      switch (sortBy) {
        case 'sent':
          return a.push.total.sent < b.push.total.sent ? sv : -sv
        case 'clickedSecondary':
          return (a.inapp?.total.click?.cta2 ?? 0) < (b.inapp?.total.click?.cta2 ?? 0) ? sv : -sv
        case 'clickedMain':
          return (a.inapp?.total.click?.cta1 ?? 0) < (b.inapp?.total.click?.cta1 ?? 0) ? sv : -sv
        case 'sentNotifOn':
          return a.push.total.sentNotifOn < b.push.total.sentNotifOn ? sv : -sv
        case 'openRate':
          return a.push.total.openRate < b.push.total.openRate ? sv : -sv
        case 'reengageRate':
          return a.push.total.reengageRate < b.push.total.reengageRate ? sv : -sv
        case 'clicked':
          return (a.inapp?.total.click?.total ?? 0) < (b.inapp?.total.click?.total ?? 0) ? sv : -sv
        case 'open':
          return a.push.total.open < b.push.total.open ? sv : -sv
        case 'influencedOpen':
          return a.push.total.influencedOpen < b.push.total.influencedOpen ? sv : -sv
        case 'reengaged':
          return a.push.total.reengaged < b.push.total.reengaged ? sv : -sv
        case 'errors':
          return a.errors.reduce(reduceSum, 0) < b.errors.reduce(reduceSum, 0) ? sv : -sv
        case 'skipped':
          return a.skipped.reduce(reduceSum, 0) < b.skipped.reduce(reduceSum, 0) ? sv : -sv
        case 'display':
          return (a.inapp?.total.display ?? 0) < (b.inapp?.total.display ?? 0) ? sv : -sv
        case 'supplied':
          return (a.inapp?.total.supplied ?? 0) < (b.inapp?.total.supplied ?? 0) ? sv : -sv
        default:
          return a.period.isBefore(b.period) ? sv : -sv
      }
    })
  }, [filteredData, sortBy, sortOrder])

  // flattened data used to display the table & compute the chart data
  // this is a List<Map<string,string|number|void>> and a "meh" design decision
  const flatData: List<Map<string, React.ReactNode>> = React.useMemo(
    () =>
      sortedData.map(day =>
        series
          .reduce((acc, serie) => {
            switch (serie.key) {
              case 'supplied':
                return acc
                  .set(serie.key + '_formated', numberFormat(day.inapp?.total.supplied))
                  .set(serie.key, day.inapp?.total.supplied)
              case 'sent':
                return acc
                  .set(serie.key + '_formated', numberFormat(day.push.total.sent))
                  .set(serie.key, day.push.total.sent)
              case 'clickedSecondary':
                return acc
                  .set(serie.key + '_formated', numberFormat(day.inapp?.total.click?.cta2 ?? 0))
                  .set(serie.key, day.inapp?.total.click?.cta2 ?? 0)
              case 'clickedMain':
                return acc
                  .set(serie.key + '_formated', numberFormat(day.inapp?.total.click?.cta1 ?? 0))
                  .set(serie.key, day.inapp?.total.click?.cta1 ?? 0)
              case 'sentNotifOn':
                return acc
                  .set(serie.key + '_formated', numberFormat(day.push.total.sentNotifOn))
                  .set(serie.key, day.push.total.sentNotifOn)
              case 'openRate':
                return acc
                  .set(serie.key + '_formated', percentage(day.push.total.openRate))
                  .set(serie.key, day.push.total.openRate)
              case 'reengageRate':
                return acc
                  .set(serie.key + '_formated', percentage(day.push.total.reengageRate))
                  .set(serie.key, day.push.total.reengageRate)
              case 'clicked':
                return acc
                  .set(serie.key + '_formated', numberFormat(day.inapp?.total.click?.total ?? 0))
                  .set(serie.key, day.inapp?.total.click?.total ?? 0)
              case 'open':
                return acc
                  .set(serie.key + '_formated', numberFormat(day.push.total.open))
                  .set(serie.key, day.push.total.open)
              case 'influencedOpen':
                return acc
                  .set(serie.key + '_formated', numberFormat(day.push.total.influencedOpen))
                  .set(serie.key, day.push.total.influencedOpen)
              case 'reengaged':
                return acc
                  .set(serie.key + '_formated', numberFormat(day.push.total.reengaged))
                  .set(serie.key, day.push.total.reengaged)
              case 'errors':
                return acc
                  .set(serie.key + '_formated', numberFormat(day.errors.reduce(reduceSum, 0)))
                  .set(
                    serie.key + '_tooltip',
                    day.errors.size === 0 ? null : (
                      <span>
                        {Immutable.List()
                          .push(...day.errors)
                          .map(([code, count]: [any, any]) => (
                            <span key={code}>
                              <strong>{code}</strong>
                              {count}
                            </span>
                          ))}
                      </span>
                    )
                  )
                  .set(serie.key, day.errors.reduce(reduceSum, 0))
              case 'skipped':
                return acc
                  .set(serie.key + '_formated', numberFormat(day.skipped.reduce(reduceSum, 0)))
                  .set(serie.key, day.skipped.reduce(reduceSum, 0))
                  .set(
                    serie.key + '_tooltip',
                    day.skipped.size === 0 ? null : (
                      <span>
                        {Immutable.List()
                          .push(...day.skipped)
                          .map(([code, count]: [any, any]) => (
                            <span key={code}>
                              <strong>{code}</strong>
                              {count}
                            </span>
                          ))}
                      </span>
                    )
                  )
              default:
                return acc
                  .set(serie.key + '_formated', numberFormat(day.inapp?.total.display ?? 0))
                  .set(serie.key, day.inapp?.total.display ?? 0)
            }
          }, Immutable.Map<string, React.ReactNode>())
          .set('period_formated', day.period.format('DD/MM/YYYY'))
      ),
    [series, sortedData]
  )

  // computed sums & averages, & built DataSet for the bar charts
  const chartData = React.useMemo(
    () =>
      pickedSeries.toList().map(serieKey => {
        const total = flatData.reduce((acc, row) => acc + Number(row.get(serieKey, 0)), 0)
        const serie = series.find(s => s.key === serieKey)
        return ChartDataFactory({
          serie: serieKey,
          total,
          average: total / flatData.size,
          hint: serie?.tooltip,
          label: serie?.label,
          sets: Immutable.List([
            DataSetFactory({
              color: serie?.color,
              total,
              label: serie?.label,
              data: flatData.map(row => {
                return DataPointFactory({
                  date: dayjs(String(row.get('period_formated', '')), 'DD/MM/YYYY', 'fr', true),
                  value: Number(row.get(serieKey, 0) ?? 0),
                })
              }),
            }),
          ]),
        })
      }),
    [pickedSeries, flatData, series]
  )

  // range shortcuts
  const predefRanges = React.useMemo(() => {
    const predefRanges: Array<{
      from: Dayjs
      name: string
      to: Dayjs
    }> = []
    const to = maxDay.startOf('day')
    const past = !to.isSame(dayjs(), 'week')
    if (!loading && !!range?.from && !!range?.to) {
      predefRangesAmplitudes({ from: minDay, to: maxDay }).forEach(a => {
        const from = to.subtract(a[0], a[1])
        let fromFormat = 'DD/MM/YY'
        const toFormat = 'DD/MM/YY'
        if (from.isSame(to, 'month')) {
          fromFormat = 'DD'
        } else if (from.isSame(to, 'year')) {
          fromFormat = 'DD/MM'
        }
        if (from.isAfter(minDay)) {
          predefRanges.push({
            from,
            to,
            name: past
              ? `${from.format(fromFormat)} to
              ${to.format(toFormat)}`
              : `Last ${a[0] > 1 ? a[0] : ''} ${a[1]}${a[0] > 1 ? 's' : ''}`,
          })
        }
      })

      predefRanges.push({
        from: minDay,
        to: maxDay.startOf('day'),
        name: 'All times',
      })
    }
    return Immutable.List(predefRanges)
  }, [range?.from, range?.to, loading, maxDay, minDay])

  // ====================== RENDER
  return (
    <Box>
      <Wrapper
        isEmpty={false}
        isLoading={loading}
        isOverlayShown={false}
        overlayProps={{
          status: 'empty',
          title: '',
        }}
      >
        <BoxHeader>
          <HeaderBoxTitle title={campaign.type === 'push' ? 'History' : 'Day to day'} />

          <HeaderBoxActions>
            {!loading && range && (
              <ThemeProvider theme={{ kind: 'filter' }}>
                <DateRangePicker
                  position="left"
                  range={range}
                  shortcuts={predefRanges}
                  setRange={setRange}
                  disabledDays={(day: Date) => minDay.isAfter(day) || maxDay.isBefore(day)}
                  style={{ maxWidth: 280 }}
                />
                <Separator style={{ marginLeft: 0 }} />
              </ThemeProvider>
            )}
            <TableToggle kind="filter">
              {modes.map((m, i) => (
                <TableToggleItem active={mode === m.value} onClick={() => setMode(m.value)} key={i}>
                  <Icon icon={m.icon} size={16} />
                </TableToggleItem>
              ))}
            </TableToggle>
          </HeaderBoxActions>
        </BoxHeader>
        <BoxBody>
          <Wrapper
            isEmpty={isEmptyForRange}
            isLoading={loading}
            isOverlayShown={!loading && isEmptyForRange}
            overlayProps={{
              status: 'empty',
              title: 'No data for this campaign.',
              description:
                campaign.state === OrchestrationState.COMPLETED
                  ? 'It might not have targeted anyone.'
                  : campaign.state === OrchestrationState.RUNNING
                    ? 'It is not completed yet.'
                    : campaign.state === OrchestrationState.STOPPED
                      ? 'It is paused.'
                      : campaign.state === OrchestrationState.DRAFT
                        ? 'It is still a draft.'
                        : '',
            }}
          >
            {mode === 'chart' && (
              <FlexLine>
                <FlexLineItem width={250} style={{ paddingRight: 0 }}>
                  <MetricsFlexLine vertical style={{ height: '100%' }}>
                    {chartData.map(cd => (
                      <MetricsColumn key={cd.serie} style={{ width: 140 }} container>
                        <ChartTitle>
                          <Dropdown
                            label={
                              <span>
                                <span style={{ letterSpacing: 0.5 }}>{cd.label}</span>
                                {cd.hint && <Hint>{cd.hint}</Hint>}
                              </span>
                            }
                            // title="Choose a metric"
                          >
                            {series
                              .filter(s => !pickedSeries.has(s.key))
                              .map(s => (
                                <Tooltip key={s.key} tooltip={s.tooltip ?? null} placement="left">
                                  <span>
                                    <Button
                                      onClick={() =>
                                        setPickedSeries(
                                          pickedSeries.map(k => (k === cd.serie ? s.key : k))
                                        )
                                      }
                                    >
                                      {s.label}
                                    </Button>
                                  </span>
                                </Tooltip>
                              ))}
                          </Dropdown>
                        </ChartTitle>
                        <ChartMetric>{cd.hint ? kformat(cd.total) : '–'}</ChartMetric>
                        {cd.serie.indexOf('Rate') !== -1 ? (
                          <ChartAverage>{percentage(cd.average)} average</ChartAverage>
                        ) : (
                          <ChartAverage>
                            <strong>{kformat(cd.average)} / day</strong> average
                          </ChartAverage>
                        )}
                      </MetricsColumn>
                    ))}
                  </MetricsFlexLine>
                </FlexLineItem>
                <FlexLineItem bl grow={1} style={{ paddingRight: 0 }}>
                  {chartData.map((cd, index) => (
                    <div
                      key={cd.serie}
                      style={{
                        borderBottom:
                          index === pickedSeries.size - 1 ? 'none' : '1px solid #F2F3F8',
                        backgroundColor: '#fefefe',
                      }}
                    >
                      <BarChart
                        sets={cd.sets}
                        setOverIndex={setOverIndex}
                        overIndex={overIndex}
                        height={128}
                        timelineMode={index === 0 ? 'top' : 'none'}
                      />
                    </div>
                  ))}
                </FlexLineItem>
              </FlexLine>
            )}
            {mode === 'table' && (
              <Table template={`repeat(${series.size + 1}, 1fr)`}>
                <TableHeader>
                  <TableCellOrder
                    sort={sortBy === 'period' ? sortOrder : false}
                    onClick={() => updateSort('period')}
                  >
                    Period
                  </TableCellOrder>
                  {series.map((serie, index) => (
                    <Tooltip
                      key={index}
                      tooltip={
                        <span>
                          <strong>
                            {serie.label}
                            {serie.tooltip ? ': ' : ''}
                          </strong>
                          {serie.tooltip}
                        </span>
                      }
                    >
                      <TableCellOrder
                        align="right"
                        sort={sortBy === serie.key ? sortOrder : false}
                        onClick={() => updateSort(serie.key)}
                      >
                        {shortName[serie.key]}
                      </TableCellOrder>
                    </Tooltip>
                  ))}
                </TableHeader>
                <TableBody
                  style={{
                    maxHeight: 422,
                    minHeight: 70,
                    overflowY: 'auto',
                    borderRadius: '0 0 5px 5px',
                  }}
                >
                  {flatData.map((row, key) => (
                    <TableRow key={key}>
                      <TableCell>{row.get('period_formated')}</TableCell>
                      {series.map((serie, colIndex) => (
                        <TableCell key={colIndex} align="right">
                          <Tooltip tooltip={row.get(serie.key + '_tooltip')}>
                            <span>{row.get(serie.key + '_formated')}</span>
                          </Tooltip>
                        </TableCell>
                      ))}
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            )}
          </Wrapper>
        </BoxBody>
      </Wrapper>
    </Box>
  )
}
