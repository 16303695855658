/* eslint-disable react/jsx-no-bind */
import Immutable from 'immutable'
import * as React from 'react'

import { DateRangePickerContainer } from 'components/campaign/campaign-list.styles'
import { DateRangerContentFilter } from 'components/campaign/filters/date-range-content-filter'
import { Filter } from 'components/campaign/filters/filter'
import { Button } from 'components/common/button'
import Hint from 'com.batch/shared/ui/component/hint'
import { Icon } from 'components/common/svg-icon'
import { Radio } from 'components/form/fields/radio'

import { type DateRange } from 'com.batch.common/dayjs.custom'
import * as predefinedRanges from 'com.batch.common/predefined-ranges'

import { type CampaignActiveFiltersRecord } from 'com.batch.redux/_records'

type DateRangeFilterProps = {
  localActiveFilters: CampaignActiveFiltersRecord
  onChange?: (arg1?: DateRange | null | undefined) => void
  onClose?: (arg1?: DateRange | null | undefined) => void
  isEmbedded: boolean
}

const ranges = Immutable.List([
  predefinedRanges.lastWeek,
  predefinedRanges.lastMonth,
  predefinedRanges.last3Months,
  predefinedRanges.allTime,
])

export const DateRangeFilter = ({
  localActiveFilters,
  onChange,
  onClose,
  isEmbedded,
}: DateRangeFilterProps): React.ReactElement => {
  const selectedDateRange = React.useMemo(() => {
    return localActiveFilters.dateRange
  }, [localActiveFilters.dateRange])

  return (
    <Filter
      isEmbedded={isEmbedded}
      title={
        <span>
          Dates
          <Hint tooltipWidth={300}>
            This will only filter on campaign with statistics. Drafts and scheduled campaigns will
            be excluded.
          </Hint>
        </span>
      }
      clearButton={
        selectedDateRange?.from && selectedDateRange?.to ? (
          <Button
            style={{ height: 28, display: 'inline-flex' }}
            addOn="prefix"
            kind="inline"
            onClick={() => onChange && onChange(null)}
          >
            <Icon icon="close" />
            Clear
          </Button>
        ) : null
      }
    >
      {isEmbedded ? (
        <React.Fragment>
          <DateRangePickerContainer>
            <DateRangerContentFilter
              selectedDateRange={selectedDateRange}
              onChange={onChange}
              onClose={onClose}
              isEmbedded={isEmbedded}
            />
          </DateRangePickerContainer>
          <div role="radiogroup" aria-label="Periods">
            {ranges.map(range => (
              <Radio
                key={range.name}
                style={{ marginTop: 14, height: '19px' }}
                onChange={() =>
                  onChange &&
                  onChange(
                    !range || predefinedRanges.isAllTime(range)
                      ? null
                      : { from: range.from, to: range.to }
                  )
                }
                label={range.name}
                className={`dates_${range.name
                  .toLowerCase()
                  .replace(/ /g, '')
                  .replace(/last/g, '')}`}
                checked={
                  (predefinedRanges.isAllTime(range) && localActiveFilters.dateRange === null) ||
                  (localActiveFilters.dateRange !== null &&
                    localActiveFilters.dateRange?.from !== undefined &&
                    localActiveFilters.dateRange?.to !== undefined &&
                    !predefinedRanges.isAllTime(range) &&
                    range.from &&
                    range.from.isSame(localActiveFilters.dateRange.from) &&
                    range.to &&
                    range.to.isSame(localActiveFilters.dateRange?.to ?? ''))
                }
              />
            ))}
          </div>
        </React.Fragment>
      ) : (
        <DateRangerContentFilter
          selectedDateRange={selectedDateRange}
          onChange={onChange}
          onClose={onClose}
          isEmbedded={false}
          shortcuts={ranges}
        />
      )}
    </Filter>
  )
}
