import {
  type EmailContentRecord,
  type PushContentRecord,
  type SmsContentRecord,
  EmailMessageFactory,
  EmailContentFactory,
  PushMessageFactory,
  PushContentFactory,
  SmsMessageFactory,
  SmsContentFactory,
} from '../models/message.records'
import { type MessageStateRecord } from './message.state'

export type PushCommonTemplatableFields =
  | 'pushBody'
  | 'pushTitle'
  | 'pushPicture'
  | 'pushIcon'
  | 'deeplink'

export const messageUpdated = (id: string, state: MessageStateRecord): MessageStateRecord =>
  state.set('updatedMessageIds', state.updatedMessageIds.add(id))

export const updateStateEmailContentForMessageIdAndLang = ({
  state,
  lang,
  messageId,
  emailContentUpdater,
}: {
  state: MessageStateRecord
  lang: string
  messageId: string
  emailContentUpdater: (arg1: EmailContentRecord) => EmailContentRecord
}): MessageStateRecord => {
  const emailStateForMessageId = state.email.get(messageId, EmailMessageFactory())
  const emailContentLocalized = emailStateForMessageId.localizedContent.get(
    lang,
    EmailContentFactory()
  )

  return messageUpdated(
    messageId,
    state.set(
      'email',
      state.email.set(
        messageId,
        emailStateForMessageId.set(
          'localizedContent',
          emailStateForMessageId.localizedContent.set(
            lang,
            emailContentUpdater(emailContentLocalized)
          )
        )
      )
    )
  )
}

export const updateStateSmsContentForMessageIdAndLang = ({
  state,
  lang,
  messageId,
  smsContentUpdater,
}: {
  state: MessageStateRecord
  lang: string
  messageId: string
  smsContentUpdater: (arg1: SmsContentRecord) => SmsContentRecord
}): MessageStateRecord => {
  const smsStateForMessageId = state.sms.get(messageId, SmsMessageFactory())
  const smsContentLocalized = smsStateForMessageId.localizedContent.get(lang, SmsContentFactory())

  return messageUpdated(
    messageId,
    state.set(
      'sms',
      state.sms.set(
        messageId,
        smsStateForMessageId.set(
          'localizedContent',
          smsStateForMessageId.localizedContent.set(lang, smsContentUpdater(smsContentLocalized))
        )
      )
    )
  )
}

export const updateStatePushContentForMessageIdAndLang = ({
  state,
  lang,
  messageId,
  pushContentUpdater,
}: {
  state: MessageStateRecord
  lang: string
  messageId: string
  pushContentUpdater: (arg1: PushContentRecord) => PushContentRecord
}): MessageStateRecord => {
  const pushStateForMessageId = state.push.get(messageId, PushMessageFactory())
  const pushContentLocalized = pushStateForMessageId.localizedContent.get(
    lang,
    PushContentFactory()
  )
  return messageUpdated(
    messageId,
    state.set(
      'push',
      state.push.set(
        messageId,
        pushStateForMessageId.set(
          'localizedContent',
          pushStateForMessageId.localizedContent.set(lang, pushContentUpdater(pushContentLocalized))
        )
      )
    )
  )
}
