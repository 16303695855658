import 'components/common/sentry'
import * as React from 'react'
import { createRoot } from 'react-dom/client'

import { DashboardRouter } from './router'

const container = document.getElementById('root')

if (container) {
  const root = createRoot(container)
  root.render(<DashboardRouter />)
}
