import * as React from 'react'

import { BoxBody, BoxHeader, BoxTitle } from 'components/common/box/box.styles'
import { Skeleton } from 'components/common/empty-states'
import { Hint } from 'com.batch/shared/ui/component/hint'
import { type LabelVariant } from 'components/common/label/label.styles'

import { MetricBlockBadge, MetricBlockContainer } from './metric-block.styles'

import { Metric } from 'com.batch/shared/ui/component/metric/metric'

type MetricBlockProps = {
  variant: LabelVariant
  label: React.ReactNode
  value: React.ReactNode
  subtitle?: React.ReactNode
  hint?: React.ReactNode
  trend?: React.ReactNode
  style?: any
}

export const MetricBlock = ({
  variant,
  label,
  value,
  subtitle,
  hint,
  trend,
  style,
}: MetricBlockProps): React.ReactElement => {
  return (
    <MetricBlockContainer style={style}>
      <BoxHeader>
        <BoxTitle>
          <Skeleton h={20} w={68} display="block">
            <MetricBlockBadge variant={variant}>
              {label}
              {hint ? (
                <Hint
                  size={13}
                  icon="help"
                  placement="bottom"
                  style={{ position: 'relative', top: '-2px', padding: '6px 2px' }}
                >
                  {hint}
                </Hint>
              ) : null}
            </MetricBlockBadge>
            {trend}
          </Skeleton>
        </BoxTitle>
      </BoxHeader>

      <BoxBody>
        <Metric value={value} subtitle={subtitle} />
      </BoxBody>
    </MetricBlockContainer>
  )
}
