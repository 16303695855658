import Immutable, { type List } from 'immutable'
import * as React from 'react'
import { useDispatch, useSelector } from 'com.batch.common/react-redux'

import { Switch } from 'components/common/button'
import { Editor, InputWrapper } from 'components/form'
import { colors } from 'components/styled/tokens'

import { getMessageConfigSelector } from 'com.batch/orchestration/store/orchestration.composed.selectors'

import { getMessageId } from 'com.batch/message/models/message.helper'
import { type PushContentRecord } from 'com.batch/message/models/message.records'
import {
  type ActionType,
  PushActionPicker,
} from 'com.batch/push/ui/push-action-picker/push-action-picker'
import {
  updatePushMessageContent,
  updatePushMessageRecord,
} from 'com.batch/push/usecases/update-push-content'

type PushAction = 'nothing' | 'deeplink' | 'landing'
const contentStores: {
  [key: string]: 'iosDeeplink' | 'androidDeeplink' | 'webpushDeeplink' | 'deeplink'
} = {
  ios: 'iosDeeplink',
  android: 'androidDeeplink',
  webpush: 'webpushDeeplink',
  all: 'deeplink',
}
type Props = {
  content: PushContentRecord
  stepMessageNodeId: string | null | undefined
  lang: string
}
export const PushDeeplinkField = ({
  content,
  stepMessageNodeId,
  lang,
}: Props): React.ReactElement => {
  const dispatch = useDispatch()
  const [pushAction, setPushAction] = React.useState<PushAction>(
    content.iosDeeplink || content.androidDeeplink || content.deeplink ? 'deeplink' : 'nothing'
  )
  const getConfig = useSelector(getMessageConfigSelector)
  const config = React.useMemo(() => {
    return getConfig({ stepMessageNodeId })
  }, [getConfig, stepMessageNodeId])
  const messageId = React.useMemo(() => getMessageId(config), [config])

  const [splitPerPlatform, setSplitPerPlatform] = React.useState(
    config.platforms.some(p => {
      switch (p) {
        case 'ios':
          return !!content.iosDeeplink
        case 'android':
          return !!content.androidDeeplink
        case 'webpush':
        default:
          return !!content.webpushDeeplink
      }
    })
  )

  const handleOnDeeplinkChange = React.useCallback(
    (platform: keyof typeof contentStores) => (value: string) => {
      dispatch(
        updatePushMessageContent({
          messageId,
          lang,
          field: contentStores[platform],
          value,
        })
      )
    },
    [dispatch, lang, messageId]
  )

  const cleanDeeplinks = React.useCallback(
    (splitted: boolean) => {
      dispatch(
        updatePushMessageRecord({
          messageId,
          lang,
          content: splitted
            ? content.set('iosDeeplink', '').set('androidDeeplink', '').set('webpushDeeplink', '')
            : content.set('deeplink', ''),
        })
      )
    },
    [content, dispatch, lang, messageId]
  )

  const onPushActionChange = React.useCallback(
    action => {
      // Si on passe de deeplink à rien, on supprime les deeplinks
      if (action?.value === 'nothing') {
        cleanDeeplinks(splitPerPlatform)
      }
      setPushAction(action ? action.value : 'nothing')
    },
    [cleanDeeplinks, splitPerPlatform]
  )

  // Quand on passe de split <=> no split, on supprime les deeplinks par plateforme ou le deeplink global
  const handleOnSplitPerPlatformChange = React.useCallback(() => {
    setSplitPerPlatform(s => !s)
    cleanDeeplinks(splitPerPlatform)
  }, [splitPerPlatform, cleanDeeplinks])

  // Si on deselect jusqu'à n'avoir qu'1 plateforme => disable split + clean
  React.useEffect(() => {
    if (config.platforms.size === 1 && splitPerPlatform) {
      setSplitPerPlatform(false)
      cleanDeeplinks(true)
    }
  }, [config.platforms, splitPerPlatform, cleanDeeplinks])

  const actions: List<{
    label: string
    description: string
    value: PushAction
  }> = React.useMemo(() => {
    const nothing: ActionType = {
      label: 'Redirect to app',
      description: 'Head to the app home screen',
      value: 'nothing',
    }
    const deeplink: ActionType = {
      label: 'Open deeplink',
      description: 'Open a specific link/screen in the app',
      value: 'deeplink',
    }

    if (config.platforms.size === 1 && config.platforms.has('webpush')) {
      nothing.label = 'Redirect to site'
      nothing.description = 'Head to the website home'
      deeplink.description = 'Open a specific link'
    } else if (config.platforms.has('webpush')) {
      nothing.label = 'Redirect to app/site'
      nothing.description = 'Head to the app home screen/website home'
    }

    return Immutable.List([nothing, deeplink])
  }, [config.platforms])

  const action = React.useMemo(
    () =>
      actions.find(o => o.value === pushAction) ??
      (actions.get(0) as {
        label: string
        description: string
        value: PushAction
      }),
    [pushAction, actions]
  )

  return (
    <InputWrapper>
      <PushActionPicker value={action} onChange={onPushActionChange} options={actions}>
        {pushAction === 'deeplink' &&
          (splitPerPlatform ? (
            <div
              style={{
                flexGrow: 1,
                paddingTop: 26,
                display: 'flex',
                flexDirection: 'column',
                gap: 8,
              }}
            >
              {config.platforms.includes('ios') && (
                <Editor
                  id="ios-deeplink"
                  value={content.iosDeeplink}
                  onChange={handleOnDeeplinkChange('ios')}
                  placeholder="app://deeplink-destination"
                  style={{ width: '100%' }}
                  prefix={{ value: 'ios', color: colors.textLight }}
                />
              )}

              {config.platforms.includes('android') && (
                <Editor
                  id="android-deeplink"
                  value={content.androidDeeplink}
                  onChange={handleOnDeeplinkChange('android')}
                  placeholder="app://deeplink-destination"
                  style={{ width: '100%' }}
                  prefix={{ value: 'android' }}
                />
              )}

              {config.platforms.includes('webpush') && (
                <Editor
                  id="web-deeplink"
                  value={content.webpushDeeplink}
                  onChange={handleOnDeeplinkChange('webpush')}
                  placeholder="https://deeplink-destination"
                  style={{ width: '100%' }}
                  prefix={{ value: 'webpush', style: { marginTop: -3 }, color: colors.textLight }}
                />
              )}
            </div>
          ) : (
            <Editor
              id="common-deeplink"
              onChange={handleOnDeeplinkChange('all')}
              placeholder="app://deeplink-destination"
              style={{ width: '100%', marginTop: 26 }}
              value={content.deeplink}
            />
          ))}

        <div style={{ position: 'absolute', right: 0, top: 0 }}>
          {pushAction === 'deeplink' && config.platforms.size > 1 && (
            <Switch isActive={splitPerPlatform} onChange={handleOnSplitPerPlatformChange}>
              <span style={{ fontWeight: 400, color: colors.textLight }}>Split by platform</span>
            </Switch>
          )}
        </div>
      </PushActionPicker>
    </InputWrapper>
  )
}
