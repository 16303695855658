import type * as React from 'react'
import { NavLink } from 'react-router-dom'
import styled, { css, keyframes } from 'styled-components'

import { HintIcon } from 'com.batch/shared/ui/component/hint'
import { IconContainer } from 'components/common/svg-icon'
import { colors, schemes, shadows } from 'components/styled/tokens'

import { buttonScheme, type ButtonSchemeIntent, type ButtonSchemeKind } from './button.scheme'

export const ToggleSwitch = styled.div`
  margin-left: 2px;
  position: absolute;
  transition: all 0.2s ease-in-out;
  -ms-transform: translateX(0%);
  transform: translateX(0%);
  border-radius: 100px;
  background-color: ${colors.fill};
  width: 11px;
  height: 11px;
`

type SwitchButtonContainerProps = {
  isActive?: boolean
}
export const SwitchButtonContainer = styled.div<SwitchButtonContainerProps>`
  width: 24px;
  height: 15px;
  border-radius: 100px;
  display: flex;
  background-color: ${p => (p.isActive ? colors.fillAction : schemes.grayscale['30'])};
  align-items: center;

  ${ToggleSwitch} {
    transform: ${p => (p.isActive ? 'translateX(9px)' : 'none')};
  }
`

const boxShadow = (kind: ButtonSchemeKind, state: string = 'default') => {
  let shadow = ''

  if ((kind === 'primary' || kind === 'secondary') && (state === 'default' || state === 'hover')) {
    shadow += shadows.raised
  }
  if (kind === 'secondary') {
    if (shadow !== '') shadow += ', '
    shadow += `0 0 0 1px ${
      state === 'hover' || state === 'active' ? colors.strokeHover : colors.stroke
    }`
  }

  if (shadow === '') return 'none'
  return shadow
}

export type ButtonProps = {
  kind?: ButtonSchemeKind
  intent?: ButtonSchemeIntent
  addOnGap?: number
  addOn?: 'suffix' | 'prefix'
  isLoading?: boolean
  isHover?: boolean
  isActive?: boolean
  isSwitchActive?: boolean
  disabled?: boolean
  onClick?: (arg1: React.MouseEvent<any>) => any
  to?: string
  href?: string
  children?: React.ReactNode
  style?: {
    [key: string]: string | number
  }
  type?: 'button' | 'submit'
  target?: string
  end?: boolean
}

// default props were not working as expected, this is a workaround
const getKind = (props: ButtonProps): ButtonSchemeKind => props.kind ?? 'inline'
const getIntent = (props: ButtonProps): ButtonSchemeIntent => props.intent ?? 'neutral'

const Spinner = keyframes`
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
`

const SpinnerStyle = css`
  color: transparent !important;

  > * {
    opacity: 0;
  }

  &:after {
    display: block;
    position: absolute;
    left: calc(50% - 7px);
    width: 14px;
    height: 14px;
    border: 1.5px solid ${colors.textDisabled};
    border-top: 1.5px solid transparent;
    border-radius: 50%;
    content: '';
    animation: ${Spinner} 0.5s linear infinite;
  }
`

export const Button = styled.button<ButtonProps>`
  display: inline-flex;
  justify-content: center;
  align-items: center;
  width: fit-content;
  height: 36px;
  padding: 8px 12px;
  position: relative;
  overflow: hidden;
  transition:
    background ease-out 0.2s,
    color ease-in 0.2s,
    border-color ease-out 0.2s,
    box-shadow ease-out 0.3s,
    width 0.2s ease-in-out;
  font-size: 1em;
  font-weight: 500;
  text-align: center;
  text-overflow: ellipsis;
  white-space: nowrap;
  border: none;
  border-radius: 6px;
  cursor: ${p => (p.disabled ? 'cursor' : 'pointer')};
  user-select: none;

  color: ${p => buttonScheme[getKind(p)][getIntent(p)].normal.color};
  background-color: ${p => buttonScheme[getKind(p)][getIntent(p)].normal.background};
  box-shadow: ${p => boxShadow(getKind(p))};

  &:hover,
  &:focus {
    color: ${p => buttonScheme[getKind(p)][getIntent(p)].hover.color};
    background-color: ${p => buttonScheme[getKind(p)][getIntent(p)].hover.background};
    box-shadow: ${p => boxShadow(getKind(p), 'hover')};
  }

  ${props =>
    props.isHover &&
    css`
      color: ${buttonScheme[getKind(props)][getIntent(props)].hover.color};
      background-color: ${buttonScheme[getKind(props)][getIntent(props)].hover.background};
      box-shadow: ${boxShadow(getKind(props), 'hover')};
    `}
  &:active, &.active {
    color: ${props => buttonScheme[getKind(props)][getIntent(props)].active.color};
    background-color: ${props => buttonScheme[getKind(props)][getIntent(props)].active.background};
    box-shadow: ${props => boxShadow(getKind(props), 'active')};
  }

  &:focus-visible {
    outline: 2px solid ${schemes.blue['30']};
  }

  &:disabled {
    color: ${p => buttonScheme[getKind(p)][getIntent(p)].disabled.color} !important;
    background-color: ${p => buttonScheme[getKind(p)][getIntent(p)].disabled.background} !important;
    box-shadow: ${p => boxShadow(getKind(p), 'disabled')} !important;
  }

  ${p =>
    p.isActive &&
    css`
      color: ${buttonScheme[getKind(p)][getIntent(p)].active.color} !important;
      background-color: ${buttonScheme[getKind(p)][getIntent(p)].active.background} !important;
      box-shadow: ${boxShadow(getKind(p), 'active')} !important;
    `}
  ${p =>
    (p.disabled || p.isLoading) &&
    css`
      color: ${buttonScheme[getKind(p)][getIntent(p)].disabled.color} !important;
      background-color: ${buttonScheme[getKind(p)][getIntent(p)].disabled.background} !important;
      box-shadow: ${boxShadow(getKind(p), 'disabled')} !important;
    `}
  ${p => p.isLoading && SpinnerStyle}
    /* Options */
  ${p =>
    p.addOn &&
    css`
      display: inline-grid;
      grid-template-columns: ${p.addOn === 'prefix' ? 'auto 1fr' : '1fr auto'};
      grid-column-gap: ${p.addOnGap ?? 8}px;
    `}
    /* Children */
  ${IconContainer} {
    margin-top: -0.15em;
  }

  ${HintIcon} {
    margin-top: -0.4em;
  }

  ${SwitchButtonContainer} {
    margin-left: -3px;
    background-color: ${p => (p.disabled ? schemes.grayscale['10'] : null)};
  }
`

export const SplitButtonContainer = styled.div`
  display: inline-flex;
  width: fit-content;

  ${Button} {
    &:first-child {
      border-top-right-radius: 0;
      border-bottom-right-radius: 0;
      border-right: 1px solid ${colors.strokeInput};
    }

    &:last-child {
      border-top-left-radius: 0;
      border-bottom-left-radius: 0;
    }
  }
`

export const ButtonNavLink = styled(Button)
  .withConfig({
    shouldForwardProp: (prop, defaultValidatorFn) => defaultValidatorFn(prop) || prop === 'end',
  })
  .attrs(props => ({
    as: props.disabled ? 'span' : NavLink,
  }))<ButtonProps>``

export const ButtonLink = styled(Button).attrs(props => ({
  as: props.disabled ? 'span' : 'a',
}))<ButtonProps>``
