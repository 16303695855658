import Immutable, { List } from 'immutable'

import { computeRate } from './compute'

import {
  type OrchestrationStatsByStepRecord,
  OrchestrationStepFactory,
  StepStatsFactory,
} from 'com.batch/orchestration-analytics/models/orchestration-stats-by-step.record'
import { type OrchestrationStatsDynamicDimension } from 'com.batch/shared/infra/types/grpc-stats-service'
import { parseTrends } from './parse-trends'

const coerceChannel = (channel: string): ChannelUntilCleanup | null | undefined => {
  switch (channel) {
    case 'email':
    case 'sms':
    case 'push':
      return channel
    default:
      return null
  }
}

export const parseStatsByStep = (
  stats?: Array<OrchestrationStatsDynamicDimension> | null
): OrchestrationStatsByStepRecord => {
  const stepIndex = stats ? stats[0].dimensionNames.indexOf('step') : -1
  const channelIndex = stats ? stats[0].dimensionNames.indexOf('channel') : -1

  return List(
    stats && stepIndex !== -1 && channelIndex !== -1
      ? stats.map(({ dimensionValues, currentPeriod, previousPeriod }) => {
          const sent = currentPeriod.sent ?? 0
          const delivered = currentPeriod.delivered ?? 0
          const click = currentPeriod.click ?? 0
          const uniqueClick = currentPeriod.uniqueClick ?? 0
          const bounce = currentPeriod.bounce ?? 0
          const open = currentPeriod.open ?? 0
          const uniqueOpen = currentPeriod.uniqueOpen ?? 0
          const unsubscribe = currentPeriod.unsubscribe ?? 0
          const uniqueUnsubscribe = currentPeriod.unsubscribe ?? 0

          const step = dimensionValues[stepIndex]
          const channel = coerceChannel(dimensionValues[channelIndex])

          // computed values
          const bounceRate = computeRate(bounce, sent)
          const clickRate = computeRate(uniqueClick, delivered)
          const deliveredRate = computeRate(delivered, sent)
          const unsubscribeRate = computeRate(uniqueUnsubscribe, delivered)
          const openRate = computeRate(uniqueOpen, delivered)
          const trend = parseTrends(
            {
              sent,
              delivered,
              openRate,
              clickRate,
              bounceRate,
              unsubscribeRate,
            },
            previousPeriod
          )

          return OrchestrationStepFactory({
            channel,
            step,
            stats: StepStatsFactory({
              sent: Immutable.Record({
                value: sent,
                rate: 0,
                trend: trend.sent.trend ?? null,
              })(),
              delivered: Immutable.Record({
                value: delivered,
                rate: deliveredRate,
                trend: trend.delivered.trend ?? null,
              })(),
              open: Immutable.Record({
                value: open,
                rate: openRate,
                trend: trend.open.trend ?? null,
              })(),
              click: Immutable.Record({
                value: click,
                rate: clickRate,
                trend: trend.click.trend ?? null,
              })(),
              bounce: Immutable.Record({
                value: bounce,
                rate: bounceRate,
                trend: trend.bounce.trend ?? null,
              })(),
              unsubscribe: Immutable.Record({
                value: unsubscribe,
                rate: unsubscribeRate,
                trend: trend.unsubscribe.trend ?? null,
              })(),
            }),
          })
        })
      : []
  )
}
