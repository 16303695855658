import S3Upload from 'react-s3-uploader/s3upload'

import { previewLanguageSelector } from 'com.batch/message/store/message.selector'
import { type DispatchBoundFn } from 'com.batch.redux/_records'
import { currentProjectSelector } from 'com.batch.redux/project.selector'
import { showToast } from 'com.batch.redux/toaster'

import {
  extractHtmlFromEntries,
  filterUselessEntries,
  getMimeTypeFromFilename,
  isImage,
  loadZipScript,
} from 'com.batch/email/infra/services/email-uploader.helper'
import { updateEmailContent } from 'com.batch/email/usecases/update-email-content'

export type UploadEmailAction = {
  type: 'UPLOAD_EMAIL'
  payload: {
    file: File
    messageId: string
  }
}
export const uploadEmail: (file: File, messageId: string) => DispatchBoundFn<Promise<void>> =
  (file: File, messageId) => async (dispatch, getState) => {
    const state = getState()
    const lang = previewLanguageSelector(state)
    const project = currentProjectSelector(state)

    dispatch(setIsEmailUploading(true, lang, messageId))

    try {
      if (file.type === 'text/html') {
        const rawHtml = await file.text()

        dispatch(
          updateEmailContent({
            messageId,
            lang,
            field: 'html',
            value: rawHtml,
            htmlEditorConfig: { type: 'CODE' },
            isInstant: false,
          })
        )
      } else if (file.type === 'application/zip' || file.type === 'application/x-zip-compressed') {
        // ensure zip lib is loaded
        await loadZipScript()
        // list files in zip
        const entries = await new window.zip.ZipReader(new window.zip.BlobReader(file)).getEntries()
        // ignore directories and macosx files
        const filteredEntries = filterUselessEntries(entries)
        // get index.html content
        const html = await extractHtmlFromEntries(filteredEntries)
        // get list of images used in html
        const usedImagesEntries = filteredEntries.filter(
          entry => isImage(entry.filename) && html.includes(entry.filename)
        )
        // get images blob and put them on S3
        const usedImages = await Promise.all(
          usedImagesEntries.map(async entry => {
            const blob = await entry.getData(new window.zip.BlobWriter())
            const mime = getMimeTypeFromFilename(entry.filename)
            const file = new File([blob], entry.filename, { type: mime })
            const s3Url = await new Promise(
              (resolve: (result: Promise<never>) => void, reject: (error?: any) => void) => {
                if (!project) {
                  reject(new Error('No project found'))
                  return
                }
                const s3upload = new S3Upload({
                  accept: 'image/*',
                  uploadRequestHeaders: {},
                  signingUrl: `/api/projects/${project.projectKey}/automations/email/s3config`,
                  onFinishS3Put: file => {
                    resolve(file.publicUrl)
                  },
                  onError: status => {
                    reject(status)
                  },
                })
                s3upload.uploadFile(file)
              }
            )
            return [entry.filename, s3Url]
          })
        )
        // replace images url in html
        const htmlWithS3Url = usedImages.reduce((acc, [filename, s3Url]: [any, any]) => {
          const escapedFileName = filename.replace(/[.*+?^${}()/|[\]\\]/g, '\\$&')
          return acc.replace(new RegExp('\\/?' + escapedFileName, 'gm'), s3Url)
        }, html)

        dispatch(
          updateEmailContent({
            messageId,
            lang,
            field: 'html',
            value: htmlWithS3Url,
            htmlEditorConfig: { type: 'CODE' },
            isInstant: true,
          })
        )
      }
    } catch (error: any) {
      console.log(error)
      dispatch(showToast({ title: 'Unable to handle your file', message: error.message }))
    } finally {
      dispatch(setIsEmailUploading(false, lang, messageId))
    }
  }

export type SetIsEmailUploadingAction = {
  type: 'SET_IS_EMAIL_UPLOADING'
  payload: {
    isEmailUploading: boolean
    lang: string
    messageId: string
  }
}

export const setIsEmailUploading = (
  isUploading: boolean,
  lang: string,
  messageId: string
): SetIsEmailUploadingAction => ({
  type: 'SET_IS_EMAIL_UPLOADING',
  payload: { isEmailUploading: isUploading, lang, messageId },
})
