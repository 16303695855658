import Immutable, { type Map } from 'immutable'

import { dayjs } from 'com.batch.common/dayjs.custom'
import { buildAgeFromDuration } from 'com.batch.common/utils'

import { TIMER_MAX_SECONDS, TIMER_MIN_SECONDS } from '../../models/constants'
import { generateUniqueEventId } from '../../models/journey-trigger.helper'
import {
  SplitBranchFactory,
  QuietTimesFactory,
  type QuietTimesRecord,
  type JourneyNodeRecord,
  JourneySettingsFactory,
  EventNextFactory,
  JourneyNodes,
  EventWithOptionalQueryFactory,
  WaitUntilTimeFactory,
} from '../../models/journey.records'
import {
  type TimerBlock,
  type Blocks,
  type OrchestrationJourney,
  isTimer,
  isMessage,
  type Decorator,
  isYesNo,
  isRandom,
  isQuietDecorator,
} from '../types/orchestration-journey.types'
import { AgeFactory, type AgeRecord } from 'com.batch.redux/_records'
import { buildLangAndRegionFromRowTargeting } from 'com.batch.redux/target/target.helper'
import { type TargetStateRecord, TargetStateFactory } from 'com.batch.redux/target/target.records'
import { type UserRecord } from 'com.batch.redux/user.records'

import {
  parseMessages,
  type messageParserConfig,
} from 'com.batch/message/infra/parses/messages.parse'
import { MessageConfigFactory, PushSettingsFactory } from 'com.batch/message/models/message.records'
import {
  OrchestrationHistoryFactory,
  type OrchestrationJourneyParserResult,
} from 'com.batch/orchestration/infra/parses/orchestration.parse'
import {
  type OrchestrationHistory,
  type DashboardConfig,
  type OrchestrationChannels,
} from 'com.batch/orchestration/infra/types/orchestration.types'
import { type OrchestrationState } from 'constants/common/orchestration-state'

type queryToParse = {
  queryId: string
  query: string
  eventId: string
  retries: number
}
type OrchestrationJourneyParser = (arg1: {
  journey: OrchestrationJourney
  config: DashboardConfig
  user: UserRecord
  state: OrchestrationState
  channels: OrchestrationChannels | null | undefined
  id: string
  name: string
  labelCodes: Array<string>
  orchestrationSource: 'DASHBOARD' | 'API'
  history?: OrchestrationHistory
}) => OrchestrationJourneyParserResult

const ensureEventNameIsPrefixed = (name?: string): string => {
  if (!name) return ''
  if (name.startsWith('e.')) {
    return name
  }
  return `e.${name}`
}

const calculateTimerMode = (raw: TimerBlock) => {
  if (raw.timer.timerReference) {
    return raw.timer?.timer?.slice(0, 1) === '-' ? 'before' : 'after'
  }
  if (raw.timer.timer) {
    return 'for'
  }
  if (raw.timer.waitUntilTime) {
    return 'until'
  }
}

const buildTimer = (timer: string, timerReferenceAttribute?: string | null): AgeRecord => {
  const durationString = timer
  const [dropNano] = durationString.split('.')
  const ensureUnitIsPresent =
    dropNano.includes('s') || dropNano.includes('h') || dropNano.includes('d')
      ? dropNano
      : `${dropNano}s`
  const finalDuration = ensureUnitIsPresent.replace('-', '')
  const timerAgeRecord = buildAgeFromDuration(finalDuration, ['m', 'h', 'd'])
  return timerAgeRecord.set(
    'valid',
    timerAgeRecord.seconds >= (timerReferenceAttribute ? 0 : TIMER_MIN_SECONDS) &&
      timerAgeRecord.seconds <= TIMER_MAX_SECONDS
  )
}

export const parseBlock = (
  raw: Blocks,
  parserConfig: {
    [key: string]: messageParserConfig
  }
): JourneyNodeRecord => {
  if (isTimer(raw)) {
    // TIMER
    /* 
      we append .000000001s to timer so it can work with negative duration on the backend
      not needed here so we drop it before parsing
    */
    return JourneyNodes.Timer({
      id: raw.name,
      timer: raw.timer.timer
        ? buildTimer(raw.timer.timer, raw.timer.timerReference?.timerReferenceAttribute)
        : AgeFactory({
            inputValue: '1',
            seconds: 3600,
            valid: true,
            unit: 'h',
            label: 'hour',
            fullText: '1 hour',
          }),
      waitUntilTime: raw.timer.waitUntilTime
        ? WaitUntilTimeFactory({
            minute: raw.timer.waitUntilTime.min,
            hour: raw.timer.waitUntilTime.hour,
            daysOfWeek: Immutable.Set<number>(
              raw.timer.waitUntilTime?.daysOfWeek
                ? raw.timer.waitUntilTime.daysOfWeek
                : [0, 1, 2, 3, 4, 5, 6]
            ),
          })
        : WaitUntilTimeFactory(),
      mode: calculateTimerMode(raw),
      timerReference: raw.timer.timerReference?.timerReferenceAttribute,
      onEvents: Immutable.List(
        !Array.isArray(raw.timer.onEvents)
          ? []
          : raw.timer.onEvents.map(({ triggers, nextNodeId }) => {
              return EventNextFactory({
                triggers: Immutable.List(
                  triggers.map(evt => {
                    const query = evt.event?.jsonQuery ?? ''
                    return EventWithOptionalQueryFactory({
                      name: ensureEventNameIsPrefixed(evt.event.event),
                      query,
                      eventId: generateUniqueEventId(raw.name),
                      requireMatchingInstanceId: evt.requireMatchingInstanceId,
                    })
                  })
                ),
                nextNodeId: nextNodeId,
              })
            })
      ),
      nextNodeId: raw.timer.nextNodeId,
    })
  } else if (isMessage(raw)) {
    // MESSAGE
    const config = parserConfig[raw.message.messageReference]

    const isFullyEmpty = (!config || config.contentFullyEmpty) && !raw.label

    return JourneyNodes.Message({
      id: raw.name,
      label: raw.label,
      messageConfig: MessageConfigFactory({
        messageTypedId: raw.message.messageReference,
        channel: config?.channel ?? 'email',
        multilanguageEnabled: config?.multilanguageEnabled ?? false,
        pushSettings: config?.pushSettings ?? PushSettingsFactory(),
        platforms: Immutable.Set(config?.platforms),
      }),
      nextNodeId: raw.message.nextNodeId,
      hasQuietTimes: Boolean(raw.decorators?.find((d: Decorator) => isQuietDecorator(d))),
      errors: Immutable.Set(
        !isFullyEmpty && (!config || config.contentInvalid) ? ['INCOMPLETE_MESSAGE'] : []
      ),
    })
  } else if (isYesNo(raw)) {
    // YESNO
    return JourneyNodes.YesNo({
      id: raw.name,
      label: raw.label,
      yesNodeId: raw.yesNo.yesNodeId,
      noNodeId: raw.yesNo.noNodeId,
    })
  } else if (isRandom(raw)) {
    // YESNO
    return JourneyNodes.Random({
      id: raw.name,
      label: raw.label,
      splits: Immutable.List(
        raw.random.splits.map(rs =>
          SplitBranchFactory({
            weight: rs.weight,
            nextNodeId: rs.nextNodeId,
          })
        )
      ),
    })
  }
  return JourneyNodes.Final({
    id: raw.name,
  })
}

const extractQueries: (nodes: Map<string, JourneyNodeRecord>) => Array<queryToParse> = nodes => {
  const queries: Array<queryToParse> = []
  nodes.forEach(node => {
    switch (node.type) {
      case 'FINAL':
      case 'MESSAGE':
        break
      case 'TIMER':
        node.onEvents.forEach(({ triggers }) => {
          triggers.forEach(({ name, query, eventId }) => {
            if (query) {
              queries.push({
                queryId: eventId,
                query: query,
                eventId: name,
                retries: 0,
              })
            }
          })
        })
    }
  })
  return queries
}

export const parseOrchestrationJourney: OrchestrationJourneyParser = ({
  id,
  state,
  user,
  channels,
  name,
  journey,
  config,
  labelCodes,
  orchestrationSource,
  history,
}) => {
  const queries: Array<{
    eventId: string
    query: string
    queryId: string
    retries: number
  }> = []
  const settings = journey.settings

  if (settings.targeting?.jsonQuery)
    queries.push({
      queryId: 'targeting',
      query: typeof settings.targeting.jsonQuery === 'string' ? settings.targeting.jsonQuery : '',
      eventId: '',
      retries: 0,
    })

  /*
    subscription status is handled on a per message basis in the backend
    and as a global setting on target in the dashboard. we retrieve the value
  */
  const messages = journey.messages
  const { subscriptionStatus, message, messageConfigs } = parseMessages({ messages, config })
  const rootId = journey.rootId
  let nodes = Immutable.Map(
    Object.keys(journey.nodes).map(key => [key, parseBlock(journey.nodes[key], messageConfigs)])
  )

  let quietTimes: QuietTimesRecord | null | undefined = null

  // extract queries and targetings from yesno blocks
  const yesNoTargets: Array<[string, TargetStateRecord]> = []
  const yesNoQueries: Array<queryToParse> = []
  Object.keys(journey.nodes).forEach(nodeId => {
    const node = journey.nodes[nodeId]
    if (isYesNo(node)) {
      let hasQuery = false
      if (node.yesNo.branchingCriteria.jsonQuery) {
        yesNoQueries.push({
          queryId: nodeId,
          query: node.yesNo.branchingCriteria.jsonQuery,
          eventId: '',
          retries: 0,
        })
        hasQuery = true
      }

      const { languages, regions, languagesInverted, regionsInverted } =
        buildLangAndRegionFromRowTargeting({
          targeting: {
            languages: node.yesNo.branchingCriteria.languages ?? [],
            regions: node.yesNo.branchingCriteria.regions ?? [],
          },
          user,
        })
      yesNoTargets.push([
        nodeId,
        TargetStateFactory({
          languages,
          languagesInverted,
          regions,
          regionsInverted,
        }),
      ])
      if (!hasQuery && !languages.size && !regions.size) {
        const nodeRecord = nodes.get(nodeId)
        if (nodeRecord && nodeRecord.type === 'YESNO') {
          nodes = nodes.set(nodeId, nodeRecord.set('errors', Immutable.Set(['MISSING_TARGETING'])))
        }
      }
    }
  })
  nodes
    .filter(node => node.type === 'MESSAGE')
    .forEach(messageNode => {
      const rawProtoNode = journey.nodes[messageNode.id]
      rawProtoNode.decorators
        ?.filter((deco: Decorator) => {
          return isQuietDecorator(deco)
        })
        .forEach(quietDecoratorProto => {
          // Check valuestype of decorator (i'll do later (gator) mister)
          if (quietDecoratorProto.quiet) {
            quietTimes = QuietTimesFactory({
              behavior:
                quietDecoratorProto.quiet?.behaviour === 'SKIP'
                  ? 'skip'
                  : !quietDecoratorProto.quiet?.behaviour
                    ? 'wait'
                    : 'wait',
              startHour: quietDecoratorProto.quiet?.startHour,
              endHour: quietDecoratorProto.quiet?.endHour,
              startMin: quietDecoratorProto.quiet?.startMin
                ? quietDecoratorProto.quiet?.startMin
                : 0,
              endMin: quietDecoratorProto.quiet?.endMin ? quietDecoratorProto.quiet?.endMin : 0,
              quietDaysOfWeek: Immutable.Set<number>(
                quietDecoratorProto.quiet?.quietDaysOfWeek ?? []
              ),
              quietHoursTimePeriodDisabled:
                quietDecoratorProto.quiet?.quietHoursTimePeriodDisabled ?? false,
            })
          }
        })
    })
  // when quiet times is disabled, activate the toggle for all messages nodes
  if (!quietTimes) {
    nodes = nodes.map(node => (node.type === 'MESSAGE' ? node.set('hasQuietTimes', true) : node))
  }
  const { languages, regions, languagesInverted, regionsInverted } =
    buildLangAndRegionFromRowTargeting({
      targeting: {
        languages: settings.targeting?.languages ?? [],
        regions: settings.targeting?.regions ?? [],
      },
      user,
    })

  let channelsEnforcedType = Immutable.Set<ChannelUntilCleanup>()
  if (channels?.includes('EMAIL')) channelsEnforcedType = channelsEnforcedType.add('email')
  if (channels?.includes('PUSH')) channelsEnforcedType = channelsEnforcedType.add('push')
  if (channels?.includes('SMS')) channelsEnforcedType = channelsEnforcedType.add('sms')

  return {
    settings: JourneySettingsFactory({
      hasInstanceId: Boolean(settings.instanceId),
      instanceId: !settings.instanceId
        ? ''
        : settings.instanceId?.type === 'LABEL'
          ? 'eventLabel()'
          : `eventAttr(attr: '${settings.instanceId.instanceIdFieldName}')`,
      hasStart: Boolean(settings?.startTime),
      start: settings?.startTime ? dayjs.utc(settings?.startTime ?? '', 'YYYY/MM/DD HH:mm') : null,
      hasEnd: Boolean(settings?.endTime),
      end: settings?.endTime ? dayjs.utc(settings?.endTime ?? '', 'YYYY/MM/DD HH:mm') : null,
      hasCapping: Boolean(settings.globalCapping),
      capping: settings.globalCapping,
      hasGrace: Boolean(settings.globalGracePeriod),
      gracePeriod: settings.globalGracePeriod
        ? buildAgeFromDuration(settings.globalGracePeriod, ['h', 'd'])
        : AgeFactory(),
      entryEvents: Immutable.List(settings.entryEvents ?? []).map((evt, key) => {
        // for now we only suppport one enter event
        if (typeof evt.jsonQuery === 'string' && key === 0) {
          queries.push({
            queryId: 'enterEvent',
            query: evt.jsonQuery,
            eventId: ensureEventNameIsPrefixed(evt.event),
            retries: 0,
          })
        }
        return EventWithOptionalQueryFactory({
          name: ensureEventNameIsPrefixed(evt.event),
          query: evt.jsonQuery ?? '',
          requireMatchingInstanceId: true,
        })
      }),
      hasQuietTimes: Boolean(quietTimes),
      quietTimes: quietTimes ? quietTimes : QuietTimesFactory(),
    }),
    message,
    queries: [...queries, ...extractQueries(nodes), ...yesNoQueries],
    targets: Immutable.Map([
      [
        'default',
        TargetStateFactory({
          languages,
          languagesInverted,
          regions,
          regionsInverted,
          subscriptionStatus,
        }),
      ],
      ...yesNoTargets,
    ]),
    incomplete: config?.incomplete ?? false,
    rootId,
    nodes,
    state,
    channels: channelsEnforcedType,
    id,
    name,
    labelCodes: Immutable.Set(labelCodes),
    createdByApi: orchestrationSource !== 'DASHBOARD',
    history: history
      ? OrchestrationHistoryFactory({
          creationDate: history.creationDate
            ? dayjs.utc(history.creationDate, 'YYYY/MM/DD HH:mm')
            : null,
          lastUpdateDate: history.lastUpdateDate
            ? dayjs.utc(history.lastUpdateDate, 'YYYY/MM/DD HH:mm')
            : null,
          lastEditDate: history.lastEditDate
            ? dayjs.utc(history.lastEditDate, 'YYYY/MM/DD HH:mm')
            : null,
          lastStatusChangeDate: history.lastStatusChangeDate
            ? dayjs.utc(history.lastStatusChangeDate, 'YYYY/MM/DD HH:mm')
            : null,
          firstRunDate: history.firstRunDate
            ? dayjs.utc(history.firstRunDate, 'YYYY/MM/DD HH:mm')
            : null,
        })
      : OrchestrationHistoryFactory(),
  }
}
