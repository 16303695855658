import Immutable, { type Map, type RecordOf } from 'immutable'
import { type TemplatingRecord } from '../models/templating.records'

type TemplatingStateProps = {
  entities: Map<string, TemplatingRecord>
  previewSourceProfileId: string | null
  previewSourceValue: string
}

export const TemplatingStateFactory = Immutable.Record<TemplatingStateProps>({
  entities: Immutable.Map(),
  previewSourceProfileId: null,
  previewSourceValue: '',
})

export type TemplatingStateRecord = RecordOf<TemplatingStateProps>
