import * as React from 'react'
import { Helmet } from 'react-helmet-async'
import { useSelector, useDispatch } from 'com.batch.common/react-redux'

import { useCurrentCompanyHasFeature, useToggle } from 'components/_hooks'
import { useDownloadSilently } from 'components/_hooks/use-download-silently'
import { CampaignBox, CampaignContent } from 'components/campaign/campaign-list.styles'
import { BoxBody } from 'components/common/box'
import { Button } from 'components/common/button'
import { Wrapper, PushEmptyIcon } from 'components/common/empty-states'
import { Pager } from 'components/common/pager'
import { Icon } from 'components/common/svg-icon'
import {
  Table,
  TableCellOrder,
  TableBody,
  TableFooter,
  TableHeader,
  TableCellHeader,
  TableTemplateCell,
} from 'components/common/table'
import { Tooltip } from 'com.batch/shared/ui/component/tooltip'

import { generateOrchestrationListUrl } from 'com.batch.common/router/url-generator-helper'
import { capitalize, singular } from 'com.batch.common/utils'

import {
  updateFilterDateRangeAction,
  fetchOrchestrationsList,
  setPage,
  updateSearch,
  updateSort,
  updateFilterStates,
  updateFilterLabels,
  updateFilterChannels,
  updateFilterSegments,
} from '../../usecases/fetch-orchestrations-list'
import {
  ReplicationModal,
  type ReplicationOrchestrationMeta,
} from '../components/replication-modal'
import { labelsSelector } from 'com.batch/labels/store/labels.selector'
import {
  pagedPartialCampaignsSelector,
  campaignsDataSelector,
  campaignListMetaSelector,
  campaignsFetchingStateSelector,
} from 'com.batch/orchestration-list/store/orchestration-list.selector'
import { type OrchestrationListSortableBy } from 'com.batch/orchestration-list/store/orchestration-list.state'
import { currentProjectSelector } from 'com.batch.redux/project.selector'

import { fetchLabelsAndCappingRules } from 'com.batch/labels/usecases/fetch-labels-and-capping-rules'
import { fetchOrchestrationDynamicStats } from 'com.batch/orchestration-analytics/usecases/fetch-dynamic-stats'
import { OrchestrationListStatsStateFactory } from 'com.batch/orchestration-list/models/orchestration-list-stats-state.records'
import { Header } from 'com.batch/orchestration-list/ui/components/header'
import { Row } from 'com.batch/orchestration-list/ui/components/row'
import { extractUrlParamsAndRestoreSavedSort } from 'com.batch/orchestration-list/ui/helpers/extract-url-params'
import { useGetSchedulingTypeFromUrl } from 'com.batch/orchestration-list/ui/hooks/use-scheduling-type-from-url'
import { NoResultWrapper } from 'com.batch/shared/ui/component/no-result-wrapper'
import { getOrchestrationsExportFileUrl } from 'com.batch/shared/usecases/get-orchestrations-export-file-url'
import { statConfigs } from 'com.batch/orchestration-analytics/usecases/fetch-dynamic-stats.helper'
import {
  RetargetingModal,
  type RetargetingOrchestrationMeta,
} from '../components/retargeting-modal'
import { OrchestrationAnalyticsDateRangeFactory } from 'com.batch/orchestration-analytics/store/orchestration-analytics.state'
import { LoadingStatus } from 'constants/common'

export const CampaignListEmptyTemplate = ({
  multipleChannelsSupported,
}: {
  multipleChannelsSupported: boolean
}): React.ReactElement => {
  return multipleChannelsSupported ? (
    <React.Fragment>
      <TableTemplateCell template="50px" />
      <TableTemplateCell template="1fr" />
      <TableTemplateCell template="1fr" />
      <TableTemplateCell template="110px" align="end" />
      <TableTemplateCell template="100px" align="end" />
      <TableTemplateCell template="111px" align="end" />
    </React.Fragment>
  ) : (
    <React.Fragment>
      <TableTemplateCell template="50px" />
      <TableTemplateCell template="1fr" />
      <TableTemplateCell template="170px" />
      <TableTemplateCell template="240px" align="end" />
      <TableTemplateCell template="100px" align="end" />
      <TableTemplateCell template="111px" align="end" />
    </React.Fragment>
  )
}

export const OrchestrationList = React.memo((): React.ReactElement => {
  // ----- router state -----

  const schedulingTypeFromUrl = useGetSchedulingTypeFromUrl()
  const emailCampaignActivated = useCurrentCompanyHasFeature('email-campaign')
  const emailRecurringActivated = useCurrentCompanyHasFeature('email-recurring')
  const pushCampaignActivated = useCurrentCompanyHasFeature('cep-push-campaign')
  const pushRecurringActivated = useCurrentCompanyHasFeature('cep-push-recurring')
  const smsCampaignActivated = useCurrentCompanyHasFeature('cep-sms-campaign')
  const smsRecurringActivated = useCurrentCompanyHasFeature('cep-sms-recurring')

  // ----- local state -----
  const loadingExportState = useToggle()
  const [replicatingOrchestrationMeta, setReplicatingOrchestrationMeta] = React.useState<
    ReplicationOrchestrationMeta | null | undefined
  >(null)
  const [retargetingOrchestrationMeta, setRetargetingOrchestrationMeta] = React.useState<
    RetargetingOrchestrationMeta | null | undefined
  >(null)
  // ----- redux state -----
  const dispatch = useDispatch()
  const meta = useSelector(campaignListMetaSelector)
  const project = useSelector(currentProjectSelector)
  const paginatedPartialOrchestrations = useSelector(pagedPartialCampaignsSelector)
  const data = useSelector(campaignsDataSelector)
  const fetchingState = useSelector(campaignsFetchingStateSelector)
  const { loadingState: labelsLoading } = useSelector(labelsSelector)
  const download = useDownloadSilently()

  // ----- derived state -----
  const multipleChannelsSupported = React.useMemo(
    () => schedulingTypeFromUrl === 'automations',
    [schedulingTypeFromUrl]
  )

  const downloadExport = React.useCallback(async () => {
    loadingExportState.open()

    try {
      const url = await dispatch(getOrchestrationsExportFileUrl())
      download(url)
    } catch (e: any) {
      console.log(e)
    }

    loadingExportState.close()
  }, [dispatch, loadingExportState, download])

  // if no cep feature activated on either campaign or automation, return true
  const shouldRedirectToMepViewIfNoCepFeaturesActivated = React.useMemo(() => {
    return (
      (!emailCampaignActivated &&
        !pushCampaignActivated &&
        !smsCampaignActivated &&
        schedulingTypeFromUrl === 'campaigns') ||
      (!emailRecurringActivated &&
        !project.emailConfigured &&
        !pushRecurringActivated &&
        !smsRecurringActivated &&
        !project.pushConfigured &&
        !project.smsConfigured &&
        schedulingTypeFromUrl === 'automations')
    )
  }, [
    emailCampaignActivated,
    emailRecurringActivated,
    project,
    schedulingTypeFromUrl,
    pushCampaignActivated,
    pushRecurringActivated,
    smsCampaignActivated,
    smsRecurringActivated,
  ])

  // ----- callbacks -----
  const dismissReplicationModal = React.useCallback(() => {
    setReplicatingOrchestrationMeta(undefined)
  }, [])

  const dismissRetargetingModal = React.useCallback(() => {
    setRetargetingOrchestrationMeta(undefined)
  }, [])

  React.useEffect(() => {
    if (shouldRedirectToMepViewIfNoCepFeaturesActivated) {
      // redirection on first app if no feature  activated
      const url = generateOrchestrationListUrl({
        companyId: project.companyId,
        channel: { appId: project.appIds.first() },
        projectId: project.id,
        scheduling: schedulingTypeFromUrl,
      })
      window.location.href = url
    }
  }, [
    shouldRedirectToMepViewIfNoCepFeaturesActivated,
    project.appIds,
    project.companyId,
    project.id,
    schedulingTypeFromUrl,
  ])

  React.useEffect(() => {
    dispatch(
      fetchOrchestrationsList({
        cacheMode: 'KEEP',
      })
    ).catch(e => {
      if (!e.aborted) console.error('Error while fetching campaigns list:', e)
    })
  }, [dispatch])

  const onPageChange = React.useCallback(
    (page: number) => {
      dispatch(setPage(page))
    },
    [dispatch]
  )
  const createOnSortChange = React.useCallback(
    (column: OrchestrationListSortableBy) => () => {
      dispatch(
        updateSort({
          sortBy: column,
          sortDirection: meta.sortDirection === 'dsc' && meta.sortBy === column ? 'asc' : 'dsc',
        })
      )
    },
    [meta.sortBy, meta.sortDirection, dispatch]
  )
  // ----- effects -----
  // stats
  React.useEffect(() => {
    const ongoing = paginatedPartialOrchestrations.filter(orc =>
      ['RUNNING', 'STOPPED'].includes(orc.state)
    )
    const completed = paginatedPartialOrchestrations.filter(orc => orc.state === 'COMPLETED')
    const ongoingAndCompleted = [ongoing, completed]
    ongoingAndCompleted.forEach((orchestrations, index) => {
      const tokensNeedingData = orchestrations
        .filter(campaign => {
          const loadingState = data.get(
            campaign.token,
            OrchestrationListStatsStateFactory()
          ).loadingState
          return loadingState === LoadingStatus.INIT
        })
        .map(campaign => campaign.token)
      if (tokensNeedingData.size > 0) {
        dispatch(
          fetchOrchestrationDynamicStats({
            tokens: tokensNeedingData,
            config:
              index === 0
                ? statConfigs.orchestrationListStatsOngoing
                : statConfigs.orchestrationListStatsCompleted,
            includePreviousPeriod: schedulingTypeFromUrl === 'automations' && index === 0,
            dateRange:
              schedulingTypeFromUrl === 'automations' && index === 0
                ? OrchestrationAnalyticsDateRangeFactory()
                : null,
          })
        ).catch(error => {
          console.error('Error while fetching campaigns data:', error)
        })
      }
    })
  }, [paginatedPartialOrchestrations, dispatch, data, schedulingTypeFromUrl])

  const onPopState = React.useCallback(
    event => {
      const params = extractUrlParamsAndRestoreSavedSort(event.target.location)
      if (params.page !== meta.page) onPageChange(params.page)
      if (params.search !== meta.search) dispatch(updateSearch(params.search))
      if (params.channels !== meta.channels) dispatch(updateFilterChannels(params.channels))
      if (params.labels !== meta.labels) dispatch(updateFilterLabels(params.labels))
      if (params.statuses !== meta.statuses) dispatch(updateFilterStates(params.statuses))
      if (params.platforms !== meta.platforms) dispatch(updateFilterStates(params.statuses))
      if (params.dateRange && params.dateRange !== meta.dateRange) {
        dispatch(updateFilterDateRangeAction(params.dateRange))
      }
      if (params.segments !== meta.segments) dispatch(updateFilterSegments(params.segments))

      if (params.sortBy !== meta.sortBy || params.sortDirection !== meta.sortDirection)
        dispatch(
          updateSort({
            sortBy: params.sortBy,
            sortDirection: params.sortDirection,
          })
        )
    },
    [
      meta.page,
      meta.search,
      meta.channels,
      meta.labels,
      meta.statuses,
      meta.platforms,
      meta.dateRange,
      meta.segments,
      meta.sortBy,
      meta.sortDirection,
      onPageChange,
      dispatch,
    ]
  )
  React.useEffect(() => {
    window.addEventListener('popstate', onPopState)
    return () => {
      window.removeEventListener('popstate', onPopState)
    }
  }, [onPopState])

  React.useEffect(() => {
    if (labelsLoading !== LoadingStatus.INIT) return
    dispatch(fetchLabelsAndCappingRules()).then(
      () => {},
      () => {}
    )
  }, [dispatch, labelsLoading])

  return (
    <React.Fragment>
      {replicatingOrchestrationMeta && (
        <ReplicationModal
          isOpened={!!replicatingOrchestrationMeta}
          dismiss={dismissReplicationModal}
          orchestrationMeta={replicatingOrchestrationMeta}
        />
      )}
      {retargetingOrchestrationMeta && (
        <RetargetingModal
          isOpened={!!retargetingOrchestrationMeta}
          onClose={dismissRetargetingModal}
          orchestrationMeta={retargetingOrchestrationMeta}
        />
      )}
      <Helmet>
        <title>
          {capitalize(schedulingTypeFromUrl)} list - {project.name}
        </title>
      </Helmet>
      <Header
        loading={fetchingState === LoadingStatus.LOADING}
        count={meta.matching}
        isEmpty={meta.total === 0}
        orchestrationListMetadata={meta}
      >
        {schedulingTypeFromUrl === 'automations' ? 'Automations' : 'Campaigns'}
      </Header>
      <CampaignContent>
        <Wrapper
          style={{ width: '1134px' }}
          isLoading={fetchingState === LoadingStatus.LOADING}
          isEmpty={meta.total === 0 && fetchingState !== LoadingStatus.ERROR}
          isOverlayShown={meta.total === 0 && fetchingState === LoadingStatus.LOADED}
          overlayProps={{
            status: 'empty-page',
            title:
              'Create your first ' +
              (schedulingTypeFromUrl === 'campaigns' ? 'campaign' : 'automation'),
            content: <PushEmptyIcon />,
            description:
              schedulingTypeFromUrl === 'campaigns'
                ? "With email messages, your users are one click away from re-visiting your website.  It's the most known communication channel. "
                : 'Automations are useful to manage a wide variety of use cases. Deliver the right message at the right time.',
          }}
        >
          <CampaignBox>
            <Wrapper
              isLoading={fetchingState === LoadingStatus.LOADING}
              isEmpty={meta.total === 0 && fetchingState === LoadingStatus.LOADED}
              isOverlayShown={fetchingState === LoadingStatus.ERROR}
              overlayProps={{
                status: 'error',
                title: 'Something went wong',
                description:
                  "This is not working properly. We are working to fix the problem and we'll be up and running shortly.",
                links: [
                  {
                    name: 'Check the status page',
                    href: 'https://status.batch.com/',
                  },
                ],
              }}
            >
              <BoxBody
                style={{
                  minHeight: fetchingState === LoadingStatus.ERROR ? 440 : 'auto',
                }}
              >
                <Table
                  rowHeight={schedulingTypeFromUrl === 'automations' ? 60 : 50}
                  style={{
                    borderRadius: 7,
                    overflow: 'hidden',
                    width: '100%',
                  }}
                  template={` ${
                    multipleChannelsSupported
                      ? '50px 1fr 270px 110px 100px 111px'
                      : '50px 1fr 170px 240px 100px 111px'
                  }`}
                >
                  <TableHeader style={{ minHeight: '52px' }}>
                    <TableCellHeader style={{ overflow: 'visible' }}>
                      <TableCellOrder
                        sort={meta.sortBy === 'token' ? meta.sortDirection : false}
                        onClick={createOnSortChange('token')}
                        style={{ marginRight: 0, marginLeft: 0 }}
                      />
                    </TableCellHeader>
                    <TableCellHeader style={{ marginLeft: -8 }}>
                      <TableCellOrder
                        sort={meta.sortBy === 'name' ? meta.sortDirection : false}
                        onClick={createOnSortChange('name')}
                      >
                        Name
                      </TableCellOrder>
                    </TableCellHeader>
                    {!multipleChannelsSupported && (
                      <TableCellHeader style={{ marginLeft: -8 }}>
                        <TableCellOrder
                          sort={meta.sortBy === 'channel' ? meta.sortDirection : false}
                          onClick={createOnSortChange('channel')}
                        >
                          Channel
                        </TableCellOrder>
                      </TableCellHeader>
                    )}
                    <TableCellHeader>
                      <div style={{ marginLeft: 0 }}>
                        <TableCellOrder
                          sort={meta.sortBy === 'when' ? meta.sortDirection : false}
                          onClick={createOnSortChange('when')}
                        >
                          When
                        </TableCellOrder>
                      </div>
                    </TableCellHeader>
                    <TableCellHeader align="right">Sent</TableCellHeader>
                    {schedulingTypeFromUrl === 'automations' && (
                      <TableCellHeader align="right">Last sent</TableCellHeader>
                    )}
                    <TableCellHeader
                      style={{ overflow: 'unset' }}
                      align="right"
                      verticalAlign="center"
                    >
                      <Tooltip
                        width={270}
                        placement="top"
                        style={{ textAlign: 'center' }}
                        tooltip="By default, export covers the last 7 days, today excluded. Use filters to adjust (up
                              to 6 months)."
                      >
                        <Button
                          addOn="prefix"
                          disabled={loadingExportState.value}
                          onClick={downloadExport}
                          intent="neutral"
                          kind="secondary"
                          style={{ width: 91, marginRight: -4 }}
                        >
                          <Icon icon={loadingExportState.value ? 'spinner' : 'download'} />
                          Export
                        </Button>
                      </Tooltip>
                    </TableCellHeader>
                  </TableHeader>
                  <NoResultWrapper
                    isEmpty={
                      meta.total !== 0 &&
                      paginatedPartialOrchestrations.size === 0 &&
                      fetchingState === LoadingStatus.LOADED
                    }
                    entityName={singular(schedulingTypeFromUrl)}
                  >
                    <TableBody
                      emptyTemplate={
                        <CampaignListEmptyTemplate
                          multipleChannelsSupported={multipleChannelsSupported}
                        />
                      }
                      templateSize={
                        paginatedPartialOrchestrations.size
                          ? paginatedPartialOrchestrations.size
                          : meta.nbPerPage
                      }
                    >
                      {paginatedPartialOrchestrations.map(partial => {
                        return (
                          <Row
                            project={project}
                            multipleChannelsSupported={multipleChannelsSupported}
                            openReplicationModalFor={setReplicatingOrchestrationMeta}
                            openRetargetingModalFor={setRetargetingOrchestrationMeta}
                            schedulingType={schedulingTypeFromUrl}
                            partialOrchestration={partial}
                            key={partial.token}
                            data={data.get(partial.token, OrchestrationListStatsStateFactory())}
                          />
                        )
                      })}
                    </TableBody>
                    {meta.matching > 10 && (
                      <TableFooter>
                        <Pager
                          nbPerPage={meta.nbPerPage}
                          total={meta.matching}
                          page={meta.page}
                          selectPage={onPageChange}
                        />
                      </TableFooter>
                    )}
                  </NoResultWrapper>
                </Table>
              </BoxBody>
            </Wrapper>
          </CampaignBox>
        </Wrapper>
      </CampaignContent>
    </React.Fragment>
  )
})
