import Immutable from 'immutable'

import { MessageStateFactory, type MessageStateRecord } from './message.state'

import {
  EmailContentFactory,
  EmailMessageFactory,
  PushContentFactory,
  PushMessageFactory,
  SmsContentFactory,
  SmsMessageFactory,
} from '../models/message.records'
import { type DuplicateMessageAction } from '../usecases/duplicate-message'
import {
  type AddLanguageAction,
  type ClearLanguagesAction,
  type RemoveLanguageAction,
  type SetActiveLanguageAction,
} from '../usecases/multilanguage'
import { type RemoveEmailContentAction } from '../usecases/remove-email-content'
import { type FetchSenderIdentitiesSuccessAction } from 'com.batch.redux/corelogic/usecases/sender-identity/fetch-sender-identities'
import { removeDeletedSenderIdentitiesFromEmailContent } from 'com.batch.redux/corelogic/usecases/sender-identity/remove-deleted-sender-identities-from-email-content'

import {
  type EmptyAllEmailSendersAction,
  type saveDragDropHtmlActions,
  type saveDragDropOptimizedTemplateActions,
  type UpdateEmailInfoAction,
  type UpdateEmailSenderAction,
} from 'com.batch/email/usecases/update-content'
import {
  type UpdateEmailRecordAction,
  type UpdateEmailContentAction,
} from 'com.batch/email/usecases/update-email-content'
import { type SetIsEmailUploadingAction } from 'com.batch/email/usecases/upload-email'
import { type UpdateAllContentAction } from 'com.batch/message/usecases/update-all-content'
import { type InitFormAction } from 'com.batch/orchestration/usecases/init-form'
import { type InsertNodeAfterAction } from 'com.batch/orchestration-journey/usecases/insert-node-after'
import { type SetEditingNodeIdAction } from 'com.batch/orchestration-journey/usecases/set-editing-node-id'
import {
  type UpdatePushMessageContentAction,
  type UpdatePushMessageRecordAction,
} from 'com.batch/push/usecases/update-push-content'
import { type UpdateSmsContentAction } from 'com.batch/sms/usecases/update-sms-content'
import { LoadingStatus } from 'constants/common'
import { type PushSettingsUpdatedForMessageIdAction } from 'com.batch/push/usecases/update-push-settings'
import {
  messageUpdated,
  updateStateEmailContentForMessageIdAndLang,
  updateStatePushContentForMessageIdAndLang,
  updateStateSmsContentForMessageIdAndLang,
} from './message.reducer.helper'
import { type updateTargetKindAction } from 'com.batch.redux/target/target'
type MessageActions =
  | UpdateEmailContentAction
  | UpdateEmailInfoAction
  | UpdateSmsContentAction
  | UpdateEmailSenderAction
  | EmptyAllEmailSendersAction
  | UpdateAllContentAction
  | InitFormAction
  | SetIsEmailUploadingAction
  | saveDragDropHtmlActions
  | saveDragDropOptimizedTemplateActions
  | FetchSenderIdentitiesSuccessAction
  | RemoveEmailContentAction
  | UpdatePushMessageRecordAction
  | UpdatePushMessageContentAction
  | AddLanguageAction
  | RemoveLanguageAction
  | ClearLanguagesAction
  | SetActiveLanguageAction
  | InsertNodeAfterAction
  | SetEditingNodeIdAction
  | UpdateEmailRecordAction
  | DuplicateMessageAction
  | updateTargetKindAction
  | PushSettingsUpdatedForMessageIdAction
export const messageReducer = (
  state: MessageStateRecord = MessageStateFactory(),
  action: MessageActions
): MessageStateRecord => {
  switch (action.type) {
    case 'TARGET_KIND': {
      /*
        We need to invalidate all messages, to be sure we generate new ids so backend
        picks up and adapt the SMS content (toggles STOP part)

        Email has the same issue, but it should be handled by the need to re-affect senders.
        I keep the check just in case.
      */
      let updatedMessageIds = state.updatedMessageIds
      state.email.forEach((_, messageId) => {
        updatedMessageIds = updatedMessageIds.add(messageId)
      })
      state.sms.forEach((_, messageId) => {
        updatedMessageIds = updatedMessageIds.add(messageId)
      })
      return state.set('updatedMessageIds', updatedMessageIds)
    }
    case 'PUSH_SETTING_UPDATED_FOR_MESSAGE_ID':
      return messageUpdated(action.payload, state)
    case 'DUPLICATE_MESSAGE': {
      if (state.push.has(action.payload.sourceTypedMessageId)) {
        return state.set(
          'push',
          state.push.set(
            action.payload.targetTypedMessageId,
            state.push.get(action.payload.sourceTypedMessageId, PushMessageFactory())
          )
        )
      }
      if (state.email.has(action.payload.sourceTypedMessageId)) {
        return state.set(
          'email',
          state.email.set(
            action.payload.targetTypedMessageId,
            state.email.get(action.payload.sourceTypedMessageId, EmailMessageFactory())
          )
        )
      }
      if (state.sms.has(action.payload.sourceTypedMessageId)) {
        return state.set(
          'sms',
          state.sms.set(
            action.payload.targetTypedMessageId,
            state.sms.get(action.payload.sourceTypedMessageId, SmsMessageFactory())
          )
        )
      }
      return state
    }
    case 'INIT_FORM': {
      switch (action.payload.channel) {
        case 'email':
          return MessageStateFactory({
            email: Immutable.Map({
              [action.payload.messageTypedId]: EmailMessageFactory({
                localizedContent: Immutable.OrderedMap({ default: EmailContentFactory() }),
              }),
            }),
          })
        case 'sms':
          return MessageStateFactory({
            sms: Immutable.Map({
              [action.payload.messageTypedId]: SmsMessageFactory({
                localizedContent: Immutable.OrderedMap({ default: SmsContentFactory() }),
              }),
            }),
          })
        case 'push':
        default:
          return MessageStateFactory({
            push: Immutable.Map({
              [action.payload.messageTypedId]: PushMessageFactory({
                localizedContent: Immutable.OrderedMap({ default: PushContentFactory() }),
              }),
            }),
          })
      }
    }
    case 'ADD_LANGUAGE': {
      const newState = state.set('previewLanguage', action.payload.lang)
      switch (action.payload.channel) {
        case 'push': {
          const pushStateForMessageId = newState.push.get(
            action.payload.messageId,
            PushMessageFactory()
          )
          const defaultPushMessage = pushStateForMessageId.localizedContent.get(
            'default',
            PushContentFactory()
          )

          const { attachmentKind, pushPicture, pushIcon } = defaultPushMessage

          const localizedContentForLangWithDefaultImages =
            pushStateForMessageId.localizedContent.get(
              action.payload.lang,
              PushContentFactory({
                attachmentKind,
                pushPicture,
                pushIcon,
              })
            )

          return messageUpdated(
            action.payload.messageId,
            newState.set(
              'push',
              newState.push.set(
                action.payload.messageId,
                pushStateForMessageId.set(
                  'localizedContent',
                  pushStateForMessageId.localizedContent.set(
                    action.payload.lang,
                    localizedContentForLangWithDefaultImages
                  )
                )
              )
            )
          )
        }
        case 'email': {
          const emailStateForMessageId = state.email.get(
            action.payload.messageId,
            EmailMessageFactory()
          )
          const defaultEmailMessage = emailStateForMessageId.localizedContent.get(
            'default',
            EmailContentFactory()
          )
          return messageUpdated(
            action.payload.messageId,
            state.set(
              'email',
              state.email.set(
                action.payload.messageId,
                emailStateForMessageId.set(
                  'localizedContent',
                  emailStateForMessageId.localizedContent.set(
                    action.payload.lang,
                    defaultEmailMessage
                  )
                )
              )
            )
          )
        }
        case 'sms': {
          const smsMessageForMessageId = newState.sms.get(
            action.payload.messageId,
            SmsMessageFactory()
          )
          return messageUpdated(
            action.payload.messageId,
            newState.set(
              'sms',
              newState.sms.set(
                action.payload.messageId,
                smsMessageForMessageId.set(
                  'localizedContent',
                  smsMessageForMessageId.localizedContent.set(
                    action.payload.lang,
                    SmsContentFactory()
                  )
                )
              )
            )
          )
        }
        default:
          return state
      }
    }
    case 'REMOVE_LANGUAGE': {
      const path = [action.payload.messageId, 'localizedContent', action.payload.lang]
      switch (action.payload.channel) {
        case 'push':
          return messageUpdated(
            action.payload.messageId,
            state.set('previewLanguage', 'default').deleteIn(['push', ...path])
          )
        case 'email':
          return messageUpdated(
            action.payload.messageId,
            state.set('previewLanguage', 'default').deleteIn(['email', ...path])
          )
        case 'sms':
          return messageUpdated(
            action.payload.messageId,
            state.set('previewLanguage', 'default').deleteIn(['sms', ...path])
          )
        default:
          return state
      }
    }
    case 'SET_ACTIVE_LANGUAGE':
      return state.set('previewLanguage', action.payload)
    case 'UPDATE_ALL_CONTENT':
      return state
        .set('email', action.payload.email)
        .set('sms', action.payload.sms)
        .set('push', action.payload.push)
    case 'UPDATE_SMS_CONTENT':
      return updateStateSmsContentForMessageIdAndLang({
        state,
        lang: action.payload.lang,
        messageId: action.payload.messageId,
        smsContentUpdater: smsContent => smsContent.set(action.payload.field, action.payload.value),
      })
    case 'UPDATE_EMAIL_SENDER': {
      return updateStateEmailContentForMessageIdAndLang({
        state,
        lang: action.payload.lang,
        messageId: action.payload.messageId,
        emailContentUpdater: localizedEmailContent =>
          localizedEmailContent
            .set('senderIdentityId', action.payload.senderIdentityId)
            .set('fromEmail', action.payload.fromEmail)
            .set('name', action.payload.name)
            .set('isPristine', false),
      })
    }
    case 'FETCH_SENDER_IDENTITIES_SUCCESS': {
      /*
        This is only usefull when we fetch orchestration BEFORE sender ids resolve.
        it's not perfect : we won't show toast or update incomplete flags on triggers nodes, but
        deleted sender ids is not really an issue and the XHR call seems to always resolve before
      */

      const [updated, email] = removeDeletedSenderIdentitiesFromEmailContent(
        state.email,
        action.payload
      )
      if (updated.length === 0) return state
      return state.set('email', email)
    }
    case 'UPDATE_EMAIL_INFO': {
      return updateStateEmailContentForMessageIdAndLang({
        state,
        lang: action.payload.lang,
        messageId: action.payload.messageId,
        emailContentUpdater: localizedEmailContent =>
          localizedEmailContent
            .set(action.payload.field, action.payload.value)
            .set('isPristine', false),
      })
    }
    case 'UPDATE_EMAIL_CONTENT': {
      return updateStateEmailContentForMessageIdAndLang({
        state,
        lang: action.payload.lang,
        messageId: action.payload.messageId,
        emailContentUpdater: localizedEmailContent => {
          let updatedContent = localizedEmailContent
            .set(action.payload.field, action.payload.value)
            .set('isPristine', false)

          if (action.payload.htmlEditorConfig || action.payload.htmlEditorConfig === null) {
            updatedContent = updatedContent.set('htmlEditorConfig', action.payload.htmlEditorConfig)
          }
          return updatedContent
        },
      })
    }
    case 'SET_IS_EMAIL_UPLOADING': {
      return updateStateEmailContentForMessageIdAndLang({
        state,
        lang: action.payload.lang,
        messageId: action.payload.messageId,
        emailContentUpdater: localizedEmailContent =>
          localizedEmailContent.set('isEmailUploading', action.payload.isEmailUploading),
      })
    }
    case 'UPDATE_DRAG_DROP_OPTIMIZED_HTML_CONTENT':
    case 'UPDATE_DRAG_DROP_HTML_CONTENT': {
      return updateStateEmailContentForMessageIdAndLang({
        state,
        lang: action.payload.lang,
        messageId: action.payload.messageId,
        emailContentUpdater: localizedEmailContent =>
          localizedEmailContent.set('loadingState', LoadingStatus.LOADING),
      })
    }
    case 'UPDATE_DRAG_DROP_OPTIMIZED_HTML_CONTENT_SUCCESS':
    case 'UPDATE_DRAG_DROP_HTML_CONTENT_SUCCESS': {
      return updateStateEmailContentForMessageIdAndLang({
        state,
        lang: action.payload.lang,
        messageId: action.payload.messageId,
        emailContentUpdater: localizedEmailContent =>
          localizedEmailContent.set('loadingState', LoadingStatus.LOADED),
      })
    }
    case 'UPDATE_DRAG_DROP_OPTIMIZED_HTML_CONTENT_FAILURE':
    case 'UPDATE_DRAG_DROP_HTML_CONTENT_FAILURE': {
      return updateStateEmailContentForMessageIdAndLang({
        state,
        lang: action.payload.lang,
        messageId: action.payload.messageId,
        emailContentUpdater: localizedEmailContent =>
          localizedEmailContent.set('loadingState', LoadingStatus.ERROR),
      })
    }
    case 'REMOVE_EMAIL_CONTENT':
      return state.set('email', state.email.delete(action.payload))
    case 'EMPTY_ALL_EMAIL_SENDERS':
      return state.set(
        'email',
        state.email.map(message => {
          const cleanedContent = message.localizedContent.map(langContent =>
            langContent.merge({ fromEmail: null, senderIdentityId: undefined, name: null })
          )
          return message.set('localizedContent', cleanedContent)
        })
      )
    case 'UPDATE_PUSH_MESSAGE_CONTENT':
      return updateStatePushContentForMessageIdAndLang({
        state,
        lang: action.payload.lang,
        messageId: action.payload.messageId,
        pushContentUpdater: pushContent => {
          let updatedPushContent = pushContent
          const { field, value } = action.payload
          updatedPushContent = updatedPushContent.set(field, value)
          return updatedPushContent
        },
      })

    case 'UPDATE_PUSH_MESSAGE_RECORD':
      return action.payload.lang && action.payload.content
        ? updateStatePushContentForMessageIdAndLang({
            state,
            lang: action.payload.lang,
            messageId: action.payload.messageId,
            pushContentUpdater: () => action.payload.content ?? PushContentFactory(),
          })
        : state
    case 'UPDATE_EMAIL_RECORD':
      return messageUpdated(
        action.payload.messageId,
        state.set('email', state.email.set(action.payload.messageId, action.payload.email))
      )
    case 'SET_EDITING_NODE_ID':
    case 'INSERT_NODE_AFTER': {
      if (state.previewLanguage !== 'default') return state.set('previewLanguage', 'default')
      return state
    }
    default:
      return state
  }
}
