import * as React from 'react'

import { BoxBody, BoxHeader, BoxTitle } from 'components/common/box'
import { Skeleton } from 'components/common/empty-states'
import { HorizontalDivider } from 'components/styled/utils'

import { kformat } from 'com.batch.common/utils'

import { convertMarketingSuscriptionPieChartData } from 'com.batch/profilebase/infra/formats/convert-marketing-subscription-pie-chart-data'
import { formatChannelName } from 'com.batch/profilebase/infra/formats/format-channel-name'
import {
  type AnalyticsChannel,
  type channelThemeType,
} from 'com.batch/profilebase/infra/types/channel-type'
import { type SubDistributionRecord } from 'com.batch/profilebase/models/sub-distribution.records'
import {
  MarketingSubscriptionBox,
  MetricsColumn,
} from 'com.batch/profilebase/ui/components/marketing-subscription-block/marketing-subscription-block.styles'
import { PUSH_PLATFORM_FILTER } from 'com.batch/shared/constants/push-platform-filter.constants'
import { type ChartData } from 'com.batch/shared/infra/types/chart-data'
import { type PushPlatformFilterType } from 'com.batch/shared/infra/types/push-platform-filter.type'
import { DonutChart, Legends } from 'com.batch/shared/ui/component/charts/modular-donus-chart'
import { Metric } from 'com.batch/shared/ui/component/metric/metric'
import { useModularDonutChart } from 'com.batch/shared/ui/hooks/use-modular-donut-chart'

type Props = {
  theme: channelThemeType
  distribution: SubDistributionRecord
  channel: AnalyticsChannel
  platformFilter?: PushPlatformFilterType
}

export const MarketingSubscriptionBlock = ({
  theme,
  distribution,
  channel,
  platformFilter,
}: Props): React.ReactElement => {
  const donutsChartProps = useModularDonutChart()

  const data: ChartData[] = React.useMemo(
    () => convertMarketingSuscriptionPieChartData(channel, distribution),
    [channel, distribution]
  )

  const selectedItem = React.useMemo(
    () => data[donutsChartProps.clickedIndex],
    [data, donutsChartProps]
  )

  const label = selectedItem.label.toLowerCase()

  return (
    <MarketingSubscriptionBox $color={theme.colors.text}>
      <BoxHeader>
        <BoxTitle>
          <Skeleton w={125} h={16}>
            {formatChannelName(channel, platformFilter, true)} subscriptions
          </Skeleton>
        </BoxTitle>
      </BoxHeader>
      <BoxBody>
        <MetricsColumn>
          <Metric
            value={kformat(selectedItem.value)}
            subtitle={`profiles ${label} ${label === 'unsubscribed' ? 'from' : 'to'} ${
              channel === 'push'
                ? `${
                    platformFilter ? platformFilter.title : PUSH_PLATFORM_FILTER[0].title
                  } notifications`
                : channel.toLowerCase()
            }`}
            align="column"
          />
          <div>
            <HorizontalDivider />
            <Legends {...donutsChartProps} data={data} />
          </div>
        </MetricsColumn>
        <div>
          <DonutChart {...donutsChartProps} data={data} width={210} height={200} hasTags={true} />
        </div>
      </BoxBody>
    </MarketingSubscriptionBox>
  )
}
