import { type TemplatingRecord } from './templating.records'
import { type Map } from 'immutable'

export type TemplateMode = 'RAW' | 'TEMPLATED' | 'TEMPLATED_HTML'

export const buildTemplatingCachekey = ({
  messageId,
  langId,
  field,
}: {
  messageId: string
  langId: string
  field: string
}) => {
  return `${messageId}-${langId}-${field}`
}

export const getTemplatingFromCache = ({
  cache,
  messageId,
  langId,
  field,
  defaultValue,
  enriched = false,
}: {
  cache: Map<string, TemplatingRecord>
  messageId: string
  langId: string
  field: string
  defaultValue: string
  enriched?: boolean
}): string => {
  // security so we don't return a cached value when adding / removing / adding language
  if (!defaultValue) return defaultValue
  const key = buildTemplatingCachekey({ messageId, langId, field })
  const templating = cache.get(key)
  const pickRichValue = (enriched || templating?.valueIsHtml) ?? false
  const hasTemplatedValue =
    templating && (pickRichValue ? templating.richTemplatedString : templating.templatedString)
  return templating && templating.fetchingState === 'LOADED' && hasTemplatedValue
    ? pickRichValue
      ? templating.richTemplatedString
      : templating.templatedString
    : defaultValue
}

export const wrapBatchTemplating = (value: string): string => {
  return value.replace(/({{[^}]*}})/gm, '<em>$1</em>')
}
