import { sortChannels, generateChannelIcon, generateChannelLabel } from 'com.batch.common/utils'
import { type MessageNodeRecord } from 'com.batch/orchestration-journey/models/journey.records'
import { NodeIconWrapper } from 'com.batch/orchestration-journey/ui/components/node-icon-wrapper'
import { type PartialOrchestrationRecord } from 'com.batch/orchestration-list/models/partial-orchestration.records'
import { type OrchestrationParserResult } from 'com.batch/orchestration/infra/parses/orchestration.parse'
import { Grid } from 'components/common/grid'
import { Icon, IconContainer } from 'components/common/svg-icon'
import { Ellipsis } from 'components/styled/text'
import { colors } from 'components/styled/tokens'
import React from 'react'
import styled from 'styled-components'
import { Tooltip } from 'com.batch/shared/ui/component/tooltip'

export const OrchestrationTooltip = ({
  orchestration,
}: {
  orchestration: PartialOrchestrationRecord | OrchestrationParserResult
}): React.ReactElement => {
  const enterEvent =
    'settings' in orchestration
      ? orchestration.settings.entryEvents.get(0)?.name
      : 'triggerConfig' in orchestration
        ? orchestration.triggerConfig?.enterEvent
        : undefined

  const startDate =
    'recurring' in orchestration
      ? orchestration.recurring?.start
      : 'settings' in orchestration && 'campaign' in orchestration
        ? orchestration.settings.start
        : undefined

  const sendDate =
    'oneTime' in orchestration
      ? orchestration.oneTime?.sendDate
      : 'settings' in orchestration && 'campaign' in orchestration
        ? orchestration.settings.start
        : undefined

  const channelGridTemplate = React.useMemo(
    () =>
      orchestration.channels.size === 1
        ? '60px'
        : orchestration.channels.size === 2
          ? '60px 60px'
          : '60px 60px 60px',
    [orchestration.channels.size]
  )

  const showOrchestrationSendAndTriggerInfo = React.useMemo(
    () => enterEvent || startDate || sendDate,
    [enterEvent, sendDate, startDate]
  )

  return (
    <div
      style={{
        fontSize: '14px',
        textAlign: 'left',
      }}
    >
      <div>{orchestration.name}</div>
      {(orchestration.channels.size > 0 || showOrchestrationSendAndTriggerInfo) && (
        <OrchestrationInformation template="repeat(auto-fill, minmax(300px, 1fr))">
          <Grid template={channelGridTemplate}>
            {sortChannels(orchestration.channels).map(channel => {
              return (
                <li key={channel}>
                  <Icon icon={generateChannelIcon(channel)} />
                  <span>{generateChannelLabel(channel)}</span>
                </li>
              )
            })}
          </Grid>
          {enterEvent && (
            <Grid template="12px auto" style={{ marginTop: '12px' }}>
              <Icon icon="action" color={colors.textContrastLight} style={{ marginRight: '8px' }} />
              <span>{enterEvent}</span>
            </Grid>
          )}
          {startDate && (
            <Grid template="12px auto" style={{ marginTop: '12px' }}>
              <Icon
                icon="calendar"
                color={colors.textContrastLight}
                style={{ marginRight: '8px' }}
              />
              <span>{startDate.format('DD/MM/YYYY [at] HH:mm')}</span>
            </Grid>
          )}
          {sendDate && (
            <Grid template="12px auto" style={{ marginTop: '12px' }}>
              <Icon
                icon="calendar"
                color={colors.textContrastLight}
                style={{ marginRight: '8px' }}
              />
              <span>{sendDate.format('DD/MM/YYYY [at] HH:mm')}</span>
            </Grid>
          )}
        </OrchestrationInformation>
      )}
    </div>
  )
}

export const StepTooltip = ({ step }: { step: MessageNodeRecord }): React.ReactElement => {
  const channel = step.messageConfig.channel
  return (
    <div
      style={{
        width: '300px',
        fontSize: '14px',
        textAlign: 'left',
      }}
    >
      <div>{step.label || step.id}</div>
      {channel && (
        <OrchestrationInformation template="auto">
          <Grid template="auto">
            <li key={channel}>
              <Icon icon={generateChannelIcon(channel)} />
              <span>{generateChannelLabel(channel)}</span>
            </li>
          </Grid>
        </OrchestrationInformation>
      )}
    </div>
  )
}

export const OptionOrchestration = (
  option: PartialOrchestrationRecord | OrchestrationParserResult | undefined,
  { context }: { context: 'value' | 'menu' }
): React.ReactElement => {
  if (!option) {
    return <div />
  }

  const channelGridTemplate =
    option.channels.size === 1
      ? 'auto'
      : option.channels.size === 2
        ? 'auto auto'
        : 'auto auto auto'

  return context === 'menu' ? (
    <div>
      <Tooltip
        tooltip={<OrchestrationTooltip orchestration={option} />}
        placement="left"
        offset={[0, 20]}
        width={300}
      >
        <Grid template="1fr auto" gap={4}>
          <Ellipsis>
            {option.name || `${'token' in option ? option.token : option.id} (Deleted)`}
          </Ellipsis>
          <Grid template={channelGridTemplate} gap={8}>
            {sortChannels(option.channels).map(channel => {
              return <Icon icon={generateChannelIcon(channel)} color={colors.textLight} />
            })}
          </Grid>
        </Grid>
      </Tooltip>
    </div>
  ) : (
    <Ellipsis style={{ marginTop: 3 }}>{option.name}</Ellipsis>
  )
}

export const OptionStep = (
  option: MessageNodeRecord,
  { context }: { context: 'value' | 'menu' }
): React.ReactElement => {
  return context === 'menu' ? (
    <Tooltip tooltip={<StepTooltip step={option} />} placement="left" offset={[0, 20]}>
      <Grid template="32px auto" gap={4} style={{ marginTop: '-3px', marginLeft: '4px' }}>
        <NodeIconWrapper kind={option.messageConfig.channel} size={26} />
        <div>{option?.label || option?.id}</div>
      </Grid>
    </Tooltip>
  ) : (
    <Grid template="32px auto" gap={4} style={{ marginLeft: '0px', padding: 0 }}>
      <NodeIconWrapper kind={option.messageConfig.channel} size={26} />
      <div>{option?.label || option?.id}</div>
    </Grid>
  )
}

export const OrchestrationInformation = styled(Grid)`
  display: grid;
  grid-column-gap: 12px;
  line-height: 12px;
  margin-top: 8px;
  color: ${colors.textContrastLight};
  font-weight: 500;
  justify-content: start;
  li {
    > span {
      padding-top: 2px;
    }
    list-style: none;
    ${IconContainer} {
      margin-right: 8px;
    }
  }
`
