import * as React from 'react'

import { useToggle } from 'components/_hooks'
import { Button } from 'components/common/button'
import { Icon } from 'components/common/svg-icon'
import { Tooltip } from 'com.batch/shared/ui/component/tooltip'
import { Ellipsis } from 'components/styled/text'

import { HideableCodeContainer } from './code.style'

type HideableCodeProps = {
  children: React.ReactNode
  accessNotAllowed?: boolean
  reveal?: boolean
}

export function HideableCode({
  children,
  accessNotAllowed = false,
  reveal = false,
}: HideableCodeProps): React.ReactElement {
  const [copied, setCopied] = React.useState<boolean>(false)
  const revealedState = useToggle(false)

  const CopyRef: {
    current: any
  } = React.createRef()

  const handleCopyClick = React.useCallback(() => {
    if (revealedState.value) {
      if (CopyRef.current) {
        const range = new Range()
        let selection = window.getSelection()
        range.selectNode(CopyRef.current)
        selection?.removeAllRanges()
        selection?.addRange(range)

        try {
          const result = document.execCommand('copy')
          if (result) setCopied(true)
        } catch (err: any) {
          console.log(err)
        }
        selection = window.getSelection()
        if (typeof selection?.removeRange === 'function') selection.removeRange(range)
        else if (typeof selection?.removeAllRanges === 'function') selection.removeAllRanges()
      }
    }
  }, [CopyRef, revealedState])

  React.useEffect(() => {
    const switchCopied = setTimeout(() => setCopied(false), 1800)
    return () => clearTimeout(switchCopied)
  }, [])

  const handleRevealed = React.useCallback(() => {
    revealedState.toggle()
    setCopied(false)
  }, [revealedState])

  React.useEffect(() => {
    if (reveal) {
      revealedState.open()
    }
  }, [reveal, revealedState])

  return (
    <Tooltip
      tooltip={
        <div style={{ padding: '6px 8px' }}>
          {copied ? (
            <React.Fragment>
              Copied <Icon icon="check" size={12} />
            </React.Fragment>
          ) : (
            <div style={!revealedState.value ? { width: 'max-content', textAlign: 'left' } : {}}>
              {revealedState.value ? (
                'Click to copy'
              ) : (
                <React.Fragment>
                  This key is sensitive! <br />
                  Don’t share it, even to support.
                </React.Fragment>
              )}
            </div>
          )}
        </div>
      }
      padding={0}
      placement="bottom"
    >
      <HideableCodeContainer
        revelead={revealedState.value}
        accessNotAllowed={accessNotAllowed}
        size="normal"
      >
        <Ellipsis ref={CopyRef} onClick={handleCopyClick}>
          {accessNotAllowed ? 'Require higher permission to display' : children}
        </Ellipsis>

        <Button
          onClick={handleRevealed}
          kind="discreet"
          intent="neutral"
          type="button"
          style={{ flex: '28px' }}
        >
          <Icon icon={revealedState.value ? 'hide' : 'show'} style={{ marginLeft: 10 }} />
        </Button>
      </HideableCodeContainer>
    </Tooltip>
  )
}
