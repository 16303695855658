import * as React from 'react'
import { createPortal } from 'react-dom'

import { Button } from 'components/common/button'
import { getConsent } from 'components/common/page-tracker'
import { Tooltip } from 'com.batch/shared/ui/component/tooltip'

export const Beamer = (): React.ReactElement | null => {
  const [ready, setReady] = React.useState(false)
  const [hasNews, setHasNews] = React.useState(false)
  const [hasMarketingCookies, setHasMarketingCookies] = React.useState(false)

  // open beamer on click (if we are ready)
  const onClick = React.useCallback(() => {
    if (ready && typeof window.Beamer !== 'undefined') {
      setHasNews(false)
      window.Beamer.show()
    }
  }, [ready])

  // callback called by beamer when JS is loaded
  const callback = (nbr: number) => {
    setHasNews(nbr > 0)
    setReady(true)
  }
  const element = document.getElementById('beamer-icon-area')
  const beamerEnabled = Boolean(element)
  // init beamer and trigger js load
  React.useEffect(() => {
    const config: {
      [key: string]: unknown
    } = {
      product_id: 'RAAGRAVw20494',
      display: 'left',
      counter: false,
      alert: false,
      notification_prompt: 'none',
      callback,
    }
    if (window.user?.externalId) {
      config['user_id'] = window.user?.externalId
    }
    window.beamer_config = config
    const script = document.createElement('script')
    script.src = 'https://app.getbeamer.com/js/beamer-embed.js'
    if (beamerEnabled && document.body) document.body.appendChild(script)
  }, [beamerEnabled])

  // Get cookie consent
  React.useEffect(() => {
    getConsent()
      .then(r => {
        setHasMarketingCookies(r.includes('MARKETING'))
      })
      .catch(() => {
        setHasMarketingCookies(false)
      })
  }, [])
  return element
    ? createPortal(
        <React.Fragment>
          <style type="text/css">{'body > #beamerLastPostTitle{ display:none; }'}</style>
          <Tooltip
            tooltip={
              hasMarketingCookies ? (
                "See what's new"
              ) : (
                <div style={{ width: 150 }}>To see What’s new, please enable marketing cookies</div>
              )
            }
            placement="right"
          >
            <span>
              <Button
                kind="inline"
                onClick={onClick}
                style={{
                  width: 36,
                  marginLeft: 24,
                  marginRight: 8,
                  cursor: hasMarketingCookies ? 'default' : 'not-allowed',
                }}
                disabled={!ready || !hasMarketingCookies}
              >
                {hasNews ? IconNotif : IconDefault}
              </Button>
            </span>
          </Tooltip>
        </React.Fragment>,
        element
      )
    : null
}

const IconDefault = (
  <svg
    className="icon-empty"
    width="1em"
    height="1em"
    viewBox="0 0 32 32"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    aria-labelledby="changelog-buttonEmpty-title changelog-buttonEmpty-desc"
    style={{ overflow: 'visible', pointerEvents: 'none' }}
  >
    <title id="changelog-buttonEmpty-title">Changelog</title>
    <desc id="changelog-buttonEmpty-desc">
      Display the product changelog (no new updates available)
    </desc>
    <path
      d="M-2 11C-2 8.17157 -2 6.75736 -1.12132 5.87868C-0.242641 5 1.17157 5 4 5H23C25.8284 5 27.2426 5 28.1213 5.87868C29 6.75736 29 8.17157 29 11V34H4C1.17157 34 -0.242641 34 -1.12132 33.1213C-2 32.2426 -2 30.8284 -2 28V11Z"
      stroke="currentColor"
      strokeWidth="1"
      vectorEffect="non-scaling-stroke"
    />
    <path
      d="M4 22C4 20.1144 4 19.1716 4.58579 18.5858C5.17157 18 6.11438 18 8 18V18C9.88562 18 10.8284 18 11.4142 18.5858C12 19.1716 12 20.1144 12 22V24C12 25.8856 12 26.8284 11.4142 27.4142C10.8284 28 9.88562 28 8 28V28C6.11438 28 5.17157 28 4.58579 27.4142C4 26.8284 4 25.8856 4 24V22Z"
      stroke="currentColor"
      strokeWidth="1"
      vectorEffect="non-scaling-stroke"
    />
    <path
      d="M4 12H24"
      stroke="currentColor"
      strokeWidth="1"
      vectorEffect="non-scaling-stroke"
      strokeLinecap="round"
    />
    <path
      d="M17 20H24"
      stroke="currentColor"
      strokeWidth="1"
      vectorEffect="non-scaling-stroke"
      strokeLinecap="round"
    />
    <path
      d="M17 26H24"
      stroke="currentColor"
      strokeWidth="1"
      vectorEffect="non-scaling-stroke"
      strokeLinecap="round"
    />
    <path
      d="M29 17H36V28C36 30.8284 36 32.2426 35.1213 33.1213C34.2426 34 32.8284 34 30 34H29V17Z"
      stroke="currentColor"
      strokeWidth="1"
      vectorEffect="non-scaling-stroke"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
)

const IconNotif = (
  <svg
    className="icon-notif"
    width="1em"
    height="1em"
    viewBox="0 0 32 32"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    aria-labelledby="changelog-buttonNotif-title changelog-buttonNotif-desc"
    style={{ overflow: 'visible', pointerEvents: 'none' }}
  >
    <title id="changelog-buttonNotif-title">Changelog</title>
    <desc id="changelog-buttonNotif-desc">
      Display the product changelog (new updates availables)
    </desc>
    <path
      d="M43 3C43 7.97056 38.9706 12 34 12C29.0294 12 25 7.97056 25 3C25 -1.97056 29.0294 -6 34 -6C38.9706 -6 43 -1.97056 43 3Z"
      fill="#DE4960"
    />
    <path
      d="M29 15V34H4C1.17157 34 -0.242641 34 -1.12132 33.1213C-2 32.2426 -2 30.8284 -2 28V11C-2 8.17157 -2 6.75736 -1.12132 5.87868C-0.242641 5 1.17157 5 4 5H21"
      stroke="currentColor"
      strokeWidth="1"
      vectorEffect="non-scaling-stroke"
      strokeLinecap="round"
    />
    <path
      d="M4 22C4 20.1144 4 19.1716 4.58579 18.5858C5.17157 18 6.11438 18 8 18V18C9.88562 18 10.8284 18 11.4142 18.5858C12 19.1716 12 20.1144 12 22V24C12 25.8856 12 26.8284 11.4142 27.4142C10.8284 28 9.88562 28 8 28V28C6.11438 28 5.17157 28 4.58579 27.4142C4 26.8284 4 25.8856 4 24V22Z"
      stroke="currentColor"
      strokeWidth="1"
      vectorEffect="non-scaling-stroke"
    />
    <path
      d="M4 12H23"
      stroke="currentColor"
      strokeWidth="1"
      vectorEffect="non-scaling-stroke"
      strokeLinecap="round"
    />
    <path
      d="M17 20H24"
      stroke="currentColor"
      strokeWidth="1"
      vectorEffect="non-scaling-stroke"
      strokeLinecap="round"
    />
    <path
      d="M17 26H24"
      stroke="currentColor"
      strokeWidth="1"
      vectorEffect="non-scaling-stroke"
      strokeLinecap="round"
    />
    <path
      d="M29 17H36V28C36 30.8284 36 32.2426 35.1213 33.1213C34.2426 34 32.8284 34 30 34H29V17Z"
      stroke="currentColor"
      strokeWidth="1"
      vectorEffect="non-scaling-stroke"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
)
