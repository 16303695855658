import * as React from 'react'
import { useNavigate, NavLink } from 'react-router-dom'

import { ButtonNavLink } from 'components/common/button'

import { AppPicker } from './app/app-picker'
import { OrchestrationPicker } from './app/orchestration-picker'
import { Header as HeaderSt } from './console.style'

export const Header = (): React.ReactElement => {
  const navigate = useNavigate()
  const onSetApp = React.useCallback(
    app => {
      if (app) navigate(`/console/apps/${app.id}`)
    },
    [navigate]
  )

  return (
    <HeaderSt>
      <h1>
        <NavLink to="/console/apps">
          <span>Admin</span>
        </NavLink>
      </h1>
      <nav>
        <ButtonNavLink intent="action" kind="inline" to="/console/users">
          Users
        </ButtonNavLink>
        <ButtonNavLink intent="action" kind="inline" to="/console/apps">
          Apps
        </ButtonNavLink>
        <ButtonNavLink intent="action" kind="inline" to="/console/companies">
          Companies
        </ButtonNavLink>
        <ButtonNavLink intent="action" kind="inline" to="/console/projects">
          Projects
        </ButtonNavLink>
        <ButtonNavLink intent="action" kind="inline" to="/console/demo-codes">
          Demo codes
        </ButtonNavLink>
      </nav>
      <div style={{ flexBasis: 210, marginRight: 12 }}>
        <OrchestrationPicker />
      </div>
      <div style={{ flexBasis: 320, marginRight: 12 }}>
        <AppPicker setApp={onSetApp} />
      </div>
    </HeaderSt>
  )
}
