import Immutable, { type Set, type Map, type RecordOf } from 'immutable'

import { type MessageStateRecord } from 'com.batch/message/store/message.state'
import { type TargetStateRecord } from 'com.batch.redux/target/target.records'
import { type UserRecord } from 'com.batch.redux/user.records'

import {
  orchestrationIsCampaign,
  type Orchestration,
} from 'com.batch/orchestration/infra/types/orchestration.types'
import { parseOrchestrationCampaign } from 'com.batch/orchestration-campaign/infra/parses/orchestration-campaign.parse'
import { type OrchestrationCampaignRecord } from 'com.batch/orchestration-campaign/models/campaign.records'
import { parseOrchestrationJourney } from 'com.batch/orchestration-journey/infra/parses/orchestration-journey.parse'
import {
  type JourneySettingsRecord,
  type JourneyNodeRecord,
} from 'com.batch/orchestration-journey/models/journey.records'
import { OrchestrationState } from 'constants/common/orchestration-state'
import { type Dayjs } from 'dayjs'

type queryToParse = {
  queryId: string
  query: string
  eventId: string
  retries: number
}
export type OrchestrationJourneyParserResult = {
  settings: JourneySettingsRecord
  nodes: Map<string, JourneyNodeRecord>
  rootId: string
  queries: Array<queryToParse>
  targets: Map<string, TargetStateRecord>
  message: MessageStateRecord
  state: OrchestrationState
  channels: Set<ChannelUntilCleanup>
  incomplete: boolean
  id: string
  name: string
  labelCodes: Set<string>
  createdByApi: boolean
  history: OrchestrationHistoryRecord
}

type OrchestrationHistoryProps = {
  creationDate: Dayjs | null
  lastUpdateDate: Dayjs | null
  lastEditDate: Dayjs | null
  lastStatusChangeDate: Dayjs | null
  firstRunDate: Dayjs | null
}

export type OrchestrationHistoryRecord = RecordOf<OrchestrationHistoryProps>

export const OrchestrationHistoryFactory = Immutable.Record<OrchestrationHistoryProps>({
  creationDate: null,
  lastUpdateDate: null,
  lastEditDate: null,
  lastStatusChangeDate: null,
  firstRunDate: null,
})

export type OrchestrationCampaignParserResult = {
  campaign: OrchestrationCampaignRecord
  queries: Array<queryToParse>
  targets: Map<string, TargetStateRecord>
  message: MessageStateRecord
  state: OrchestrationState
  channels: Set<ChannelUntilCleanup>
  incomplete: boolean
  id: string
  name: string
  labelCodes: Set<string>
  createdByApi: boolean
  history: OrchestrationHistoryRecord
}
export type OrchestrationParserResult =
  | OrchestrationJourneyParserResult
  | OrchestrationCampaignParserResult
export const isJourneyParserResult = (
  result: OrchestrationParserResult
): result is OrchestrationJourneyParserResult => {
  return 'settings' in result
}
export const isCampaignParserResult = (
  result: OrchestrationParserResult
): result is OrchestrationCampaignParserResult => {
  return 'campaign' in result
}
type OrchestrationParser = (arg1: {
  orchestration: Orchestration
  user: UserRecord
}) => OrchestrationParserResult

export const parseOrchestration: OrchestrationParser = ({ orchestration, user }) => {
  if (!orchestrationIsCampaign(orchestration)) {
    return parseOrchestrationJourney({
      journey: orchestration.journey,
      config: orchestration.dashboardConfig,
      state: orchestration.orchestrationState ?? OrchestrationState.DRAFT,
      channels: orchestration.orchestrationChannels,
      id: orchestration.id ?? '',
      user,
      name: orchestration.name,
      labelCodes: orchestration.journey.settings.labels ?? [],
      orchestrationSource: orchestration.orchestrationSource,
      history: orchestration.orchestrationHistory,
    })
  } else {
    return parseOrchestrationCampaign({
      campaign: orchestration.campaign,
      user,
      messages: orchestration.messages,
      config: orchestration.dashboardConfig,
      state: orchestration.orchestrationState ?? OrchestrationState.DRAFT,
      channels: orchestration.orchestrationChannels,
      id: orchestration.id ?? '',
      name: orchestration.name,
      labelCodes: orchestration.campaign.content.labels ?? [],
      orchestrationSource: orchestration.orchestrationSource,
      history: orchestration.orchestrationHistory,
    })
  }

  throw new Error('Unhandled orchestration kind')
}
