/* eslint-disable react/jsx-no-bind */
import { type Dayjs } from 'dayjs'
import Immutable from 'immutable'
import * as React from 'react'
import { useSelector, useDispatch } from 'com.batch.common/react-redux'
import { ThemeProvider } from 'styled-components'

import { EventFormatter } from 'components/campaign/event-formatter'
import { TriggerPicker } from 'components/campaign/trigger-picker'
import { Alert } from 'components/common/alert'
import { Box, BoxHeader, HeaderBoxTitle } from 'components/common/box'
import { Switch } from 'components/common/button'
import DateTimePicker from 'components/common/date-time-picker'
import { FlexLine, FlexLineItem, FlexLineItemTitle } from 'components/common/flexline'
import { Hint } from 'com.batch/shared/ui/component/hint'
import { TableToggle, TableToggleItem } from 'components/common/tabletoggle'
import { Tooltip } from 'com.batch/shared/ui/component/tooltip'
import { Select } from 'components/form'
import AgePicker from 'components/targeting/age-picker'

import { dayjs } from 'com.batch.common/dayjs.custom'

import { currentAppSelector } from 'com.batch.redux/app'
import { visibleInstallEventsSelector } from 'com.batch.redux/attribute.selector'
import * as campaignActions from 'com.batch.redux/campaign.action'
import {
  triggerSelector,
  triggerLabelSelector,
  cappingSelector,
  inAppPrioritySelector,
  gracePeriodSelector,
  startSelector,
  tzAwareSelector,
  endSelector,
} from 'com.batch.redux/campaign.selector'

const PRIORITIES = Immutable.List([
  { value: 'standard', label: 'Standard' } as const,
  { value: 'important', label: 'Important' } as const,
  { value: 'critical', label: 'Critical' } as const,
])

export const InAppTrigger = ({ isNewCampaign }: { isNewCampaign: boolean }): React.ReactElement => {
  const onCappingToggled = () => updateCapping(capping === 0 ? 1 : 0)
  const onGraceToggled = () => updateGrace(gracePeriod === 0 ? 1 : 0)

  // ====================== STATE
  const app = useSelector(currentAppSelector)
  const trigger = useSelector(triggerSelector)
  const triggerLabel = useSelector(triggerLabelSelector)
  const capping = useSelector(cappingSelector)
  const gracePeriod = useSelector(gracePeriodSelector)
  const start = useSelector(startSelector)
  const end = useSelector(endSelector)
  const priority = useSelector(inAppPrioritySelector)
  const tzAware = useSelector(tzAwareSelector)
  const events = useSelector(visibleInstallEventsSelector)
  // ====================== ACTIONS
  const dispatch = useDispatch()
  const updateTimezoneAware = React.useCallback(
    (t: boolean) => dispatch(campaignActions.updateTimezoneAware(t)),
    [dispatch]
  )
  const updateGrace = React.useCallback(
    (g: number) => dispatch(campaignActions.updateGrace(g)),
    [dispatch]
  )
  const updateCapping = React.useCallback(
    (c: number) => dispatch(campaignActions.updateCapping(c)),
    [dispatch]
  )
  const updateRange = React.useCallback(
    (d?: Dayjs | null, e: boolean = false) => dispatch(campaignActions.updateRange(d, e)),
    [dispatch]
  )
  const updateTrigger = React.useCallback(
    (t: string) => dispatch(campaignActions.updateTrigger(t)),
    [dispatch]
  )
  const updatePriority = React.useCallback(
    (p: inAppPriority) => dispatch(campaignActions.updateInAppPriority(p)),
    [dispatch]
  )
  const updateTriggerLabel = React.useCallback(
    (l?: string | null) => dispatch(campaignActions.updateTriggerLabel(l)),
    [dispatch]
  )

  // const updateTrigger =

  return (
    <Box style={{ zIndex: 'auto' }}>
      {/* TRIGGER ============================================== */}
      <BoxHeader>
        <HeaderBoxTitle title="Trigger" />
      </BoxHeader>
      <FlexLine container>
        <FlexLineItem width={140}>
          <FlexLineItemTitle>Display event</FlexLineItemTitle>
        </FlexLineItem>
        <TriggerPicker
          app={app}
          events={events}
          trigger={trigger}
          triggerLabel={triggerLabel}
          update={(trigger, label) => {
            updateTrigger(trigger)
            updateTriggerLabel(label)
          }}
        />
      </FlexLine>
      {trigger === 'now' && (
        <FlexLine>
          <FlexLineItem grow={1} style={{ padding: '0 50px 20px 50px' }}>
            <Alert icon="danger" kind="warning">
              The In-App message will be displayed as soon as it has been downloaded, and at every
              following session (unless a capping rule has been defined). Due to network delays,
              this can happen at anytime no matter where your user is in your application. This
              could be frustrating for your user: consider using "next session", or configure the{' '}
              <a
                href={`https://doc.batch.com/${app.platform}/in-app-messaging.html#_controlling-the-display-using-do-not-disturb-mode`}
              >
                Do not disturb
              </a>{' '}
              mode.
            </Alert>
          </FlexLineItem>
        </FlexLine>
      )}
      {/* PRIORITY ============================================== */}
      <FlexLine container separator>
        <FlexLineItem width={140}>
          <FlexLineItemTitle>
            Priority{' '}
            <Hint>
              If multiple campaigns are triggered off by the same display event, priority ensures
              most valuable campaign level are displayed first to users.
            </Hint>
          </FlexLineItemTitle>
        </FlexLineItem>
        <FlexLineItem grow={1}>
          <Tooltip
            tooltip={
              !app.features.has('inapp-priority') &&
              'Upgrade your plan to manage the priority of your campaign.'
            }
            placement="bottom-start"
          >
            <div>
              <ThemeProvider theme={{ kind: 'capture' }}>
                <Select
                  onChange={opt => {
                    if (opt) updatePriority(opt.value)
                  }}
                  options={PRIORITIES}
                  menuOffset={0}
                  optionFormatter={opt => opt.label}
                  optionToString={opt => opt?.value ?? ''}
                  isDisabled={!app.features.has('inapp-priority')}
                  value={PRIORITIES.find(p => p.value === priority)}
                />
              </ThemeProvider>
            </div>
          </Tooltip>
        </FlexLineItem>
      </FlexLine>

      <FlexLine container separator>
        <FlexLineItem grow={1}>
          {/* CAPPING FIRST LINE ==================================== */}
          <FlexLine mb>
            <FlexLineItem width={140}>
              <FlexLineItemTitle>Capping</FlexLineItemTitle>
            </FlexLineItem>
            <FlexLineItem grow={1}>
              <FlexLine>
                <FlexLineItem style={{ paddingBottom: 0 }} switch>
                  <Switch isActive={capping > 0} onChange={onCappingToggled} />
                </FlexLineItem>
                {capping > 0 ? (
                  <FlexLineItem>Display only</FlexLineItem>
                ) : (
                  <FlexLineItem>
                    No capping. The user will view the message on each{' '}
                    <code>
                      <EventFormatter eventCode={trigger} eventLabel={triggerLabel} />
                    </code>
                    .
                  </FlexLineItem>
                )}
                {capping > 0 ? (
                  <FlexLineItem>
                    <input
                      type="number"
                      style={{ maxWidth: '80px' }}
                      className="form-control"
                      value={capping}
                      onChange={evt => updateCapping(parseInt(evt.target.value))}
                    />
                  </FlexLineItem>
                ) : null}
                {capping > 0 ? (
                  <FlexLineItem>{`time${capping > 1 ? 's' : ''} per user`}</FlexLineItem>
                ) : null}
              </FlexLine>
            </FlexLineItem>
          </FlexLine>
          {/* CAPPING SECOND LINE ==================================== */}
          <FlexLine>
            <FlexLineItem width={140}> </FlexLineItem>
            <FlexLineItem switch style={{ paddingBottom: 0 }}>
              <Switch isActive={gracePeriod > 0} onChange={onGraceToggled} />
            </FlexLineItem>
            {gracePeriod > 0 ? (
              <FlexLineItem style={{ minWidth: '200px' }}>
                Do not trigger if already shown in the last
              </FlexLineItem>
            ) : (
              <FlexLineItem>
                No grace period. The in-app message might be triggered multiple times in a very
                short time range.
              </FlexLineItem>
            )}
            {gracePeriod > 0 ? (
              <FlexLineItem>
                <AgePicker
                  age={
                    gracePeriod % 24 === 0 && gracePeriod > 47
                      ? `${gracePeriod / 24}d`
                      : `${gracePeriod}h`
                  }
                  defaultUnit="h"
                  onChange={age => {
                    const unit = age.substring(age.length - 1, age.length)
                    const value = parseInt(age.substring(0, age.length - 1))
                    updateGrace(unit === 'h' ? value : value * 24)
                  }}
                  allowedUnits={['h', 'd']}
                />
              </FlexLineItem>
            ) : null}
          </FlexLine>
        </FlexLineItem>
      </FlexLine>

      <FlexLine container separator>
        <FlexLineItem grow={1}>
          {/* RANGE START ==================================== */}
          <FlexLine mb>
            <FlexLineItem width={140}>
              <FlexLineItemTitle>Availability</FlexLineItemTitle>
            </FlexLineItem>
            <FlexLineItem grow={1}>
              <FlexLine>
                <FlexLineItem width={70}>Starting</FlexLineItem>
                <FlexLineItem width={260}>
                  <TableToggle width={250}>
                    <TableToggleItem
                      active={!start}
                      onClick={() => updateRange(null)}
                      style={{ flexBasis: '80px' }}
                    >
                      Now
                    </TableToggleItem>
                    <TableToggleItem
                      active={!!start}
                      onClick={() =>
                        updateRange(
                          dayjs()
                            .hour(dayjs().hour() + 1)
                            .minute(15)
                        )
                      }
                    >
                      On&nbsp;a&nbsp;specific&nbsp;date
                    </TableToggleItem>
                  </TableToggle>
                </FlexLineItem>
                {!!start && (
                  <FlexLineItem>
                    <DateTimePicker value={start} update={d => updateRange(d)} />
                  </FlexLineItem>
                )}
                {!!start && !isNewCampaign && (
                  <FlexLineItem>{tzAware ? 'Local time' : 'UTC'}</FlexLineItem>
                )}
              </FlexLine>
            </FlexLineItem>
          </FlexLine>
          {/* RANGE END ===================================== */}
          <FlexLine mb={isNewCampaign}>
            <FlexLineItem width={140}> </FlexLineItem>
            <FlexLineItem width={70}>Ending</FlexLineItem>
            <FlexLineItem width={260}>
              <TableToggle width={250}>
                <TableToggleItem
                  active={!end}
                  onClick={() => updateRange(null, true)}
                  style={{ flexBasis: '80px' }}
                >
                  Never
                </TableToggleItem>
                <TableToggleItem
                  active={!!end}
                  onClick={() => updateRange(dayjs().add(2, 'week').hour(18).minute(30), true)}
                >
                  On&nbsp;a&nbsp;specific&nbsp;date
                </TableToggleItem>
              </TableToggle>
            </FlexLineItem>
            {!!end && (
              <FlexLineItem>
                <DateTimePicker value={end} update={d => updateRange(d, true)} />
              </FlexLineItem>
            )}
            {!!end && !isNewCampaign && (
              <FlexLineItem>{tzAware ? 'Local time' : 'UTC'}</FlexLineItem>
            )}
          </FlexLine>
          {isNewCampaign && (
            <FlexLine>
              <FlexLineItem width={140}> </FlexLineItem>
              <FlexLineItem width={70}>&nbsp;</FlexLineItem>
              <FlexLineItem grow={1}>
                <TableToggle width={300}>
                  <TableToggleItem
                    active={tzAware}
                    disabled={!isNewCampaign || (!start && !end)}
                    onClick={() => updateTimezoneAware(true)}
                  >
                    Local Time
                  </TableToggleItem>
                  <TableToggleItem
                    active={!tzAware}
                    disabled={!isNewCampaign || (!start && !end)}
                    onClick={() => updateTimezoneAware(false)}
                  >
                    Global Time (UTC)
                  </TableToggleItem>
                </TableToggle>
              </FlexLineItem>
            </FlexLine>
          )}
        </FlexLineItem>
      </FlexLine>
    </Box>
  )
}
