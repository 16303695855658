import * as React from 'react'
import styled from 'styled-components'

import { Icon } from 'components/common/svg-icon'
import { Tooltip, type placement as TooltipPlacement } from 'com.batch/shared/ui/component/tooltip'
import { colors } from 'components/styled/tokens'

type Props = {
  children: React.ReactNode
  size?: number
  tooltipWidth?: number
  placement?: TooltipPlacement
  icon?: 'help' | 'details'
  intent?: 'default' | 'disabled'
  noMargin?: boolean
  style?: any
  tooltipStyle?: any
}

export function Hint({
  children,
  size,
  tooltipWidth,
  placement = 'top',
  noMargin,
  icon = 'help',
  intent = 'default',
  style,
  tooltipStyle,
}: Props): React.ReactElement {
  const content = React.useMemo(
    () => (
      <Tooltip tooltip={children} placement={placement} width={tooltipWidth} style={tooltipStyle}>
        <div style={{ display: 'inline-block' }}>
          <HintIcon size={size} noMargin={noMargin} style={style} icon={icon} intent={intent} />
        </div>
      </Tooltip>
    ),
    [children, placement, tooltipWidth, tooltipStyle, size, noMargin, style, icon, intent]
  )
  return noMargin ? content : <span style={{ marginLeft: 4 }}>{content}</span>
}

type HintIconProps = {
  noMargin?: boolean
  intent: 'default' | 'disabled'
}
export const HintIcon = styled(Icon)<HintIconProps>`
  vertical-align: baseline;
  /* padding: 6px; */
  margin: ${p => (p.noMargin ? '0' : '-6px 0 -6px 0')};
  color: ${colors.textDisabled};
  cursor: pointer;
  &:hover {
    color: ${colors.textNeutral};
  }
`

export default Hint
