import Immutable, { type OrderedSet, type List, type Set } from 'immutable'
import * as React from 'react'
import request from 'superagent-interface-promise'

import { CheckboxRole, CheckboxText } from 'components/account/team/permissions'
import {
  RestrictionsHeader,
  RestrictionsLabel,
  RestrictionsApp,
  RestrictionsSelect,
  RestrictionAppName,
  RestrictionsEmpty,
} from 'components/account/team/user-form.styles'
import {
  permissionNameFormatter,
  getAllowedAppsOnCompanyForUser,
  ROLES,
} from 'components/account/team/utils'
import { Avatar } from 'components/common/avatar'
import {
  Box,
  BoxBody,
  HeaderBoxTitle,
  BoxSection,
  BoxFooter,
  BoxHeader,
  HeaderBoxActions,
} from 'components/common/box'
import { Button } from 'components/common/button'
import { Grid } from 'components/common/grid'
import { Hint } from 'com.batch/shared/ui/component/hint'
import { Popin } from 'components/common/popin/popin'
import { Icon } from 'components/common/svg-icon'
import { InputWrapper, Input, Checkbox, Feedback } from 'components/form'

import { generateUrl } from 'com.batch.common/router'

import { CompanyPicker } from '../company/company-picker'
import { type CompanyRecord, type AppRecord } from 'com.batch.redux/_records'
import { normalizeApp } from 'com.batch.redux/app.api'
import {
  type UserRecord,
  type CompanyUserPermissionsRecord,
  type groupOnlyPermissionType,
} from 'com.batch.redux/user.records'

type CompanyPermissionPopinProps = {
  company: CompanyRecord | null | undefined
  user: UserRecord
  permissions: CompanyUserPermissionsRecord
  updatePermissions: (company: CompanyRecord, permissions: CompanyUserPermissionsRecord) => void
  close: () => void
  opened: boolean
}

export const CompanyPermissionPopin = ({
  close,
  opened,
  user,
  permissions,
  updatePermissions,
  company,
}: CompanyPermissionPopinProps): React.ReactElement => {
  const [perms, setPerms] = React.useState<OrderedSet<groupOnlyPermissionType>>(
    permissions.permissions
  )
  const [apps, setApps] = React.useState<List<AppRecord>>(Immutable.List())
  const [targetCompany, setTargetCompany] = React.useState<CompanyRecord | null | undefined>(
    company
  )
  const [searchMode, setSearchMode] = React.useState(false)
  const [search, setSearch] = React.useState('')
  const [allowedApps, setAllowedApps] = React.useState<Set<number>>(
    !targetCompany ? Immutable.Set() : getAllowedAppsOnCompanyForUser(targetCompany, user)
  )

  const isAdmin = perms.has('group:administrate')
  const allowedCount = isAdmin ? apps.size : allowedApps.size
  const toggleMode: 'all' | 'none' | 'some' =
    allowedCount === apps.size ? 'all' : allowedApps.size === 0 ? 'none' : 'some'

  const appsFiltered = apps.filter(
    app => search === '' || app.name.toLowerCase().indexOf(search.toLowerCase()) !== -1
  )

  // refresh company on load
  React.useEffect(() => {
    setTargetCompany(company)
  }, [company])

  // refresh apps on targetCompany change
  React.useEffect(() => {
    if (targetCompany && targetCompany.id) {
      setAllowedApps(getAllowedAppsOnCompanyForUser(targetCompany, user))
      request
        .get(generateUrl('console_api_apps', { companyId: targetCompany.id, count: 999 }))
        .then(({ body }) => {
          setApps(Immutable.List(body.entities.map(normalizeApp)))
        })
    } else {
      setAllowedApps(Immutable.Set())
      setApps(Immutable.List())
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [targetCompany])

  const canUseAppRestriction = Boolean(
    targetCompany &&
      !!targetCompany.planFeaturesCode &&
      (targetCompany.planFeaturesCode.has('app-user-restriction') ||
        targetCompany.additionalFeaturesCode.has('app-user-restriction')) &&
      !targetCompany.disabledFeaturesCode.has('app-user-restriction')
  )
  const onRoleToggle = React.useCallback(
    role => () => {
      setPerms(perms.has(role.id) ? perms.remove(role.id) : perms.add(role.id))
    },
    [perms]
  )
  const onPreventEnter = React.useCallback(evt => {
    if (evt.key === 'Enter') {
      evt.preventDefault()
    }
  }, [])
  const onSearchChange = React.useCallback(evt => setSearch(evt.target.value), [])
  const onAllAppToggle = React.useCallback(() => {
    setAllowedApps(toggleMode !== 'none' ? Immutable.Set() : Immutable.Set(apps.map(app => app.id)))
  }, [apps, toggleMode])
  const onEnableSearch = React.useCallback(() => setSearchMode(true), [])
  const onSavePerm = React.useCallback(() => {
    if (targetCompany) {
      updatePermissions(
        targetCompany,
        permissions.set('permissions', perms).set('apps', allowedApps)
      )
    }
    close()
  }, [allowedApps, close, permissions, perms, targetCompany, updatePermissions])
  const onToggleApp = React.useCallback(
    (app: AppRecord) => () =>
      setAllowedApps(
        allowedApps.has(app.id) ? allowedApps.remove(app.id) : allowedApps.add(app.id)
      ),
    [allowedApps]
  )
  return (
    <Popin opened={opened} close={close} style={{ margin: 0, width: 900 }}>
      <Box style={{ width: 820 }}>
        <BoxHeader>
          {company ? (
            <HeaderBoxTitle
              title={`Edit ${user.firstName} ${user.lastName} permissions for ${company.name}`}
            />
          ) : (
            <HeaderBoxTitle title={`Add a company to ${user.firstName} ${user.lastName}`} />
          )}
          <HeaderBoxActions>
            <Button onClick={close}>
              <Icon icon="close" />
            </Button>
          </HeaderBoxActions>
        </BoxHeader>
        <BoxBody>
          <Grid template={canUseAppRestriction ? '1fr 380px' : '1fr'} alignItems="stretch" gap={0}>
            <BoxSection
              $padding
              style={{
                height: 'calc(100vh - 200px)',
                maxHeight: 470,
                overflowY: 'auto',
                overflowX: 'hidden',
              }}
            >
              {!company && (
                <InputWrapper
                  label="Company"
                  htmlFor="company"
                  additional={
                    <Feedback
                      type="insight"
                      message="You can search a company by her ID or name, or create a new one by entering a
                    new name"
                    />
                  }
                >
                  <CompanyPicker
                    creatable
                    companyId={targetCompany ? targetCompany.id : null}
                    setCompany={setTargetCompany}
                  />
                </InputWrapper>
              )}
              <InputWrapper label="Roles">
                <Grid template={canUseAppRestriction ? '1fr' : '1fr 1fr'}>
                  {[0, 1].map(col => (
                    <div key={col}>
                      {ROLES.filter((role, key) => (col === 0 ? key < 3 : key >= 3)).map(role => (
                        <div key={role.id} style={{ marginBottom: 20 }}>
                          <Checkbox
                            onChange={onRoleToggle(role)}
                            label={
                              <React.Fragment>
                                <CheckboxRole>{permissionNameFormatter(role.id)}</CheckboxRole>
                                <CheckboxText>
                                  {role.desc}
                                  {role.hint && <Hint>{role.hint}</Hint>}
                                </CheckboxText>
                              </React.Fragment>
                            }
                            disabled={role.autoGrantedWith.reduce(
                              (acc, curr) => perms.has(curr) || acc,
                              false
                            )}
                            checked={
                              role.autoGrantedWith.reduce(
                                (acc, curr) => perms.has(curr) || acc,
                                false
                              ) || perms.has(role.id)
                            }
                          />
                        </div>
                      ))}
                    </div>
                  ))}
                </Grid>
              </InputWrapper>
            </BoxSection>
            {canUseAppRestriction && (
              <BoxSection
                style={{
                  height: 'calc(100vh - 200px)',
                  maxHeight: 470,
                  overflowY: 'auto',
                  marginRight: 2,
                }}
              >
                <RestrictionsHeader>
                  {searchMode ? (
                    <Input
                      autoFocus
                      value={search}
                      onKeyPress={onPreventEnter}
                      onChange={onSearchChange}
                      placeholder="Search for an app..."
                      suffix={{
                        kind: 'icon',
                        value: 'close',
                        handler: () => {
                          setSearchMode(false)
                          setSearch('')
                        },
                      }}
                      style={{
                        paddingLeft: 8,
                        paddingRight: 14,
                        background: 'transparent',
                        border: 'none',
                        boxShadow: 'none',
                      }}
                    />
                  ) : (
                    <RestrictionsSelect>
                      <Checkbox
                        checked={
                          toggleMode === 'some' ? undefined : toggleMode === 'all' ? true : false
                        }
                        handleIndeterminate
                        disabled={isAdmin}
                        onChange={onAllAppToggle}
                        label={
                          <RestrictionsLabel>
                            Has access to :&nbsp;
                            {toggleMode === 'some' ? (
                              <span>
                                {allowedCount} app{allowedApps.size > 1 && 's'} selected
                                <Hint>Grant or revoke access to specific app</Hint>
                              </span>
                            ) : toggleMode === 'none' ? (
                              <span>
                                No app selected <Hint>Grant or revoke access to specific app</Hint>
                              </span>
                            ) : (
                              <span>
                                All apps selected ({allowedCount})
                                <Hint>
                                  {isAdmin
                                    ? 'Users with administrate permission can always access all apps'
                                    : 'Grant or revoke access to specific app'}
                                </Hint>
                              </span>
                            )}
                          </RestrictionsLabel>
                        }
                        style={{ flex: '1 1 auto' }}
                      />
                      <Button
                        type="button"
                        style={{ height: 28, width: 28 }}
                        onClick={onEnableSearch}
                      >
                        <Icon icon="search" />
                      </Button>
                    </RestrictionsSelect>
                  )}
                </RestrictionsHeader>
                {apps.size > 0 && appsFiltered.size <= 0 ? (
                  <RestrictionsEmpty>No apps matching your search</RestrictionsEmpty>
                ) : (
                  appsFiltered.map(app => (
                    <RestrictionsApp key={app.id}>
                      <Checkbox
                        checked={isAdmin || allowedApps.has(app.id)}
                        disabled={isAdmin}
                        onChange={onToggleApp(app)}
                        label={
                          <React.Fragment>
                            <Avatar
                              size={24}
                              url={app.icon}
                              placeholder={app.name.split(' ')[0]}
                              platform={app.platform}
                              style={{ flex: '0 0 auto', margin: '0 10px 0 2px' }}
                            />
                            <RestrictionAppName>{app.name}</RestrictionAppName>
                          </React.Fragment>
                        }
                      />
                    </RestrictionsApp>
                  ))
                )}
              </BoxSection>
            )}
          </Grid>
        </BoxBody>
        <BoxFooter>
          <Button
            kind="primary"
            type="submit"
            intent="action"
            disabled={!targetCompany}
            isLoading={user.loading}
            onClick={onSavePerm}
          >
            Update company permissions
          </Button>
        </BoxFooter>
      </Box>
    </Popin>
  )
}
