import * as React from 'react'

import { texts } from 'components/styled/tokens'

import { capitalize, kformat, percentage, pluralizeAndKformat } from 'com.batch.common/utils'

import { STAT_TYPE } from 'com.batch/orchestration-analytics/infra/types/stat-type'
import { type StatsRecord } from 'com.batch/orchestration-analytics/models/orchestration-stats.record'
import { useGetAnalyticsFilters } from 'com.batch/orchestration-analytics/ui/hooks/use-get-analytics-filters'
import { MetricBlock } from 'com.batch/shared/ui/component/metric-block/metric-block'
import { MetricBlockCard } from 'com.batch/shared/ui/component/metric-block/metric-block.styles'
import { Trend, TrendTooltip } from 'com.batch/shared/ui/component/trend/trend'

type Props = {
  stats: StatsRecord | null | undefined
  context: 'automations' | 'campaigns' | 'project'
  token?: string
}
export const PushKeyMetrics = ({ stats, context, token }: Props): React.ReactElement => {
  const hasTrends = React.useMemo(() => context !== 'campaigns', [context])
  const { dateRange } = useGetAnalyticsFilters(token)

  return (
    <React.Fragment>
      <MetricBlockCard>
        <MetricBlock
          variant="success"
          label={capitalize(STAT_TYPE.SENT)}
          value={kformat(stats?.sent.value ?? 0)}
          hint={
            <p style={{ whiteSpace: 'nowrap' }}>
              Push notifications are sent to all
              <br />
              opted-in devices associated to Profiles
            </p>
          }
          trend={
            dateRange && hasTrends ? (
              <Trend
                trend={stats?.sent.trend ?? 0}
                tooltip={
                  <TrendTooltip
                    metricContent={
                      <span>
                        <strong>{kformat(stats?.sent.previousPeriodValue ?? 0)}</strong> sent push
                        notification
                        {stats?.sent.previousPeriodValue && stats?.sent.previousPeriodValue > 1
                          ? 's'
                          : ''}
                      </span>
                    }
                    dateRange={dateRange}
                  />
                }
              />
            ) : undefined
          }
        />
      </MetricBlockCard>
      <MetricBlockCard>
        <MetricBlock
          variant="action"
          label={capitalize(STAT_TYPE.OPENED)}
          value={
            <span>
              {percentage(stats?.open.rate ?? 0, 2, false, false)}
              <span style={texts.metric}>%</span>
            </span>
          }
          subtitle={pluralizeAndKformat('unique open', stats?.open.unique ?? 0)}
          trend={
            dateRange && hasTrends ? (
              <Trend
                trend={stats?.open.trend ?? 0}
                tooltip={
                  <TrendTooltip
                    metricContent={
                      <span>
                        <strong>{percentage(stats?.open.previousPeriodRate ?? 0, 2)}</strong> opened
                        push notifications
                      </span>
                    }
                    dateRange={dateRange}
                  />
                }
              />
            ) : undefined
          }
        />
      </MetricBlockCard>
      <MetricBlockCard>
        <MetricBlock
          variant="danger"
          label={capitalize(STAT_TYPE.BOUNCED)}
          value={
            <span>
              {percentage(stats?.bounce.rate ?? 0, 2, false, false)}
              <span style={texts.metric}>%</span>
            </span>
          }
          subtitle={`${kformat(stats?.bounce.value ?? 0)} unique bounces`}
          trend={
            dateRange && hasTrends ? (
              <Trend
                trend={stats?.bounce.trend ?? 0}
                isGrowthUnhealthy={true}
                tooltip={
                  <TrendTooltip
                    metricContent={
                      <span>
                        <strong>{percentage(stats?.bounce.previousPeriodRate ?? 0, 2)}</strong>{' '}
                        bounced push notifications
                      </span>
                    }
                    dateRange={dateRange}
                  />
                }
              />
            ) : undefined
          }
        />
      </MetricBlockCard>
    </React.Fragment>
  )
}
