import * as React from 'react'
import { useSelector } from 'com.batch.common/react-redux'

import { useAction } from 'components/_hooks'
import { Button, ButtonNavLink } from 'components/common/button'
import { confirm } from 'components/common/confirm'

import { dayjs } from 'com.batch.common/dayjs.custom'

import { type CampaignRecord } from 'com.batch.redux/_records'
import { currentAppSelector } from 'com.batch.redux/app'
import {
  archive,
  setCampaignStateRunning,
  setCampaignStateStopped,
} from 'com.batch.redux/campaign.action'
import { AbTestedInAppFactory } from 'com.batch.redux/content.records'
import { AbTestedThemeFactory, ThemeFactory } from 'com.batch.redux/theme.records'
import { ThemesSelector } from 'com.batch.redux/theme.selector'

import { getConfirmWordings } from 'com.batch/orchestration-list/ui/components/confirm-wording'
import { useGetSchedulingTypeFromUrl } from 'com.batch/orchestration-list/ui/hooks/use-scheduling-type-from-url'
import { OrchestrationState } from 'constants/common'

type CampaignTableProps = {
  campaign: CampaignRecord
  children?: React.ReactNode
  closeDropdown: () => void
  url: string
  appId: number
}

export const CampaignTableDropDownItems = ({
  campaign,
  url,
  closeDropdown,
  appId,
}: CampaignTableProps): React.ReactElement => {
  const archiveBound = useAction(() => archive(campaign))
  const stopCampaign = useAction(() => setCampaignStateStopped({ appId, campaign }))
  const runCampaign = useAction(() => setCampaignStateRunning({ appId, campaign }))
  const app = useSelector(currentAppSelector)
  const themes = useSelector(ThemesSelector)
  const wordings = React.useMemo(() => getConfirmWordings({ campaign }), [campaign])
  const runVerb = React.useMemo(
    () => (campaign.schedulingType === 'campaigns' ? 'Run' : 'Launch'),
    [campaign.schedulingType]
  )
  const campaignWord = useGetSchedulingTypeFromUrl()

  const orchestrationName = campaignWord === 'campaigns' ? 'campaign' : 'automation'
  const deleteConfirmModal = React.useCallback(() => {
    closeDropdown()
    confirm({
      ...wordings.archive,
      sensitive: true,
    }).then(archiveBound, () => {})
  }, [archiveBound, closeDropdown, wordings.archive])

  const stopConfirmModal = React.useCallback(() => {
    closeDropdown()
    confirm({
      ...wordings.stop,
      sensitive: true,
    }).then(stopCampaign, () => {})
  }, [closeDropdown, stopCampaign, wordings.stop])

  const runConfirmModal = React.useCallback(
    (campaign: CampaignRecord) => () => {
      closeDropdown()

      const variantsThemes = AbTestedThemeFactory({
        a: themes.find(t => t.code === campaign.variantsThemeCodes.a, ThemeFactory()),
        b: themes.find(t => t.code === campaign.variantsThemeCodes.b, ThemeFactory()),
      })

      let warningWebiewNonSecure = ''
      const variants = ['a', 'b'] as const
      variants.forEach(variant => {
        const theme = variantsThemes.get(variant)

        const webviewURL = campaign.data.inapp
          .get(app.defaultLanguageId ?? 'default', AbTestedInAppFactory())
          .get(variant).webviewUrl

        if (
          (campaign.type === 'in-app' || campaign.hasLanding) &&
          theme &&
          theme.payloadVars.kind === 'webview' &&
          webviewURL.indexOf('http://') !== -1 &&
          warningWebiewNonSecure === ''
        ) {
          warningWebiewNonSecure =
            ' It uses a non secure URL for webview, which is not recommanded and can lead to unexpected errors.'
        }
      })

      const confirmProps = {
        title: wordings.run.title,
        message: (
          <React.Fragment>
            {wordings.run.message}
            {warningWebiewNonSecure}
          </React.Fragment>
        ),
        confirm: wordings.run.confirm,
      }

      confirm(confirmProps).then(runCampaign, () => {})
    },
    [
      app.defaultLanguageId,
      closeDropdown,
      runCampaign,
      themes,
      wordings.run.confirm,
      wordings.run.message,
      wordings.run.title,
    ]
  )

  return (
    <React.Fragment>
      <ButtonNavLink to={url}>Go to analytics</ButtonNavLink>
      {campaign.state === OrchestrationState.RUNNING && (
        <Button onClick={stopConfirmModal}>Stop {orchestrationName}</Button>
      )}
      {(campaign.state === OrchestrationState.STOPPED ||
        campaign.state === OrchestrationState.DRAFT) && (
        <Button
          onClick={runConfirmModal(campaign)}
          disabled={
            campaign.state === OrchestrationState.DRAFT &&
            campaign.start &&
            campaign.start.isBefore(dayjs.utc())
              ? true
              : false
          }
        >
          {runVerb} {orchestrationName}
        </Button>
      )}
      <Button onClick={deleteConfirmModal} intent="danger">
        Delete
      </Button>
    </React.Fragment>
  )
}
