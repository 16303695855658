import { type OrchestrationHistoryRecord } from 'com.batch/orchestration/infra/parses/orchestration.parse'
import { Grid } from 'components/common/grid'
import { CampaignStateIcon } from 'components/project/campaign/common/campaign-state-icon'
import { colors } from 'components/styled/tokens'
import { HorizontalDivider } from 'components/styled/utils'
import { OrchestrationState } from 'constants/common'
import { type Dayjs } from 'dayjs'
import React from 'react'
import styled from 'styled-components'

type HistoryTooltipProps = {
  history: OrchestrationHistoryRecord
  orchestrationState: OrchestrationState
  schedulingType: schedulingType
}

export const HistoryTooltip = ({
  history,
  orchestrationState,
  schedulingType,
}: HistoryTooltipProps): React.ReactElement => {
  const isCompleted = React.useMemo(() => {
    return orchestrationState === OrchestrationState.COMPLETED
  }, [orchestrationState])

  const showFirstRunDate = React.useMemo(() => {
    return !history.firstRunDate?.isSame(history.lastStatusChangeDate)
  }, [history])

  return (
    <Grid
      template="1fr"
      style={{
        justifyContent: 'flex-start',
        fontSize: 14,
        fontWeight: 400,
        textAlign: 'left',
        lineHeight: '26px',
      }}
    >
      <Grid
        template={isCompleted ? '1fr auto' : '56px 134px auto'}
        style={{
          fontWeight: 500,
          color: colors.textContrast,
          lineHeight: '22px',
        }}
      >
        {!isCompleted ? 'Currently' : ''}{' '}
        <Capitalized>{orchestrationState.toLowerCase()}</Capitalized>
        <CampaignStateIcon
          state={orchestrationState}
          size={16}
          schedulingType={schedulingType}
          grayscale
        />
      </Grid>
      {history.lastStatusChangeDate && orchestrationState !== OrchestrationState.COMPLETED && (
        <div style={{ color: colors.textContrastLight, fontWeight: 400 }}>
          Since {history.lastStatusChangeDate.local().format('MMM D, YYYY⋅HH:mm')}
        </div>
      )}
      <HorizontalDivider
        color={colors.strokeContrast}
        style={{ width: 226 }}
        margin="8px 0 8px 0"
      />
      {history.lastEditDate && <DateLine name="Last edit" date={history.lastEditDate} />}
      {history.firstRunDate && schedulingType !== 'campaigns' && showFirstRunDate && (
        <DateLine name="First run" date={history.firstRunDate} />
      )}
      {history.creationDate && <DateLine name="Created at" date={history.creationDate} />}
    </Grid>
  )
}

const DateLine = ({ name, date }: { name: string; date: Dayjs }): React.ReactElement => {
  return (
    <div style={{ display: 'flex', justifyContent: 'space-between' }}>
      <div style={{ fontWeight: 500, fontSize: 14, color: colors.textContrast }}>{name}</div>
      <div style={{ color: colors.textContrastLight }}>
        {date.local().format('MMM D, YYYY⋅HH:mm')}
      </div>
    </div>
  )
}

const Capitalized = styled.div`
  text-transform: capitalize;
`
