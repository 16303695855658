import { type DispatchBoundFn } from 'com.batch.redux/_records'

import {
  type FilterPushTokensType,
  type PushContentRecord,
  type PushSettingsRecord,
} from 'com.batch/message/models/message.records'
import { setTemplating } from 'com.batch/templating/usecases/set-templating'

export type UpdatePushMessageRecordAction = {
  type: 'UPDATE_PUSH_MESSAGE_RECORD'
  payload: {
    messageId: string
    lang?: string
    content?: PushContentRecord
  }
}
export const updatePushMessageRecord = ({
  messageId,
  lang,
  content,
}: {
  messageId: string
  lang?: string
  content?: PushContentRecord
  settings?: PushSettingsRecord
}): DispatchBoundFn<UpdatePushMessageRecordAction> => {
  return dispatch => {
    return dispatch({
      type: 'UPDATE_PUSH_MESSAGE_RECORD',
      payload: {
        messageId,
        lang,
        content,
      },
    })
  }
}

export type UpdatePushMessageRecordTokenModeAction = {
  type: 'UPDATE_PUSH_MESSAGE_TOKEN_MODE_RECORD'
  payload: {
    tokenMode: FilterPushTokensType
  }
}

export const updatePushMessageRecordTokenModeAction = ({
  tokenMode,
}: {
  tokenMode: FilterPushTokensType
}): DispatchBoundFn<UpdatePushMessageRecordTokenModeAction> => {
  return dispatch => {
    return dispatch({
      type: 'UPDATE_PUSH_MESSAGE_TOKEN_MODE_RECORD',
      payload: {
        tokenMode,
      },
    })
  }
}

export type UpdatePushMessageContentAction = {
  type: 'UPDATE_PUSH_MESSAGE_CONTENT'
  payload: {
    messageId: string
    lang: string
    field:
      | 'pushBody'
      | 'pushTitle'
      | 'pushPicture'
      | 'pushIcon'
      | 'deeplink'
      | 'iosDeeplink'
      | 'androidDeeplink'
      | 'webpushDeeplink'
    value: string
  }
}

export const updatePushMessageContent = ({
  messageId,
  lang,
  field,
  value,
}: {
  messageId: string
  lang: string
  field:
    | 'pushBody'
    | 'pushTitle'
    | 'pushPicture'
    | 'pushIcon'
    | 'deeplink'
    | 'iosDeeplink'
    | 'androidDeeplink'
    | 'webpushDeeplink'
  value: string
  isInstant?: boolean
}): DispatchBoundFn<void> => {
  return dispatch => {
    dispatch<UpdatePushMessageContentAction>({
      type: 'UPDATE_PUSH_MESSAGE_CONTENT',
      payload: {
        messageId,
        lang,
        field,
        value,
      },
    })
    dispatch(
      setTemplating({
        messageId,
        field,
        value,
        valueIsHtml: false,
        langId: lang,
      })
    )
  }
}
