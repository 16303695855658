import * as Sentry from '@sentry/react'
import { useEffect } from 'react'
import {
  createRoutesFromChildren,
  matchRoutes,
  useLocation,
  useNavigationType,
} from 'react-router-dom'

const isProductionSentry = window.location.host.indexOf('batch.com') !== -1

Sentry.init({
  dsn: process.env.sentryDSN,
  release: process.env.sentryRelease,
  environment: isProductionSentry ? 'production' : 'staging',
  integrations: [
    // See docs for support of different versions of variation of react router
    // https://docs.sentry.io/platforms/javascript/guides/react/configuration/integrations/react-router/
    Sentry.reactRouterV6BrowserTracingIntegration({
      useEffect,
      useLocation,
      useNavigationType,
      createRoutesFromChildren,
      matchRoutes,
    }),
    // Sentry.replayIntegration(),
  ],
  beforeSend(event) {
    event.breadcrumbs?.map(breadcrumb => {
      if (breadcrumb.category === 'redux.action' && breadcrumb.data?.payload) {
        delete breadcrumb.data.payload
      }
    })
    return event
  },
  beforeBreadcrumb(breadcrumb) {
    if (
      (breadcrumb.category === 'fetch' || breadcrumb.category === 'xhr') &&
      breadcrumb.data?.url
    ) {
      const ignoredDomains = [
        'fullstory.com',
        'segment.io',
        'segment.com',
        'getbeamer.com',
        'pingdom.net',
        'google-analytics.com',
      ]
      const shouldIgnore = ignoredDomains.some(domain => breadcrumb.data?.url.includes(domain))
      if (shouldIgnore) {
        return null
      }
    }
    return breadcrumb
  },

  // Set tracesSampleRate to 1.0 to capture 100%
  // of transactions for tracing.
  tracesSampleRate: 0.5,

  // Set `tracePropagationTargets` to control for which URLs trace propagation should be enabled
  tracePropagationTargets: [`${window.location.origin}/api`],

  // SESSION REPLAY
  // Capture Replay for 10% of all sessions,
  // plus for 100% of sessions with an error
  // replaysSessionSampleRate: 0.1,
  // replaysOnErrorSampleRate: 1.0,
})
