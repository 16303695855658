import * as React from 'react'
import styled from 'styled-components'

import { MetricLabel, MetricValue } from 'components/campaign/review/data/metric'
import { FlexLineItem } from 'components/common/flexline'
import { Tooltip } from 'com.batch/shared/ui/component/tooltip'
import * as colorsLegacy from 'components/styled/colors'
import { colors } from 'components/styled/tokens'

import { kformat, percentage, numberFormat } from 'com.batch.common/utils'

type AnalyticsMetricProps = {
  buildTooltip?: (formatedValue: string) => React.ReactNode
  className?: any
  color: string
  label: string
  previousValue: number
  style?: any
  value: number
}

export const AnalyticsMetricItem = ({
  buildTooltip,
  className,
  color,
  label,
  previousValue,
  style,
  value,
}: AnalyticsMetricProps): React.ReactElement => {
  const diff = previousValue ? previousValue - value : 0
  return (
    <FlexLineItem
      grow={1}
      style={Object.assign({}, style, {
        boxShadow: `0 3px 0 0 ${color} inset`,
      })}
      className={className}
    >
      <Tooltip
        tooltip={buildTooltip ? buildTooltip(numberFormat(value)) : numberFormat(value)}
        placement="top"
        width={200}
        style={{ textAlign: 'center' }}
      >
        <div style={{ margin: 32, display: 'flex', alignItems: 'center', flexDirection: 'column' }}>
          <MetricLabel>{label}</MetricLabel>
          <MetricValue>{kformat(value)}</MetricValue>
          {diff === 0 ? (
            <MetricIndicator style={{ color: '#CCC' }}>–</MetricIndicator>
          ) : (
            <MetricIndicator
              style={{ color: diff < 0 ? colorsLegacy.good.shade.s4 : colors.textDanger }}
            >
              {diff && (
                <React.Fragment>
                  {diff > 0 ? '-' : '+'}
                  {percentage(diff / previousValue, 0)} ({kformat(previousValue)})
                </React.Fragment>
              )}
            </MetricIndicator>
          )}
        </div>
      </Tooltip>
    </FlexLineItem>
  )
}
const MetricIndicator = styled.label`
  margin-top: 3px;
`
