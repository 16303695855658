import { type Set, type Map } from 'immutable'

import {
  type Experiment,
  type EmailContentForCampaign,
  type ChannelMessage,
} from '../types/orchestration-campaign.types'
import { type MessageStateRecord } from 'com.batch/message/store/message.state'
import { type ProjectRecord } from 'com.batch.redux/project.records'
import { getLangAndRegionArray } from 'com.batch.redux/target/target.helper'
import { type TargetStateRecord } from 'com.batch.redux/target/target.records'
import { type UserRecord } from 'com.batch.redux/user.records'

import { getWeight } from 'com.batch/message/models/message.helper'
import { type OrchestrationCampaignOnly } from 'com.batch/orchestration/infra/types/orchestration.types'
import { type OrchestrationCampaignRecord } from 'com.batch/orchestration-campaign/models/campaign.records'
import { formatPushMessage } from 'com.batch/push/infra/parses/push.format'
import { type OrchestrationPush } from 'com.batch/push/infra/types/push.message.types'
import { type SmsContent } from 'com.batch/sms/infra/types/sms.message.types'
import { OrchestrationState } from 'constants/common/orchestration-state'

type OrchestrationCampaignFormatterProps = {
  target: TargetStateRecord
  campaign: OrchestrationCampaignRecord
  project: ProjectRecord
  state: OrchestrationState
  messageIdsReplacement: Map<string, string>
  name: string
  id: string | null | undefined
  query: string | null | undefined
  message: MessageStateRecord
  labelCodes: Set<string>
  user: UserRecord
}

type OrchestrationCampaignFormatter = (
  arg1: OrchestrationCampaignFormatterProps
) => OrchestrationCampaignOnly
export const formatOrchestrationCampaign: OrchestrationCampaignFormatter = ({
  target,
  campaign,
  messageIdsReplacement,
  project,
  query,
  id,
  state,
  message,
  name,
  user,
  labelCodes,
}) => {
  const messagesMap: {
    [key: string]:
      | {
          emailChannel: EmailContentForCampaign
        }
      | {
          smsMessage: SmsContent
        }
      | {
          pushMessage: OrchestrationPush
        }
  } = {}
  const experimentDashboard = campaign.messageConfig.experiment
  let experimentOS: Experiment | null | undefined
  const variantsOS: Array<{
    id?: number
    activated?: boolean
    weight?: number
    messageReference: string
  }> = []
  if (experimentDashboard.enabled) {
    experimentDashboard.variants.forEach((messageId, variantId) => {
      variantsOS.push({
        id: variantId,
        messageReference: messageIdsReplacement.get(messageId, messageId),
        activated:
          !experimentDashboard.winnerVariantId || experimentDashboard.winnerVariantId === variantId,
        weight:
          // Si c'est le winner son poids est de 100
          experimentDashboard.winnerVariantId === variantId
            ? 100
            : // Si non, on calcule son poids, sauf si il y a un winner dans ce cas le poids est forcément de 0
              experimentDashboard.winnerVariantId
              ? 0
              : getWeight({ variantId, count: experimentDashboard.variants.size }),
      })
    })
    experimentOS = {
      id: experimentDashboard.id,
      status: experimentDashboard.winningVariantSelectionDate
        ? 'EXPERIMENT_STATUS_COMPLETED'
        : 'EXPERIMENT_STATUS_ONGOING',
      variants: variantsOS,
      winningVariantSelectionDate:
        experimentDashboard.winningVariantSelectionDate?.format() ?? undefined,
    }
  }
  const ids = experimentDashboard.enabled
    ? experimentDashboard.variants.toList().toArray()
    : [campaign.messageConfig.messageTypedId]
  const channels: Array<ChannelMessage> = []
  ids.forEach(messageTypedId => {
    const replacedMessageId = messageIdsReplacement.get(messageTypedId, messageTypedId)
    const emailMsg = message.email.get(messageTypedId)
    const multilanguageEnabled = campaign.messageConfig.multilanguageEnabled
    if (emailMsg && campaign.messageConfig.channel === 'email') {
      channels.push({
        emailChannel: {
          messageReference: replacedMessageId,
        },
      })
      const localizedContent = emailMsg.get('localizedContent')
      messagesMap[replacedMessageId] = {
        emailChannel: {
          email: {
            emailTarget: target.subscriptionStatus === 'marketing' ? 'Marketing' : 'Fullbase',
            localizedEmails: (multilanguageEnabled
              ? localizedContent
              : localizedContent.filter((_, lang) => lang === 'default')
            )
              .map((email, language) => {
                return {
                  ...(language && language !== 'default' ? { language } : {}),
                  subject: email.subject ?? '',
                  html: email.html ?? '',
                  replyTo: email.replyTo ?? undefined,
                  from: {
                    senderIdentityId: email.senderIdentityId?.toString() ?? '',
                    address: {
                      email: email.fromEmail ?? '',
                      name: email.name ?? '',
                    },
                  },
                }
              })
              .toArray()
              .map(kv => kv[1]),
          },
        },
      }
    }
    const pushMsg = message.push.get(messageTypedId)
    if (pushMsg && campaign.messageConfig.channel === 'push') {
      const messages = pushMsg.localizedContent
      const { pushSettings: settings, platforms } = campaign.messageConfig
      channels.push({
        messageReference: replacedMessageId,
        channelType: 'CHANNEL_TYPE_PUSH',
      })
      messagesMap[replacedMessageId] = {
        pushMessage: formatPushMessage({
          settings,
          messages,
          multilanguageEnabled,
          platforms,
        }),
      }
    }
    const smsMsg = message.sms.get(messageTypedId)
    if (smsMsg && campaign.messageConfig.channel === 'sms') {
      channels.push({
        messageReference: replacedMessageId,
        channelType: 'CHANNEL_TYPE_SMS',
      })
      const localizedContent = smsMsg.get('localizedContent')
      messagesMap[replacedMessageId] = {
        smsMessage: {
          target: target.subscriptionStatus === 'marketing' ? 'MARKETING' : 'TRANSACTIONAL',
          localizedSms: (multilanguageEnabled
            ? localizedContent
            : localizedContent.filter((_, lang) => lang === 'default')
          )
            .map((sms, language) => {
              return {
                ...(language && language !== 'default' ? { language } : {}),
                message: {
                  content: { text: sms.smsMessage },
                },
              }
            })
            .toArray()
            .map(kv => kv[1]),
        },
      }
    }
  })
  const { languages, regions } = getLangAndRegionArray({ targetingState: target, user })
  return {
    campaign: {
      scheduling: {
        ...{
          token: id ?? '',
          localTimezoneDependant: campaign.sendType === 'now' ? false : campaign.tzAware,
          state:
            state === OrchestrationState.RUNNING || state === OrchestrationState.STOPPED
              ? state
              : OrchestrationState.STOPPED,
          projectKey: {
            textual: { text: project.projectKey },
          },
        },
        ...(campaign.sendType === 'scheduled' && campaign.oneTime.sendDate
          ? { oneTime: { pushDate: campaign.oneTime.sendDate.format('YYYYMMDDHHmmss') } }
          : campaign.sendType === 'now'
            ? { oneTime: {} }
            : campaign.sendType === 'recurring'
              ? {
                  recurrent: {
                    recurrence: campaign.recurrent.recurrence,
                    frequency: campaign.recurrent.frequency,
                    startDate: campaign.recurrent.startDate
                      ? campaign.recurrent.startDate.format('YYYYMMDDHHmmss')
                      : undefined,
                    endDate: campaign.recurrent.endDate
                      ? campaign.recurrent.endDate.format('YYYYMMDDHHmmss')
                      : undefined,
                  },
                }
              : {}),
      },
      content: {
        name,
        query,
        capping:
          campaign.sendType === 'recurring' && campaign.recurrent.hasCapping
            ? campaign.recurrent.capping
            : undefined,
        targeting: {
          languages,
          regions,
        },
        channels,
        labels: labelCodes.toArray(),
        ...(experimentOS ? { experiment: experimentOS } : {}),
      },
    },
    messages: messagesMap,
  }
}
