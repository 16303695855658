import { LoadingStatus } from 'constants/common'
import Immutable, { type RecordOf } from 'immutable'

import { type Dayjs } from 'com.batch.common/dayjs.custom'

type OrchestrationListStatsStateProps = {
  loadingState: LoadingStatus
  sentLatestPeriod: number
  trend: number
  lastSent: Dayjs | null
  totalSent: number
}

export const OrchestrationListStatsStateFactory =
  Immutable.Record<OrchestrationListStatsStateProps>({
    loadingState: LoadingStatus.INIT,
    sentLatestPeriod: 0,
    trend: 0,
    lastSent: null,
    totalSent: 0,
  } as OrchestrationListStatsStateProps)

export type OrchestrationListStatsStateRecord = RecordOf<OrchestrationListStatsStateProps>
