import * as React from 'react'
import { Provider, Root, Portal, Content } from '@radix-ui/react-tooltip'
import { useSpring } from '@react-spring/web'
import { TooltipTrigger, TooltipContent } from './tooltip.styles'
import { type CSSProperties } from 'styled-components'

export type placement =
  | 'top'
  | 'top-start'
  | 'top-end'
  | 'right'
  | 'right-start'
  | 'right-end'
  | 'bottom'
  | 'bottom-start'
  | 'bottom-end'
  | 'left'
  | 'left-start'
  | 'left-end'

export type Props = {
  children: React.ReactElement
  delay?: number
  // offset[0] = sideOffset & offset[1] = alignOffset
  offset?: [number, number]
  padding?: number
  placement?: placement
  tooltip?: React.ReactNode
  width?: number
  style?: CSSProperties
}

export const Tooltip = ({
  children,
  delay = 0,
  offset = [4, 0],
  padding = 8,
  placement = 'top-start',
  tooltip,
  width,
  style = {},
}: Props): React.ReactElement => {
  const [isOpen, setIsOpen] = React.useState(false)

  const { side, align } = React.useMemo(
    () => ({
      side: placement.split('-')[0] as 'top' | 'right' | 'bottom' | 'left',
      align: (placement.split('-')[1] ?? 'center') as 'start' | 'center' | 'end',
    }),
    [placement]
  )

  const animationMargin = React.useMemo(() => {
    switch (side) {
      case 'bottom':
        return 'translate3d(0,-10px,0)'

      case 'right':
        return 'translate3d(-10px,0,0)'

      case 'left':
        return 'translate3d(10px,0,0)'

      default:
        return 'translate3d(0,10px,0)'
    }
  }, [side])

  const styleAnimation = useSpring({
    opacity: isOpen ? 1 : 0,
    display: isOpen ? 'block' : 'none',
    transform: isOpen ? 'translate3d(0,0,0)' : animationMargin,
    config: {
      mass: 0.1,
      tension: 100,
      friction: 6,
    },
  })

  const isTooltipEmpty = React.useMemo(() => !tooltip, [tooltip])

  const handleClick = React.useCallback(() => setIsOpen(!isOpen), [isOpen])

  if (isTooltipEmpty) return children
  return (
    <Provider delayDuration={delay} disableHoverableContent={false}>
      <Root open={isOpen} onOpenChange={handleClick}>
        <TooltipTrigger asChild>{children}</TooltipTrigger>

        <Portal forceMount={true}>
          <Content
            align={align}
            alignOffset={offset[1]}
            arrowPadding={20}
            avoidCollisions={true}
            side={side}
            sideOffset={offset[0]}
            sticky="partial"
            collisionPadding={30}
            style={{ zIndex: 40 }}
          >
            <TooltipContent
              $padding={padding}
              $width={width}
              style={{ ...style, ...styleAnimation }}
            >
              {tooltip}
            </TooltipContent>
          </Content>
        </Portal>
      </Root>
    </Provider>
  )
}
