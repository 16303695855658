import Immutable, { type Map } from 'immutable'

import {
  OrchestrationListStatsStateFactory,
  type OrchestrationListStatsStateRecord,
} from 'com.batch/orchestration-list/models/orchestration-list-stats-state.records'
import { type OrchestrationStatsDynamicDimension } from 'com.batch/shared/infra/types/grpc-stats-service'
import { computeEvolution } from 'com.batch/orchestration-analytics/infra/parses/compute'
import { dayjs } from 'com.batch.common/dayjs.custom'
import { LoadingStatus } from 'constants/common'

export const parseOrchestrationOngoingStatsToListStatsState = (
  stats?: Array<OrchestrationStatsDynamicDimension> | null
): Map<string, OrchestrationListStatsStateRecord> => {
  return stats
    ? stats.reduce((acc, { currentPeriod, previousPeriod }) => {
        const sentLatestPeriod = currentPeriod.sent ?? 0
        const previousSent = previousPeriod?.sent ?? 0
        const trend = computeEvolution(sentLatestPeriod, previousSent)
        const lastSent = currentPeriod.lastSent ? dayjs.unix(currentPeriod.lastSent) : null

        return acc.set(
          currentPeriod.token,
          OrchestrationListStatsStateFactory({
            loadingState: LoadingStatus.LOADED,
            sentLatestPeriod,
            trend,
            lastSent,
          })
        )
      }, Immutable.Map<string, OrchestrationListStatsStateRecord>())
    : Immutable.Map<string, OrchestrationListStatsStateRecord>()
}

export const parseOrchestrationCompletedStatsToListStatsState = (
  stats?: Array<OrchestrationStatsDynamicDimension> | null
): Map<string, OrchestrationListStatsStateRecord> => {
  return stats
    ? stats.reduce((acc, { currentPeriod }) => {
        const totalSent = currentPeriod.sent ?? 0
        const lastSent = currentPeriod.lastSent ? dayjs.unix(currentPeriod.lastSent) : null

        return acc.set(
          currentPeriod.token,
          OrchestrationListStatsStateFactory({
            loadingState: LoadingStatus.LOADED,
            totalSent,
            lastSent,
          })
        )
      }, Immutable.Map<string, OrchestrationListStatsStateRecord>())
    : Immutable.Map<string, OrchestrationListStatsStateRecord>()
}
