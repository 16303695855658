import styled, { css } from 'styled-components'

import { Icon } from 'components/common/svg-icon'
import { colors, schemes } from 'components/styled/tokens'
import { base } from 'components/styled/tokens/texts'

export const Separator = styled.div`
  width: 1px;
  height: 20px;
  margin-left: 4px;
  background-color: ${colors.stroke};
`
type ButtonDevLiveProps = {
  isFocus?: boolean
}
export const ButtonDevLive = styled.button<ButtonDevLiveProps>`
  height: 36px;
  padding: 10px 8px;
  font-weight: 500;
  font-size: 13px;
  line-height: 12px;
  border: 0;
  border-radius: 4px;

  ${props =>
    props.isFocus
      ? css`
          color: ${schemes.grayscale['60']};
          background-color: ${schemes.darklucent['10']};
        `
      : css`
          color: ${colors.textLight};
        `};

  &:hover {
    background-color: ${schemes.darklucent['20']};
  }

  &:focus {
    outline: 0;
  }
`

export const CounterItemsContainer = styled.span`
  font-size: ${base.size};
  font-weight: 400;
  color: ${colors.textLight};
  margin-left: 10px;
`

export const TestIcon = styled(Icon)`
  margin-left: 10px;
  font-size: 12px;

  path {
    color: white;
    fill: ${colors.textLight};
  }

  &:hover {
    cursor: pointer;

    path {
      color: white;
      fill: ${colors.textLight};
    }
  }
`

export const AllowedAttributeList = styled.div`
  width: 100%;
  text-align: left;

  & > div {
    padding: 7px 10px;
    border-bottom: 1px solid rgba(255, 255, 255, 0.06);

    &:last-of-type {
      border: 0;
    }
  }
`

export const TypeTooltipMessage = styled.div`
  width: 100%;
  text-align: left;
  font-size: 12px;
  line-height: 16px;
  letter-spacing: -0.01em;

  > div {
    margin-top: 5px;
    font-weight: 300;
  }
`

export const EmptyScopedCustomData = styled.div`
  text-align: center;

  h3 {
    margin-bottom: 16px;
    font-size: 14px;
    font-weight: 500;
    color: ${colors.text};
  }
`
