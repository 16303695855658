import Immutable, { type Set } from 'immutable'

import { dayjs, type DateRange } from 'com.batch.common/dayjs.custom'

import { type OrchestrationListSortableBy } from 'com.batch/orchestration-list/store/orchestration-list.state'
import { OrchestrationState } from 'constants/common/orchestration-state'

export const extractUrlParamsAndRestoreSavedSort = (location: {
  search: string
  pathname: string
}): {
  mode: schedulingType
  search: string
  page: number
  sortBy: OrchestrationListSortableBy
  sortDirection: 'asc' | 'dsc'
  statuses: Set<OrchestrationState>
  platforms: Set<ProjectPlatforms>
  channels: Set<ChannelUntilCleanup>
  labels: Set<string>
  segments: Set<string>
  dateRange: DateRange | null | undefined
} => {
  const qs = new URLSearchParams(location.search)
  const page = parseInt(qs.get('page') || '1')
  const search = qs.get('search') || ''
  const mode: schedulingType = location.pathname?.includes('/campaigns')
    ? 'campaigns'
    : 'automations'

  const statusesString =
    qs
      .get('statuses')
      ?.split(',')
      .filter((status: string) => status) ?? []
  const statuses = Immutable.Set.of(
    ...statusesString.map(parseStatusParam).filter(status => !!status)
  )

  const channelString =
    qs
      .get('channels')
      ?.split(',')
      .filter((channel: string) => channel) ?? []
  const channels = Immutable.Set.of(
    ...channelString.map(parseChannelParam).filter(channel => !!channel)
  )
  const platformsString =
    qs
      .get('platforms')
      ?.split(',')
      .filter((platform: string) => platform) ?? []
  const platforms = Immutable.Set.of(
    ...platformsString.map(parsePlatformsParam).filter(platform => !!platform)
  )

  const labelString = qs.get('labels') ?? ''
  const labels = Immutable.Set.of(...labelString.split(',').filter(label => label))

  const segmentString = qs.get('segments') ?? ''
  const segments = Immutable.Set.of(...segmentString.split(',').filter(segment => segment))

  const fromString = qs.get('from') ?? ''
  const toString = qs.get('to') ?? ''
  const dateRange = fromString && toString ? { from: dayjs(fromString), to: dayjs(toString) } : null

  const projectId = window?.initialData?.projectId ?? ''
  const defaultSortDirection = localStorage.getItem(`${projectId}-${mode}-sortDirection`) ?? 'dsc'
  const defaultSortBy = localStorage.getItem(`${projectId}-${mode}-sortBy`) ?? 'token'
  const coercedSortDirection = defaultSortDirection === 'asc' ? 'asc' : 'dsc'
  const coersedSortBy =
    defaultSortBy === 'name' ? 'name' : defaultSortBy === 'token' ? 'token' : 'when'
  return {
    page,
    mode,
    sortDirection: qs.get('sortDirection') === 'asc' ? 'asc' : coercedSortDirection,
    sortBy:
      qs.get('sortBy') === 'name' ? 'name' : qs.get('sortBy') === 'when' ? 'when' : coersedSortBy,
    search,
    statuses,
    channels,
    labels,
    platforms,
    dateRange,
    segments,
  }
}

const parseStatusParam = (status: string): OrchestrationState | null => {
  switch (status) {
    case 'draft':
      return OrchestrationState.DRAFT
    case 'running':
      return OrchestrationState.RUNNING
    case 'stopped':
      return OrchestrationState.STOPPED
    case 'completed':
      return OrchestrationState.COMPLETED
    default:
      return null
  }
}

const parseChannelParam = (channel: string): ChannelUntilCleanup | null => {
  switch (channel) {
    case 'push':
      return 'push'
    case 'sms':
      return 'sms'
    case 'email':
      return 'email'
    default:
      return null
  }
}

const parsePlatformsParam = (platform: string): ProjectPlatforms | null => {
  switch (platform) {
    case 'ios':
      return 'ios'
    case 'android':
      return 'android'
    case 'webpush':
      return 'webpush'
    default:
      return null
  }
}
