import * as React from 'react'
import { useSelector } from 'com.batch.common/react-redux'

import { useAction } from 'components/_hooks/use-action'
import { Button } from 'components/common/button'
import { Icon } from 'components/common/svg-icon'
import { Tooltip } from 'com.batch/shared/ui/component/tooltip'
import { colors } from 'components/styled/tokens'

import { inlineEditorConfigSelector } from 'com.batch/message-builder/store/inline-editor.selector'

import { setInlineEditor } from 'com.batch/message-builder/usecases/manage-inline-editor'

type InlineControlsProps = {
  value: string
  canUseTemplating: boolean
  hasCharCount: boolean
}

export const InlineControls = ({
  value,
  canUseTemplating,
  hasCharCount,
}: InlineControlsProps): React.ReactElement => {
  const setInlineEditorBound = useAction(setInlineEditor)
  const inlineEditorConfig = useSelector(inlineEditorConfigSelector)

  const openTemplateWizard = React.useCallback(() => {
    setInlineEditorBound(inlineEditorConfig.set('personalizationModalIsOpen', true))
  }, [inlineEditorConfig, setInlineEditorBound])

  const charCount = React.useMemo(() => value.length, [value])

  return (
    <div
      style={{
        position: 'absolute',
        top: 3,
        right: 3,
        zIndex: 2,
        display: 'flex',
        alignItems: 'center',
      }}
    >
      {hasCharCount && charCount > 0 && (
        <p style={{ color: colors.textLight, marginRight: 5 }}>{charCount}</p>
      )}
      {canUseTemplating && (
        <Tooltip
          tooltip="Use Custom Data to add Dynamic Content to your message"
          width={200}
          placement="top"
        >
          <Button
            type="button"
            kind="inline"
            style={{ width: 30, height: 30 }}
            tabIndex={-1}
            onClick={openTemplateWizard}
          >
            <Icon icon="macros" />
          </Button>
        </Tooltip>
      )}
    </div>
  )
}
