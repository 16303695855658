import { type Dayjs } from 'dayjs'
import Immutable, { type List, type Map, type RecordOf, type Set } from 'immutable'

import { dayjs } from 'com.batch.common/dayjs.custom'

import { type CompanyRecord, type AppRecord } from 'com.batch.redux/_records'
import { type ProjectRecord } from 'com.batch.redux/project.records'
import { AnalyticDataFactory, type AnalyticDataRecord } from 'com.batch.redux/stat.records'
import { UserFactory, type UserRecord } from 'com.batch.redux/user.records'
import { type RootState } from '../app'

// ====================== APPS
// app filter
type AppFilterProps = {
  companyId: number | null | undefined
  query: string
}
export const AppFilterFactory = Immutable.Record<AppFilterProps>({
  companyId: null,
  query: '',
} as AppFilterProps)

export type AppFilterRecord = RecordOf<AppFilterProps>

// app state
type AppStateProps = {
  entities: Map<number, AppRecord>
  idsPerPage: Map<number, List<number>>
  loading: boolean
  page: number
  count: number
  query: string
  idsPerCompany: Map<number, List<number>>
  nbPerPage: number
  filters: AppFilterRecord
}
export const AppStateFactory = Immutable.Record<AppStateProps>({
  entities: Immutable.Map(),
  idsPerPage: Immutable.Map(),
  loading: false,
  page: 0,
  count: 0,
  query: '',
  idsPerCompany: Immutable.Map(),
  nbPerPage: 10,
  filters: AppFilterFactory(),
  codes: Immutable.List(),
} as AppStateProps)

export type AppStateRecord = RecordOf<AppStateProps>

// ====================== USERS
type UserFilterProps = {
  companyId: number | null | undefined
  search: string
}
export const UserFilterFactory = Immutable.Record<UserFilterProps>({
  companyId: null,
  search: '',
} as UserFilterProps)

export type UserFilterRecord = RecordOf<UserFilterProps>

export type UserStateProps = {
  filters: UserFilterRecord
  entities: Map<number, UserRecord>
  newUser: UserRecord
  idsPerPage: Map<number, List<number>>
  loading: boolean
  count: number
  page: number
  nbPerPage: number
}

export const UserStateFactory = Immutable.Record<UserStateProps>({
  filters: UserFilterFactory(),
  entities: Immutable.Map(),
  newUser: UserFactory(),
  idsPerPage: Immutable.Map(),
  loading: false,
  count: 0,
  page: 0,
  nbPerPage: 10,
} as UserStateProps)

export type UserStateRecord = RecordOf<UserStateProps>

export type State = RootState
// ====================== METADATA
export type Flux =
  | 'ucp_native_events_sdk'
  | 'ucp_custom_events_sdk'
  | 'ucp_custom_events_api'
  | 'ucp_custom_data_api'
  | 'ucp_gdpr_api'
  | 'ucp_gdpr_sdk'
  | 'profile_api_data_compatibility'
  | 'profile_api_event_compatibility'

type PlatformFluxProps = {
  ios: Set<Flux>
  android: Set<Flux>
  webpush: Set<Flux>
}

export const coerceStringToFlux = (s: string): Flux | null | undefined => {
  if (
    s === 'ucp_native_events_sdk' ||
    s === 'ucp_custom_events_sdk' ||
    s === 'ucp_custom_events_api' ||
    s === 'ucp_custom_data_api' ||
    s === 'ucp_gdpr_api' ||
    s === 'ucp_gdpr_sdk' ||
    s === 'profile_api_data_compatibility' ||
    s === 'profile_api_event_compatibility'
  ) {
    return s
  }
  return undefined
}

export const PlatformFluxFactory = Immutable.Record<PlatformFluxProps>({
  ios: Immutable.Set(),
  android: Immutable.Set(),
  webpush: Immutable.Set(),
} as PlatformFluxProps)

export type PlatformFluxRecord = RecordOf<PlatformFluxProps>

// ====================== COMPANY
type CompanyStateProps = {
  count: number
  loading: boolean
  entities: Map<number, CompanyRecord>
  nbPerPage: number
  idsPerPage: Map<number, List<number>>
  page: number
  filter: string
}
export const CompanyStateFactory = Immutable.Record<CompanyStateProps>({
  count: 0,
  loading: false,
  entities: Immutable.Map(),
  nbPerPage: 0,
  idsPerPage: Immutable.Map(),
  page: 0,
  filter: '',
} as CompanyStateProps)

export type CompanyStateRecord = RecordOf<CompanyStateProps>

// ====================== COMPANY
type ProjectStateProps = {
  count: number
  loading: boolean
  entities: Map<string, ProjectRecord>
  nbPerPage: number
  idsPerPage: Map<number, List<string>>
  page: number
  filters: AppFilterRecord
}
export const ProjectStateFactory = Immutable.Record<ProjectStateProps>({
  count: 0,
  loading: false,
  entities: Immutable.Map(),
  nbPerPage: 0,
  idsPerPage: Immutable.Map(),
  page: 0,
  filters: AppFilterFactory(),
} as ProjectStateProps)
export type ProjectStateRecord = RecordOf<ProjectStateProps>

// ====================== CONSOLE STATS
type AnalyticByCompanyProp = {
  companyId: number | null
  data: AnalyticDataRecord
}
export const AnalyticByCompanyFactory = Immutable.Record<AnalyticByCompanyProp>({
  companyId: null,
  data: AnalyticDataFactory(),
} as AnalyticByCompanyProp)

export type AnalyticByCompanyRecord = RecordOf<AnalyticByCompanyProp>

// ====================== EXPORTS
// Data Export Job
export type DataExportJobEntity =
  | 'campaign'
  | 'in-app-campaign'
  | 'transactional'
  | 'userbase'
  | 'reachability'
  | null
  | undefined
type DataExportJobProps = {
  entity: DataExportJobEntity
  kind: 'ids' | 'events' | null | undefined
  identifier: string | null | undefined
  from: Dayjs | null
  to: Dayjs | 'now' | null
  events: Array<
    'push_sent' | 'push_open' | 'push_error' | 'inapp_shown' | 'inapp_clicked' | 'inapp_closed'
  >
  ids: Array<'install_id' | 'custom_id' | 'advertising_id'>
  id: string
  status: string
  uri: string
  requestDate: Dayjs
}
export const DataExportJobFactory = Immutable.Record<DataExportJobProps>({
  entity: 'userbase',
  kind: 'ids',
  identifier: '',
  from: null,
  to: 'now',
  events: [],
  ids: ['install_id', 'custom_id', 'advertising_id'],
  id: '',
  status: '',
  uri: '',
  requestDate: dayjs(),
} as DataExportJobProps)

export type DataExportJobRecord = RecordOf<DataExportJobProps>

// Export job
type ExportJobProps = {
  entities: List<DataExportJobRecord>
  loading: boolean
}
export const ExportJobFactory = Immutable.Record<ExportJobProps>({
  entities: Immutable.List(),
  loading: false,
} as ExportJobProps)

export type ExportJobRecord = RecordOf<ExportJobProps>

// ====================== DEMO CODES
// app demo code
type AppDemoCodeProps = {
  id: number | null
  name: string
  apiKey: string
}
export const AppDemoCodeFactory = Immutable.Record<AppDemoCodeProps>({
  id: null,
  name: '',
  apiKey: '',
} as AppDemoCodeProps)

export type AppDemoCodeRecord = RecordOf<AppDemoCodeProps>

// demo code
type DemoCodeProps = {
  id: string
  description: string
  ios: AppDemoCodeRecord
  android: AppDemoCodeRecord
}
export const DemoCodeFactory = Immutable.Record<DemoCodeProps>({
  id: '',
  description: '',
  ios: AppDemoCodeFactory(),
  android: AppDemoCodeFactory(),
} as DemoCodeProps)

export type DemoCodeRecord = RecordOf<DemoCodeProps>

// codes state
type CodesStateProps = {
  entities: Map<string, DemoCodeRecord>
  loading: boolean
  count: number
}
export const CodeStateFactory = Immutable.Record<CodesStateProps>({
  entities: Immutable.Map(),
  loading: false,
  count: 0,
} as CodesStateProps)

export type CodeStateRecord = RecordOf<CodesStateProps>
