import Immutable, { type Map, type List } from 'immutable'

import {
  type FetchOrchestrationStatsEmailActions,
  type FetchOrchestrationStatsPushActions,
  type FetchOrchestrationStatsSmsActions,
  type FetchOrchestrationStatsByVariantActions,
  type FetchOrchestrationStatsByClassificationActions,
  type FetchOrchestrationStatsByDayActions,
  type FetchOrchestrationStatsByProviderActions,
  type FetchOrchestrationStatsByStepActions,
  type FetchOrchestrationStatsByChannelAndPeriodActions,
} from '../usecases/fetch-dynamic-stats.helper'
import {
  OrchestrationAnalyticsFactory,
  type OrchestrationAnalyticsRecord,
} from 'com.batch/orchestration-analytics/store/orchestration-analytics.state'

import {
  type RestoreAnalytisFiltersAndDateRangeAction,
  type UpdateAnalyticsDateRangeAction,
  type UpdateAnalyticsFiltersAction,
} from 'com.batch/orchestration-analytics/usecases/analytics-filters'
import { type FetchClicksByUrlActions } from 'com.batch/orchestration-analytics/usecases/fetch-clicks-by-url'
import { type FetchDimensionValuesActions } from '../usecases/fetch-dimension-values'
import { type FilterDiscoveryActions } from '../usecases/filter-discovery'
import { LoadingStatus } from 'constants/common'

type OrchestrationAnalyticsActions =
  | UpdateAnalyticsFiltersAction
  | UpdateAnalyticsDateRangeAction
  | RestoreAnalytisFiltersAndDateRangeAction
  | FetchOrchestrationStatsEmailActions
  | FetchOrchestrationStatsPushActions
  | FetchOrchestrationStatsSmsActions
  | FetchOrchestrationStatsByDayActions
  | FetchOrchestrationStatsByStepActions
  | FetchOrchestrationStatsByVariantActions
  | FetchOrchestrationStatsByProviderActions
  | FetchOrchestrationStatsByClassificationActions
  | FetchOrchestrationStatsByChannelAndPeriodActions
  | FetchClicksByUrlActions
  | FetchDimensionValuesActions
  | FilterDiscoveryActions

const getSingleTokenOrProjectKey = ({
  tokens,
  projectKey,
}: { tokens?: List<string>; projectKey?: string } & { [key: string]: unknown }) =>
  tokens?.first() || projectKey

export const orchestrationAnalyticsReducer = (
  state = Immutable.Map<string, OrchestrationAnalyticsRecord>(),
  action: OrchestrationAnalyticsActions
): Map<string, OrchestrationAnalyticsRecord> => {
  switch (action.type) {
    case 'UPDATE_ANALYTICS_FILTERS':
      return state.set(
        action.payload.token,
        state
          .get(action.payload.token, OrchestrationAnalyticsFactory())
          .set('filters', action.payload.filters)
      )
    case 'UPDATE_ANALYTICS_DATE_RANGE':
      return state.set(
        action.payload.token,
        state
          .get(action.payload.token, OrchestrationAnalyticsFactory())
          .set('dateRange', action.payload.dateRange)
      )
    case 'RESTORE_ANALYTICS_FILTERS_AND_DATE_RANGE':
      return state.set(
        action.payload.token,
        state
          .get(action.payload.token, OrchestrationAnalyticsFactory())
          .set('filters', action.payload.filters ?? Immutable.Map())
          .set('dateRange', action.payload.dateRange ?? null)
          .set('filtersRestored', true)
      )
    case 'FETCH_DIMENSION_VALUES_SUCCESS':
      return state.set(
        action.payload.projectKey,
        state
          .get(action.payload.projectKey, OrchestrationAnalyticsFactory())
          .setIn(['discovery', action.payload.dimension], action.payload.values)
      )
    case 'FILTER_DISCOVERY':
      return state.set(
        action.payload.projectKey,
        state
          .get(action.payload.projectKey, OrchestrationAnalyticsFactory())
          .set('filterDiscoveryState', LoadingStatus.LOADING)
      )
    case 'FILTER_DISCOVERY_SUCCESS':
      return state.set(
        action.payload.projectKey,
        state
          .get(action.payload.projectKey, OrchestrationAnalyticsFactory())
          .set('filterDiscoveryState', LoadingStatus.LOADED)
      )
    case 'FETCH_ORCHESTRATION_STATS_EMAIL': {
      const key = getSingleTokenOrProjectKey(action.payload)
      return key
        ? state.set(
            key,
            state
              .get(key, OrchestrationAnalyticsFactory())
              .set('emailLoadingState', LoadingStatus.LOADING)
          )
        : state
    }
    case 'FETCH_ORCHESTRATION_STATS_EMAIL_SUCCESS': {
      const key = getSingleTokenOrProjectKey(action.payload)
      return key
        ? state.set(
            key,
            state
              .get(key, OrchestrationAnalyticsFactory())
              .set('emailLoadingState', LoadingStatus.LOADED)
              .setIn(['channels', 'email', 'stats'], action.payload.parsed.email)
          )
        : state
    }
    case 'FETCH_ORCHESTRATION_STATS_EMAIL_FAILURE': {
      const key = getSingleTokenOrProjectKey(action.payload)
      return key
        ? state.set(
            key,
            state
              .get(key, OrchestrationAnalyticsFactory())
              .set('emailLoadingState', LoadingStatus.ERROR)
          )
        : state
    }
    case 'FETCH_ORCHESTRATION_STATS_PUSH': {
      const key = getSingleTokenOrProjectKey(action.payload)
      return key
        ? state.set(
            key,
            state
              .get(key, OrchestrationAnalyticsFactory())
              .set('pushLoadingState', LoadingStatus.LOADING)
          )
        : state
    }
    case 'FETCH_ORCHESTRATION_STATS_PUSH_SUCCESS': {
      const key = getSingleTokenOrProjectKey(action.payload)
      return key
        ? state.set(
            key,
            state
              .get(key, OrchestrationAnalyticsFactory())
              .set('pushLoadingState', LoadingStatus.LOADED)
              .setIn(['channels', 'push', 'stats'], action.payload.parsed.push)
          )
        : state
    }
    case 'FETCH_ORCHESTRATION_STATS_PUSH_FAILURE': {
      const key = getSingleTokenOrProjectKey(action.payload)
      return key
        ? state.set(
            key,
            state
              .get(key, OrchestrationAnalyticsFactory())
              .set('pushLoadingState', LoadingStatus.ERROR)
          )
        : state
    }
    case 'FETCH_ORCHESTRATION_STATS_SMS': {
      const key = getSingleTokenOrProjectKey(action.payload)
      return key
        ? state.set(
            key,
            state
              .get(key, OrchestrationAnalyticsFactory())
              .set('smsLoadingState', LoadingStatus.LOADING)
          )
        : state
    }
    case 'FETCH_ORCHESTRATION_STATS_SMS_SUCCESS': {
      const key = getSingleTokenOrProjectKey(action.payload)
      return key
        ? state.set(
            key,
            state
              .get(key, OrchestrationAnalyticsFactory())
              .set('smsLoadingState', LoadingStatus.LOADED)
              .setIn(['channels', 'sms', 'stats'], action.payload.parsed.sms)
          )
        : state
    }
    case 'FETCH_ORCHESTRATION_STATS_SMS_FAILURE': {
      const key = getSingleTokenOrProjectKey(action.payload)
      return key
        ? state.set(
            key,
            state
              .get(key, OrchestrationAnalyticsFactory())
              .set('smsLoadingState', LoadingStatus.ERROR)
          )
        : state
    }
    case 'FETCH_ORCHESTRATION_BOUNCES_BY_DAY': {
      const key = getSingleTokenOrProjectKey(action.payload)
      return key
        ? state.set(
            key,
            state
              .get(key, OrchestrationAnalyticsFactory())
              .set('daysLoadingState', LoadingStatus.LOADING)
          )
        : state
    }
    case 'FETCH_ORCHESTRATION_BOUNCES_BY_DAY_SUCCESS': {
      const key = getSingleTokenOrProjectKey(action.payload)
      return key
        ? state.set(
            key,
            state
              .get(key, OrchestrationAnalyticsFactory())
              .set('daysLoadingState', LoadingStatus.LOADED)
              .setIn(['channels', 'email', 'bounces', 'periods'], action.payload.parsed)
          )
        : state
    }
    case 'FETCH_ORCHESTRATION_BOUNCES_BY_DAY_FAILURE': {
      const key = getSingleTokenOrProjectKey(action.payload)
      return key
        ? state.set(
            key,
            state
              .get(key, OrchestrationAnalyticsFactory())
              .set('daysLoadingState', LoadingStatus.ERROR)
          )
        : state
    }
    case 'FETCH_ORCHESTRATION_STATS_BY_STEP': {
      const key = getSingleTokenOrProjectKey(action.payload)
      return key
        ? state.set(
            key,
            state
              .get(key, OrchestrationAnalyticsFactory())
              .set('stepsLoadingState', LoadingStatus.LOADING)
          )
        : state
    }
    case 'FETCH_ORCHESTRATION_STATS_BY_STEP_SUCCESS': {
      const key = getSingleTokenOrProjectKey(action.payload)
      return key
        ? state.set(
            key,
            state
              .get(key, OrchestrationAnalyticsFactory())
              .set('stepsLoadingState', LoadingStatus.LOADED)
              .set('steps', action.payload.parsed)
          )
        : state
    }
    case 'FETCH_ORCHESTRATION_STATS_BY_STEP_FAILURE': {
      const key = getSingleTokenOrProjectKey(action.payload)
      return key
        ? state.set(
            key,
            state
              .get(key, OrchestrationAnalyticsFactory())
              .set('stepsLoadingState', LoadingStatus.ERROR)
          )
        : state
    }
    case 'FETCH_ORCHESTRATION_STATS_BY_VARIANT': {
      const key = getSingleTokenOrProjectKey(action.payload)
      return key
        ? state.set(
            key,
            state
              .get(key, OrchestrationAnalyticsFactory())
              .set('variantsLoadingState', LoadingStatus.LOADING)
          )
        : state
    }
    case 'FETCH_ORCHESTRATION_STATS_BY_VARIANT_SUCCESS': {
      const key = getSingleTokenOrProjectKey(action.payload)
      return key
        ? state.set(
            key,
            state
              .get(key, OrchestrationAnalyticsFactory())
              .set('variantsLoadingState', LoadingStatus.LOADED)
              .set('variants', action.payload.parsed)
          )
        : state
    }
    case 'FETCH_ORCHESTRATION_STATS_BY_VARIANT_FAILURE': {
      const key = getSingleTokenOrProjectKey(action.payload)
      return key
        ? state.set(
            key,
            state
              .get(key, OrchestrationAnalyticsFactory())
              .set('variantsLoadingState', LoadingStatus.ERROR)
          )
        : state
    }
    case 'FETCH_ORCHESTRATION_STATS_BY_PROVIDER': {
      const key = getSingleTokenOrProjectKey(action.payload)
      return key
        ? state.set(
            key,
            state
              .get(key, OrchestrationAnalyticsFactory())
              .set('providersLoadingState', LoadingStatus.LOADING)
          )
        : state
    }
    case 'FETCH_ORCHESTRATION_STATS_BY_PROVIDER_SUCCESS': {
      const key = getSingleTokenOrProjectKey(action.payload)
      return key
        ? state.set(
            key,
            state
              .get(key, OrchestrationAnalyticsFactory())
              .set('providersLoadingState', LoadingStatus.LOADED)
              .setIn(['channels', 'email', 'providers'], action.payload.parsed)
          )
        : state
    }
    case 'FETCH_ORCHESTRATION_STATS_BY_PROVIDER_FAILURE': {
      const key = getSingleTokenOrProjectKey(action.payload)
      return key
        ? state.set(
            key,
            state
              .get(key, OrchestrationAnalyticsFactory())
              .set('providersLoadingState', LoadingStatus.ERROR)
          )
        : state
    }
    case 'FETCH_ORCHESTRATION_STATS_BY_BOUNCE_CLASSIFICATION': {
      const key = getSingleTokenOrProjectKey(action.payload)
      return key
        ? state.set(
            key,
            state
              .get(key, OrchestrationAnalyticsFactory())
              .set('classificationsLoadingState', LoadingStatus.LOADING)
          )
        : state
    }
    case 'FETCH_ORCHESTRATION_STATS_BY_BOUNCE_CLASSIFICATION_SUCCESS': {
      const key = getSingleTokenOrProjectKey(action.payload)
      return key
        ? state.set(
            key,
            state
              .get(key, OrchestrationAnalyticsFactory())
              .set('classificationsLoadingState', LoadingStatus.LOADED)
              .setIn(['channels', 'email', 'bounces', 'categories'], action.payload.parsed)
          )
        : state
    }
    case 'FETCH_ORCHESTRATION_STATS_BY_BOUNCE_CLASSIFICATION_FAILURE': {
      const key = getSingleTokenOrProjectKey(action.payload)
      return key
        ? state.set(
            key,
            state
              .get(key, OrchestrationAnalyticsFactory())
              .set('classificationsLoadingState', LoadingStatus.ERROR)
          )
        : state
    }
    case 'FETCH_ORCHESTRATION_STATS_BY_CHANNEL_AND_PERIOD': {
      const key = getSingleTokenOrProjectKey(action.payload)
      return key
        ? state.set(
            key,
            state
              .get(key, OrchestrationAnalyticsFactory())
              .set('channelAndPeriodLoadingState', LoadingStatus.LOADING)
          )
        : state
    }
    case 'FETCH_ORCHESTRATION_STATS_BY_CHANNEL_AND_PERIOD_SUCCESS': {
      const key = getSingleTokenOrProjectKey(action.payload)
      if (!key) return state

      let updatedState = state.get(key, OrchestrationAnalyticsFactory())
      updatedState = action.payload.parsed.reduce((acc, periodic, channel) => {
        switch (channel) {
          case 'email':
            return acc.setIn(['channels', 'email', 'periodic'], periodic)
          case 'sms':
            return acc.setIn(['channels', 'sms', 'periodic'], periodic)
          case 'push':
            return acc.setIn(['channels', 'push', 'periodic'], periodic)
          default:
            return acc
        }
      }, updatedState)
      return state.set(key, updatedState.set('channelAndPeriodLoadingState', LoadingStatus.LOADED))
    }
    case 'FETCH_ORCHESTRATION_STATS_BY_CHANNEL_AND_PERIOD_FAILURE': {
      const key = getSingleTokenOrProjectKey(action.payload)
      return key
        ? state.set(
            key,
            state
              .get(key, OrchestrationAnalyticsFactory())
              .set('channelAndPeriodLoadingState', LoadingStatus.ERROR)
          )
        : state
    }
    case 'FETCH_CLICKS_BY_URL':
      return state.set(
        action.payload.token,
        state
          .get(action.payload.token, OrchestrationAnalyticsFactory())
          .set('linksLoadingState', LoadingStatus.LOADING)
      )
    case 'FETCH_CLICKS_BY_URL_SUCCESS':
      return state.set(
        action.meta.token,
        state
          .get(action.meta.token, OrchestrationAnalyticsFactory())
          .set('linksLoadingState', LoadingStatus.LOADED)
          .setIn(['channels', 'email', 'links'], action.payload)
      )
    case 'FETCH_CLICKS_BY_URL_FAILURE':
      return state.set(
        action.meta.token,
        state
          .get(action.meta.token, OrchestrationAnalyticsFactory())
          .set('linksLoadingState', LoadingStatus.ERROR)
      )

    default:
      return state
  }
}
