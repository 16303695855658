import * as React from 'react'
import { useDispatch, useSelector } from 'com.batch.common/react-redux'

import { useIsCurrentUserAllowedTo } from 'components/_hooks/use-allowed'
import { Button } from 'components/common/button'
import { confirm } from 'components/common/confirm'
import { ConfirmHighlight } from 'components/common/confirm.styles'
import { Icon } from 'components/common/svg-icon'
import { Tooltip } from 'com.batch/shared/ui/component/tooltip'
import { HorizontalDivider, Separator } from 'components/styled/utils'

import { getVariantLabel } from 'com.batch.common/utils'

import { getMessageConfigSelector } from 'com.batch/orchestration/store/orchestration.composed.selectors'

import { type VariantId } from 'com.batch/message/models/message.records'
import { addVariant, removeVariant, selectVariant } from 'com.batch/message/usecases/experiment'
import {
  DeleteLangButton,
  TopToolbarContainer,
} from 'com.batch/message-builder/ui/components/lang-picker/lang-picker.styles'

type VariantPickerProps = {
  stepMessageNodeId: string | null | undefined
}
export const VariantPicker = ({
  stepMessageNodeId,
}: VariantPickerProps): React.ReactElement | null => {
  const dispatch = useDispatch()
  const getMessageConfig = useSelector(getMessageConfigSelector)
  const config = React.useMemo(
    () => getMessageConfig({ stepMessageNodeId }),
    [getMessageConfig, stepMessageNodeId]
  )
  const userHasWritePermission = useIsCurrentUserAllowedTo(['app', 'push:write'])

  const canEditVariant = React.useMemo(
    () => !config.experiment.locked && userHasWritePermission,
    [config.experiment.locked, userHasWritePermission]
  )
  const onCopyVariant = React.useCallback(
    () =>
      dispatch(
        addVariant({
          stepMessageNodeId,
          sourceVariantId: config.experiment.selectedVariantId,
          copySourceVariant: true,
        })
      ),
    [dispatch, config.experiment.selectedVariantId, stepMessageNodeId]
  )
  const createOnSelectVariant = React.useCallback(
    (variantId: VariantId) => () => {
      dispatch(selectVariant({ stepMessageNodeId, variantId }))
    },
    [dispatch, stepMessageNodeId]
  )
  const onDeleteVariant = React.useCallback(
    (variantId: VariantId) => (evt: any) => {
      evt.preventDefault()
      evt.stopPropagation()
      confirm({
        title: 'Delete this variant?',
        sensitive: true,
        message: (
          <React.Fragment>
            This will delete{' '}
            <ConfirmHighlight>variant {getVariantLabel(variantId)}</ConfirmHighlight>
          </React.Fragment>
        ),
        confirm: 'Yes, delete',
      }).then(
        () => {
          dispatch(removeVariant({ stepMessageNodeId, variantId }))
        },
        () => {}
      )
    },
    [dispatch, stepMessageNodeId]
  )
  const onAddVariant = React.useCallback(() => {
    dispatch(
      addVariant({
        stepMessageNodeId,
        sourceVariantId: config.experiment.selectedVariantId,
        copySourceVariant: false,
      })
    )
  }, [dispatch, config.experiment.selectedVariantId, stepMessageNodeId])
  if (!config.experiment.enabled) return null
  return (
    <React.Fragment>
      <TopToolbarContainer $isVariant>
        <Icon icon="experiment" style={{ marginTop: 9, marginRight: 8 }} />
        {config.experiment.variants.toArray().map(([variantId]) => {
          const isWinner = config.experiment.winnerVariantId === variantId
          return (
            <Button
              className={isWinner ? 'styled-winner' : ''}
              key={variantId}
              addOn={
                config.experiment.variants.size > 1 && canEditVariant
                  ? 'suffix'
                  : isWinner
                    ? 'prefix'
                    : undefined
              }
              isActive={variantId === config.experiment.selectedVariantId}
              onClick={createOnSelectVariant(variantId)}
            >
              {isWinner && <Icon icon="cup-fill" />}
              Variant {getVariantLabel(variantId)}
              {config.experiment.variants.size > 1 && canEditVariant && (
                <DeleteLangButton onClick={onDeleteVariant(variantId)}>
                  <Icon icon="close" />
                </DeleteLangButton>
              )}
            </Button>
          )
        })}

        {config.experiment.variants.size < 4 && canEditVariant && (
          <React.Fragment>
            <Separator />
            <Tooltip tooltip="Duplicate current variant" placement="bottom">
              <Button
                className="styled-tool-button"
                onClick={onCopyVariant}
                style={{ height: 36, width: 38 }}
              >
                <Icon icon="copy" style={{ marginRight: 1 }} />
              </Button>
            </Tooltip>

            <Tooltip tooltip="Create a new variant" placement="bottom">
              <Button
                className="styled-tool-button"
                onClick={onAddVariant}
                style={{ height: 36, width: 38 }}
              >
                <Icon icon="add" />
              </Button>
            </Tooltip>
          </React.Fragment>
        )}
      </TopToolbarContainer>
      <HorizontalDivider />
    </React.Fragment>
  )
}
