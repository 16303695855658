import * as React from 'react'
import { useSelector } from 'com.batch.common/react-redux'

import { filters } from 'components/campaign/campaign-list-utils'
import { Filter } from 'components/campaign/filters/filter'
import { Tooltip } from 'com.batch/shared/ui/component/tooltip'
import { Checkbox } from 'components/form'

import {
  type CampaignActiveFiltersRecord,
  type CampaignFilterRecord,
} from 'com.batch.redux/_records'
import { currentAppSelector } from 'com.batch.redux/app'

type TypeFilterProps = {
  localActiveFilters: CampaignActiveFiltersRecord
  onChange: (arg1?: any) => void
}

export const TypeFilter = ({
  localActiveFilters,
  onChange,
}: TypeFilterProps): React.ReactElement => {
  const app = useSelector(currentAppSelector)
  const handleOnChange = React.useCallback(
    (when: CampaignFilterRecord) => () => onChange(when),
    [onChange]
  )
  return (
    <Filter isEmbedded={true} title="Types">
      {filters
        .filter(f => f.category === 'when')
        .map(when => (
          <Tooltip
            key={when.name}
            tooltip={
              !(
                when.name !== 'trigger' ||
                (app.platform !== 'webpush' && app.features.has('trigger-campaigns')) ||
                (app.platform === 'webpush' && app.features.has('webpush-trigger-campaigns'))
              ) && 'Upgrade your plan to use trigger campaigns.'
            }
            placement={'left'}
          >
            <div key={when.name} className="checkbox-container">
              <Checkbox
                key={when.name}
                name={when.name}
                label={when.label}
                className={`types_${when.label.toLocaleLowerCase().trim()}`}
                checked={localActiveFilters.commons.has(when)}
                onChange={handleOnChange(when)}
                disabled={
                  when.name === 'trigger' &&
                  ((app.platform !== 'webpush' && !app.features.has('trigger-campaigns')) ||
                    (app.platform === 'webpush' && !app.features.has('webpush-trigger-campaigns')))
                }
              />
            </div>
          </Tooltip>
        ))}
    </Filter>
  )
}
