import React from 'react'
import { useMatch } from 'react-router-dom'

const PROFILES_TABS_WITH_CHANNELS = ['audiences', '', 'privacy', 'custom-data']

export const useMatchRoutes = (): {
  hasChannelsNav: boolean
  isOmnichannelPath: boolean
  isCepAutomations: boolean
  isCepCampaigns: boolean
  isEmailPath: boolean
  isPushPath: boolean
  isSmsPath: boolean
  isAnalytics: boolean
  isMepTeam: boolean
  isMepPrivacy: boolean
  isMepCustomData: boolean
  isChannelSettings: boolean
} => {
  const matchProfiles = useMatch('/:companyId/projects/:projectId/apps/:appId/profiles/:activeTab')
  const matchProjectProfiles = useMatch('/:companyId/projects/:projectId/profiles/:activeTab/*')
  const matchOmniChannelAudienceTab = useMatch('/:companyId/projects/:projectId/profiles/audiences')
  const matchOmniChannelCappingsTab = useMatch('/:companyId/projects/:projectId/settings/cappings')
  const matchOmniChannelLabelsTab = useMatch('/:companyId/projects/:projectId/settings/labels')
  const matchEmailSettingsTab = useMatch('/:companyId/projects/:projectId/settings/email')
  const matchCepAnalytics = useMatch('/:companyId/projects/:projectId/analytics/:channel')
  const matchAppAnalytics = useMatch('/:companyId/projects/:projectId/apps/:appId/analytics')
  const isMepTeam = Boolean(useMatch('/:companyId/projects/:projectId/apps/:appId/settings/team'))
  const isMepPushSettings = Boolean(
    useMatch('/:companyId/projects/:projectId/apps/:appId/settings/push')
  )
  const isEmailSettings = Boolean(useMatch('/:companyId/projects/:projectId/settings/email'))
  const isChannelSettings = isMepPushSettings || isEmailSettings
  const isMepCustomData = Boolean(
    useMatch('/:companyId/projects/:projectId/apps/:appId/profiles/custom-data')
  )
  const isMepPrivacy = Boolean(
    useMatch('/:companyId/projects/:projectId/apps/:appId/profiles/privacy')
  )
  const matchEmailCrossOrchestrationAnalyticsTab = useMatch(
    '/:companyId/projects/:projectId/analytics/email'
  )
  const matchSmsCrossOrchestrationAnalyticsTab = useMatch(
    '/:companyId/projects/:projectId/analytics/sms'
  )
  const matchPushCrossOrchestrationAnalyticsTab = useMatch(
    '/:companyId/projects/:projectId/analytics/push'
  )
  const matchProjectSettings = useMatch('/:companyId/projects/:projectId/settings/:activeTab')
  const matchSettings = useMatch(
    '/:companyId/projects/:projectId/apps/:appId/settings/:activeTab/*'
  )
  const matchCepCampaignForm = useMatch('/:companyId/projects/:projectId/campaigns/:channel/*')
  const matchCepAutomationForm = useMatch(
    '/:companyId/projects/:projectId/automations/:automationKind/*'
  )
  const matchCepAutomations = useMatch('/:companyId/projects/:projectId/automations/*')
  const matchCepCampaigns = useMatch('/:companyId/projects/:projectId/campaigns/*')
  const isSettingsPath = React.useMemo(() => {
    return Boolean(matchSettings) || Boolean(matchProjectSettings)
  }, [matchProjectSettings, matchSettings])

  const activeTabProfiles = React.useMemo(
    () => matchProfiles?.params?.activeTab ?? matchProjectProfiles?.params?.activeTab ?? '',
    [matchProjectProfiles?.params?.activeTab, matchProfiles?.params?.activeTab]
  )

  const activeTabSettings = React.useMemo(
    () => matchSettings?.params?.activeTab ?? matchProjectSettings?.params?.activeTab ?? '',
    [matchProjectSettings?.params?.activeTab, matchSettings?.params?.activeTab]
  )

  // e.g. /79/projects/MNnLDAp/profiles/segments
  const isProfilesWithoutSubNav = React.useMemo(() => {
    if (!matchProfiles && !matchProjectProfiles) return false
    return !PROFILES_TABS_WITH_CHANNELS.includes(activeTabProfiles)
  }, [activeTabProfiles, matchProjectProfiles, matchProfiles])

  // e.g. /79/projects/MNnLDAp/settings/segments
  const isSettingsWithoutSubNav = React.useMemo(() => {
    if (!isSettingsPath) return false
    return activeTabSettings === 'global'
  }, [activeTabSettings, isSettingsPath])

  // e.g. /79/projects/MNnLDAp/profiles/audiences
  const isOmnichannelPath = React.useMemo(() => {
    return (
      Boolean(matchOmniChannelAudienceTab) ||
      Boolean(matchOmniChannelLabelsTab) ||
      Boolean(matchOmniChannelCappingsTab)
    )
  }, [matchOmniChannelAudienceTab, matchOmniChannelCappingsTab, matchOmniChannelLabelsTab])

  // e.g. /79/projects/MNnLDAp/settings/email
  const isEmailPath = React.useMemo(() => {
    return Boolean(matchEmailSettingsTab) || Boolean(matchEmailCrossOrchestrationAnalyticsTab)
  }, [matchEmailSettingsTab, matchEmailCrossOrchestrationAnalyticsTab])

  // e.g. /79/projects/MNnLDAp/analytics/sms
  const isSmsPath = Boolean(matchSmsCrossOrchestrationAnalyticsTab)

  // e.g. /79/projects/MNnLDAp/analytics/push
  const isPushPath = Boolean(matchPushCrossOrchestrationAnalyticsTab)

  // e.g. /79/projects/MNnLDAp/analytics/email
  const isAnalytics = Boolean(matchCepAnalytics) || Boolean(matchAppAnalytics)

  const hasChannelsNav = React.useMemo(() => {
    return (
      !matchCepCampaignForm &&
      !matchCepAutomationForm &&
      !isProfilesWithoutSubNav &&
      !isSettingsWithoutSubNav
    )
  }, [
    matchCepCampaignForm,
    matchCepAutomationForm,
    isProfilesWithoutSubNav,
    isSettingsWithoutSubNav,
  ])

  return {
    hasChannelsNav,
    isOmnichannelPath,
    isEmailPath,
    isSmsPath,
    isPushPath,
    isMepTeam,
    isMepPrivacy,
    isCepAutomations: Boolean(matchCepAutomations),
    isCepCampaigns: Boolean(matchCepCampaigns),
    isAnalytics,
    isMepCustomData,
    isChannelSettings,
  }
}
