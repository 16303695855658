import * as React from 'react'
import { useDispatch, useSelector } from 'com.batch.common/react-redux'

import { useToggle } from 'components/_hooks'
import { Box } from 'components/common/box'
import { PermissionButton } from 'components/common/button'
import { GlobalErrorOverlayProps, Wrapper } from 'components/common/empty-states'
import { Grid } from 'components/common/grid'
import { Pager } from 'components/common/pager'
import { Popin } from 'components/common/popin/popin'
import { Icon } from 'components/common/svg-icon'
import { Table, TableFooter, TableTemplateCell } from 'components/common/table'
import { Tooltip } from 'com.batch/shared/ui/component/tooltip'
import { FilterSearch } from 'components/filter'
import { Content } from 'components/styled/blocs'
import { Title } from 'components/styled/text'
import { Separator } from 'components/styled/utils'

import { labelsSelector } from 'com.batch/labels/store/labels.selector'

import { LabelFactory, type LabelRecord } from 'com.batch/labels/models/labels.records'
import { Body } from 'com.batch/labels/ui/components/body'
import { Header } from 'com.batch/labels/ui/components/header'
import { LabelPopin } from 'com.batch/labels/ui/components/label-popin'
import { fetchLabelsAndCappingRules } from 'com.batch/labels/usecases/fetch-labels-and-capping-rules'
import { saveLabel } from 'com.batch/labels/usecases/save-label'
import { usePaginationParams } from 'com.batch/shared/hooks/use-pagination-params'
import { useSort } from 'com.batch/shared/hooks/use-sort'
import { type sortDirection, useSortParams } from 'com.batch/shared/hooks/use-sort-params'
import { type OverlayEmptyProps } from 'components/common/empty-states/overlay'
import { LoadingStatus } from 'constants/common'

// TODO: extract this to a shared component
export const EmptyListTemplate = (): React.ReactElement => {
  return (
    <React.Fragment>
      <TableTemplateCell template="1fr" />
      <TableTemplateCell template="1fr" />
      <TableTemplateCell template="1fr" />
      <TableTemplateCell template="1fr" align="end" />
    </React.Fragment>
  )
}

const NUMBER_OF_ENTITIES_PER_PAGE = 10
const MAXIMUM_LABEL_LIMIT = 50
export const MINIMUM_LABEL_LENGTH = 2

export const LabelsList = (): React.ReactElement => {
  // hooks
  const dispatch = useDispatch()
  const { sortEntitiesByKey } = useSort<LabelRecord>()
  const { currentPage, setPaginationParam } = usePaginationParams()
  const { sortOrderByParam, sortDirectionParam, setSortParams } = useSortParams()
  const createPopinState = useToggle()
  const [search, setSearch] = React.useState('')

  // state
  const { labels, loadingState } = useSelector(labelsSelector)

  const sortedAndFilteredLabels = React.useMemo(
    () =>
      sortEntitiesByKey(
        labels.filter(label => label.description.toLowerCase().includes(search.toLowerCase())),
        sortOrderByParam ?? 'description',
        sortDirectionParam ?? 'asc'
      ),
    [sortEntitiesByKey, labels, sortOrderByParam, sortDirectionParam, search]
  )

  // split sortedLabels into pages with 10 per page
  const paginatedSortedLabels = React.useMemo(
    () =>
      sortedAndFilteredLabels.slice(
        (currentPage - 1) * NUMBER_OF_ENTITIES_PER_PAGE,
        currentPage * NUMBER_OF_ENTITIES_PER_PAGE
      ),
    [currentPage, sortedAndFilteredLabels]
  )

  const hasNoContent = React.useMemo(
    () =>
      loadingState === LoadingStatus.ERROR ||
      (labels.size === 0 && loadingState === LoadingStatus.LOADED),
    [loadingState, labels.size]
  )

  const onSortChange = React.useCallback(
    column => (): void => {
      const sortedColumn = column ?? 'description'

      const direction: sortDirection =
        sortOrderByParam === sortedColumn ? (sortDirectionParam === 'asc' ? 'dsc' : 'asc') : 'asc'

      setSortParams(sortedColumn, direction)
    },
    [sortOrderByParam, sortDirectionParam, setSortParams]
  )

  const isLoading = React.useMemo(() => {
    return loadingState === LoadingStatus.LOADING || loadingState === LoadingStatus.INIT
  }, [loadingState])

  const createLabel = React.useCallback(
    (e: React.SyntheticEvent<HTMLFormElement>, labelDescription: string, labelCode: string) => {
      e.preventDefault()

      if (labelDescription.length >= MINIMUM_LABEL_LENGTH) {
        dispatch(
          saveLabel(
            LabelFactory({
              description: labelDescription,
              code: labelCode,
            })
          )
        ).then(() => {
          createPopinState.close()
          setSearch('')
        })
      }
    },
    [createPopinState, dispatch]
  )

  React.useEffect(() => {
    dispatch(fetchLabelsAndCappingRules())
  }, [dispatch])

  React.useEffect(() => {
    // redirect to previous page if no labels on the current page
    if (paginatedSortedLabels.size === 0 && currentPage > 1) {
      setPaginationParam(currentPage - 1)
    }
  })

  return (
    <Content top>
      <Wrapper
        isEmpty={hasNoContent}
        isLoading={isLoading}
        isOverlayShown={loadingState === LoadingStatus.ERROR || hasNoContent}
        overlayProps={
          loadingState === LoadingStatus.ERROR
            ? GlobalErrorOverlayProps
            : emptyPageOverlayProps(createPopinState.open)
        }
      >
        <Grid template="1fr auto 1px auto" gap={8} margin={[0, 0, 32, 0]}>
          <Title mb={0} overEmptyState>
            Labels
          </Title>
          <FilterSearch
            value={search}
            onChange={setSearch}
            disabled={hasNoContent}
            expandedMaxWidth={220}
            identifier={'labels'}
          />
          <Separator />
          <Tooltip
            tooltip={
              labels.size >= MAXIMUM_LABEL_LIMIT &&
              `Impossible to create more than ${MAXIMUM_LABEL_LIMIT.toString()} labels.`
            }
            placement="bottom"
          >
            <div>
              <PermissionButton
                intent="action"
                kind="primary"
                addOn="prefix"
                onClick={createPopinState.open}
                disabled={
                  (hasNoContent && loadingState === LoadingStatus.LOADING) ||
                  labels.size >= MAXIMUM_LABEL_LIMIT
                }
                isAllowed={true}
              >
                <Icon icon="add" />
                New label
              </PermissionButton>
            </div>
          </Tooltip>
        </Grid>
        <Box
          style={{
            overflow: 'hidden',
          }}
        >
          <Table template="1fr 200px 236px 110px" rowHeight={52}>
            <Header onSortChange={onSortChange} />
            <Body
              templateSize={NUMBER_OF_ENTITIES_PER_PAGE}
              labels={paginatedSortedLabels}
              emptyTemplate={<EmptyListTemplate />}
            />
            {(sortedAndFilteredLabels.size > 10 ||
              (sortedAndFilteredLabels.size === 0 && isLoading)) && (
              <TableFooter>
                <Pager
                  forceLoading={isLoading}
                  page={currentPage}
                  total={sortedAndFilteredLabels.size}
                  nbPerPage={NUMBER_OF_ENTITIES_PER_PAGE}
                  selectPage={setPaginationParam}
                />
              </TableFooter>
            )}
          </Table>
        </Box>
        <Popin
          opened={createPopinState.value}
          close={createPopinState.close}
          style={{ width: 440, margin: 0, padding: 0 }}
        >
          <LabelPopin close={createPopinState.close} onSubmit={createLabel} />
        </Popin>
      </Wrapper>
    </Content>
  )
}

// TODO: extract this to a shared component
const emptyPageOverlayProps = (onClickNewLabel: () => void): OverlayEmptyProps => {
  return {
    status: 'empty-page',
    title: 'No labels to display',
    description: (
      <div>
        <p style={{ marginBottom: 10 }}>
          You haven’t created any labels for this project for now. You can create your first one
          right now by filling out the following form.
        </p>
        <PermissionButton intent="action" kind="primary" onClick={onClickNewLabel} isAllowed={true}>
          <Icon icon="add" style={{ paddingRight: '5px' }} />
          New label
        </PermissionButton>
      </div>
    ),
  }
}
