import * as React from 'react'

import styled from 'styled-components'

import Hint from 'com.batch/shared/ui/component/hint'
import { Icon } from 'components/common/svg-icon'
import { Tooltip } from 'com.batch/shared/ui/component/tooltip'
import { HiddenPlaceholder } from 'components/styled/placeholder'
import { colors } from 'components/styled/tokens'

import { kformat, percentage, numberFormat } from 'com.batch.common/utils'

type MetricProps = {
  children: React.ReactNode
  appendNode?: React.ReactNode
  isRatio?: boolean
  value: number
  ratio?: number
  color?: string
  alternateTooltipIcon?: boolean
  labelColor?: string
  small?: boolean
  smallNoArrow?: boolean
  align?: 'center' | 'right' | 'left'
  tooltip?: React.ReactNode
}
const Metric = ({
  children,
  value,
  ratio,
  appendNode,
  alternateTooltipIcon = false,
  color,
  small,
  smallNoArrow,
  align,
  isRatio = false,
  tooltip,
}: MetricProps): React.ReactElement => {
  const formated = isRatio ? percentage(value, 2) : kformat(value)
  const isAbbreviation = !!value && !isRatio && formated !== value.toString()
  return (
    <MetricContainer align={align ?? 'inherit'}>
      <MetricLabel>
        {small && !smallNoArrow && <Icon icon="enter" style={{ margin: '0 6px 0 0' }} />}
        {children}
        {tooltip && (
          <Hint
            noMargin
            style={{ margin: '0 -6px 0 0' }}
            icon={alternateTooltipIcon ? 'details' : 'help'}
          >
            {tooltip}
          </Hint>
        )}
      </MetricLabel>
      {isAbbreviation ? (
        <Tooltip
          tooltip={<MetricValueTooltip>{numberFormat(value)}</MetricValueTooltip>}
          placement="right"
        >
          <MetricValue small={!!small || !!smallNoArrow}>{formated}</MetricValue>
        </Tooltip>
      ) : (
        <MetricValue small={!!small || !!smallNoArrow}>{formated}</MetricValue>
      )}
      {!!ratio && <MetricRatio color={color}>{percentage(ratio)}</MetricRatio>}
      {appendNode}
    </MetricContainer>
  )
}

export default Metric
export const MetricLabelText = styled.span`
  display: inline-block;
  margin: 0 4px 0 0;
`

type MetricLabelProps = {
  color?: string
}
export const MetricLabel = styled.label<MetricLabelProps>`
  display: block;
  font-size: 10px;
  font-weight: 500;
  color: ${props => (props.color ? props.color : '#89909a')};
  letter-spacing: 1px;
  text-transform: uppercase;
`

type MetricValueProps = {
  small?: boolean
}
export const MetricValue = styled.label.attrs({
  width: '2.5em',
})<MetricValueProps>`
  display: inline;
  font-size: ${props => (props.small ? 24 : 28)}px;
  font-weight: 300;
  color: ${colors.text};
  letter-spacing: 0.5px;
  padding: 8px;
  margin: -8px -8px -8px ${props => (props.small ? -8 : -8)}px;
`
const MetricValueTooltip = styled.p`
  display: block;
  margin: -4px;
  text-align: left;
`

type MetricRatioProps = {
  color?: string
}
export const MetricRatio = styled(HiddenPlaceholder.withComponent('span'))<MetricRatioProps>`
  display: inline-block;
  margin: 0 0 0 6px;
  font-size: 13px;
  font-weight: 600;
  color: ${(props: { color?: string }) => (props.color ? props.color : '#8e959e')};
`

type MetricContainerProps = {
  align?: 'center' | 'right' | 'left' | 'inherit'
}
export const MetricContainer = styled.div<MetricContainerProps>`
  display: block;
  text-align: ${props => props.align};
  cursor: default;

  ${MetricLabel} {
    margin: ${props => (props.align === 'left' ? '0 10px 4px 0' : '0 0 4px 0')};
  }
`
