import { type DispatchExtraBoundFn, type ReduxAction } from 'com.batch.redux/_records'
import { promiseActionCreator } from 'com.batch.redux/actionCreator'
import { currentProjectSelector } from 'com.batch.redux/project.selector'
import { type OrchestrationState } from 'constants/common/orchestration-state'

type ChangeOrchestrationStateAction = ReduxAction<'CHANGE_ORCHESTRATION_STATE', string>
export type ChangeOrchestrationStateSuccessAction = ReduxAction<
  'CHANGE_ORCHESTRATION_STATE_SUCCESS',
  {
    token: string
    state: OrchestrationState.STOPPED | OrchestrationState.RUNNING
  }
>
export type ChangeOrchestrationStateFailureAction = ReduxAction<
  'CHANGE_ORCHESTRATION_STATE_FAILURE',
  {
    error: string
    token: string
  }
>

export type ChangeOrchestrationStateActions =
  | ChangeOrchestrationStateAction
  | ChangeOrchestrationStateSuccessAction
  | ChangeOrchestrationStateFailureAction

export const changeOrchestrationState = (
  token: string,
  state: OrchestrationState.STOPPED | OrchestrationState.RUNNING
): DispatchExtraBoundFn<
  Promise<{
    token: string
    state: OrchestrationState
  }>
> => {
  return (dispatch, getState, { orchestrationService }) => {
    const reduxState = getState()
    const project = currentProjectSelector(reduxState)

    return promiseActionCreator({
      dispatch,
      promise: orchestrationService
        .changeState({ project, state, token })
        .then(() => ({
          token,
          state,
        }))
        .catch(response => {
          throw { token, error: response?.error?.message ?? 'Unknown error' }
        }),
      payload: token,
      actionName: 'CHANGE_ORCHESTRATION_STATE',
    })
  }
}
