import { type DispatchBoundFn } from 'com.batch.redux/_records'

import { setTemplating } from 'com.batch/templating/usecases/set-templating'

export type UpdateSmsContentAction = {
  type: 'UPDATE_SMS_CONTENT'
  payload: {
    messageId: string
    lang: string
    field: 'smsMessage'
    value: string
  }
}
export const updateSmsContent = ({
  messageId,
  lang,
  field,
  value,
}: {
  messageId: string
  lang: string
  field: 'smsMessage'
  value: string
}): DispatchBoundFn<void> => {
  return dispatch => {
    dispatch({
      type: 'UPDATE_SMS_CONTENT',
      payload: {
        messageId,
        lang,
        field,
        value,
      },
    })
    dispatch(
      setTemplating({
        messageId,
        field,
        value,
        langId: lang,
        valueIsHtml: false,
      })
    )
  }
}
