import * as React from 'react'
import { useDispatch } from 'com.batch.common/react-redux'
import { useTheme } from 'styled-components'

import { BoxHeader, BoxBody } from 'components/common/box'
import { Button } from 'components/common/button'
import { EmptyField } from 'components/common/empty-states'
import { Pager } from 'components/common/pager'
import { Icon } from 'components/common/svg-icon'
import { TableRow, TableBody, TableCell, TableFooter, Table } from 'components/common/table/index'
import { Tooltip } from 'com.batch/shared/ui/component/tooltip'
import { Ellipsis, Timezone } from 'components/styled/text'
import { colors } from 'components/styled/tokens'

import { kformat, numberFormat } from 'com.batch.common/utils'

import {
  DatasHeader,
  AttributeContainer,
  AttributeTeaseWrapper,
  AttributeTableEmptyTemplate,
} from './userbase.styles'

import { type AttributeRecord, type AttributeValuesListRecord } from 'com.batch.redux/_records'
import { setAttributeValuesPage, setAttributeValuesMode } from 'com.batch.redux/attributeValue'

type AttributeWithValuesProps = {
  attribute: AttributeRecord
  datas: AttributeValuesListRecord
  loading: boolean
  failure: boolean
  tease?: boolean
}

export const AttributeWithValues: React.ComponentType<AttributeWithValuesProps> =
  React.memo<AttributeWithValuesProps>(
    ({ attribute, datas, loading, failure, tease }: AttributeWithValuesProps) => {
      const dispatch = useDispatch()
      const theme = useTheme()

      const attr = attribute

      const page = datas.page
      const mode = datas.mode
      const shown = datas.shown
      const noData = shown.size === 0
      const isEvent = attr.type === 'EVENT'

      const values = datas.values.get(mode === '__default' ? '__default' : 'events')
      const count = values ? values.size : 0

      const { canDisplayLabels } = datas

      const isEmpty = (!!theme && theme.isEmpty) || loading

      // ====================== CALLBACKS
      const eventsMode = React.useCallback(
        () => dispatch(setAttributeValuesMode(attr.id, 'events')),
        [attr.id, dispatch]
      )
      const valuesMode = React.useCallback(
        () => dispatch(setAttributeValuesMode(attr.id, '__default')),
        [attr.id, dispatch]
      )
      const onPageSelected = React.useCallback(
        (p: number) => dispatch(setAttributeValuesPage(attr.id, p)),
        [attr.id, dispatch]
      )

      // ====================== RENDER
      return (
        <AttributeContainer>
          <BoxHeader style={{ height: 44, padding: 0 }}>
            <DatasHeader>
              {!isEmpty ? (
                <React.Fragment>
                  <Icon icon={attr.icon} style={{ flex: '0 0 auto' }} />
                  <Ellipsis title={attr.label} style={{ flex: '0 1 auto' }}>
                    {attr.label}
                  </Ellipsis>
                  {!loading &&
                    (attr.category === 'user_attribute' ||
                      attr.category === 'user_tag' ||
                      attr.category === 'user_event') && (
                      <Tooltip
                        tooltip={`${
                          attr.category === 'user_event' ? 'Trigger event' : 'User attribute'
                        },
                  populated by ${
                    attr.category === 'user_event' ? 'Trigger Event' : 'Custom Data'
                  } API. Data preview refreshed once per 24h. Last
                  update on ${
                    attr.lastUpdate ? attr.lastUpdate.format('DD/MM/YYYY [at] HH:mm') : 'NA'
                  }
                `}
                      >
                        <Timezone style={{ flex: '0 0 auto', margin: '0 8px' }}>User</Timezone>
                      </Tooltip>
                    )}
                  {!loading && isEvent && (
                    <div style={{ display: 'flex', flex: '1 1 auto', justifyContent: 'flex-end' }}>
                      <Button
                        kind="inline"
                        onClick={eventsMode}
                        isActive={mode === 'events'}
                        style={{ marginRight: 6, height: 28 }}
                      >
                        5 days
                      </Button>
                      <Button
                        kind="inline"
                        onClick={valuesMode}
                        isActive={mode === '__default'}
                        disabled={!canDisplayLabels}
                        style={{ flex: '0 0 auto', height: 28 }}
                      >
                        Label
                      </Button>
                    </div>
                  )}
                </React.Fragment>
              ) : (
                <EmptyField _width={80} _height={14} />
              )}
            </DatasHeader>
          </BoxHeader>

          <AttributeTeaseWrapper tease={!loading && !noData && !failure && tease}>
            <BoxBody>
              <Table template="minmax(100px, 1fr) 60px 70px">
                <TableBody templateSize={5} emptyTemplate={<AttributeTableEmptyTemplate />}>
                  {shown.map((data, indice) => {
                    const name =
                      data.pretty === 'true'
                        ? 'True'
                        : data.pretty === 'false'
                          ? 'False'
                          : data.pretty

                    return (
                      <TableRow key={'data_' + indice}>
                        <TableCell>
                          <Tooltip tooltip={name}>
                            <Ellipsis>{name}</Ellipsis>
                          </Tooltip>
                        </TableCell>
                        <TableCell
                          align="right"
                          style={{ marginRight: 0, color: colors.textLight }}
                        >
                          {data.get('pcent')}
                        </TableCell>
                        <TableCell align="right" style={{ color: colors.text }}>
                          <Tooltip tooltip={numberFormat(data.installs)}>
                            <span>{kformat(data.installs)}</span>
                          </Tooltip>
                        </TableCell>
                      </TableRow>
                    )
                  })}
                </TableBody>
              </Table>
            </BoxBody>

            {(count > 5 || isEmpty) && (
              <TableFooter>
                <Pager
                  size="small"
                  total={count}
                  nbPerPage={5}
                  page={page}
                  selectPage={onPageSelected}
                />
              </TableFooter>
            )}
          </AttributeTeaseWrapper>
        </AttributeContainer>
      )
    }
  )
