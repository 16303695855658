import Immutable, { type Set, type List } from 'immutable'
import * as React from 'react'

import { Filter } from 'components/campaign/filters/filter'
import { Icon } from 'components/common/svg-icon'
import { FilterSelectMulti } from 'components/filter'
import { Grid } from 'components/common/grid'
import { Checkbox } from 'components/form'

type SelectOptions = ChannelUntilCleanup | ProjectPlatforms
const options = Immutable.List<SelectOptions>(['email', 'push', 'ios', 'android', 'webpush', 'sms'])
const optToString = (opt?: SelectOptions | null) => opt ?? ''

type ChannelsFilterProps = {
  channels: Set<ChannelUntilCleanup>
  onChannelsChange: (channels: Set<ChannelUntilCleanup>) => void
  platforms: Set<ProjectPlatforms>
  onPlatformsChange: (platforms: Set<ProjectPlatforms>) => void
}
const formatOptionWord = (option: SelectOptions) => {
  switch (option) {
    case 'sms':
      return 'SMS'
    case 'email':
      return 'Email'
    case 'push':
      return 'Push'
    case 'ios':
      return 'iOS'
    case 'android':
      return 'Android'
    case 'webpush':
      return 'Web'
    default:
      return ''
  }
}

const selectOptionIsChannel = (option: SelectOptions): option is ChannelUntilCleanup =>
  option === 'push' || option === 'email' || option === 'sms'
const selectOptionIsPlatform = (option: SelectOptions): option is ProjectPlatforms =>
  option === 'ios' || option === 'android' || option === 'webpush'
export const ChannelsFilter = ({
  channels,
  onChannelsChange,
  platforms,
  onPlatformsChange,
}: ChannelsFilterProps): React.ReactElement => {
  const onSelect = React.useCallback(
    (values: List<SelectOptions>) => {
      const selectedPlat = values.filter(selectOptionIsPlatform).toSet()
      const selectedChan = values.filter(selectOptionIsChannel).toSet()
      const aPlatformWasPicked = selectedPlat.subtract(platforms).size > 0
      const pushChannelWasPicked = selectedChan.has('push') && !channels.has('push')
      const pushChannelWasRemoved = !selectedChan.has('push') && channels.has('push')
      const lastPlatformWasRemoved = platforms.size === 1 && selectedPlat.size === 0
      onPlatformsChange(
        pushChannelWasPicked
          ? Immutable.Set(['ios', 'android', 'webpush'])
          : pushChannelWasRemoved
            ? Immutable.Set()
            : selectedPlat
      )
      onChannelsChange(
        aPlatformWasPicked
          ? selectedChan.add('push')
          : lastPlatformWasRemoved
            ? selectedChan.remove('push')
            : selectedChan
      )
    },
    [onChannelsChange, onPlatformsChange, platforms, channels]
  )
  const formatOption = React.useCallback(
    (option: ChannelUntilCleanup) => {
      let checked: undefined | boolean =
        (selectOptionIsChannel(option) && channels.has(option)) ||
        (selectOptionIsPlatform(option) && platforms.has(option))
      if (option === 'push' && platforms.size < 3 && platforms.size > 0) checked = undefined
      return (
        <Grid
          template="auto 1fr auto"
          style={{ marginLeft: selectOptionIsChannel(option) ? 0 : 16 }}
        >
          <Checkbox checked={checked} handleIndeterminate />
          {formatOptionWord(option)}
          {selectOptionIsChannel(option) && <Icon icon={option === 'email' ? 'mail' : option} />}
        </Grid>
      )
    },
    [channels, platforms]
  )

  const formatTerm = React.useCallback(() => {
    const count = channels.size

    return count === 1
      ? `${formatOptionWord(channels.first())} ${
          platforms.size === 1
            ? formatOptionWord(platforms.first())
            : platforms.size === 0 || platforms.size === 3
              ? ''
              : platforms.has('android') && platforms.has('ios')
                ? 'App'
                : '*'
        }`
      : `${count} channels`
  }, [channels, platforms])

  return (
    <Filter title="Channel">
      <FilterSelectMulti
        options={options}
        value={(channels.toList() as List<SelectOptions>).push(
          ...(platforms.toList() as List<SelectOptions>)
        )}
        onChange={onSelect}
        placeholder="All channels"
        term={formatTerm}
        showCheckbox={false}
        optionMenuShownCount={6}
        optionMenuHeight={36}
        isSearchable={false}
        optionToString={optToString}
        optionFormatter={formatOption}
        menuOffset={20}
        style={{
          minWidth: '140px',
          maxWidth: '170px',
        }}
        optionMenuStyle={{ minWidth: '140px', maxWidth: '170px' }}
      />
    </Filter>
  )
}
