import { TemplatingStateFactory, type TemplatingStateRecord } from './templating.state'

import { type TEMPLATING_UNSET_ACTION } from '../usecases/set-templating'

import type {
  TEMPLATING_FETCH_ACTION,
  TEMPLATING_FETCH_FAILURE_ACTION,
  TEMPLATING_FETCH_SUCCESS_ACTION,
} from '../usecases/fetch-templating'
import { type SeekDynamicContentProfileIdSuccessAction } from 'com.batch/email/usecases/update-preview-profile'
import { type DuplicateMessageAction } from 'com.batch/message/usecases/duplicate-message'
import { type TemplatingRecord } from '../models/templating.records'
type HandledActions =
  | TEMPLATING_UNSET_ACTION
  | TEMPLATING_FETCH_ACTION
  | TEMPLATING_FETCH_SUCCESS_ACTION
  | TEMPLATING_FETCH_FAILURE_ACTION
  | SeekDynamicContentProfileIdSuccessAction
  | DuplicateMessageAction
export function templatingReducer(
  state: TemplatingStateRecord = TemplatingStateFactory(),
  action: HandledActions
): TemplatingStateRecord {
  switch (action.type) {
    case 'TEMPLATING_UNSET': {
      return state.deleteIn(['entities', action.payload])
    }
    case 'TEMPLATING_FETCH_SUCCESS':
    case 'TEMPLATING_FETCH_FAILURE':
    case 'TEMPLATING_FETCH': {
      return state.set('entities', state.entities.merge(action.payload))
    }
    case 'SEEK_DYNAMIC_CONTENT_PROFILE_ID_SUCCESS':
      return state
        .set('previewSourceProfileId', action.payload.profileId)
        .set('previewSourceValue', action.payload.value)
    case 'DUPLICATE_MESSAGE': {
      const tuplesArray: [string, TemplatingRecord][] = []
      state.entities
        .filter((_, key) => key.includes(action.payload.sourceTypedMessageId))
        .forEach((rec, key) => {
          const newKey = key.replace(
            action.payload.sourceTypedMessageId,
            action.payload.targetTypedMessageId
          )
          tuplesArray.push([newKey, rec])
        })
      if (tuplesArray.length > 0) {
        return state.set('entities', state.entities.merge(tuplesArray))
      }
      return state
    }
    default:
      return state
  }
}
