import * as React from 'react'
import { useDispatch, useSelector } from 'com.batch.common/react-redux'

import { DropdownMenu, Button, useDropdown } from 'components/common/button'
import { confirm } from 'components/common/confirm'
import { Icon } from 'components/common/svg-icon'
import { Tooltip } from 'com.batch/shared/ui/component/tooltip'

import { generateOrchestrationListUrl } from 'com.batch.common/router/url-generator-helper'

import {
  getConfirmWordings,
  type ProjectMode,
  type ProjectPartialMode,
  isProjectPartialMode,
  isProjectMode,
} from './confirm-wording'
import { OrchestrationMenuChangeState } from './orchestration-menu-changestate'

import { currentProjectSelector } from 'com.batch.redux/project.selector'

import { deleteOrchestration } from 'com.batch/orchestration-list/usecases/delete-orchestration'
import { useCurrentCompanyHasFeature } from 'components/_hooks'
import { LoadingStatus, OrchestrationState } from 'constants/common'

type OrchestrationMenuProps = {
  schedulingType: schedulingType
  openReplication: () => void
  openRetargeting: () => void
  data: ProjectMode | ProjectPartialMode
  userHasWritePermission: boolean
}
/*
  menu used in both campaign-list and campaign form pages
    on campaign list, expect a partialCampaign, and will show change state buttons
    on form header, expect an orchestrationStateRecord, will only offer replicate & delete, and will redirect on delete
*/
export const OrchestrationMenu = React.memo(
  ({
    data,
    schedulingType,
    openReplication,
    openRetargeting,
    userHasWritePermission,
  }: OrchestrationMenuProps) => {
    const dispatch = useDispatch()
    const project = useSelector(currentProjectSelector)
    const hasRetargeting = useCurrentCompanyHasFeature('cep-retargeting')
    const wordings = React.useMemo(() => {
      return getConfirmWordings(data)
    }, [data])
    const isLoading = React.useMemo(() => {
      return (
        isProjectPartialMode(data) &&
        data.partialOrchestration.loadingState === LoadingStatus.LOADING
      )
    }, [data])

    const token = React.useMemo(() => {
      if (isProjectPartialMode(data)) {
        return data.partialOrchestration.token
      } else if (data.orchestration) {
        return data.orchestration.id
      }
      return 'trigger'
    }, [data])

    const { triggerProps, dropdownProps, closeDropdown } = useDropdown({
      forcedWidth: 155,
      placement: isProjectPartialMode(data) ? 'left-start' : 'bottom-end',
      offset: [data ? 0 : 60, 4],
    })

    const deleteConfirmModal = React.useCallback(() => {
      closeDropdown()
      confirm({
        ...wordings.archive,
        sensitive: true,
      })
        .then(() => {
          dispatch(deleteOrchestration(token))
            .then(() => {
              if (isProjectMode(data)) {
                const url = generateOrchestrationListUrl({
                  projectId: project.id,
                  channel: data.orchestration.channels.first() ?? 'email',
                  companyId: project.companyId,
                  scheduling: schedulingType,
                })
                window.location.href = url
              }
            })
            .catch(() => {})
        })
        .catch(() => {})
      // () => {}
    }, [
      closeDropdown,
      wordings.archive,
      dispatch,
      token,
      data,
      project.id,
      project.companyId,
      schedulingType,
    ])
    const openReplicationAndCloseDropdown = React.useCallback(() => {
      openReplication()
      closeDropdown()
    }, [openReplication, closeDropdown])

    const openRetargetingAndCloseDropdown = React.useCallback(() => {
      openRetargeting()
      closeDropdown()
    }, [openRetargeting, closeDropdown])

    const orchestrationChannels = React.useMemo(() => {
      return isProjectPartialMode(data)
        ? data.partialOrchestration.channels
        : data.orchestration.channels
    }, [data])

    const orcherstationState = React.useMemo(() => {
      return isProjectPartialMode(data) ? data.partialOrchestration.state : data.orchestration.state
    }, [data])

    const orchestrationIsCampaign = React.useMemo(() => {
      return isProjectMode(data)
        ? data.orchestration.campaign.sendType === 'now' ||
            data.orchestration.campaign.sendType === 'scheduled'
        : data.partialOrchestration.sendType === 'now' ||
            data.partialOrchestration.sendType === 'scheduled'
    }, [data])

    return (
      <React.Fragment>
        <Tooltip tooltip="More actions" placement="top">
          <Button
            {...triggerProps}
            kind={isProjectPartialMode(data) ? 'inline' : 'secondary'}
            style={
              isProjectPartialMode(data) ? { height: 36, width: 36 } : { marginTop: 0, width: 35 }
            }
          >
            <Icon icon={isLoading ? 'spinner' : 'options'} />
          </Button>
        </Tooltip>
        <DropdownMenu {...dropdownProps}>
          {/* Change state option is offered only on listing, with partial object*/}
          {isProjectPartialMode(data) && userHasWritePermission && (
            <OrchestrationMenuChangeState
              partialOrchestration={data.partialOrchestration}
              closeDropdown={closeDropdown}
            />
          )}

          <Button onClick={openReplicationAndCloseDropdown} disabled={!userHasWritePermission}>
            Replicate
          </Button>
          {!orchestrationChannels.includes('push') &&
            orcherstationState === OrchestrationState.COMPLETED &&
            orchestrationIsCampaign &&
            hasRetargeting && (
              <Button onClick={openRetargetingAndCloseDropdown}>Retarget campaign</Button>
            )}
          <Button intent="danger" onClick={deleteConfirmModal} disabled={!userHasWritePermission}>
            Delete
          </Button>
        </DropdownMenu>
      </React.Fragment>
    )
  }
)
OrchestrationMenu.displayName = 'OrchestrationMenu'
