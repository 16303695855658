/* legacy navigation */

import * as React from 'react'
import { useDispatch } from 'com.batch.common/react-redux'

import { useWidthObserver } from 'components/_hooks'
import { ButtonLink, Button } from 'components/common/button'
import { Icon } from 'components/common/svg-icon'
import { SubNav } from 'components/navigation/sub-nav.styles'
import { NavbarContainer, NavbarMenu, Separator } from 'components/project/navigation/navbar.styles'

import { useBuildLegacyNav } from 'com.batch.common/router'

import { type AppRecord } from 'com.batch.redux/_records'
import { openIntegrate } from 'com.batch.redux/app.action'
import { Tooltip } from 'com.batch/shared/ui/component/tooltip'

type NavbarProps = {
  app: AppRecord
  showIntegrate: boolean
  subNavItems?: React.ReactNode
}

const MepNavbar: React.ComponentType<NavbarProps> = React.memo(
  ({ app, showIntegrate, subNavItems }: NavbarProps): React.ReactElement => {
    const dispatch = useDispatch()
    const ref = React.useRef(null)
    const width = useWidthObserver(ref, 1200)
    const breakpoint = React.useMemo(
      () => (width < 380 ? 'small' : width < 680 ? 'medium' : 'large'),
      [width]
    )
    const onIntegrateClick = React.useCallback(() => dispatch(openIntegrate()), [dispatch])
    const navData = useBuildLegacyNav(app)

    return (
      <React.Fragment>
        <NavbarContainer ref={ref} $breakpoint={breakpoint} data-testid="mep-navbar">
          <NavbarMenu className="navbar-navigation styled-react-to-breakpoint">
            {navData.map(({ label, isActive, url, icon }) => (
              <Tooltip key={label} tooltip={breakpoint !== 'large' && label}>
                <ButtonLink
                  href={url}
                  isActive={isActive}
                  kind="inlineDark"
                  intent="neutral"
                  aria-current="page"
                  role="link"
                  data-testid={`navbar-${label.toLowerCase()}`}
                  addOn={icon ? 'prefix' : undefined}
                  addOnGap={icon ? 10 : 0}
                >
                  {icon && <Icon icon={icon} />}
                  {icon ? <span className="styled-hide-on-medium">{label}</span> : label}
                </ButtonLink>
              </Tooltip>
            ))}
          </NavbarMenu>
          {showIntegrate && (
            <NavbarMenu className="navbar-navigation styled-react-to-breakpoint">
              <Separator />
              <Button
                onClick={onIntegrateClick}
                kind="inlineDark"
                intent="neutral"
                style={{ marginLeft: 12 }}
              >
                Integrate
              </Button>
            </NavbarMenu>
          )}
        </NavbarContainer>
        {subNavItems && <SubNav>{subNavItems}</SubNav>}
      </React.Fragment>
    )
  }
)

export { MepNavbar }
