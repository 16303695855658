import * as React from 'react'

import Hint from 'com.batch/shared/ui/component/hint'

import { Feedback } from './feedback'
import { InputWrapperContainer } from './input-wrapper.styles'

type InputWrapperProps = {
  label?: React.ReactNode
  hint?: React.ReactNode
  labelStyle?: Record<any, any>
  hintPlacement?: 'top' | 'bottom' | 'left' | 'right'
  hintMinSize?: number
  style?: Record<any, any>
  feedback?: React.ReactNode
  feedbackType?: 'error' | 'warning' | 'insight'
  feedbackWeight?: number
  additional?: React.ReactNode
  htmlFor?: string
  children?: React.ReactNode
  optional?: boolean
  testId?: string
  id?: string
}

const InputWrapper = ({
  label,
  style,
  hint,
  labelStyle,
  hintPlacement,
  hintMinSize,
  feedback,
  feedbackType,
  feedbackWeight,
  additional,
  children,
  htmlFor,
  optional,
  testId,
  id,
}: InputWrapperProps): React.ReactElement => {
  return (
    <InputWrapperContainer style={style} data-testid={testId}>
      {(label || hint) && (
        <span>
          {label && (
            <label style={labelStyle} htmlFor={htmlFor} id={id}>
              {label}{' '}
              {optional && <span style={{ fontWeight: 400, color: '#818792' }}>optional</span>}
            </label>
          )}
          {hint && (
            <Hint
              style={{ padding: 6 }}
              tooltipWidth={hintMinSize ?? 120}
              placement={hintPlacement ? hintPlacement : 'top'}
            >
              {hint}
            </Hint>
          )}
        </span>
      )}
      {Boolean(children) && (
        <div style={{ width: '100%' }}>
          {children}
          {(!!feedback || !!additional) && (
            <div className="styled-input-wrapper-message">
              {feedback && (
                <Feedback
                  type={feedbackType || 'error'}
                  message={feedback}
                  $fontWeight={feedbackWeight}
                />
              )}
              {additional && additional}
            </div>
          )}
        </div>
      )}
    </InputWrapperContainer>
  )
}

export { InputWrapper }
