import * as React from 'react'

import { ColorPicker } from 'components/common/color-picker'
import { Grid } from 'components/common/grid'
import { TableToggle, TableToggleItem } from 'components/common/tabletoggle'
import { Tooltip } from 'com.batch/shared/ui/component/tooltip'

import { OpacityEditor } from './opacity-editor'

import { type FieldRecord, type StyleProperty } from 'com.batch.redux/theme.records'

type StyleEditorProps = {
  field: FieldRecord
  update: (property: StyleProperty, value?: any) => void
}

const labelForStyle = {
  backgroundColor: 'Background',
  backgroundOpacity: 'Opacity',
  color: 'Color',
  borderRadius: 'Border radius',
  fontSize: 'Font size',
  fontWeight: 'Font weight',
  backgroundSize: 'Size',
  padding: 'Padding',
  margin: 'Margin',
  borderWidth: 'Border width',
  borderColor: 'Border',
}

export const StyleEditor = ({ field, update }: StyleEditorProps): React.ReactElement | null => {
  const onBorderRadiusChange = React.useCallback(
    (evt: React.ChangeEvent<HTMLInputElement>) => {
      const val = parseInt(evt.target.value)
      if (!isNaN(val) && val >= 0) {
        update('borderRadius', `${val}px`)
      } else {
        update('borderRadius', '')
      }
    },
    [update]
  )
  const borderRadius = React.useMemo(() => {
    const val = field.style.get('borderRadius', '')
    return val ? parseInt(val.toString().replace(/px/g, '')) : ''
  }, [field.style])
  const onOpacityChange = React.useCallback(
    val => {
      update('backgroundOpacity', val)
    },
    [update]
  )
  const opacity = React.useMemo(() => {
    const bgOpacity = field.style.get('backgroundOpacity', 100)
    return typeof bgOpacity === 'string' ? parseInt(bgOpacity) : bgOpacity ?? 100
  }, [field.style])

  const createOnColorChange = React.useCallback(
    (name: StyleProperty) => (color: string) => {
      update(name, color)
    },
    [update]
  )
  const createPropUpdater = React.useCallback(
    (name: StyleProperty, value: any) => () => {
      update(name, value)
    },
    [update]
  )
  const renderStyle = React.useCallback(
    (name: StyleProperty) => {
      switch (name) {
        case 'borderColor':
        case 'color':
        case 'backgroundColor': {
          const color = field.style.get(name, '') || ''
          return (
            <Grid template="1fr">
              <ColorPicker color={color.toString()} onChange={createOnColorChange(name)} />
            </Grid>
          )
        }
        case 'backgroundOpacity':
          return (
            <Grid template="1fr auto">
              <OpacityEditor value={opacity} onChange={onOpacityChange} />
              <span className="unit">%</span>
            </Grid>
          )
        case 'borderRadius':
          return (
            <Grid template="1fr auto">
              <input
                type="number"
                onChange={onBorderRadiusChange}
                value={borderRadius}
                className="form-control"
              />
              <span className="unit">px</span>
            </Grid>
          )
        case 'backgroundSize':
          return (
            <Grid template="1fr">
              <TableToggle>
                <TableToggleItem
                  active={field.style.get(name) === 'cover'}
                  onClick={createPropUpdater(name, 'cover')}
                >
                  <Tooltip
                    tooltip="Scales the image as large as possible without stretching the image. If the proportions of the image differ from the device, it is cropped either vertically or horizontally so that no empty space remains."
                    width={300}
                    placement="top"
                  >
                    <div>Cover</div>
                  </Tooltip>
                </TableToggleItem>
                <TableToggleItem
                  active={field.style.get(name) === 'contain'}
                  onClick={createPropUpdater(name, 'contain')}
                >
                  <Tooltip
                    tooltip="Scales the image as large as possible without cropping or stretching the image."
                    width={300}
                    placement="top"
                  >
                    <div>Contain</div>
                  </Tooltip>
                </TableToggleItem>
              </TableToggle>
            </Grid>
          )
        default:
          return null
      }
    },
    [
      borderRadius,
      createOnColorChange,
      createPropUpdater,
      field.style,
      onBorderRadiusChange,
      onOpacityChange,
      opacity,
    ]
  )

  return (
    <div>
      {field &&
        field.editableStyles.size > 0 &&
        field.editableStyles.map(propertyName => {
          return (
            <div key={`${field.id}-${propertyName}`} className="form-group form-group--inline">
              <label className="form-group--inline__label">{labelForStyle[propertyName]}</label>
              {renderStyle(propertyName)}
            </div>
          )
        })}
    </div>
  )
}
