import * as React from 'react'

import { Icon } from 'components/common/svg-icon'
import { colors } from 'components/styled/tokens'
import { OrchestrationState } from 'constants/common/orchestration-state'

type CampaignStateIconProps = {
  state: OrchestrationState
  size?: number
  schedulingType: schedulingType
  grayscale?: boolean
} & React.ComponentPropsWithoutRef<'i'>

export const CampaignStateIcon: React.ComponentType<CampaignStateIconProps> = React.forwardRef<
  HTMLElement,
  CampaignStateIconProps
>(
  (
    { state, schedulingType, size, grayscale = false, ...props }: CampaignStateIconProps,
    ref
  ): React.ReactElement => {
    const commonProps = {
      size: size ?? 18,
      ref,
      ...props,
    }

    switch (state) {
      case OrchestrationState.COMPLETED:
        return (
          <Icon
            {...commonProps}
            icon="success"
            color={grayscale ? colors.textContrastDisabled : colors.textSuccess}
            style={{ paddingBottom: 2 }}
          />
        )
      case OrchestrationState.RUNNING:
        return schedulingType === 'automations' ? (
          <Icon
            {...commonProps}
            icon="running-fill"
            color={grayscale ? colors.textContrastDisabled : colors.fillAction}
          />
        ) : (
          <Icon
            {...commonProps}
            icon="planned"
            color={grayscale ? colors.textContrastDisabled : colors.textAction}
          />
        )
      case OrchestrationState.DRAFT:
        return (
          <Icon
            {...commonProps}
            icon="draft-fill"
            color={grayscale ? colors.textContrastDisabled : colors.textDisabled}
          />
        )
      default:
        return (
          <Icon
            {...commonProps}
            icon="stopped"
            color={grayscale ? colors.textContrastDisabled : colors.textDanger}
          />
        )
    }
  }
)
