import { get } from 'lodash-es'
import * as React from 'react'

import { useCurrentCompanyHasFeature } from 'components/_hooks'
import { Avatar } from 'components/common/avatar'
import {
  ButtonLink,
  DropdownMenu,
  DropdownSeparator,
  Button,
  useDropdown,
} from 'components/common/button'
import { Icon } from 'components/common/svg-icon'

import { generateUrl } from 'com.batch.common/router'

import { ProfileSection, ProfileSectionHeader } from './dropdown.styles'

import { type CompanyRecord } from 'com.batch.redux/_records'
import { type UserRecord } from 'com.batch.redux/user.records'

type ProfileDropDownProps = {
  user: UserRecord
  company: CompanyRecord
  light?: boolean
  style?: {
    [key: string]: string | number
  }
}

export const ProfileDropDown = ({ user, company }: ProfileDropDownProps): React.ReactElement => {
  const { triggerProps, dropdownProps } = useDropdown({ placement: 'left-start' })
  const onCookieManage = React.useCallback(
    () => window.Osano.cm.showDrawer('osano-cm-dom-info-dialog-open'),
    []
  )
  const cepOnly = useCurrentCompanyHasFeature('cep-only')
  return (
    <React.Fragment>
      <Button {...triggerProps} kind="inlineDark" style={{ height: 44 }} intent="neutral">
        <Avatar
          url={user?.avatarUrl || undefined}
          placeholder={`${user.firstName} ${user.lastName}`}
          size={30}
        >
          <Avatar
            style={{ width: 15, height: 15 }}
            placeholder={`${company.name}`}
            url={company.avatarUrl}
          />
        </Avatar>
      </Button>
      <DropdownMenu {...dropdownProps}>
        <ProfileSection style={{ padding: 0 }}>
          <ProfileSectionHeader style={{ paddingRight: 0 }}>
            <Avatar
              url={user?.avatarUrl || undefined}
              placeholder={`${user.firstName} ${user.lastName}`}
              size={34}
            />
            <div className="profile-section-infos">
              <p>
                {user.firstName} {user.lastName}
              </p>
              <span>Your profile</span>
            </div>
            <ButtonLink kind="inline" href={logoutUrl} style={{ width: 'unset' }}>
              <Icon icon="logout" />
            </ButtonLink>
          </ProfileSectionHeader>
          <ButtonLink
            addOnGap={10}
            href={generateUrl('account_settings')}
            kind="inline"
            addOn="prefix"
          >
            <Icon icon="user" />
            Account settings
          </ButtonLink>
          <ButtonLink
            addOnGap={10}
            href={generateUrl('account_security')}
            kind="inline"
            addOn="prefix"
          >
            <Icon icon="lock" />
            Security
          </ButtonLink>
        </ProfileSection>
        <DropdownSeparator />
        <ProfileSection style={{ padding: 0 }}>
          <ProfileSectionHeader>
            <Avatar url={company.avatarUrl} placeholder={`${company.name}`} size={34} />
            <div className="profile-section-infos">
              <p>{company.name}</p>
              <span>Your company</span>
            </div>
          </ProfileSectionHeader>
          <ButtonLink
            addOnGap={10}
            href={generateUrl('user_list_by_company', { companyId: company.id })}
            kind="inline"
            addOn="prefix"
          >
            <Icon icon="team" />
            Manage team
          </ButtonLink>
          <ButtonLink
            addOnGap={10}
            href={generateUrl('company_settings', { companyId: company.id })}
            kind="inline"
            addOn="prefix"
          >
            <Icon icon="settings" />
            Settings
          </ButtonLink>
          <ButtonLink
            addOnGap={10}
            href={generateUrl('company_billing', { companyId: company.id })}
            kind="inline"
            addOn="prefix"
          >
            <Icon icon="billing" />
            Plans & billing
          </ButtonLink>
          <ButtonLink
            addOnGap={10}
            href={generateUrl('company_gdpr', { companyId: company.id })}
            kind="inline"
            addOn="prefix"
          >
            <Icon icon="privacy" />
            GDPR & Privacy
          </ButtonLink>
        </ProfileSection>
        <DropdownSeparator />
        <ProfileSection style={{ padding: 0 }}>
          <ButtonLink addOnGap={10} onClick={onCookieManage} kind="inline" addOn="prefix">
            <Icon icon="team" />
            Manage cookie preferences
          </ButtonLink>
        </ProfileSection>
        {!cepOnly && (
          <ProfileSection style={{ padding: '8px 2px 2px 2px' }}>
            <ButtonLink href="/" kind="secondary" style={{ textAlign: 'center' }}>
              Switch to app view
            </ButtonLink>
          </ProfileSection>
        )}
      </DropdownMenu>
    </React.Fragment>
  )
}

const logoutUrl = generateUrl('logout', {
  _csrf_token: get(document.querySelector('head > meta[name=account-csrf]'), 'content'),
})
