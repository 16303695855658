import styled, { css } from 'styled-components'
import { buttonScheme } from 'components/common/button/button.scheme'
import * as schemes from 'components/styled/tokens/schemes'
import * as colors from 'components/styled/tokens/colors'
import { overlay } from 'components/styled/tokens/shadows'

export const IntercomButton = styled.button<{ $isDisabled: boolean }>`
  border: none;
  background-color: ${buttonScheme.primary.action.normal.background};
  color: ${schemes.lightlucent['90']};
  position: fixed;
  bottom: 150px;
  right: 0;
  border-bottom-left-radius: 8px;
  border-top-left-radius: 8px;
  height: 44px;
  width: 44px;
  min-width: 0;
  z-index: 999;
  padding: 0;
  box-shadow: ${overlay};

  &:hover {
    background-color: ${buttonScheme.primary.action.hover.background};
    color: ${schemes.lightlucent['100']};
  }

  &:active {
    background-color: ${buttonScheme.primary.action.active.background};
    color: ${schemes.lightlucent['100']};
  }

  ${p =>
    p.$isDisabled &&
    css`
      color: ${buttonScheme.primary.action.disabled.color} !important;
      background-color: ${buttonScheme.primary.action.disabled.background} !important;
      cursor: not-allowed;
    `}
`

export const IntercomUnreadBadge = styled.div`
  background-color: ${colors.fillDanger};
  border: 2px solid ${colors.fillBackground};
  width: 16px;
  height: 16px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 10px;
  position: absolute;
  top: -6px;
  left: -6px;
  font-size: 10px;
`
