import { request } from 'com.batch.common/request'
import { generateUrl } from 'com.batch.common/router/router'
import { type Map } from 'immutable'
import { generateProjectKeyBlockRequest } from './generate-block-request'

import { parseEstimateProfile } from 'com.batch/orchestration/infra/parses/estimate-profile.parse'
import { type ProfileEstimateResponse } from 'com.batch/orchestration/infra/types/estimate.types'
import { type EstimateProfileRecord } from 'com.batch/orchestration/models/profile-estimate.records'
import {
  TemplatingFactory,
  type TemplatingRecord,
} from 'com.batch/templating/models/templating.records'
import { wrapBatchTemplating } from 'com.batch/templating/models/templating.utils'
import { LoadingStatus } from 'constants/common'

export type OurSqlService = {
  fetchProfileEstimateFor(arg1: {
    projectKey: string
    query: string
    languages?: Array<string>
    regions?: Array<string>
  }): Promise<EstimateProfileRecord>
  templating(arg1: {
    projectKey: string
    profileId: string
    contents: Map<string, TemplatingRecord>
    abortSignal?: AbortSignal
  }): Promise<Map<string, TemplatingRecord>>
}

export const ourSqlService: OurSqlService = {
  fetchProfileEstimateFor: async ({
    projectKey,
    query,
    languages = [] as Array<string>,
    regions = [] as Array<string>,
  }) => {
    const response = await request.post<{
      response: ProfileEstimateResponse
    }>(
      generateUrl('api_grpc_oursql_service', {
        methodName: 'EstimateOC',
      }),
      {
        ...generateProjectKeyBlockRequest(projectKey),
        query,
        languages,
        regions,
      }
    )
    return parseEstimateProfile(response.response)
  },
  templating: async ({ projectKey, profileId, contents, abortSignal }) => {
    const tuples = contents.toArray()
    const contentArray = tuples.reduce(
      (
        acc: { type: 'HTML_TEMPLATE' | 'TEMPLATE'; value: string; key: string }[],
        [key, templatingRec]
      ) => {
        if (!templatingRec.valueIsHtml)
          acc.push({
            key,
            type: 'HTML_TEMPLATE',
            value: wrapBatchTemplating(templatingRec.value),
          })
        acc.push({
          key,
          type: templatingRec.valueIsHtml ? 'HTML_TEMPLATE' : 'TEMPLATE',
          value: templatingRec.value,
        })
        return acc
      },
      []
    )

    const payload = {
      ...generateProjectKeyBlockRequest(projectKey),
      profileID: profileId
        ? {
            data: profileId,
          }
        : null,
      contents: [
        {
          elements: [
            {
              contents: contentArray.map(({ type, value }) => ({ type, value })),
            },
          ],
        },
      ],
    }

    const response = await request.post<any>(
      generateUrl('api_grpc_oursql_service', {
        methodName: 'RenderContent',
      }),
      payload,
      abortSignal
    )
    const responseContents = response?.contentSetResults[0].contents ?? []

    contentArray.forEach(({ key, type, value }, index) => {
      const rec = contents.get(key, TemplatingFactory())
      contents = contents.set(
        key,
        rec
          .set('fetchingState', LoadingStatus.LOADED)
          .set('value', value)
          .set(
            type === 'HTML_TEMPLATE' ? 'richTemplatedString' : 'templatedString',
            responseContents?.[index]?.content ?? ''
          )
      )
    })
    return contents
  },
}
