import * as React from 'react'

import { ThemeProvider, useTheme } from 'styled-components'

import { useToggle } from 'components/_hooks'
import { Tooltip } from 'com.batch/shared/ui/component/tooltip'

import { PopinSendTestPush } from './popin-send-test-push'
import { PushLayerBarSendTest } from './push-layer-bar-send-test'

import { type MessageBuilderEditableField } from 'com.batch/message-builder/models/message-builder-field'
import { LayerBarDropdown } from 'com.batch/message-builder/ui/components/layerbar-button'
import {
  DoubleLayerBarButton,
  LayerBarDivider,
  LayerBarWrapper,
} from 'com.batch/message-builder/ui/components/layerbar.styles'
import { PreviewProfilePopin } from 'com.batch/shared/ui/component/popins/preview-profile-popin'
import { PreviewAs } from 'com.batch/shared/ui/component/preview-as'
import { useDispatch, useSelector } from 'react-redux'
import { templatingCacheSelector } from 'com.batch/templating/store/templating.selector'
import { fetchTemplating } from 'com.batch/templating/usecases/fetch-templating'

type PushLayerBarProps = {
  stepMessageNodeId: string | null | undefined
  hasMedia: boolean
  hasIcon: boolean
  showIcon: boolean
  setEditing: (field: MessageBuilderEditableField, caret: number) => void
  openFileUploader: () => void
  openMediaPopin: () => void
}

const PushLayerBarRaw = ({
  stepMessageNodeId,
  hasMedia,
  hasIcon,
  showIcon,
  setEditing,
  openFileUploader,
  openMediaPopin,
}: PushLayerBarProps): React.ReactElement => {
  const previewProfilePopinState = useToggle()
  const sendTestPopinState = useToggle()
  const templatingCache = useSelector(templatingCacheSelector)
  const dispatch = useDispatch()
  const updateTemplate = React.useCallback(() => {
    dispatch<any>(fetchTemplating(templatingCache, true))
  }, [dispatch, templatingCache])
  const theme = useTheme()

  const handlePreviewAsClick = React.useCallback(() => {
    previewProfilePopinState.open()
  }, [previewProfilePopinState])
  const handleSendTestClick = React.useCallback(() => {
    sendTestPopinState.open()
  }, [sendTestPopinState])

  const createHandleSetEditing = React.useCallback(
    (type: MessageBuilderEditableField) => () => {
      setEditing(type, -1)
    },
    [setEditing]
  )

  const isMacOrIOS = React.useMemo(
    () => (theme.previewPlatform ? ['ios', 'webMac'].includes(theme.previewPlatform) : false),
    [theme]
  )

  return (
    <LayerBarWrapper $itemsCount={!theme?.disabledMode ? (showIcon ? 4 : 3) : 2}>
      <ThemeProvider theme={{ disabledMode: false }}>
        <PreviewProfilePopin
          togglePopin={previewProfilePopinState}
          updateTemplate={updateTemplate}
        />
        <PopinSendTestPush togglePopin={sendTestPopinState} stepMessageNodeId={stepMessageNodeId} />
      </ThemeProvider>

      {!theme?.disabledMode && (
        <DoubleLayerBarButton>
          <LayerBarDropdown
            embedded
            label={!hasMedia ? 'Add media' : 'Media'}
            icon={<img src="/medias/img/builders/icons/media.svg" alt="Adding a media" />}
            addable={!hasMedia}
            onClick={createHandleSetEditing('image')}
            options={[
              { label: 'From computer', prefix: 'upload', action: openFileUploader },
              { label: 'From link', prefix: 'link', action: openMediaPopin },
            ]}
          />

          {!theme?.disabledMode && showIcon && (
            <React.Fragment>
              <LayerBarDivider />
              <Tooltip
                tooltip={
                  isMacOrIOS && (
                    <span style={{ whiteSpace: 'nowrap' }}>
                      Icons are not available on{' '}
                      {theme?.previewPlatform === 'ios' ? 'iOS' : 'Web Mac'}
                    </span>
                  )
                }
                placement="top"
              >
                <div>
                  <LayerBarDropdown
                    embedded
                    label={!hasIcon ? 'Add icon' : 'Icon'}
                    icon={<img src="/medias/img/builders/icons/icon.svg" alt="Adding an icon" />}
                    addable={!hasIcon}
                    onClick={createHandleSetEditing('icon')}
                    options={[
                      { label: 'From computer', prefix: 'upload', action: openFileUploader },
                      { label: 'From link', prefix: 'link', action: openMediaPopin },
                    ]}
                  />
                </div>
              </Tooltip>
            </React.Fragment>
          )}
        </DoubleLayerBarButton>
      )}

      <PushLayerBarSendTest
        handleSendTestClick={handleSendTestClick}
        stepMessageNodeId={stepMessageNodeId}
      />
      <PreviewAs handlePreviewAsClick={handlePreviewAsClick} updateTemplate={updateTemplate} />
    </LayerBarWrapper>
  )
}

export const PushLayerBar: React.ComponentType<PushLayerBarProps> = React.memo(PushLayerBarRaw)
