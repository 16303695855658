import { type State } from 'com.batch.redux/_records'
import { type Map } from 'immutable'
import { type TemplatingRecord } from '../models/templating.records'
import { createSelector } from 'reselect'

const templatingSelector = (state: State) => state.messageTemplating
type Extract<T> = (arg1: State) => T

export const templatingCacheSelector: Extract<Map<string, TemplatingRecord>> = createSelector(
  templatingSelector,
  ts => ts.entities
)

export const previewProfileIdCacheSelector: Extract<string> = createSelector(
  templatingSelector,
  ts => ts.previewSourceProfileId ?? ''
)
