import Immutable, { type Set, type List } from 'immutable'
import * as React from 'react'

import {
  Box,
  BoxHeader,
  BoxBody,
  BoxSection,
  BoxFooter,
  FooterBoxActions,
  HeaderBoxActions,
  HeaderBoxTitle,
} from 'components/common/box'
import { Button, ButtonLink } from 'components/common/button'
import { Overlay } from 'components/common/empty-states'
import { Grid } from 'components/common/grid'
import { Popin } from 'components/common/popin/popin'
import { Icon } from 'components/common/svg-icon'
import { FilterSearch } from 'components/filter'
import { QueryBuilderContext } from 'components/query/query-builder.context'

import {
  CategoryContainer,
  CategoryTitle,
  SubCategoryContainer,
  SubCategoryTitle,
} from './query.styles'

import { type QueryAttributeRecord } from 'com.batch.redux/query/query.records'
import { useToggle } from 'components/_hooks'
import {
  AttributeButton,
  mepFakeOmniFormFilters,
  profileFilters,
} from './add-condition-modal.helper'

type AddConditionModalProps = {
  closeModal: () => void
  attributes: List<QueryAttributeRecord>
  addCondition: (arg1: QueryAttributeRecord) => void
}

export const AddConditionModal = ({
  closeModal,
  attributes,
  addCondition,
}: AddConditionModalProps): React.ReactElement => {
  const { isProfileMode } = React.useContext(QueryBuilderContext)
  const [selected, setSelected] = React.useState<Set<QueryAttributeRecord>>(Immutable.Set())
  const searchFocusedState = useToggle()
  const [search, setSearch] = React.useState('')

  const filteredAttributes = React.useMemo(
    () =>
      attributes.filter(
        attr => !search || attr.label.toLowerCase().indexOf(search.toLowerCase()) !== -1
      ),
    [search, attributes]
  )

  const isSearchFullWidth = React.useMemo(
    () => searchFocusedState.value || search !== '',
    [searchFocusedState.value, search]
  )

  const filters = React.useMemo(
    () =>
      (isProfileMode ? profileFilters : mepFakeOmniFormFilters).filter(
        f => attributes.filter(f.filter).size > 0
      ),
    [attributes, isProfileMode]
  )

  const save = React.useCallback(() => {
    selected.forEach(attribute => addCondition(attribute))
    closeModal()
  }, [addCondition, closeModal, selected])

  const onSearch = React.useCallback((search: string) => {
    setSearch(search)
  }, [])

  const getAttributeCallback = React.useCallback(
    (attribute: QueryAttributeRecord) => () =>
      setSelected(selected.has(attribute) ? selected.remove(attribute) : selected.add(attribute)),
    [selected]
  )
  return (
    <Popin opened={true} close={closeModal} style={{ width: 740 }}>
      <Box style={{ maxHeight: 620, height: 'calc(100vh - 40px)' }}>
        <BoxHeader>
          <HeaderBoxTitle title="Add condition(s)" />
          <HeaderBoxActions>
            <Button kind="inline" intent="neutral" onClick={closeModal}>
              <Icon icon="close" />
            </Button>
          </HeaderBoxActions>
        </BoxHeader>
        <BoxBody>
          <Grid
            template={isSearchFullWidth ? '1fr' : '1fr auto'}
            style={{
              height: 52,
              margin: isSearchFullWidth ? '0 10px' : '0 2px 0 10px',
            }}
          >
            <FilterSearch
              onChange={onSearch}
              onFocus={searchFocusedState.open}
              onBlur={searchFocusedState.close}
              value={search}
              expandable={false}
            />
            {!isSearchFullWidth && (
              <div style={{ textAlign: 'right' }}>
                {filters.map(f => (
                  <ButtonLink
                    key={f.id}
                    kind="inline"
                    intent="neutral"
                    href={`#${f.id}`}
                    style={{ marginRight: 8 }}
                  >
                    {f.label}
                  </ButtonLink>
                ))}
              </div>
            )}
          </Grid>
          <BoxSection
            style={{ margin: 0, borderBottom: 'none', borderRight: 'none', borderLeft: 'none' }}
          >
            <div
              id="js-modal-scroll-container"
              style={{
                maxHeight: '464px',
                height: 'calc(100vh - 235px)',
                padding: '0px 24px 80px 24px',
                overflowY: 'auto',
              }}
            >
              {search.length > 0 ? (
                filteredAttributes.size === 0 ? null : (
                  <CategoryContainer>
                    {filteredAttributes.map(attribute => {
                      return (
                        <AttributeButton
                          isSelected={selected.has(attribute)}
                          key={attribute.api}
                          attribute={attribute}
                          onClick={getAttributeCallback(attribute)}
                          withIcon
                        />
                      )
                    })}
                  </CategoryContainer>
                )
              ) : (
                filters.map(f => {
                  const attrForCat = filteredAttributes.filter(f.filter)
                  return (
                    <CategoryContainer key={f.id} id={f.id} className="js-watch-scroll">
                      <CategoryTitle>
                        {f.label}
                        {f.id === 'native' ? ' data' : ''}
                      </CategoryTitle>
                      {!f.subFilters || (f.id !== 'native' && attrForCat.size < 20)
                        ? attrForCat.map(attribute => {
                            return (
                              <AttributeButton
                                isSelected={selected.has(attribute)}
                                key={attribute.api}
                                attribute={attribute}
                                onClick={getAttributeCallback(attribute)}
                                withIcon
                              />
                            )
                          })
                        : f.subFilters.map(subFilter => {
                            const attrForSubCat = attrForCat.filter(subFilter.filter)
                            if (attrForSubCat.size === 0) return null
                            return (
                              <SubCategoryContainer key={subFilter.id}>
                                <SubCategoryTitle id={subFilter.id}>
                                  {subFilter.icon && <Icon icon={subFilter.icon} />}
                                  {subFilter.label}
                                </SubCategoryTitle>
                                {attrForSubCat.map(attribute => (
                                  <AttributeButton
                                    isSelected={selected.has(attribute)}
                                    key={attribute.api}
                                    attribute={attribute}
                                    onClick={getAttributeCallback(attribute)}
                                    withIcon={f.id === 'native'}
                                  />
                                ))}
                              </SubCategoryContainer>
                            )
                          })}
                    </CategoryContainer>
                  )
                })
              )}
              {filteredAttributes.size === 0 && (
                <div
                  style={{
                    maxWidth: '100%',
                    marginTop: 170,
                    display: 'flex',
                    justifyContent: 'center',
                  }}
                >
                  <Overlay
                    status="empty"
                    title={
                      <React.Fragment>
                        <Icon icon="no-result" style={{ marginRight: 6 }} />
                        No conditions to display
                      </React.Fragment>
                    }
                    description="There’s nothing matching your criteria, try something else."
                  />
                </div>
              )}
            </div>
          </BoxSection>
        </BoxBody>
        <BoxFooter isEditable>
          <Button kind="inline" intent="neutral" onClick={closeModal}>
            Cancel
          </Button>
          <FooterBoxActions>
            <Button kind="primary" intent="action" onClick={save} disabled={selected.size === 0}>
              Add {selected.size > 0 ? selected.size : ''} condition{selected.size > 1 && 's'}
            </Button>
          </FooterBoxActions>
        </BoxFooter>
      </Box>
    </Popin>
  )
}
