import Immutable, { type RecordOf, type Map } from 'immutable'
import {
  type AppDispatch,
  type DispatchExtraBoundFn,
  type ReduxAction,
} from 'com.batch.redux/_records'
import { currentProjectSelector } from 'com.batch.redux/project.selector'
import { type TemplatingRecord } from '../models/templating.records'
import { type OurSqlService } from 'com.batch/shared/infra/oursql.service'
import { previewProfileIdCacheSelector } from '../store/templating.selector'
import { showToast } from 'com.batch.redux/toaster'
import { LoadingStatus } from 'constants/common'

export type TEMPLATING_FETCH_ACTION = ReduxAction<'TEMPLATING_FETCH', Map<string, TemplatingRecord>>
export type TEMPLATING_FETCH_SUCCESS_ACTION = ReduxAction<
  'TEMPLATING_FETCH_SUCCESS',
  Map<string, TemplatingRecord>
>
export type TEMPLATING_FETCH_FAILURE_ACTION = ReduxAction<
  'TEMPLATING_FETCH_FAILURE',
  Map<string, TemplatingRecord>
>
const DEBOUNCE_MS = 500

type AbortableDebouncedProps = {
  abortController?: AbortController
  timeoutId?: number
}
const AbortableDebouncedFactory = Immutable.Record<AbortableDebouncedProps>({
  abortController: undefined,
  timeoutId: undefined,
})
type AbortableDebouncedRecord = RecordOf<AbortableDebouncedProps>

let awaitingOrInFlight = Immutable.Map<string, AbortableDebouncedRecord>()

export const fetchTemplating = (
  contents: Map<string, TemplatingRecord>,
  isInstant: boolean
): DispatchExtraBoundFn<void> => {
  return (dispatch, getState, { ourSqlService }) => {
    if (contents.size === 0) return
    const state = getState()
    const project = currentProjectSelector(state)
    const profileId = previewProfileIdCacheSelector(state)
    if (isInstant) {
      reallyFetch({
        ourSqlService,
        projectKey: project.projectKey,
        profileId,
        contents,
        dispatch,
      })
    } else {
      const key = contents.keySeq().first() ?? '' // always set
      const abortableDebounced = awaitingOrInFlight.get(key, AbortableDebouncedFactory())
      if (abortableDebounced.timeoutId) window.clearTimeout(abortableDebounced.timeoutId)
      if (abortableDebounced.abortController) abortableDebounced.abortController.abort()
      const abortController = new AbortController()
      awaitingOrInFlight = awaitingOrInFlight.set(
        key,
        abortableDebounced.set('abortController', abortController).set(
          'timeoutId',
          window.setTimeout(() => {
            reallyFetch({
              ourSqlService,
              projectKey: project.projectKey,
              profileId,
              contents,
              abortSignal: abortController.signal,
              dispatch,
            })
          }, DEBOUNCE_MS)
        )
      )
    }
  }
}

const reallyFetch = ({
  ourSqlService,
  projectKey,
  profileId,
  contents,
  abortSignal,
  dispatch,
}: {
  ourSqlService: OurSqlService
  projectKey: string
  profileId: string
  contents: Map<string, TemplatingRecord>
  abortSignal?: AbortSignal
  dispatch: AppDispatch
}) => {
  dispatch<TEMPLATING_FETCH_ACTION>({
    type: 'TEMPLATING_FETCH',
    payload: contents.map(t => t.set('fetchingState', LoadingStatus.LOADING)),
  })
  ourSqlService
    .templating({
      projectKey: projectKey,
      profileId,
      contents,
      abortSignal,
    })
    .then(response => {
      dispatch<TEMPLATING_FETCH_SUCCESS_ACTION>({
        type: 'TEMPLATING_FETCH_SUCCESS',
        payload: response,
      })
    })
    .catch(error => {
      console.log(error)
      if (!error.aborted) {
        dispatch(
          showToast({
            message: error?.error?.message ?? 'Failed to fetch templating',
            kind: 'error',
          })
        )
        dispatch<TEMPLATING_FETCH_FAILURE_ACTION>({
          type: 'TEMPLATING_FETCH_FAILURE',
          payload: contents.map(t => t.set('fetchingState', LoadingStatus.ERROR)),
        })
      }
    })
}
