import { get } from 'lodash-es'
import * as React from 'react'
import styled, { css } from 'styled-components'

import { Avatar } from 'components/common/avatar'
import { ButtonLink } from 'components/common/button'
import { Icon } from 'components/common/svg-icon'
import * as colorsLegacy from 'components/styled/colors'
import { Link } from 'components/styled/text'
import { colors, schemes } from 'components/styled/tokens'

import { generateUrl } from 'com.batch.common/router'

import { PADDING, INNER_PADDING } from './sidebar.styles'

import { type CompanyRecord } from 'com.batch.redux/_records'
import { type ProjectRecord } from 'com.batch.redux/project.records'
import { type UserRecord, type companyPermissionType } from 'com.batch.redux/user.records'
import { Tooltip } from 'com.batch/shared/ui/component/tooltip'
import { useCurrentCompanyHasFeature } from 'components/_hooks'

type SidebarAccountProps = {
  user: UserRecord
  project: ProjectRecord | null | undefined
  company: CompanyRecord
  className?: string
  style?: any
}

type SectionMenuProps = {
  children: React.ReactNode
  href?: string
  lock?: boolean
  onClick?: () => void
}

export const SidebarAccount = (props: SidebarAccountProps): React.ReactElement => {
  const { user, company, className, style, project } = props
  const compId = company.id

  const isLockedFromCepInterface = useCurrentCompanyHasFeature('mep-only')

  const isLocked = (perm: companyPermissionType): boolean =>
    !user.permissionsForCurrentCompany.has(perm)

  const onCookieManage = React.useCallback(
    () => window.Osano.cm.showDrawer('osano-cm-dom-info-dialog-open'),
    []
  )
  const hasAtLeastOneCepChannel = React.useMemo(
    () => project?.emailConfigured || project?.smsConfigured || project?.anyPushConfigured,
    [project]
  )

  return (
    <Container className={className} style={style}>
      <Section>
        <SectionHeader>
          <Avatar url={user.avatarUrl || undefined} placeholder={user.firstName} size={26} />
          <SectionTitle title={`${user.firstName} ${user.lastName}`}>
            {user.firstName} {user.lastName}
          </SectionTitle>
          <Tooltip tooltip="Log out from Batch" placement="right">
            <ButtonLink href={logoutUrl} style={{ margin: '0 0 0 12px' }}>
              <Icon icon="logout" />
            </ButtonLink>
          </Tooltip>
        </SectionHeader>
        <SectionMenu href={getRoute('account_settings')}>
          <Icon icon="user" /> Account settings
        </SectionMenu>
        <SectionMenu href={getRoute('account_security')}>
          <Icon icon="unlock" /> Security
        </SectionMenu>
      </Section>
      <Section>
        <SectionHeader title={company.name}>
          <Avatar url={company.avatarUrl} placeholder={company.name} size={26} />
          <SectionTitle>{getCompanyHead(company.name, compId)}</SectionTitle>
        </SectionHeader>
        <SectionMenu href={getRoute('user_list_by_company', compId)} lock={isLocked('users')}>
          <Icon icon="team" /> <span>Manage team</span>
        </SectionMenu>
        <SectionMenu href={getRoute('company_settings', compId)} lock={isLocked('administrate')}>
          <Icon icon="settings" /> <span>Settings</span>
        </SectionMenu>
        <SectionMenu href={getRoute('company_billing', compId)} lock={isLocked('billing')}>
          <Icon icon="billing" /> <span>Plans & billing</span>
        </SectionMenu>
        <SectionMenu href={getRoute('company_gdpr', compId)} lock={isLocked('gdpr')}>
          <Icon icon="privacy" /> <span>GDPR & privacy</span>
        </SectionMenu>
      </Section>
      <Section>
        <SectionMenu onClick={onCookieManage}>
          <Icon icon="cookie" />
          Manage cookie preferences
        </SectionMenu>
      </Section>
      {project && !isLockedFromCepInterface && (
        <Section>
          <ButtonLink
            kind="secondary"
            href={
              hasAtLeastOneCepChannel
                ? `/${compId}/projects/${project.id}/analytics`
                : `/${compId}/projects/${project.id}/apps/${project.appIds.first()}`
            }
            style={{ textAlign: 'center', width: '100%' }}
          >
            Switch to project view
          </ButtonLink>
        </Section>
      )}
    </Container>
  )
}

export const SectionMenu = (props: SectionMenuProps): React.ReactElement => {
  const { children, href, lock, onClick } = props
  const active = props.href === window.location.pathname
  const menuProps: {
    forbidden: boolean
    as: 'a' | 'div'
    active: boolean
    href?: string
  } = {
    as: lock ? 'div' : 'a',
    forbidden: !!lock,
    active,
  }
  if (!lock) {
    menuProps.href = href
  }
  const menu = (
    <SectionMenuContainer {...menuProps} onClick={onClick}>
      {children}
      {lock && <Icon icon="lock" size={11} style={{ marginRight: 3 }} />}
    </SectionMenuContainer>
  )
  return lock ? (
    <Tooltip placement="right" tooltip="You don't have permission to access this section">
      {menu}
    </Tooltip>
  ) : (
    menu
  )
}

function getRoute(route: string, companyId?: number) {
  const params = companyId ? { companyId } : {}
  return generateUrl(route, params)
}

function getCompanyHead(name: string, id: number): React.ReactNode {
  if (name && name.replace(/ /g, '').length > 0) return name
  else
    return (
      <React.Fragment>
        Your company
        <Link href={getRoute('company_settings', id)} intent="action" style={{ marginLeft: 10 }}>
          Edit name
        </Link>
      </React.Fragment>
    )
}

const logoutUrl = generateUrl('logout', {
  _csrf_token: get(document.querySelector('head > meta[name=account-csrf]'), 'content'),
})
type SectionMenuContainerProps = {
  active?: boolean
  forbidden?: boolean
}
export const SectionMenuContainer = styled.a<SectionMenuContainerProps>`
  display: flex;
  align-items: baseline;
  padding: 7px 10px;
  border-radius: 4px;
  color: ${p => (p.active ? colors.text : colors.textLight)};
  background: ${p => (p.active ? schemes.darklucent['20'] : 'transparent')};
  cursor: ${p => (p.forbidden ? 'not-allowed' : 'pointer')};
  user-select: none;

  i {
    margin: 0 14px 0 0;
  }

  span {
    flex: 1 1 auto;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
  }

  & + & {
    margin-top: 2px;
  }

  &:hover,
  &:active {
    color: ${colors.text};
    background: ${schemes.darklucent['10']};
  }

  &:active {
    background: ${schemes.darklucent['20']};
  }

  ${p =>
    p.forbidden &&
    css`
      &,
      &:hover,
      &:active {
        color: ${colors.textDisabled};
        background: none;
      }
    `}
`

export const SectionTitle = styled.span`
  font-weight: 600;
  color: ${colors.text};
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
`

export const SectionHeader = styled.div`
  display: flex;
  align-items: center;
  margin: 0 -2px 18px -3px;
  cursor: default;

  ${SectionTitle} {
    flex: 1 1 auto;
    margin: 0 12px;
  }

  a {
    min-width: 16px;

    .styled-button-text {
      width: 13px;
    }
  }
`

export const Section = styled.div`
  padding: 20px ${INNER_PADDING + PADDING}px 0 ${INNER_PADDING + PADDING}px;

  ${SectionMenuContainer} {
    margin-left: -10px;
    margin-right: -10px;
  }

  & + & {
    margin-top: 24px;
    border-top: 1px solid ${colorsLegacy.stroke.lighter};
  }
`

export const Container = styled.div``

export default SidebarAccount
