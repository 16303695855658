import * as React from 'react'

import { BoxHeader, BoxTitle, BoxBody } from 'components/common/box'
import { Skeleton } from 'components/common/empty-states'
import Hint from 'com.batch/shared/ui/component/hint'
import { Label } from 'components/common/label/label.styles'
import { Icon } from 'components/common/svg-icon'

import { kformat, percentage } from 'com.batch.common/utils'

import {
  ProfilesMetricsBox,
  HelpContainer,
} from 'com.batch/profilebase/ui/components/profiles-metrics/profiles-metrics.styles'
import { Metric } from 'com.batch/shared/ui/component/metric/metric'

type Props = {
  total: number
  identified: number
}
export const ProfilesMetrics = ({ total, identified }: Props): React.ReactElement => (
  <ProfilesMetricsBox>
    <BoxHeader>
      <BoxTitle>
        <Skeleton h={25} w={68}>
          <Label variant="neutral">
            <Icon icon="user" />
            Profiles
          </Label>
        </Skeleton>
      </BoxTitle>
    </BoxHeader>
    <BoxBody>
      <Metric value={kformat(total)} subtitle="total profiles" skeletonMaxWidth={100} />
      <Metric
        size="M"
        value={kformat(identified)}
        skeletonMaxWidth={125}
        subtitle={
          <React.Fragment>
            identified ({percentage(identified / total, 0)})
            <Hint intent="disabled" placement="bottom-start">
              <HelpContainer>
                <span>Profiles with a Custom ID</span>
                Most of the times, these are logged-in users
              </HelpContainer>
            </Hint>
          </React.Fragment>
        }
      />
    </BoxBody>
  </ProfilesMetricsBox>
)
