import * as React from 'react'
import styled, { css, ThemeProvider } from 'styled-components'

import { type availableIcons, Icon, IconContainer } from 'components/common/svg-icon'
import { Tooltip } from 'com.batch/shared/ui/component/tooltip'
import { colors, schemes } from 'components/styled/tokens'

const ItemContainer = styled.div<{
  borderTop?: boolean
  dotted?: boolean
  vertical?: boolean
}>`
  display: flex;
  align-items: first baseline;
  margin: 0;
  padding: ${props =>
    props.theme.icon && props.theme.inline ? '20px 20px 18px 47px' : '20px 20px 18px 20px'};

  ${props =>
    props.borderTop &&
    css`
      border-top: 1px solid #f4f4f4;
    `}
  ${props =>
    props.dotted &&
    css`
      border-bottom: 1px solid #f4f4f4;
    `}
    ${props =>
    props.vertical &&
    css`
      flex-wrap: wrap;
    `}
  

  .targeting {
    white-space: nowrap;
  }
  > ${IconContainer} {
    display: inline-block;
    margin: ${props => (props.theme.inline ? '0 10px 0 -25px' : '0 10px 0 -2px')};
    font-size: 12px;
    color: #2c3747;
  }
  code {
    line-height: 1.4;
  }
`

const ItemName = styled.h6`
  display: inline-block;
  text-transform: uppercase;
  letter-spacing: 0.5px;
  font-size: 11px;
  line-height: 28px;
  color: #89909a;
  font-weight: 500;
  ${props => css`
    flex: ${props.theme.inline
      ? 'none'
      : props.theme.vertical
        ? '1 1 auto'
        : props.theme.autoSize
          ? '0 0 auto'
          : '0 0 125px'};
    min-width: ${props => (props.theme.autoSize ? 'auto' : '125px')};
    margin: ${props.theme.vertical ? '0 0 20px 0' : '0 0 0 0'};
  `};
`

export const ItemDescription = styled.p`
  display: block;
  color: #a8a8a8;
`

export const ItemContent = styled.div<{
  word: boolean
}>`
  display: ${props => (props.theme.inline ? 'inline' : props.theme.noFlex ? 'block' : 'flex')};
  flex-wrap: wrap;
  width: 100%;
  flex: 1 1 100%;
  align-items: first baseline;
  line-height: 28px;
  overflow-wrap: break-word;
  :has(pre) {
    overflow-x: auto;
  }
  word-break: ${props => (props.word ? 'break-word' : 'break-all')};
  pre,
  > .cloader {
    width: 100%;
  }
  code:not(.hljs) {
    border-radius: 5px;
    display: inline;
    margin: 0 2px 0 0;
  }
`

type ItemProps = {
  name?: string
  children?: React.ReactNode
  borderTop?: boolean
  word?: boolean
  noFlex?: boolean
  vertical?: boolean
  icon?: availableIcons
  autoSize?: boolean
  dotted?: boolean
  inline?: boolean
}

export const Item = ({
  name,
  children,
  word = false,
  borderTop = false,
  vertical = false,
  noFlex = false,
  icon,
  autoSize,
  dotted,
  inline,
}: ItemProps): React.ReactElement => (
  <ThemeProvider theme={{ inline, vertical, icon, autoSize, noFlex }}>
    <ItemContainer borderTop={borderTop} dotted={!!dotted}>
      {icon && <Icon icon={icon} size={12} />}
      {name && <ItemName>{name}</ItemName>}
      <ItemContent word={word}>{children}</ItemContent>
    </ItemContainer>
  </ThemeProvider>
)

const FadedCSS = css`
  color: #8c95a9;

  &:after {
    content: ' ';
    white-space: pre;
  }
`
export const ValueAlt = styled.span`
  font-family: 'Fira Code', 'Menlo', 'Segoe UI mono', monospace;
  letter-spacing: -0.07em;
  padding: 0 0.4em;
  display: inline-block;
  color: #a1a7af;
`
export const FadedLabel = styled.span`
  ${FadedCSS};
`
export const FadedLink = styled.a`
  ${FadedCSS};

  i {
    font-size: 13px;
    margin: 0 0 0 6px;
  }

  &:hover,
  &:focus {
    ${FadedCSS};
  }
`

const LinkedContentContainer = styled.div`
  display: flex;
  justify-content: space-between;
  flex-wrap: nowrap;
  overflow: hidden;
  width: 100%;

  ${FadedLink} {
    padding: 0 0 0 24px;
  }
  + ${ItemDescription} {
    margin: 4px 0 0 0;
  }
`

const LinkedContentLabel = styled.span`
  flex: 1;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`

type LinkedContentProps = {
  linkLabel: string
  linkUrl: string
  children?: React.ReactNode
}

export const LinkedContent = ({
  linkLabel,
  linkUrl,
  children,
}: LinkedContentProps): React.ReactElement => (
  <LinkedContentContainer>
    <LinkedContentLabel>{children}</LinkedContentLabel>
    <FadedLink href={linkUrl} target="_blank">
      {linkLabel} <Icon icon="external" size={10} style={{ margin: '0 0 0 6px' }} />
    </FadedLink>
  </LinkedContentContainer>
)

export const TextContentContainer = styled.div`
  display: inline-block;
  line-height: 20px;
  margin: 0 14px 0 0;

  &:before,
  &:after {
    display: inline-block;
    content: '“';
    font-family: Droid serif;
    font-family: Georgia;
    font-size: 28px;
    color: #d3d5d9;
    line-height: 13px;
    user-select: none;
    pointer-events: none;
  }
  &:before {
    margin: 0 3px 0 -15px;
    transform: translate3d(0, 80%, 0);
  }
  &:after {
    margin: 0 0 0 3px;
    transform: scale(-1, -1) translate3d(0, 30%, 0);
  }
`
export const TextContent = TextContentContainer
export const ReviewContainer = styled.div`
  position: relative;
`

const sizes = [340, 300, 280, 220]

export const GrabbablePreview = styled.div<{
  grabbable?: boolean
  grabbing: boolean | null | undefined
}>`
  position: fixed;
  z-index: 0;
  ${props =>
    props.grabbable &&
    css`
      cursor: ${props.grabbing ? 'grabbing' : 'grab'};
      * {
        pointer-events: none;
        user-select: none;
      }
    `}
`

export const PreviewContent = styled.div`
  position: absolute;
  bottom: 0;
  top: 0;
  right: 0;
  width: ${sizes[0]}px;
  @media (max-width: 1250px) {
    width: ${sizes[1]}px;
  }
  @media (max-width: 1150px) {
    width: ${sizes[2]}px;
  }
  @media (max-width: 1050px) {
    width: ${sizes[3]}px;
  }
  @media (max-width: 680px) {
    position: unset;
    width: 100%;
    ${GrabbablePreview} {
      position: unset;
    }
  }
`

export const ToolbarContainer = styled.div`
  flex: 0 0 ${sizes[0]}px;
  @media (max-width: 1250px) {
    flex: 0 0 ${sizes[1]}px;
  }
  @media (max-width: 1150px) {
    flex: 0 0 ${sizes[2]}px;
  }
  @media (max-width: 1050px) {
    flex: 0 0 ${sizes[3]}px;
  }
  @media (max-width: 680px) {
    display: none;
  }
`

export const ReviewContent = styled.div`
  position: relative;
  padding-right: ${sizes[0] + 42}px;
  @media (max-width: 1250px) {
    padding-right: ${sizes[1] + 30}px;
  }
  @media (max-width: 1150px) {
    padding-right: ${sizes[2] + 20}px;
  }
  @media (max-width: 1050px) {
    padding-right: ${sizes[3] + 20}px;
  }
  @media (max-width: 680px) {
    padding-right: 0;
  }
  > div:last-child {
    padding-bottom: 10vh;
  }
`
export const PreviewContentSticky = styled.div`
  position: relative;
  width: ${sizes[0]}px;
  @media (max-width: 1250px) {
    width: ${sizes[1]}px;
  }
  @media (max-width: 1150px) {
    width: ${sizes[2]}px;
  }
  @media (max-width: 1050px) {
    width: ${sizes[3]}px;
  }
  @media (max-width: 680px) {
    width: 100%;
  }
`

export const FullWdithPreviewContainer = styled.div<{ height: number }>`
  width: 100%;
  height: ${props => props.height}px;
  display: flex;
  align-items: center;
  justify-content: center;
  > div {
    flex: 1 0 0;
  }
`
//
export const ActionReview = styled.div<{ isAdditional?: boolean }>`
  font-weight: 500;
  font-size: 11px;
  line-height: 16px;
  color: ${p => (p.isAdditional ? colors.textLight : colors.text)};

  & > span {
    font-size: 13px;
    margin-left: 4px;
  }
`

export const CTAReviewContainer = styled.div`
  display: flex;

  ${ActionReview} {
    margin-right: 16px;

    &:last-of-type {
      margin-right: 0;
    }
  }
`

export const CTAReviewTooltip = styled.div<{
  isAdditional: boolean
}>`
  ${'' /* max-width: 300px; */}
  padding: 11px 10px;
  text-align: ${p => (p.isAdditional ? 'left' : 'center')};

  font-size: 12px;
  line-height: 15px;
  letter-spacing: -0.01em;
  color: ${schemes.lightlucent['100']};
`

// ====================== CTA REVIEW
type CTAReviewProps = {
  label: string
  icon: any
  tooltip?: React.ReactNode
  isAdditional?: boolean
}
export const CTAReview = ({
  label,
  icon,
  tooltip,
  isAdditional = false,
}: CTAReviewProps): React.ReactElement => {
  return (
    <Tooltip tooltip={tooltip ?? null} placement="top">
      <ActionReview isAdditional={isAdditional}>
        <Icon icon={icon} />
        <span>{label}</span>
      </ActionReview>
    </Tooltip>
  )
}
