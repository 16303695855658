import * as React from 'react'

// we do not import from / to avoid circular dependency
import {
  Button,
  ButtonLink,
  ButtonNavLink,
  type ButtonProps,
} from 'components/common/button/button.styles'
import { Tooltip } from 'com.batch/shared/ui/component/tooltip'

// NB : Change props
export type PermissionButtonProps = ButtonProps & {
  notAllowedMessage?: string
  isAllowed: boolean
}

export const PermissionButton = React.forwardRef<HTMLButtonElement, PermissionButtonProps>(
  (
    {
      notAllowedMessage,
      isAllowed,
      ...buttonProps
    }: ButtonProps & {
      notAllowedMessage?: string
      isAllowed: boolean
    },
    ref
  ): React.ReactElement => {
    const { to, href, children } = buttonProps

    if (isAllowed) {
      if (to) return <ButtonNavLink {...buttonProps}>{children}</ButtonNavLink>
      else if (href) return <ButtonLink href={to} {...buttonProps} ref={ref} />
      else
        return (
          <Button ref={ref} {...buttonProps}>
            {children}
          </Button>
        )
    }

    return (
      <Tooltip tooltip={notAllowedMessage ?? 'This feature requires higher permissions level'}>
        <span>
          <Button {...buttonProps} disabled>
            {children}
          </Button>
        </span>
      </Tooltip>
    )
  }
)
