import * as React from 'react'
import type Immutable from 'immutable'

import { Checkbox } from 'components/form'
import { featureDescription, hiddenFeatures } from './company-plan-helper'

import { type CompanyRecord } from 'com.batch.redux/_records'
import { Tooltip } from 'com.batch/shared/ui/component/tooltip'

type Props = {
  company: CompanyRecord
  feature: {
    code: FeatureCode
    description: string
    platform: string
  }
  additionalFeatures: Immutable.OrderedSet<FeatureCode>
  disabledFeatures: Immutable.OrderedSet<FeatureCode>
  setAdditionalFeatures: React.Dispatch<React.SetStateAction<Immutable.OrderedSet<FeatureCode>>>
  setDisabledFeatures: React.Dispatch<React.SetStateAction<Immutable.OrderedSet<FeatureCode>>>
}

export const CompanyPlanFeature = ({
  company,
  feature,
  additionalFeatures,
  disabledFeatures,
  setAdditionalFeatures,
  setDisabledFeatures,
}: Props): React.ReactNode => {
  const isPlanFeature = React.useMemo(
    () => company.planFeaturesCode.has(feature.code),
    [company.planFeaturesCode, feature.code]
  )

  const isChecked = React.useMemo(
    () =>
      isPlanFeature
        ? !disabledFeatures.includes(feature.code)
        : additionalFeatures.includes(feature.code),
    [feature.code, isPlanFeature, disabledFeatures, additionalFeatures]
  )

  const isDisabled = React.useMemo(
    () =>
      (feature.code === 'cep-only' &&
        !disabledFeatures.includes('mep-only') &&
        additionalFeatures.includes('mep-only')) ||
      (feature.code === 'mep-only' &&
        !disabledFeatures.includes('cep-only') &&
        additionalFeatures.includes('cep-only')),
    [feature.code, disabledFeatures, additionalFeatures]
  )

  const handleCheckboxChange = React.useCallback(() => {
    if (isPlanFeature)
      setDisabledFeatures(
        disabledFeatures.includes(feature.code)
          ? disabledFeatures.remove(feature.code)
          : disabledFeatures.add(feature.code)
      )
    else
      setAdditionalFeatures(
        additionalFeatures.includes(feature.code)
          ? additionalFeatures.remove(feature.code)
          : additionalFeatures.add(feature.code)
      )
  }, [
    feature.code,
    isPlanFeature,
    disabledFeatures,
    additionalFeatures,
    setAdditionalFeatures,
    setDisabledFeatures,
  ])

  if (hiddenFeatures.includes(feature.code)) return null
  return (
    <Tooltip
      key={feature.code}
      width={280}
      tooltip={
        <div style={{ textAlign: 'left', lineHeight: '21px' }}>
          <div style={{ fontSize: 14, fontWeight: 700, marginBottom: 10 }}>
            {feature.description}
          </div>
          {featureDescription[feature.code] && (
            <div style={{ fontSize: 14 }}>{featureDescription[feature.code]}</div>
          )}
          <div style={{ textAlign: 'right', marginTop: 7 }}>
            <code>{feature.code}</code>
          </div>
        </div>
      }
      placement="left"
    >
      <span title={feature.description}>
        <Checkbox
          key={feature.code}
          label={feature.description}
          checked={isChecked}
          onChange={handleCheckboxChange}
          style={{ marginTop: 5 }}
          disabledTooltip={true}
          disabled={isDisabled}
        />
      </span>
    </Tooltip>
  )
}
