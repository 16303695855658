import Immutable, { type Set } from 'immutable'
import * as React from 'react'
import { useTheme } from 'styled-components'

import { Box, BoxHeader, BoxBody, BoxSection, HeaderBoxTitle } from 'components/common/box'
import { Switch } from 'components/common/button'
import { Grid } from 'components/common/grid'
import { DateTimePicker, InputWrapper, Radio, Input, Select } from 'components/form'
import { TimingOption } from 'components/project/campaign/push/timing-option'
import * as colors from 'components/styled/tokens/colors'

import { dayjs } from 'com.batch.common/dayjs.custom'

import { type OrchestrationCampaignRecord } from 'com.batch/orchestration-campaign/models/campaign.records'
import { OrchestrationState } from 'constants/common/orchestration-state'

type WhenOrchestrationProps = {
  value: OrchestrationCampaignRecord
  schedulingType: schedulingType
  onChange: (arg1: OrchestrationCampaignRecord) => void
  state: OrchestrationState
}
type RepeatModeOption = {
  value: repeatUnitType
  label: string
}

const repeatModeOptionToString = (opt?: RepeatModeOption | null) => opt?.value ?? ''
const TIMING_OPT_MARGINS = [18, 0, 0, 0]
const REPEAT_OPTIONS = Immutable.List<RepeatModeOption>([
  { value: 'DAILY', label: 'day' },
  { value: 'WEEKLY', label: 'week' },
  { value: 'MONTHLY', label: 'month' },
])
export const WhenOrchestration: React.ComponentType<WhenOrchestrationProps> = React.memo(
  ({ value, onChange, schedulingType, state }: WhenOrchestrationProps): React.ReactElement => {
    const isStartDayDisabled = React.useCallback(day => {
      return dayjs(day).isBefore(dayjs(), 'day')
    }, [])

    const [touched, setTouched] = React.useState<Set<string>>(Immutable.Set())
    const theme = useTheme()
    const createTzAwareUpdater = React.useCallback(
      (tzAware: boolean) => () => onChange(value.set('tzAware', tzAware)),
      [value, onChange]
    )
    const onChangeSendType = React.useCallback(
      sendType => () => onChange(value.set('sendType', sendType)),
      [value, onChange]
    )
    const onCappingChange = React.useCallback(
      evt => {
        const newCapping = Number(evt.target.value)
        onChange(value.setIn(['recurrent', 'capping'], newCapping))
      },
      [value, onChange]
    )
    const onToggleCapping = React.useCallback(() => {
      onChange(value.setIn(['recurrent', 'hasCapping'], !value.recurrent.hasCapping))
    }, [value, onChange])
    const onRepeatFrequencyChange = React.useCallback(
      evt => onChange(value.setIn(['recurrent', 'frequency'], Number(evt.target.value))),
      [value, onChange]
    )
    const createOnFieldFocus = React.useCallback(
      (field: string) => () => setTouched(touched => touched.add(field)),
      []
    )
    const formatRepeatModeOption = React.useCallback(
      (opt: RepeatModeOption) => `${opt.label}${value.recurrent.frequency > 1 ? 's' : ''}`,
      [value.recurrent.frequency]
    )
    const onRepeatModeChange = React.useCallback(
      opt => onChange(value.setIn(['recurrent', 'recurrence'], opt?.value ?? 'DAILY')),
      [onChange, value]
    )
    const updateOneTime = React.useCallback(
      oneTime => {
        onChange(value.set('oneTime', oneTime))
      },
      [onChange, value]
    )
    const onSendDateChange = React.useCallback(
      sendDate => {
        updateOneTime(value.oneTime.set('sendDate', sendDate))
      },
      [updateOneTime, value.oneTime]
    )
    const updateRecurrent = React.useCallback(
      recurrent => onChange(value.set('recurrent', recurrent)),
      [onChange, value]
    )

    const onStartChange = React.useCallback(
      start => {
        updateRecurrent(value.recurrent.set('startDate', start))
      },
      [updateRecurrent, value.recurrent]
    )
    const onEndChange = React.useCallback(
      end => {
        updateRecurrent(value.recurrent.set('endDate', end))
      },
      [updateRecurrent, value.recurrent]
    )
    return (
      <Box style={{ marginTop: 35 }} id="when">
        <BoxHeader
          style={{
            height: 'auto',
            display: 'block',
            padding: '16px 20px',
            ...(value.sendType === 'now'
              ? {
                  borderBottom: 0,
                  borderBottomLeftRadius: 20,
                  borderBottomRightRadius: 20,
                }
              : {}),
          }}
        >
          <HeaderBoxTitle title="Timing" />
          {!theme?.disabledMode && (
            <div>
              {schedulingType === 'campaigns' && (
                <Grid template="1fr 1fr" margin={TIMING_OPT_MARGINS} gap={12}>
                  <TimingOption
                    value="now"
                    isSelected={value.sendType === 'now'}
                    onChange={onChangeSendType('now')}
                    label="Now"
                  />
                  <TimingOption
                    value="scheduled"
                    isSelected={value.sendType === 'scheduled'}
                    onChange={onChangeSendType('scheduled')}
                    label="Scheduled"
                  />
                </Grid>
              )}
            </div>
          )}
        </BoxHeader>

        {value.sendType !== 'now' && (
          <BoxBody style={{ overflow: 'hidden' }}>
            {value.sendType === 'scheduled' && (
              <BoxSection $padding>
                <InputWrapper
                  label="Send on"
                  hintMinSize={240}
                  hint={
                    <div style={{ textAlign: 'left' }}>
                      If you choose local time, profiles with no timezones will receive the message
                      at global time (UTC).
                    </div>
                  }
                >
                  <Grid template={'370px 1fr'}>
                    <div style={{ marginRight: 15 }}>
                      <DateTimePicker
                        date={value.oneTime.sendDate}
                        onChange={onSendDateChange}
                        onFocus={createOnFieldFocus('start')}
                        invalid={!value.oneTime.sendDate && touched.has('start')}
                        disabledDays={isStartDayDisabled}
                      />
                    </div>

                    <div
                      role="radiogroup"
                      aria-label="Timezone"
                      style={{ display: 'inline-flex', gap: 10 }}
                    >
                      <Radio
                        label="Local&nbsp;time"
                        onChange={createTzAwareUpdater(true)}
                        checked={value.tzAware}
                        disabled={state === OrchestrationState.RUNNING}
                      />

                      <Radio
                        label="Global time (UTC)"
                        onChange={createTzAwareUpdater(false)}
                        checked={!value.tzAware}
                        disabled={state === OrchestrationState.RUNNING}
                      />
                    </div>
                  </Grid>
                </InputWrapper>
              </BoxSection>
            )}
            {value.sendType === 'recurring' && (
              <BoxSection $padding>
                <Grid template="1fr 1px 1fr" gap={32}>
                  <InputWrapper label="First message sent on">
                    <DateTimePicker
                      date={value.recurrent.startDate}
                      onChange={onStartChange}
                      invalid={!value.recurrent.startDate && touched.has('start')}
                      onFocus={createOnFieldFocus('start')}
                      disabledDays={isStartDayDisabled}
                    />
                  </InputWrapper>
                  <div style={{ borderLeft: '1px solid #F0F0F0', height: 65 }} />
                  <InputWrapper label="Until" style={{ marginTop: 0 }}>
                    <DateTimePicker
                      date={value.recurrent.endDate}
                      onChange={onEndChange}
                      invalid={
                        touched.has('end') &&
                        (!value.recurrent.endDate ||
                          (!!value.recurrent.startDate &&
                            value.recurrent.endDate.isBefore(value.recurrent.startDate)))
                      }
                      onFocus={createOnFieldFocus('end')}
                      disabledDays={isStartDayDisabled}
                    />
                  </InputWrapper>
                </Grid>

                <Grid template="1fr 47%">
                  <InputWrapper label="Timezone" id="radiogroup-label-timezone">
                    <Grid
                      template={'120px auto'}
                      role="radiogroup"
                      aria-labelledby="radiogroup-label-timezone"
                    >
                      <Radio
                        label="Local time"
                        onChange={createTzAwareUpdater(true)}
                        checked={value.tzAware}
                        disabled={state === OrchestrationState.RUNNING}
                      />

                      <Radio
                        label="Global time (UTC)"
                        onChange={createTzAwareUpdater(false)}
                        checked={!value.tzAware}
                        disabled={state === OrchestrationState.RUNNING}
                      />
                    </Grid>
                  </InputWrapper>
                  <InputWrapper label="Frequency">
                    <Grid template="50px 150px">
                      <Input
                        value={value.recurrent.frequency}
                        onChange={onRepeatFrequencyChange}
                        type="number"
                      />
                      <Select
                        options={REPEAT_OPTIONS}
                        optionFormatter={formatRepeatModeOption}
                        optionToString={repeatModeOptionToString}
                        value={REPEAT_OPTIONS.find(opt => opt.value === value.recurrent.recurrence)}
                        onChange={onRepeatModeChange}
                      />
                    </Grid>
                  </InputWrapper>
                </Grid>
              </BoxSection>
            )}

            {value.sendType === 'recurring' && (
              <BoxSection
                style={{
                  borderTop: '1px solid #eeeeee',
                  padding: value.recurrent.capping !== null ? '20px' : '20px 20px 12px 20px',
                }}
              >
                <InputWrapper
                  label={
                    <Switch
                      isActive={value.recurrent.hasCapping}
                      onChange={onToggleCapping}
                      disabled={theme?.disabledMode}
                    >
                      <p
                        style={{
                          color: theme?.disabledMode ? colors.textDisabled : colors.text,
                        }}
                      >
                        Capping
                      </p>
                    </Switch>
                  }
                >
                  {value.recurrent.hasCapping && (
                    <Grid template="65px 50px 1fr">
                      Send only
                      <Input
                        value={value.recurrent.capping}
                        type="number"
                        onChange={onCappingChange}
                        aria-label="Capping (messages per user)"
                      />
                      message
                      {value.recurrent.capping && value.recurrent.capping > 1 ? 's' : ''} per user
                    </Grid>
                  )}
                  {!value.recurrent.hasCapping && (
                    <div style={{ color: colors.textLight }}>
                      The user might receive the campaign each time the timing occurs.
                    </div>
                  )}
                </InputWrapper>
              </BoxSection>
            )}
          </BoxBody>
        )}
      </Box>
    )
  }
)

WhenOrchestration.displayName = 'WhenOrchestration'
