import Immutable, { type Set } from 'immutable'

import {
  type pushCampaignSendType,
  type DispatchExtraBoundFn,
  type ReduxAction,
} from 'com.batch.redux/_records'
import { removeDeletedSenderIdentitiesFromEmailContent } from 'com.batch.redux/corelogic/usecases/sender-identity/remove-deleted-sender-identities-from-email-content'
import { currentProjectSelector } from 'com.batch.redux/project.selector'
import { resetAllTarget } from 'com.batch.redux/target/target'
import { TargetStateFactory } from 'com.batch.redux/target/target.records'
import { attributesLoadedSelector } from 'com.batch.redux/target/target.selector'
import { showToast } from 'com.batch.redux/toaster'
import { currentUserSelector } from 'com.batch.redux/user.selector'

import { triggerTemplateEvaluation } from 'com.batch/message/usecases/trigger-template-evaluation'
import { updateAllContent } from 'com.batch/message/usecases/update-all-content'
import {
  isCampaignParserResult,
  isJourneyParserResult,
  type OrchestrationHistoryRecord,
  type OrchestrationParserResult,
} from 'com.batch/orchestration/infra/parses/orchestration.parse'
import { initOrchestrationCampaign } from 'com.batch/orchestration-campaign/usecases/init-orchestration-campaign'
import { checkMissingEventsOnEntryAndTimer } from 'com.batch/orchestration-journey/models/events.helper'
import { flagIncomplete } from 'com.batch/orchestration-journey/usecases/flag-incomplete-message-id'
import { initSettings } from 'com.batch/orchestration-journey/usecases/init-settings'
import { setNodes } from 'com.batch/orchestration-journey/usecases/set-nodes'
import { LoadingStatus } from 'constants/common'
import { computeOrchestrationWeight } from './compute-orchestration-weight'
import { type OrchestrationState } from 'constants/common/orchestration-state'

export type fetchOrchestrationAction = ReduxAction<'FETCH_ORCHESTRATION', null>
export type fetchOrchestrationSuccessAction = ReduxAction<
  'FETCH_ORCHESTRATION_SUCCESS',
  {
    id: string
    state: OrchestrationState
    channels: Set<ChannelUntilCleanup>
    name: string
    incomplete: boolean
    sendType: pushCampaignSendType
    labelCodes: Set<string>
    createdByApi: boolean
    history: OrchestrationHistoryRecord
  }
>
export type fetchOrchestrationFailureAction = ReduxAction<'FETCH_ORCHESTRATION_FAILURE', string>

export const fetchOrchestration: (arg1: string) => DispatchExtraBoundFn<Promise<void>> = token => {
  return async (dispatch, getState, { orchestrationService }) => {
    const state = getState()
    const project = currentProjectSelector(state)
    const user = currentUserSelector(state)
    try {
      dispatch({ type: 'FETCH_ORCHESTRATION', payload: null })
      const parsed: OrchestrationParserResult = await orchestrationService.get({
        token,
        project,
        user,
      })
      const targets = parsed.targets.set(
        'default',
        parsed.targets
          .get('default', TargetStateFactory())
          .set('attributesLoaded', attributesLoadedSelector(getState()))
      )
      dispatch(resetAllTarget(targets))
      if (isJourneyParserResult(parsed)) {
        // this is a trigger

        /*
          if attributes are already loaded, we run a check to remove missing events 
          overwise, it will be done in orchestration.reducer, on FETCH_UNIFIED_CUSTOMER_DATA_SUMMARY_SUCCESS
        */
        const fixed =
          getState().attribute.config.attributeLoadingState === LoadingStatus.LOADED
            ? checkMissingEventsOnEntryAndTimer(
                { settings: parsed.settings, nodes: parsed.nodes },
                getState().attribute.entities
              )
            : { settings: parsed.settings, nodes: parsed.nodes }
        dispatch(initSettings(fixed.settings))
        dispatch(setNodes({ nodes: fixed.nodes, rootId: parsed.rootId }))
      } else if (parsed.campaign) {
        // this a now, a onetime or recurring
        dispatch(initOrchestrationCampaign(parsed.campaign))
      }
      dispatch({
        type: 'CAMPAIGN_HAS_QUERIES_TO_PARSE',
        payload: parsed.queries.length > 0,
      })

      parsed.queries.forEach(queryObj => {
        dispatch({
          type: 'T_NEW_QUERY',
          payload: queryObj,
        })
      })
      let message = parsed.message
      const senderIdentityState = getState().senderIdentity
      if (senderIdentityState.loadingState === LoadingStatus.LOADED) {
        const [updated, updatedMessages] = removeDeletedSenderIdentitiesFromEmailContent(
          parsed.message.email,
          senderIdentityState.entities
        )
        message = message.set('email', updatedMessages)
        if (updated.length > 0) {
          dispatch(
            showToast({
              message:
                'Some sender informations have been removed from this orchestration because they were deleted from the project.',
            })
          )
          updated.forEach(messageId => {
            dispatch(flagIncomplete({ messageId, incomplete: true }))
          })
        }
      }
      dispatch(updateAllContent(message))
      dispatch(triggerTemplateEvaluation())
      dispatch({
        type: 'FETCH_ORCHESTRATION_SUCCESS',
        payload: {
          id: parsed.id,
          state: parsed.state,
          name: parsed.name,
          incomplete: parsed.incomplete,
          sendType: isCampaignParserResult(parsed) ? parsed.campaign.sendType : 'trigger',
          labelCodes: parsed.labelCodes || Immutable.Set(),
          channels: parsed.channels,
          createdByApi: parsed.createdByApi,
          history: parsed.history,
        },
      } as fetchOrchestrationSuccessAction)
      dispatch(computeOrchestrationWeight())
    } catch (e: any) {
      console.log(e)
      dispatch({ type: 'FETCH_ORCHESTRATION_FAILURE', payload: e })
    }
  }
}
