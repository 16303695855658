import * as React from 'react'

import { ButtonNavLink } from 'components/common/button'
import { Tooltip } from 'com.batch/shared/ui/component/tooltip'

type CreateCampaignDropdownItemProps = {
  disabled?: boolean
  to: string
  tooltip?: string
  children: React.ReactNode
  testId?: string
}

type DropdownItemContentProps = {
  disabled?: boolean
  to: string
  children: React.ReactNode
  testId?: string
}

const DropdownItemContent = ({
  children,
  disabled,
  to,
  testId,
}: DropdownItemContentProps): React.ReactElement => (
  <ButtonNavLink
    disabled={disabled}
    to={to}
    data-testid={testId}
    style={{ display: 'flex', gap: '8px', marginBottom: '0px' }}
  >
    {children}
  </ButtonNavLink>
)

export const CreateCampaignDropdownItem: React.ComponentType<CreateCampaignDropdownItemProps> =
  React.memo(({ disabled, to, tooltip, testId, children }: CreateCampaignDropdownItemProps) => {
    if (disabled) {
      return (
        <Tooltip tooltip={tooltip} placement="left" offset={[15, 0]}>
          <span>
            <DropdownItemContent disabled={disabled} to={to} testId={testId}>
              {children}
            </DropdownItemContent>
          </span>
        </Tooltip>
      )
    }
    return (
      <DropdownItemContent disabled={disabled} to={to} testId={testId}>
        {children}
      </DropdownItemContent>
    )
  })

CreateCampaignDropdownItem.displayName = 'CreateCampaignDropdownItem'
