import { type Dayjs } from 'dayjs'
import { type List } from 'immutable'
import * as React from 'react'
import { useDispatch, useSelector } from 'com.batch.common/react-redux'

import { EventFormatter } from 'components/campaign/event-formatter'
import ReviewField from 'components/campaign/review-field'
import ReviewLine from 'components/campaign/review-line'
import { Box, BoxBody, BoxHeader, BoxSection, HeaderBoxTitle } from 'components/common/box'
import { Switch } from 'components/common/button'
import { Choice } from 'components/common/choice-field'
import DateTimePicker from 'components/common/date-time-picker'
import { FlexLine, FlexLineItem } from 'components/common/flexline'
import { WrapLabel } from 'components/common/form/wrap-label'
import { Grid } from 'components/common/grid'
import { Utc } from 'components/common/utc'

import { dayjs } from 'com.batch.common/dayjs.custom'
import { computeRepeatWord, formatInstanceId } from 'com.batch.common/utils'

import { PushWhenTrigger } from './push-when-trigger'

import { optionalCurrentProjectSelector } from '../../redux/project.selector'
import { useCurrentCompanyHasFeature } from '../_hooks/use-company-feature'
import {
  type TriggerConfigRecord,
  type CampaignRecord,
  type AppRecord,
  type AttributeRecord,
  type BatchChoice,
} from 'com.batch.redux/_records'
import { updatePushWhen } from 'com.batch.redux/campaign.action'

import { DELAY_MODE, OrchestrationState } from 'constants/common'

type PushWhenProps = {
  campaign: CampaignRecord
  app: AppRecord
  isWeb: boolean
  hasTriggerFeature: boolean
  events: List<AttributeRecord>
}

const sendTypes: Array<BatchChoice> = [
  {
    value: 'now',
    label: 'Now',
    icon: 'send',
  },
  {
    value: 'scheduled',
    label: 'Scheduled',
    icon: 'waiting',
  },
  {
    value: 'recurring',
    label: 'Recurring',
    icon: 'running',
  },
  {
    value: 'trigger',
    label: 'Trigger',
    icon: 'action',
    lock: false,
    empty: false,
  },
]
const tzAwareness: Array<BatchChoice> = [
  {
    value: 'local',
    label: 'Local time',
  },
  {
    value: 'utc',
    label: 'Global Time (UTC)',
  },
]

const repeatUnits: Array<BatchChoice> = [
  {
    value: 'DAILY',
    label: 'Days',
  },
  {
    value: 'WEEKLY',
    label: 'Weeks',
  },
  {
    value: 'MONTHLY',
    label: 'Months',
  },
]
export const PushWhenConnected = (props: PushWhenProps): React.ReactElement => {
  const dispatch = useDispatch()
  const updateWhen = React.useCallback(when => dispatch(updatePushWhen(when)), [dispatch])
  return <PushWhen {...props} updateWhen={updateWhen} />
}

export const PushWhen = ({
  campaign,
  hasTriggerFeature,
  events,
  updateWhen,
}: PushWhenProps & {
  updateWhen: (arg1: { value: any | null | undefined; what: string }) => void
}): React.ReactElement => {
  const ref = React.useRef<HTMLDivElement>()

  const maybeProject = useSelector(optionalCurrentProjectSelector)
  const cepCompanyCanUseTrigger = useCurrentCompanyHasFeature('cep-show-legacy-trigger')
  const cepCompanyCanUseRecurring = useCurrentCompanyHasFeature('cep-show-legacy-recurring')

  const updateStart = React.useCallback(
    (start?: Dayjs | null) => {
      updateWhen({
        what: 'start',
        value: start,
      })
    },
    [updateWhen]
  )

  const updateEnd = React.useCallback(
    (end?: Dayjs | null) => {
      updateWhen({
        what: 'end',
        value: end,
      })
    },
    [updateWhen]
  )

  const updateSendType = React.useCallback(
    (type: string) => {
      updateWhen({
        what: 'sendType',
        value: type,
      })
      if (type === 'recurring') {
        updateWhen({
          what: 'repeatFrequency',
          value: 1,
        })
        updateWhen({
          what: 'tzAware',
          value: true,
        })
      } else {
        updateWhen({
          what: 'repeatFrequency',
          value: 0,
        })
        updateWhen({
          what: 'end',
          value: null,
        })
      }
      if (type === 'now' && !campaign.tzAware) {
        updateWhen({
          what: 'tzAware',
          value: true,
        })
      }
    },
    [updateWhen, campaign.tzAware]
  )

  const isStartDayDisabled = React.useCallback(
    (day: Date) => {
      return campaign.state === OrchestrationState.NEW && dayjs(day).isBefore(dayjs(), 'day')
    },
    [campaign.state]
  )

  const updateTzAware = React.useCallback(
    (tzAware: string) => {
      updateWhen({
        what: 'tzAware',
        value: tzAware === 'local',
      })
    },
    [updateWhen]
  )

  const updateRepeatUnit = React.useCallback(
    (unit: string) => {
      updateWhen({
        what: 'repeatUnit',
        value: unit,
      })
    },
    [updateWhen]
  )

  const toggleCapping = React.useCallback(() => {
    updateWhen({
      what: 'capping',
      value: campaign.capping && campaign.capping > 0 ? 0 : 1,
    })
  }, [updateWhen, campaign.capping])

  const updateCapping = React.useCallback(
    ({ target }) => {
      updateWhen({
        what: 'capping',
        value: parseInt(target.value),
      })
    },
    [updateWhen]
  )

  const triggerConfigChanged = React.useCallback(
    (config: TriggerConfigRecord) => {
      updateWhen({
        what: 'triggerConfig',
        value: config,
      })
    },
    [updateWhen]
  )

  const updateFrequency = React.useCallback(
    ({ target }) => {
      updateWhen({
        what: 'repeatFrequency',
        value: parseInt(target.value),
      })
    },
    [updateWhen]
  )

  const schedulingType = React.useMemo(() => campaign.schedulingType, [campaign.schedulingType])
  const automationsSendTypes = React.useMemo(() => {
    if (!maybeProject) return ['recurring', 'trigger']
    else
      return [
        ...(cepCompanyCanUseTrigger ? ['trigger'] : []),
        ...(cepCompanyCanUseRecurring ? ['recurring'] : []),
      ]
  }, [cepCompanyCanUseRecurring, cepCompanyCanUseTrigger, maybeProject])
  const hasTriggerEvent = React.useMemo(
    () => events.filter(evt => evt.type === 'EVENT').size > 0,
    [events]
  )
  const sendTypesForApp = React.useMemo(
    () =>
      sendTypes
        .filter(t => {
          if (schedulingType === 'automations' && automationsSendTypes.indexOf(t.value) === -1) {
            return false
          }
          if (schedulingType === 'campaigns' && ['now', 'scheduled'].indexOf(t.value) === -1) {
            return false
          }
          return (
            (t.value !== 'recurring' && t.value !== 'trigger') ||
            campaign.state === OrchestrationState.NEW
          )
        })
        .map(({ value, lock, empty, ...t }) => {
          if (value === 'trigger') {
            if (!hasTriggerFeature) {
              lock =
                'Trigger campaigns are not enabled on your account. Please contact your sales representative.'
            } else if (!hasTriggerEvent) {
              empty =
                'No trigger event available. Trigger events must be sent with the Trigger Events API and activated'
            }
          }
          return { ...t, value, lock, empty }
        }),
    [automationsSendTypes, campaign.state, hasTriggerEvent, hasTriggerFeature, schedulingType]
  )
  return (
    <Grid template="1.5fr 1fr" alignItems="start">
      <Box style={{ zIndex: 'initial' }}>
        <BoxHeader>
          <HeaderBoxTitle title="When" />
        </BoxHeader>
        <BoxBody>
          <BoxSection style={{ padding: '20px', borderRadius: '0 0 8px 8px' }}>
            {(campaign.sendType !== 'trigger' || campaign.state === OrchestrationState.NEW) &&
              (campaign.state === OrchestrationState.NEW ||
                (campaign.state === OrchestrationState.DRAFT &&
                  campaign.sendType !== 'recurring')) &&
              sendTypesForApp.length > 1 && (
                <Choice
                  choices={sendTypesForApp}
                  onChange={updateSendType}
                  value={campaign.sendType}
                />
              )}

            {(campaign.state === OrchestrationState.NEW ||
              campaign.state === OrchestrationState.DRAFT) &&
              campaign.sendType !== 'now' &&
              campaign.sendType !== 'trigger' && (
                <Choice
                  choices={tzAwareness}
                  onChange={updateTzAware}
                  value={campaign.tzAware ? 'local' : 'utc'}
                />
              )}

            {campaign.sendType !== 'now' && campaign.sendType !== 'trigger' && (
              <WrapLabel
                label={campaign.sendType === 'recurring' ? 'First push sent on:' : 'Send on:'}
              >
                <DateTimePicker
                  ref={ref}
                  id="start"
                  value={campaign.start}
                  update={updateStart}
                  disabledDays={isStartDayDisabled}
                />
              </WrapLabel>
            )}
            {campaign.sendType === 'recurring' && (
              <div>
                <WrapLabel label="Send push notifications every:" style={{ margin: 0 }}>
                  <FlexLine top>
                    <FlexLineItem width={60} style={{ marginTop: 3 }}>
                      <input
                        type="number"
                        min="0"
                        className="form-control"
                        value={campaign.repeatFrequency}
                        onChange={updateFrequency}
                      />
                    </FlexLineItem>
                    <FlexLineItem grow={1}>
                      <Choice
                        choices={repeatUnits}
                        onChange={updateRepeatUnit}
                        value={campaign.repeatUnit}
                      />
                    </FlexLineItem>
                  </FlexLine>
                </WrapLabel>
                <WrapLabel label="Until:">
                  <DateTimePicker value={campaign.end} update={updateEnd} id="end" />
                </WrapLabel>
                <FlexLine>
                  <FlexLineItem>Capping?</FlexLineItem>

                  <FlexLineItem switch style={{ paddingBottom: 0 }}>
                    <Switch onChange={toggleCapping} isActive={Boolean(campaign.capping)} />
                  </FlexLineItem>
                  {Boolean(campaign.capping) && <FlexLineItem>Send Only</FlexLineItem>}
                  {Boolean(campaign.capping) && (
                    <FlexLineItem width={60}>
                      <input
                        type="number"
                        min="0"
                        className="form-control"
                        value={campaign.capping ?? undefined}
                        onChange={updateCapping}
                      />
                    </FlexLineItem>
                  )}
                  {Boolean(campaign.capping) && <FlexLineItem>push per user</FlexLineItem>}
                </FlexLine>
              </div>
            )}
            {/* ====== TRIGGER ========================================== */}
            {campaign.sendType === 'trigger' && (
              <PushWhenTrigger
                events={events}
                isEmailAutomation={false}
                warnOnEdit={campaign.state === OrchestrationState.RUNNING}
                config={campaign.triggerConfig}
                update={triggerConfigChanged}
              />
            )}
          </BoxSection>
        </BoxBody>
      </Box>

      <Box style={{ zIndex: 'auto' }}>
        <BoxHeader>
          <HeaderBoxTitle
            title={
              campaign.sendType === 'trigger'
                ? 'The following rules are set for this automation'
                : `This ${
                    campaign.sendType === 'recurring' ? 'automation' : 'campaign'
                  } will send a push notification`
            }
          />
        </BoxHeader>

        <BoxBody $padding>
          {/* ====== SCHEDULED ========================================== */}
          {campaign.sendType === 'scheduled' && (
            <ReviewLine icon="calendar">
              On{' '}
              <ReviewField
                placeholder="Pick a date"
                // eslint-disable-next-line react/jsx-no-bind
                onPlaceholderClick={() => ref.current?.focus()}
              >
                {campaign.start && campaign.start.format('dddd, MMMM D, YYYY [at] HH:mm')}
              </ReviewField>
              {campaign.start && (campaign.tzAware ? " (in the user's timezone)" : ' (UTC)')}
            </ReviewLine>
          )}
          {/* ====== TRIGGER =============================================== */}
          {campaign.sendType === 'trigger' && (
            <div style={{ maxWidth: 400 }}>
              <ReviewLine icon="trigger">
                {campaign.triggerConfig.hasInstanceId ? 'Each time a ' : 'When the '}
                <ReviewField placeholder="Pick an event">
                  {campaign.triggerConfig.enterEvent && (
                    <EventFormatter
                      eventCode={campaign.triggerConfig.enterEvent}
                      eventQuery={campaign.triggerConfig.enterEventQuery}
                    />
                  )}
                </ReviewField>{' '}
                event occurs
                {campaign.triggerConfig.hasInstanceId && (
                  <React.Fragment>
                    {' '}
                    with a different{' '}
                    <ReviewField placeholder="Pick an instance ID">
                      {formatInstanceId(campaign.triggerConfig.instanceId)}
                    </ReviewField>
                  </React.Fragment>
                )}
                , the sending of a notification is scheduled.
              </ReviewLine>
              <ReviewLine icon="schedule">
                The push notification is sent{' '}
                <React.Fragment>
                  {campaign.triggerConfig.delayMode === DELAY_MODE.TIMER ? (
                    <ReviewField placeholder="Pick a timer">
                      {campaign.triggerConfig.pushTimer.fullText.replace('-', '')}
                    </ReviewField>
                  ) : (
                    <ReviewField>immediately</ReviewField>
                  )}
                  <ReviewField>
                    {campaign.triggerConfig.pushTimerMode === 'before' ? 'before' : 'after'}
                  </ReviewField>{' '}
                  <ReviewField placeholder="Pick a date attribute">
                    {campaign.triggerConfig.pushTimerMode === 'event'
                      ? 'the trigger event occurs'
                      : campaign.triggerConfig.pushTimerReference}
                  </ReviewField>
                  . <br />
                </React.Fragment>
                {campaign.triggerConfig.delayMode === DELAY_MODE.TIMER && (
                  <React.Fragment>
                    The timer is reset each time the{' '}
                    <ReviewField placeholder="Pick an event">
                      {campaign.triggerConfig.enterEvent && (
                        <EventFormatter
                          eventCode={campaign.triggerConfig.enterEvent}
                          eventQuery={campaign.triggerConfig.enterEventQuery}
                        />
                      )}
                    </ReviewField>{' '}
                    event occurs when a notification is already scheduled.
                  </React.Fragment>
                )}
              </ReviewLine>
              {campaign.triggerConfig.delayMode === DELAY_MODE.TIMER &&
                campaign.triggerConfig.hasExitEvent && (
                  <ReviewLine icon="close">
                    {campaign.triggerConfig.exitEvents.size === 1 ? (
                      <React.Fragment>
                        The sending is cancelled when the{' '}
                        <ReviewField placeholder="Pick an event">
                          {campaign.triggerConfig.exitEvents.getIn([0, 'eventId']) ? (
                            <EventFormatter
                              eventCode={
                                campaign.triggerConfig.exitEvents.getIn([0, 'eventId']) as string
                              }
                              eventQuery={campaign.triggerConfig.exitEvents.getIn([0, 'query'])}
                            />
                          ) : null}
                        </ReviewField>{' '}
                        event occurs.
                      </React.Fragment>
                    ) : (
                      <React.Fragment>
                        The sending is cancelled when one of the following events occurs:
                        {campaign.triggerConfig.exitEvents.map((exitEvent, key) => (
                          <React.Fragment key={key}>
                            <ReviewField placeholder="Pick an event">
                              {exitEvent.eventId && (
                                <EventFormatter
                                  eventCode={exitEvent.eventId}
                                  eventQuery={exitEvent.query}
                                />
                              )}
                            </ReviewField>
                            {campaign.triggerConfig.exitEvents.size - 2 > key && ', '}
                            {campaign.triggerConfig.exitEvents.size - 2 === key && ' or '}
                          </React.Fragment>
                        ))}
                        .
                      </React.Fragment>
                    )}
                  </ReviewLine>
                )}
              {(campaign.triggerConfig.hasGrace || campaign.triggerConfig.hasCapping) && (
                <ReviewLine icon="settings">
                  The notification will not be sent to a user
                  {campaign.triggerConfig.hasCapping && (
                    <React.Fragment>
                      {' '}
                      more than{' '}
                      <ReviewField placeholder="Pick a capping">
                        {Boolean(campaign.triggerConfig.capping) &&
                          (campaign.triggerConfig.capping === 1
                            ? 'once'
                            : campaign.triggerConfig.capping === 2
                              ? 'twice'
                              : `${campaign.triggerConfig.capping} times`)}
                      </ReviewField>
                      {!campaign.triggerConfig.hasGrace && '.'}
                    </React.Fragment>
                  )}
                  {campaign.triggerConfig.hasGrace && (
                    <React.Fragment>
                      {campaign.triggerConfig.hasCapping ? ' or if ' : ' if '}
                      they already received the notification in the last{' '}
                      <ReviewField placeholder="Pick a grace period">
                        {campaign.triggerConfig.grace.valid &&
                          campaign.triggerConfig.grace.fullText}
                      </ReviewField>
                      .
                    </React.Fragment>
                  )}
                </ReviewLine>
              )}
              {(campaign.triggerConfig.hasStart || campaign.triggerConfig.hasEnd) && (
                <ReviewLine icon="calendar">
                  The campaign is set to{' '}
                  {campaign.triggerConfig.hasStart &&
                    campaign.triggerConfig.hasEnd &&
                    ' be active between '}
                  {campaign.triggerConfig.hasStart && !campaign.triggerConfig.hasEnd && 'start on '}
                  {!campaign.triggerConfig.hasStart && campaign.triggerConfig.hasEnd && 'end on '}
                  {campaign.triggerConfig.hasStart && (
                    <ReviewField placeholder="Pick a start date">
                      {campaign.triggerConfig.start && (
                        <React.Fragment>
                          {campaign.triggerConfig.start.format('dddd, MMMM D, YYYY [at] HH:mm')}
                          {campaign.triggerConfig.start && <Utc m={campaign.triggerConfig.start} />}
                        </React.Fragment>
                      )}
                    </ReviewField>
                  )}
                  {campaign.triggerConfig.hasStart && campaign.triggerConfig.hasEnd && ' and '}
                  {campaign.triggerConfig.hasEnd && (
                    <ReviewField placeholder="Pick a end date">
                      {campaign.triggerConfig.end && (
                        <React.Fragment>
                          {campaign.triggerConfig.end.format('dddd, MMMM D, YYYY [at] HH:mm')}
                          {campaign.triggerConfig.end && <Utc m={campaign.triggerConfig.end} />}
                        </React.Fragment>
                      )}
                    </ReviewField>
                  )}
                </ReviewLine>
              )}
            </div>
          )}
          {/* ====== NOW ================================================ */}
          {campaign.sendType === 'now' && <ReviewLine icon="send">Now</ReviewLine>}
          {/* ====== RECURRING ========================================== */}
          {campaign.sendType === 'recurring' && (
            <ReviewLine icon="running">
              Every{' '}
              <ReviewField
                placeholder="Pick a first push date"
                // eslint-disable-next-line react/jsx-no-bind
                onPlaceholderClick={() => ref.current?.focus()}
              >
                {campaign.start &&
                  computeRepeatWord(campaign.repeatUnit, campaign.repeatFrequency, campaign.start) +
                    (campaign.start ? campaign.start.format(' [at] HH:mm') : '')}
              </ReviewField>
              {campaign.start && (campaign.tzAware ? " (in the user's timezone)" : ' (UTC)')}
            </ReviewLine>
          )}
          {(campaign.sendType === 'scheduled' || campaign.sendType === 'recurring') &&
            campaign.start &&
            !campaign.tzAware && (
              <div className="utcInfo">
                <p>
                  Campaign will start at{' '}
                  {campaign.start.add(dayjs().utcOffset(), 'minute').format('HH:mm')} (UTC +{' '}
                  {dayjs().utcOffset() / 60}) in your local time
                </p>
              </div>
            )}
          {campaign.sendType === 'recurring' && (
            <ReviewLine icon="calendar">
              Starting{' '}
              <ReviewField
                placeholder="Pick a first push date"
                // eslint-disable-next-line react/jsx-no-bind
                onPlaceholderClick={() => ref.current?.focus()}
              >
                {campaign.start && campaign.start.format('dddd, MMMM D, YYYY')}
              </ReviewField>
            </ReviewLine>
          )}
          {campaign.sendType === 'recurring' && campaign.end && (
            <ReviewLine icon="calendar">
              <span>{campaign.end.format('[Until ]dddd, MMMM D, YYYY')}</span>
            </ReviewLine>
          )}
          {campaign.sendType === 'recurring' && !!campaign.capping && campaign.capping > 0 && (
            <ReviewLine icon="settings">
              {campaign.capping === 1
                ? 'Only once per user'
                : campaign.capping === 2
                  ? 'Only twice per user'
                  : `Only ${campaign.capping} times per user`}
            </ReviewLine>
          )}
        </BoxBody>
      </Box>
    </Grid>
  )
}
