import {
  type MessageNodeProps,
  type MessageNodeRecord,
} from 'com.batch/orchestration-journey/models/journey.records'
import {
  PartialOrchestrationFactory,
  type PartialOrchestrationRecord,
} from 'com.batch/orchestration-list/models/partial-orchestration.records'
import {
  pagedPartialCampaignsSelector,
  campaignsFetchingStateSelector,
} from 'com.batch/orchestration-list/store/orchestration-list.selector'
import {
  type OrchestrationParserResult,
  type OrchestrationJourneyParserResult,
  type OrchestrationCampaignParserResult,
} from 'com.batch/orchestration/infra/parses/orchestration.parse'
import { fetchOrchestrationWithoutStoring } from 'com.batch/orchestration/usecases/fetch-orchestration-without-storing'
import { Grid } from 'components/common/grid'
import { SelectSearch } from 'components/form/fields/select/select-search'
import Immutable, { type List } from 'immutable'
import * as React from 'react'
import { useSelector } from 'react-redux'
import { type InputProps } from '../helper'
import { OptionOrchestration, OptionStep, OrchestrationTooltip } from './input-orchestration-option'
import { useDispatch } from 'com.batch.common/react-redux'
import { useLoadAndFilterOrchestrationOptions } from './use-load-and-filter-orchestration-options'
import { authorizedChannelsForRetargetingEvent } from 'com.batch.redux/attribute.api'
import { Tooltip } from 'com.batch/shared/ui/component/tooltip'

const orchestrationToString = (orchestration: PartialOrchestrationRecord) =>
  orchestration?.name ?? ''

const stepToString = (step: MessageNodeProps) => step?.label || step?.id

export const InputOrchestration = ({
  condition,
  updateCondition,
  isDisabled,
  schedulingMode,
  parentCondition,
}: InputProps): React.ReactElement => {
  const dispatch = useDispatch()
  const [selectedOrchestration, setSelectedOrchestration] = React.useState<
    OrchestrationParserResult | PartialOrchestrationRecord | undefined
  >(undefined)
  const orchestrationList = useSelector(pagedPartialCampaignsSelector)
  const orchestrationLoadingState = useSelector(campaignsFetchingStateSelector)
  const loadOrchestrationOptions = useLoadAndFilterOrchestrationOptions({
    parentCondition,
    schedulingMode,
  })

  const selectedOrchestrationToken = React.useMemo(
    () => condition.value?.retargetedOrchestration?.orchestrationToken,
    [condition]
  )
  const selectedStepId = React.useMemo(
    () => condition.value?.retargetedOrchestration?.stepId,
    [condition]
  )

  const handleChangeOrchestration = React.useCallback(
    (value: PartialOrchestrationRecord) => {
      if (!value) {
        return
      }

      updateCondition(
        condition
          .setIn(['value', 'retargetedOrchestration', 'orchestrationToken'], value.token)
          .setIn(['value', 'retargetedOrchestration', 'stepId'], undefined)
      )

      // if the orchestration has a trigger config, fetch the orchestration to get the steps
      if (value.triggerConfig) {
        dispatch(fetchOrchestrationWithoutStoring(value.token)).then(orchestration => {
          setSelectedOrchestration(orchestration)
        })
      } else {
        setSelectedOrchestration(value)
      }
    },
    [condition, dispatch, updateCondition]
  )

  const handleChangeStep = React.useCallback(
    (value: MessageNodeRecord) => {
      updateCondition(condition.setIn(['value', 'retargetedOrchestration', 'stepId'], value?.id))
    },
    [condition, updateCondition]
  )

  // reset the selected orchestration when the scheduling mode changes
  React.useEffect(() => {
    if (schedulingMode) {
      setSelectedOrchestration(undefined)
    }
  }, [dispatch, schedulingMode])

  // fetch the orchestration on mount or when the selected orchestration token changes
  React.useEffect(() => {
    if (selectedOrchestrationToken && !selectedOrchestration) {
      dispatch(fetchOrchestrationWithoutStoring(selectedOrchestrationToken)).then(orchestration => {
        const orchestrationCampaign = orchestration as OrchestrationCampaignParserResult
        const OrchestrationJourneyParserResult = orchestration as OrchestrationJourneyParserResult
        setSelectedOrchestration(orchestration)
        updateCondition(
          condition.setIn(
            ['attribute', 'label'],
            OrchestrationJourneyParserResult.nodes ||
              orchestrationCampaign.campaign.sendType === 'recurring'
              ? 'automation'
              : 'campaign'
          )
        )
      })
    }
  }, [dispatch, selectedOrchestrationToken, selectedOrchestration, updateCondition, condition])

  const steps: List<MessageNodeRecord> = React.useMemo(() => {
    const selectedOrchestrationJourney = selectedOrchestration as OrchestrationJourneyParserResult
    if (selectedOrchestrationJourney?.nodes) {
      const steps = selectedOrchestrationJourney.nodes
        .filter(node => node.type === 'MESSAGE')
        .filter(node => {
          const authorizedChannelsForEvent = authorizedChannelsForRetargetingEvent.get(
            parentCondition?.attribute?.api ?? ''
          )

          return (
            authorizedChannelsForEvent &&
            authorizedChannelsForEvent.filter(channel => channel === node.messageConfig.channel)
              .size > 0
          )
        })
        .toList()

      return steps
    } else {
      return Immutable.List<MessageNodeRecord>()
    }
  }, [parentCondition?.attribute?.api, selectedOrchestration])

  const showStepSelector = React.useMemo(() => {
    const selectedOrchestrationJourney = selectedOrchestration as OrchestrationJourneyParserResult

    // check the orchestration list for a trigger config, otherwise use the fetched orchestration
    return (
      orchestrationList.find(orchestration => orchestration.token === selectedOrchestrationToken)
        ?.triggerConfig ?? selectedOrchestrationJourney?.nodes
    )
  }, [orchestrationList, selectedOrchestration, selectedOrchestrationToken])

  // create a dummy orchestration to display the deleted orchestration
  const dummyOrchestration = React.useMemo(() => {
    if (!condition.value?.retargetedOrchestration?.orchestrationToken) {
      return undefined
    }

    return PartialOrchestrationFactory({
      name: `${condition.value?.retargetedOrchestration?.orchestrationToken}`,
    })
  }, [condition])

  return (
    <Grid template={showStepSelector ? '371px auto' : 'auto'}>
      <Tooltip
        tooltip={
          condition.value?.retargetedOrchestration?.orchestrationToken ? (
            selectedOrchestration ? (
              <OrchestrationTooltip orchestration={selectedOrchestration} />
            ) : (
              <div>Your previous selection has been deleted, please select another</div>
            )
          ) : undefined
        }
        placement="bottom"
        offset={[0, 10]}
        width={300}
      >
        <Grid template="auto">
          <SelectSearch
            isDisabled={Boolean(isDisabled)}
            // check the orchestration list, otherwise use the fetched orchestration, otherwise use the dummy orchestration
            value={
              orchestrationList.find(
                orchestration => orchestration.token === selectedOrchestrationToken
              ) ??
              selectedOrchestration ??
              dummyOrchestration
            }
            placeholder={
              schedulingMode === 'automations' ? 'Select an automation' : 'Select a campaign'
            }
            loadOptions={loadOrchestrationOptions}
            onChange={handleChangeOrchestration}
            optionToString={orchestrationToString}
            optionFormatter={OptionOrchestration}
            isLoading={!selectedOrchestration}
            // key is used to rerender the component when the scheduling mode changes
            key={schedulingMode}
          />
        </Grid>
      </Tooltip>
      {showStepSelector && (
        <SelectSearch
          isDisabled={Boolean(isDisabled)}
          options={steps}
          placeholder="Select a step (optional)"
          value={steps?.find(step => step.id === selectedStepId)}
          onChange={handleChangeStep}
          optionToString={stepToString}
          optionFormatter={OptionStep}
          isLoading={orchestrationLoadingState === 'LOADING'}
          noOptionText="No steps available."
          noResultText="No steps correspond to this search."
          isClearable
        />
      )}
    </Grid>
  )
}
