import * as React from 'react'
import { useDispatch, useSelector } from 'com.batch.common/react-redux'
import { ThemeProvider } from 'styled-components'

import { useToggle } from 'components/_hooks'

import { SmsLayerBarSendTest } from './sms-layer-bar-send-test'

import { fetchTemplating } from 'com.batch/templating/usecases/fetch-templating'
import { LayerBarWrapper } from 'com.batch/message-builder/ui/components/layerbar.styles'
import { PreviewProfilePopin } from 'com.batch/shared/ui/component/popins/preview-profile-popin'
import { PreviewAs } from 'com.batch/shared/ui/component/preview-as'
import { PopinSendTestSms } from 'com.batch/sms/ui/components/popin-send-test-sms'
import { templatingCacheSelector } from 'com.batch/templating/store/templating.selector'
type SmsLayerBarProps = {
  messageId: string
}
export const SmsLayerBar = ({ messageId }: SmsLayerBarProps): React.ReactElement => {
  const dispatch = useDispatch()
  const popinSmsSendTestState = useToggle()
  const previewProfilePopinState = useToggle()

  const templatingCache = useSelector(templatingCacheSelector)
  const updateTemplate = React.useCallback(() => {
    dispatch(fetchTemplating(templatingCache, true))
  }, [dispatch, templatingCache])

  const handleSendTestClick = React.useCallback(() => {
    popinSmsSendTestState.open()
  }, [popinSmsSendTestState])

  const handlePreviewAsClick = React.useCallback(() => {
    previewProfilePopinState.open()
  }, [previewProfilePopinState])

  return (
    <React.Fragment>
      <ThemeProvider theme={{ disabledMode: false }}>
        <PopinSendTestSms messageId={messageId} togglePopin={popinSmsSendTestState} />
        <PreviewProfilePopin
          togglePopin={previewProfilePopinState}
          updateTemplate={updateTemplate}
        />
      </ThemeProvider>
      <LayerBarWrapper $itemsCount={2}>
        <SmsLayerBarSendTest handleSendTestClick={handleSendTestClick} messageId={messageId} />
        <PreviewAs handlePreviewAsClick={handlePreviewAsClick} updateTemplate={updateTemplate} />
      </LayerBarWrapper>
    </React.Fragment>
  )
}
