import { type Dayjs } from 'dayjs'
import * as React from 'react'

import { ConfirmHighlight } from 'components/common/confirm.styles'

import { computeRepeatWord, ucFirst, pluralizeAndKformat } from 'com.batch.common/utils'

import { type OrchestrationStateRecord } from 'com.batch/orchestration/store/orchestration.state'
import {
  TriggerConfigFactory,
  type CampaignRecord,
  type TriggerConfigRecord,
  type pushCampaignSendType,
} from 'com.batch.redux/_records'

import { type EstimateProfileRecord } from 'com.batch/orchestration/models/profile-estimate.records'
import { type PartialOrchestrationRecord } from 'com.batch/orchestration-list/models/partial-orchestration.records'
import { OrchestrationState } from 'constants/common/orchestration-state'

type LegacyMode = {
  campaign: CampaignRecord
}
export type ProjectPartialMode = {
  partialOrchestration: PartialOrchestrationRecord
}
export type ProjectMode = {
  orchestration: OrchestrationStateRecord
  estimate: EstimateProfileRecord
  isTransactional: boolean
  channel: ChannelUntilCleanup | null | undefined
}
type Modes = LegacyMode | ProjectPartialMode | ProjectMode

export const isLegacyMode = (mode: Modes): mode is LegacyMode =>
  Boolean((mode as LegacyMode).campaign)
export const isProjectPartialMode = (mode: Modes): mode is ProjectPartialMode =>
  Boolean((mode as ProjectPartialMode).partialOrchestration)
export const isProjectMode = (mode: Modes): mode is ProjectMode =>
  Boolean((mode as ProjectMode).orchestration)

type ParsedProps = {
  name: string
  stateRunWord: string
  alreadyHasData: boolean
  sendType: pushCampaignSendType
  state: OrchestrationState
  start: Dayjs | null | undefined
  repeatUnit: repeatUnitType
  waitingCount: number
  inAppTriggerEvent: string | null | undefined
  repeatFrequency: number
  tzAware: boolean
  orchestrationName: 'campaign' | 'automation'
  triggerConfig: TriggerConfigRecord | null | undefined
  estimateProfile: EstimateProfileRecord | null | undefined
  isTransactional: boolean | null | undefined
  channel: ChannelUntilCleanup | null | undefined
}
const extractFromOrchestration = ({
  orchestration,
  estimate,
  isTransactional,
  channel,
}: ProjectMode): ParsedProps => {
  const sendType = orchestration.campaign.sendType
  return {
    name: orchestration.name,
    alreadyHasData: false,
    sendType: orchestration.campaign.sendType,
    stateRunWord:
      orchestration.state === OrchestrationState.RUNNING
        ? 'update'
        : sendType === 'trigger' || sendType === 'recurring'
          ? 'launch'
          : 'run',
    orchestrationName:
      sendType === 'trigger' || sendType === 'recurring' ? 'automation' : 'campaign',
    start:
      sendType === 'recurring'
        ? orchestration.campaign.recurrent?.startDate
        : sendType === 'scheduled'
          ? orchestration.campaign.oneTime?.sendDate
          : null,
    state: orchestration.state,
    inAppTriggerEvent: undefined,
    repeatUnit: orchestration.campaign.recurrent.recurrence,
    waitingCount: -1,
    repeatFrequency: orchestration.campaign.recurrent.frequency,
    tzAware: orchestration.campaign.tzAware,
    triggerConfig: TriggerConfigFactory({
      enterEvent: orchestration.triggerSettings.entryEvents.first()?.name ?? '',
    }),
    estimateProfile: estimate,

    isTransactional,
    channel,
  }
}

const extractFromCampaign = ({ campaign }: LegacyMode): ParsedProps => {
  const alreadyHasData = campaign.stats.loading || campaign.stats.trigger.exited.stop > 0
  return {
    name: campaign.name,
    alreadyHasData,
    sendType: campaign.sendType,
    stateRunWord:
      campaign.state === OrchestrationState.RUNNING
        ? 'update'
        : campaign.schedulingType === 'automations'
          ? 'launch'
          : 'run',
    orchestrationName: campaign.schedulingType === 'automations' ? 'automation' : 'campaign',
    start: campaign?.start,
    state: campaign.state,
    inAppTriggerEvent:
      campaign.type === 'in-app'
        ? campaign?.trigger.substring(campaign.trigger.indexOf('.') + 1)
        : undefined,
    repeatUnit: campaign?.repeatUnit,
    waitingCount: alreadyHasData ? campaign?.stats.trigger.waiting : -1,
    repeatFrequency: campaign.repeatFrequency,
    tzAware: campaign.tzAware,
    triggerConfig: campaign.triggerConfig,
    estimateProfile: null,
    isTransactional: false,
    channel: null,
  }
}

const extractFromPartial = ({ partialOrchestration }: ProjectPartialMode): ParsedProps => {
  return {
    name: partialOrchestration.name,
    alreadyHasData: false,
    sendType: partialOrchestration.sendType,
    stateRunWord:
      partialOrchestration.state === OrchestrationState.RUNNING
        ? 'update'
        : partialOrchestration.routingMode === 'automations'
          ? 'launch'
          : 'run',
    orchestrationName:
      partialOrchestration.routingMode === 'automations' ? 'automation' : 'campaign',
    state: partialOrchestration.state,
    inAppTriggerEvent: undefined,
    start:
      partialOrchestration.sendType === 'recurring'
        ? partialOrchestration.recurring?.start
        : partialOrchestration.sendType === 'scheduled'
          ? partialOrchestration.oneTime?.sendDate
          : null,
    repeatUnit: partialOrchestration.recurring?.repeatUnit ?? 'DAILY',
    waitingCount: 0,
    repeatFrequency: partialOrchestration.recurring?.repeatFrequency ?? 0,
    tzAware: partialOrchestration.recurring
      ? partialOrchestration.recurring.localTimezoneDependant
      : partialOrchestration.oneTime
        ? partialOrchestration.oneTime.localTimezoneDependant
        : false,
    triggerConfig: partialOrchestration.triggerConfig,
    estimateProfile: null,
    isTransactional: false,
    channel: null,
  }
}

const defaultValuesNeverUsed: ParsedProps = {
  name: '',
  alreadyHasData: false,
  orchestrationName: 'campaign',
  inAppTriggerEvent: '',
  stateRunWord: '',
  sendType: 'trigger',
  start: null,
  state: OrchestrationState.DRAFT,
  repeatFrequency: 1,
  repeatUnit: 'DAILY',
  tzAware: true,
  waitingCount: 222,
  triggerConfig: TriggerConfigFactory(),
  estimateProfile: null,
  isTransactional: false,
  channel: null,
}
type confirmBaseProps = {
  title: string
  confirm: string
  message: React.ReactNode
}
export const getConfirmWordings = (
  props: LegacyMode | ProjectPartialMode | ProjectMode
): {
  archive: confirmBaseProps
  stop: confirmBaseProps
  run: confirmBaseProps
} => {
  const {
    name,
    orchestrationName,
    alreadyHasData,
    inAppTriggerEvent,
    stateRunWord,
    sendType,
    start,
    state,
    repeatFrequency,
    repeatUnit,
    tzAware,
    waitingCount,
    triggerConfig,
    estimateProfile,
    channel,
    isTransactional,
  } = isLegacyMode(props)
    ? extractFromCampaign(props)
    : isProjectPartialMode(props)
      ? extractFromPartial(props)
      : props.orchestration
        ? extractFromOrchestration(props)
        : defaultValuesNeverUsed

  const warnLegacyTriggerAlreadyRunning: React.ReactNode | null | undefined =
    state === OrchestrationState.STOPPED && alreadyHasData && sendType === 'trigger' ? (
      <React.Fragment>
        <p>
          This {name} was running before. If you {stateRunWord} it again, users who were in a
          waiting state might receive the notification the next time they fire any event.
        </p>
        {waitingCount >= 0 && (
          <p>
            {waitingCount} users were waiting when the {name} was stopped.
          </p>
        )}
        <p>
          We always advise to create new {orchestrationName}, for easier troubleshooting and better
          analytics.
        </p>
      </React.Fragment>
    ) : null
  let matching = 0
  let total = 0

  if (estimateProfile) {
    if (!channel) {
      matching =
        estimateProfile[isTransactional ? 'matching' : 'matchingOptin'].reachableProfileCount
      total = estimateProfile[isTransactional ? 'total' : 'totalOptin'].reachableProfileCount
    } else {
      matching =
        estimateProfile[isTransactional ? 'matching' : 'matchingOptin'][channel].uniqueCount
      total =
        estimateProfile[isTransactional || channel === 'push' ? 'total' : 'totalOptin'][channel]
          .uniqueCount
    }
  }

  return {
    archive: {
      title: `Delete this ${orchestrationName}?`,
      confirm: 'Yes, delete',
      message: (
        <p>
          This will delete <ConfirmHighlight>{name}</ConfirmHighlight> {orchestrationName}{' '}
          immediately.
        </p>
      ),
    },
    stop: {
      title: `Stop this ${orchestrationName}?`,
      confirm: 'Yes, stop',
      message: (
        <p>
          This will stop <ConfirmHighlight>{name}</ConfirmHighlight> {orchestrationName}{' '}
          immediately.
        </p>
      ),
    },
    run: {
      title: `${ucFirst(stateRunWord)} this ${orchestrationName}?`,
      message: (
        <React.Fragment>
          <p>
            This will {stateRunWord} <ConfirmHighlight>{name}</ConfirmHighlight> {orchestrationName}
            {estimateProfile && (
              <React.Fragment>
                {' '}
                and target{' '}
                <ConfirmHighlight>{pluralizeAndKformat('profile', matching)}</ConfirmHighlight> out
                of
                <ConfirmHighlight>{pluralizeAndKformat('total profile', total)}</ConfirmHighlight>
              </React.Fragment>
            )}
            .
          </p>
          {warnLegacyTriggerAlreadyRunning}
          {sendType === 'now' && (
            <p>
              It will start <ConfirmHighlight>right now</ConfirmHighlight>.
            </p>
          )}
          {sendType === 'scheduled' && start && (
            <p>
              It will start on{' '}
              <ConfirmHighlight>
                {start && start.format('dddd, MMMM D, YYYY')} at {start && start.format('HH:mm')}
              </ConfirmHighlight>
              {tzAware ? " in the user's timezone" : ' UTC'}.
            </p>
          )}
          {sendType === 'now' && !inAppTriggerEvent && <p>A started campaign cannot be stopped.</p>}
          {sendType === 'recurring' && (
            <p>
              It will start on{' '}
              <ConfirmHighlight>
                {start && start.format('dddd, MMMM D, YYYY')} at {start && start.format('HH:mm')}
              </ConfirmHighlight>{' '}
              and send a message every{' '}
              <ConfirmHighlight>
                {repeatUnit &&
                  repeatFrequency &&
                  computeRepeatWord(repeatUnit, repeatFrequency, start)}
              </ConfirmHighlight>
              {tzAware ? " in the user's timezone" : ' UTC'}.
            </p>
          )}
          {sendType === 'trigger' && !inAppTriggerEvent && !warnLegacyTriggerAlreadyRunning && (
            <p>
              It will be triggered by an{' '}
              <ConfirmHighlight>
                {triggerConfig?.enterEvent.substring(triggerConfig.enterEvent.indexOf('.') + 1)}
              </ConfirmHighlight>{' '}
              event.
            </p>
          )}
          {inAppTriggerEvent && (
            <React.Fragment>
              <br />
              It will be triggered by an <ConfirmHighlight>
                {inAppTriggerEvent}
              </ConfirmHighlight>{' '}
              event.
            </React.Fragment>
          )}
        </React.Fragment>
      ),
      confirm: `Yes, ${stateRunWord.toLowerCase()} it`,
    },
  }
}
