import { type List } from 'immutable'
import * as React from 'react'

import { EmptyField } from 'components/common/empty-states'

import { dayjs } from 'com.batch.common/dayjs.custom'

import { type Platform } from 'com.batch/profile/infra/debug/models/shared.model'
import { getBrowserName } from 'com.batch/profile/infra/formats/get-browser-name'
import {
  type InstallRecord,
  type ProfileWebpushPlatformsRecord,
} from 'com.batch/profile/models/profile.records'
import { PlatformButton } from 'com.batch/profile/ui/components/profile-hero/profile-platforms/platform-button'
import { PlatformButtonSkeleton } from 'com.batch/profile/ui/components/profile-hero/profile-platforms/platform-button.styles'

type Props = {
  isLoading: boolean
  mobilePlatforms: List<InstallRecord>
  webpushPlatforms: List<ProfileWebpushPlatformsRecord>
  setSelectedPlatform: (id: string | null) => void
  findDebugUrl: (installId: string, platform: Platform) => string
  platformsCount: number
}
export const ProfilePlatformsList = ({
  isLoading,
  setSelectedPlatform,
  mobilePlatforms,
  webpushPlatforms,
  findDebugUrl,
  platformsCount,
}: Props): React.ReactElement => {
  const onButtonClicked = React.useCallback(
    install => () => setSelectedPlatform(install.installID),
    [setSelectedPlatform]
  )

  const nativeMobilePlatforms = React.useMemo(
    () =>
      mobilePlatforms
        .filter(f => !f.isImported)
        .sort((a, b) => {
          const dateA = dayjs(a.lastActivity)
          const dateB = dayjs(b.lastActivity)
          if (dateA.isValid() && dateB.isValid() && dateA.isBefore(dateB)) return 1
          return -1
        }),
    [mobilePlatforms]
  )

  const importedMobilePlatforms = React.useMemo(
    () => mobilePlatforms.filter(f => f.isImported),
    [mobilePlatforms]
  )

  if (isLoading)
    return (
      <React.Fragment>
        {Array.from({ length: platformsCount > 0 ? platformsCount : 1 }, (_, i) => (
          <PlatformButtonSkeleton key={`empty_platform-${i}`}>
            <EmptyField _height={16} _width={16} style={{ marginRight: 8 }} />
            <EmptyField _height={16} _width={96} />
            <EmptyField _height={16} _width={200} _display="block" style={{ marginTop: 10 }} />
          </PlatformButtonSkeleton>
        ))}
      </React.Fragment>
    )

  if (mobilePlatforms.size === 0 && webpushPlatforms.size === 0)
    return <p>No platforms attached to this profile.</p>

  return (
    <React.Fragment>
      {nativeMobilePlatforms.map((install, key) => (
        <PlatformButton
          key={`install_${key}`}
          platform={install.platform}
          onClick={onButtonClicked(install)}
          lastActivity={install.lastActivity}
          name={install.deviceMarketingName || install.type}
          subscriptionStatus={install.subscriptionStatus}
        />
      ))}

      {webpushPlatforms.map((install, key) => (
        <PlatformButton
          key={`install_${key}`}
          platform={install.platform}
          browser={install.browser}
          version={install.browserVersion}
          href={findDebugUrl(install.installId, install.platform)}
          lastActivity={install.lastActivity}
          name={
            install.browser === 'UNKNOWN' && install.browserType.length > 0
              ? install.browserType
              : getBrowserName(install.browser)
          }
          subscriptionStatus={install.subscriptionStatus}
        />
      ))}

      {importedMobilePlatforms.map((install, key) => (
        <PlatformButton
          key={`install_${key}`}
          isImported={true}
          platform={install.platform}
          onClick={onButtonClicked(install)}
          lastActivity={install.lastActivity}
          name="Unknown device"
          subscriptionStatus={install.subscriptionStatus}
        />
      ))}
    </React.Fragment>
  )
}
