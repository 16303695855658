import { useSelector } from 'com.batch.common/react-redux'

import { type State, type CompanyRecord } from 'com.batch.redux/_records'
// we redeclare to avoid dep cycle
const currentCompanySelector = (state: State): CompanyRecord => state.company

type companyIdAndFeatureActivated = {
  companyId: number
  featureActivated: boolean
}

export const checkFeatureForCompany = (company: CompanyRecord, feature: FeatureCode): boolean =>
  (company?.planFeaturesCode.has(feature) || company?.additionalFeaturesCode.has(feature)) &&
  !company?.disabledFeaturesCode.has(feature)

export const useGetCompanyIdAndFeatureActivated = (
  feature: FeatureCode
): companyIdAndFeatureActivated => {
  const company = useSelector(currentCompanySelector)
  return {
    companyId: company.id,
    featureActivated: checkFeatureForCompany(company, feature),
  }
}
export const useCurrentCompanyHasFeature = (feature: FeatureCode): boolean => {
  const { featureActivated } = useGetCompanyIdAndFeatureActivated(feature)
  return featureActivated
}

export const useCurrentCompanyHasOneFeatureAmongst = (features: FeatureCode[]): boolean => {
  const company = useSelector(currentCompanySelector)
  return features.some(feature => checkFeatureForCompany(company, feature))
}

export const useCurrentCompanyHasAllFeatures = (features: FeatureCode[]): boolean => {
  const company = useSelector(currentCompanySelector)
  return features.every(feature => checkFeatureForCompany(company, feature))
}

export const useCurrentCompanyFilterActiveFeatures = (
  features: Readonly<FeatureCode[]>
): FeatureCode[] => {
  const company = useSelector(currentCompanySelector)
  return features.filter(feature => checkFeatureForCompany(company, feature))
}
export const useCurrentCompanyHasOnlyCep = () => {
  const company = useSelector(currentCompanySelector)
  const hasCepOnly = checkFeatureForCompany(company, 'cep-only')
  const mepOnCepFeatures = [
    'cep-show-legacy-campaign',
    'cep-show-legacy-campaign-omni',
    'cep-show-legacy-recurring',
    'cep-show-legacy-trigger',
    'inapp-campaigns',
  ] as const
  const hasOneMepFeature = mepOnCepFeatures.some(feature =>
    checkFeatureForCompany(company, feature)
  )
  return hasCepOnly && !hasOneMepFeature
}
