import Immutable from 'immutable'

import {
  orchestrationCampaignSelector,
  orchestrationLabelSelector,
  orchestrationMetaSelector,
} from 'com.batch/orchestration/store/orchestration.selectors'
import { type DispatchBoundFn, type ReduxAction } from 'com.batch.redux/_records'

import { currentProjectSelector } from 'com.batch.redux/project.selector'
import { getAPIQueryForIdSelector } from 'com.batch.redux/query/query.selector'
import { currentUserSelector } from 'com.batch.redux/user.selector'

import { formatOrchestration } from 'com.batch/orchestration/infra/formats/orchestration.format'
import {
  journeySettingsSelector,
  journeyTreeSelector,
} from 'com.batch/orchestration-journey/models/journey.selectors'
import { OrchestrationState } from 'constants/common'

export type SetOrchestrationWeightRatioAction = ReduxAction<
  'SET_ORCHESTRATION_WEIGHT_RATIO',
  number
>

/*
  la limite du proto est à 4 Mo
  au doigt mouillé, le JSON sera plus gros, et en testant la bonne limite semble être là :
*/
const MAX_BYTES = 1024 * 1024 * 4.2

export const computeOrchestrationWeight =
  (): DispatchBoundFn<Promise<number>> => async (dispatch, getState) => {
    const state = getState()
    const user = currentUserSelector(state)
    const project = currentProjectSelector(state)
    const settings = journeySettingsSelector(state)
    const meta = orchestrationMetaSelector(state)
    const selectedOrchestrationLabels = orchestrationLabelSelector(state)
    const orchestrationCampaign = orchestrationCampaignSelector(state)
    const getQuery = getAPIQueryForIdSelector(state)
    const queries: Array<[string, string]> = []
    state.query.forEach((_, queryId) => {
      const query = getQuery(queryId)
      if (query) queries.push([queryId, JSON.stringify(query, null, 2)])
    })
    const { rootNodeId, nodesMap } = journeyTreeSelector(state)
    const orchestration = formatOrchestration({
      project,
      settings,
      user,
      rootNodeId,
      messageIdsToReplace:
        meta.state === OrchestrationState.RUNNING || meta.state === OrchestrationState.STOPPED
          ? state.message.updatedMessageIds
          : Immutable.Set(),
      incomplete: true,
      campaign: orchestrationCampaign,
      nodes: nodesMap,
      targets: state.target,
      name: meta.name,
      id: meta.id,
      state: meta.state,
      message: state.message,
      queries: Immutable.Map(queries),
      labelCodes: Immutable.Set(selectedOrchestrationLabels.map(label => label.code)),
    })
    const ratio = new Blob([JSON.stringify(orchestration)]).size / MAX_BYTES
    dispatch<SetOrchestrationWeightRatioAction>({
      type: 'SET_ORCHESTRATION_WEIGHT_RATIO',
      payload: ratio,
    })
    return ratio
  }
