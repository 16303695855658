import * as React from 'react'

import { Hint } from 'com.batch/shared/ui/component/hint'
import { TableCell, TableRow } from 'components/common/table'
import { colors } from 'components/styled/tokens'

import { kformat, percentage } from 'com.batch.common/utils'

import { OpensCell, HintContainer } from './stats-per-mailbox-provider.styles'

import { formatRate } from '../format-rate'

import { computeRate } from 'com.batch/orchestration-analytics/infra/parses/compute'
import { type EmailProviderRecord } from 'com.batch/orchestration-analytics/models/orchestration-stats-by-provider.record'

type Props = {
  provider: EmailProviderRecord
}
export const StatsPerMailboxProviderRow = ({ provider }: Props): React.ReactElement => {
  const { sent, delivered, open, click, bounce, unsubscribe, mpp } = provider.stats

  const nonMachineOpenRate = React.useMemo(
    () =>
      typeof open.unique === 'number' && typeof mpp.unique === 'number'
        ? computeRate(open.unique - mpp.unique, delivered.value ?? 0)
        : 0,
    [delivered.value, mpp.unique, open.unique]
  )

  return (
    <TableRow style={{ columnGap: 8, minHeight: 52 }}>
      <TableCell style={{ color: colors.text }}>{provider.name}</TableCell>
      <TableCell align="right" style={{ marginRight: 89, color: colors.textLight }}>
        {kformat(sent)}
      </TableCell>
      <TableCell align="right" style={{ marginRight: 58, color: colors.textLight }}>
        {kformat(delivered.value)}
      </TableCell>
      <TableCell align="right" style={{ marginRight: 67, color: colors.textLight }}>
        {mpp.unique ? (
          <OpensCell>
            {formatRate(open.rate ?? 0)}
            <HintContainer>
              <Hint
                size={12}
                icon="help"
                placement="bottom"
                style={{ paddingLeft: 0 }}
                tooltipWidth={270}
              >
                <p style={{ textAlign: 'start' }}>
                  Opened excluding machine opens: {percentage(nonMachineOpenRate, 2)}
                  <br />
                  Machine opens: {percentage(mpp.rate, 2)}
                </p>
              </Hint>
            </HintContainer>
          </OpensCell>
        ) : (
          formatRate(open.rate ?? 0)
        )}
      </TableCell>
      <TableCell align="right" style={{ marginRight: 70, color: colors.textLight }}>
        {formatRate(click.rate ?? 0)}
      </TableCell>
      <TableCell align="right" style={{ marginRight: 62, color: colors.textLight }}>
        {formatRate(bounce.rate)}
      </TableCell>
      <TableCell align="right" style={{ marginRight: 38, color: colors.textLight }}>
        {formatRate(unsubscribe.rate ?? 0)}
      </TableCell>
    </TableRow>
  )
}
