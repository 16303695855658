import Immutable, { type List, type Map } from 'immutable'

import { type DateRange } from 'com.batch.common/dayjs.custom'
import { request } from 'com.batch.common/request'
import { generateUrl } from 'com.batch.common/router'

import { type OrchestrationListSortableBy } from 'com.batch/orchestration-list/store/orchestration-list.state'
import { type ProjectRecord } from 'com.batch.redux/project.records'
import { type UserRecord } from 'com.batch.redux/user.records'

import { parseOrchestrationToPartial } from 'com.batch/orchestration/infra/parses/orchestration-to-partial.parse'
import {
  parseOrchestration,
  type OrchestrationParserResult,
} from 'com.batch/orchestration/infra/parses/orchestration.parse'
import {
  type Orchestration,
  type ListOrchestrationType,
} from 'com.batch/orchestration/infra/types/orchestration.types'
import { type PartialOrchestrationRecord } from 'com.batch/orchestration-list/models/partial-orchestration.records'
import { fetchPaginatedEntities } from 'com.batch/shared/infra/fetch-paginated-entities'
import { generateProjectKeyBlockRequest } from 'com.batch/shared/infra/generate-block-request'
import { type PushPlatforms } from 'com.batch/push/infra/types/push.message.types'
import { OrchestrationState } from 'constants/common/orchestration-state'

type OrchestrationListResponse = {
  orchestrations: Array<ListOrchestrationType>
  countFiltered?: number
  countTotal?: number
}
export type fetchCampaignsProps = {
  abortSignal?: AbortSignal
  project: ProjectRecord
  search: string
  statuses: Array<OrchestrationState>
  filterType: Array<'TRIGGER' | 'RECURRENT' | 'ONETIME'>
  channels: Array<ChannelUntilCleanup>
  labels: Array<string>
  dateRange: DateRange | null | undefined
  mode: 'automations' | 'campaigns'
  orderBy: OrchestrationListSortableBy
  orderDirection: 'asc' | 'dsc'
  count: number | null
  countTotal: number | null
  offset: number
  pageSize: number
  segments: Array<string>
  pushPlatforms: Array<PushPlatforms>
  trashCache?: boolean
}

const grpcRoute = (methodName: string) =>
  generateUrl('api_grpc_orchestration_service', {
    methodName: methodName,
  })
export const orchestrationService = {
  get: async ({
    token,
    project,
    user,
  }: {
    token: string
    project: ProjectRecord
    user: UserRecord
  }): Promise<OrchestrationParserResult> => {
    try {
      const response = await request.post<{
        orchestration: Orchestration
      }>(grpcRoute('Get'), {
        ...generateProjectKeyBlockRequest(project.projectKey),
        id: token,
      })
      return parseOrchestration({ orchestration: response.orchestration, user })
    } catch (err: any) {
      console.log(err)
      throw err?.errors?.[0]?.message ?? 'Unknown error'
    }
  },
  replicate: async ({
    token,
    project,
    targetProject,
  }: {
    token: string
    project: ProjectRecord
    targetProject: ProjectRecord
  }): Promise<string> => {
    try {
      const response = await request.post<{
        id: string
      }>(grpcRoute('Replicate'), {
        ...generateProjectKeyBlockRequest(project.projectKey),
        targetProjectKey: {
          textual: {
            text: targetProject.projectKey,
          },
        },
        id: token,
      })
      return response.id
    } catch (err: any) {
      console.log(err)
      throw err
    }
  },
  create: async (orchestration: Orchestration): Promise<string> => {
    try {
      const response = await request.post<{
        id: string
      }>(grpcRoute('Create'), {
        orchestration: orchestration,
      })
      return response.id
    } catch (e: any) {
      throw e.error?.message ?? 'Unknown error'
    }
  },
  update: async (orchestration: Orchestration): Promise<string> => {
    try {
      await request.post<Record<any, any>>(grpcRoute('Update'), {
        orchestration: orchestration,
      })
      return orchestration.id ?? ''
    } catch (e: any) {
      throw e.error?.message ?? 'Unknown error'
    }
  },
  changeState: async ({
    project,
    token,
    state,
  }: {
    token: string
    project: ProjectRecord
    state: OrchestrationState.RUNNING | OrchestrationState.STOPPED
  }): Promise<void> => {
    await request.post<Record<any, any>>(grpcRoute('ChangeState'), {
      ...generateProjectKeyBlockRequest(project.projectKey),
      id: token,
      state,
    })
  },
  delete: async ({ token, project }: { token: string; project: ProjectRecord }): Promise<void> => {
    await request.post<Record<any, any>>(grpcRoute('Delete'), {
      ...generateProjectKeyBlockRequest(project.projectKey),
      id: token,
    })
  },
  selectWinningVariant: async ({
    project,
    orchestrationId,
    winningVariantId,
  }: {
    project: ProjectRecord
    orchestrationId: string
    winningVariantId: number
  }): Promise<void> => {
    return request.post(grpcRoute('SelectWinningVariant'), {
      ...generateProjectKeyBlockRequest(project.projectKey),
      orchestrationID: orchestrationId,
      winningVariantID: winningVariantId,
    })
  },
  fetchOrchestrations: async ({
    project,
    orderBy,
    orderDirection,
    abortSignal,
    mode,
    filterType,
    statuses,
    channels,
    labels,
    pushPlatforms,
    segments,
    dateRange,
    search,
    count,
    countTotal,
    offset,
    pageSize,
    trashCache,
  }: fetchCampaignsProps): Promise<{
    count: number
    countTotal: number
    entities: List<PartialOrchestrationRecord>
  }> => {
    try {
      const filterTypeForMode = mode === 'automations' ? ['TRIGGER', 'RECURRENT'] : ['ONETIME']

      const props = {
        ...generateProjectKeyBlockRequest(project.projectKey),
        countFilterType: filterTypeForMode,
        sortBy: orderBy === 'when' ? 'TIMESTAMP' : orderBy === 'name' ? 'NAME' : 'ID',
        sortDirection: orderDirection === 'asc' ? 'ASC' : 'DESC',
        filterType: filterType.length === 0 ? filterTypeForMode : filterType,
      }

      const searchProps = {
        filterState:
          statuses.length === 0
            ? Object.values(OrchestrationState).filter(state => state !== OrchestrationState.NEW)
            : statuses,
        filterChannels: channels.length === 0 ? [] : channels.map(channel => channel.toUpperCase()),
        filterLabels: labels.length === 0 ? [] : labels,
        filterSegments: segments,
        filterActiveFrom: dateRange?.from.startOf('day').toISOString(),
        filterActiveTo: dateRange?.to.endOf('day').toISOString(),
        filterPushPlatforms: pushPlatforms,
        filterName: search,
      }

      const fetcher = async ({ page, pageSize }: { page: number; pageSize: number }) => {
        const response = await request.post<OrchestrationListResponse>(
          grpcRoute('List'),
          {
            ...props,
            ...searchProps,
            offset: page,
            size: pageSize,
          },
          abortSignal
        )

        return {
          entities: response.orchestrations,
          total: response.countTotal,
          totalMatching: response.countFiltered,
        }
      }

      const response = await fetchPaginatedEntities<ListOrchestrationType>({
        total: trashCache ? null : countTotal,
        totalMatching: trashCache ? null : count,
        page: offset,
        pageSize,
        fetcher,
      })

      return {
        count: response?.totalMatching ?? count ?? 0,
        countTotal: response.total ?? countTotal ?? 0,
        entities: Immutable.List(
          response.entities?.map((orchestration: ListOrchestrationType) =>
            parseOrchestrationToPartial(orchestration)
          ) ?? []
        ),
      }
    } catch (e: any) {
      throw e.aborted ? e : e.error?.errors?.[0]?.message ?? 'Unknown error'
    }
  },
  getLabelCountByLabelCodes: async ({
    projectKey,
    labelCodes,
  }: {
    projectKey: string
    labelCodes: List<string>
  }): Promise<Map<string, number>> => {
    try {
      const response = await request.post<{
        counts: {
          [key: string]: number
        }
      }>(grpcRoute('CountByLabels'), {
        ...generateProjectKeyBlockRequest(projectKey),
        labelCodes,
      })
      const entries: Array<[string, number]> = Object.keys(response.counts ?? {}).map(key => [
        key,
        response.counts[key],
      ])
      return Immutable.Map(entries)
    } catch (e: any) {
      console.log(e)
      throw e
    }
  },
}
