import * as React from 'react'

import { BoxedCheckboxLabel } from 'components/campaign/boxed-checkbox.styles'
import { Grid } from 'components/common/grid'
import { Tooltip } from 'com.batch/shared/ui/component/tooltip'
import { Checkbox } from 'components/form'

type BoxedCheckboxProps = {
  isSelected: boolean
  title?: string
  description?: string
  isDisabled?: boolean
  onChange?: () => void
  tooltip?: string
  style?: any
}

export const BoxedCheckbox = ({
  isSelected,
  title,
  description,
  isDisabled,
  onChange,
  tooltip,
  style,
}: BoxedCheckboxProps): React.ReactElement => {
  return (
    <Tooltip tooltip={tooltip} placement="right" width={213} style={{ textAlign: 'center' }}>
      <BoxedCheckboxLabel style={{ ...style }} isSelected={isSelected} isDisabled={isDisabled}>
        <Grid template="20px 1fr" gap={0}>
          <Checkbox
            name={title}
            disabled={isDisabled}
            checked={isSelected}
            onChange={onChange}
            style={{ marginRight: 9 }}
            ariaLabel={title}
          />
          <span className="styled-boxedcheckbox-title">{title}</span>
        </Grid>
        <div className="styled-boxedcheckbox-text">{description}</div>
      </BoxedCheckboxLabel>
    </Tooltip>
  )
}
