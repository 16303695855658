import styled, { css } from 'styled-components'

import { textLight } from 'components/styled/tokens/colors'
import { grayscale } from 'components/styled/tokens/schemes'
import { block } from 'components/styled/tokens/shadows'

type MessageBubbleProps = {
  border?: boolean
}
export const MessageBubble = styled.div<MessageBubbleProps>`
  background: ${grayscale['05']};
  display: grid;
  width: 386px;
  padding: 10px;
  border-radius: 14px;
  position: relative;

  ${p =>
    p.border
      ? css`
          border: 1px solid #d3d3d3;
          box-shadow: ${block};
        `
      : css`
          margin-bottom: 50px;
        `};

  :after {
    bottom: -0.1rem;
    content: ${p =>
      p.border
        ? "url('/medias/icons/msg-bubble-comma.svg')"
        : "url('/medias/icons/msg-bubble-comma-no-border.svg')"};
    position: absolute;
    transform: ${p => (p.border ? 'translate(-8.1px, 7px)' : 'translate(-5.3px, 6px)')};
  }
`

export const CustomSenderContainer = styled.div`
  margin-top: 70px;
  margin-left: 101px;
  width: 195px;
  font-weight: 500;
  text-align: center;
`

export const UnsubMessage = styled.div`
  color: ${textLight};
  padding-inline: 6px;
  margin-top: 4px;
`
export const FadeOverflow = styled.div`
  content: '';
  height: 70px;
  background: linear-gradient(
    to bottom,
    rgba(253, 253, 253, 0) 0%,
    rgba(253, 253, 253, 0.1) 15%,
    rgba(253, 253, 253, 0.5) 35%,
    rgba(253, 253, 253, 1) 55%
  );
  position: fixed;
  bottom: 0;
  width: 390px;
`
