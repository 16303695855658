import Immutable, { type Map, type Set } from 'immutable'

import { generateTypedId } from 'com.batch.common/typed-id'

import { getMessageIds } from '../../../message/models/message.helper'
import {
  type Orchestration,
  type DashboardEmailConfigByLanguage,
  type DashboardConfigByMessageId,
} from '../types/orchestration.types'
import { type MessageStateRecord } from 'com.batch/message/store/message.state'
import { type ProjectRecord } from 'com.batch.redux/project.records'
import { TargetStateFactory, type TargetStateRecord } from 'com.batch.redux/target/target.records'
import { type UserRecord } from 'com.batch.redux/user.records'

import { formatOrchestrationCampaign } from 'com.batch/orchestration-campaign/infra/formats/orchestration-campaign.format'
import { type OrchestrationCampaignRecord } from 'com.batch/orchestration-campaign/models/campaign.records'
import { formatOrchestrationJourney } from 'com.batch/orchestration-journey/infra/formats/orchestration-journey.format'
import {
  type JourneySettingsRecord,
  type JourneyNodeRecord,
} from 'com.batch/orchestration-journey/models/journey.records'
import { generateProjectKeyBlockRequest } from 'com.batch/shared/infra/generate-block-request'
import { type OrchestrationState } from 'constants/common/orchestration-state'

export type OrchestrationFormatterParams = {
  targets: Map<string, TargetStateRecord>
  project: ProjectRecord
  campaign: OrchestrationCampaignRecord
  settings: JourneySettingsRecord
  queries: Map<string, string>
  rootNodeId: string
  nodes: Map<string, JourneyNodeRecord>
  messageIdsToReplace: Set<string>
  message: MessageStateRecord
  name: string
  id: string | null | undefined
  incomplete: boolean
  user: UserRecord
  state: OrchestrationState
  labelCodes: Set<string>
}
type OrchestrationFormatter = (arg1: OrchestrationFormatterParams) => Orchestration
export const formatOrchestration: OrchestrationFormatter = ({
  targets,
  nodes,
  messageIdsToReplace,
  message,
  campaign,
  rootNodeId,
  incomplete = false,
  queries,
  project,
  settings,
  state,
  user,
  id,
  name,
  labelCodes,
}) => {
  const messageIdsReplacementTuples: Array<[string, string]> = messageIdsToReplace
    .toArray()
    .map(id => {
      return [id, generateTypedId('message')]
    })
  const messageIdsReplacement = Immutable.Map(messageIdsReplacementTuples)

  /*
    dashboard config holds data for dashboard only — stripo unoptimized html
    intermediate variables are here to please Flow
  */
  const usedMessageIds: Array<string> = []
  if (campaign.sendType === 'trigger') {
    nodes
      .filter(node => node.type === 'MESSAGE')
      .forEach(node => {
        if (node.type === 'MESSAGE')
          getMessageIds(node.messageConfig).forEach(id => {
            usedMessageIds.push(id)
          })
      })
  } else {
    getMessageIds(campaign.messageConfig).forEach(id => {
      usedMessageIds.push(id)
    })
  }

  const configByMessageId: {
    [key: string]: DashboardConfigByMessageId
  } = {}
  message.email
    .filter((_, id) => usedMessageIds.includes(id))
    .forEach((message, messageId) => {
      const configByLanguage: {
        [lang: string]: DashboardEmailConfigByLanguage
      } = {}
      message.get('localizedContent').forEach((content, lang) => {
        if (content.htmlEditorConfig)
          configByLanguage[lang] = {
            htmlEditorConfig: {
              type: content.htmlEditorConfig.type,
              templateId: content.htmlEditorConfig.templateId,
              html: content.htmlEditorConfig.html,
              css: content.htmlEditorConfig.css,
            },
          }
      })
      configByMessageId[messageIdsReplacement.get(messageId, messageId)] = {
        langConfigs: configByLanguage,
      }
    })
  const journeyOrCampaign =
    campaign.sendType === 'trigger'
      ? {
          journey: formatOrchestrationJourney({
            targets,
            nodes,
            rootNodeId,
            message,
            messageIdsReplacement,
            queries,
            settings,
            name,
            state,
            user,
            labelCodes,
          }),
        }
      : formatOrchestrationCampaign({
          target: targets.get('default', TargetStateFactory()),
          campaign,
          project,
          messageIdsReplacement,
          query: queries.get('targeting'),
          id,
          state,
          message,
          name,
          user,
          labelCodes,
        })
  return {
    orchestrationState: state,
    orchestrationSource: 'DASHBOARD',
    orchestrationType:
      campaign.sendType === 'trigger'
        ? 'TRIGGER'
        : campaign.sendType === 'recurring'
          ? 'RECURRENT'
          : 'ONETIME',
    orchestrationChannels: undefined, // filled by backend
    dashboardConfig: { incomplete, emailAdditionnalConfig: configByMessageId },
    ...generateProjectKeyBlockRequest(project.projectKey),
    id: id,
    name: name,
    ...journeyOrCampaign,
  }
}
