import { textUsesTemplating } from 'com.batch.common/utils'

import { type DispatchExtraBoundFn } from 'com.batch.redux/_records'
import {
  TemplatingFactory,
  type TemplatingRecord,
} from 'com.batch/templating/models/templating.records'
import Immutable from 'immutable'
import { buildTemplatingCachekey } from 'com.batch/templating/models/templating.utils'
import { fetchTemplating } from 'com.batch/templating/usecases/fetch-templating'

export const triggerTemplateEvaluation = (): DispatchExtraBoundFn<void> => {
  return (dispatch, getState) => {
    const state = getState()
    const { email, sms, push } = state.message

    let mapTemplatingRecord = Immutable.Map<string, TemplatingRecord>()
    email.forEach(({ localizedContent }, messageId) =>
      localizedContent.forEach((content, lang) => {
        const fields = ['subject', 'html', 'replyTo'] as const
        fields.forEach(field => {
          if (textUsesTemplating(content[field])) {
            mapTemplatingRecord = mapTemplatingRecord.set(
              buildTemplatingCachekey({
                messageId,
                langId: lang,
                field,
              }),
              TemplatingFactory({ value: content[field] ?? '', valueIsHtml: field === 'html' })
            )
          }
        })
      })
    )
    sms.forEach(({ localizedContent }, messageId) =>
      localizedContent.forEach((content, lang) => {
        if (textUsesTemplating(content.smsMessage)) {
          mapTemplatingRecord = mapTemplatingRecord.set(
            buildTemplatingCachekey({
              messageId,
              langId: lang,
              field: 'smsMessage',
            }),
            TemplatingFactory({ value: content.smsMessage })
          )
        }
      })
    )

    const pushTemplatableField = [
      'pushTitle',
      'pushBody',
      'pushPicture',
      'pushIcon',
      'deeplink',
      'androidDeeplink',
      'iosDeeplink',
      'webpushDeeplink',
      'iosVideo',
      'iosAudio',
    ] as const
    push.forEach(({ localizedContent }, messageId) =>
      localizedContent.forEach((content, lang) => {
        pushTemplatableField.forEach(field => {
          const value = content.get(field)
          if (textUsesTemplating(value)) {
            mapTemplatingRecord = mapTemplatingRecord.set(
              buildTemplatingCachekey({
                messageId,
                langId: lang,
                field,
              }),
              TemplatingFactory({ value })
            )
          }
        })
      })
    )
    if (mapTemplatingRecord) {
      dispatch(fetchTemplating(mapTemplatingRecord, true))
    }
  }
}
