import Immutable, { type Map } from 'immutable'
import { createSelector } from 'reselect'

import { isEmail, textUsesTemplating, getVariantLabel } from 'com.batch.common/utils'

import {
  messageStateSelector,
  getLangFromContent,
  previewLanguageSelector,
} from 'com.batch/message/store/message.selector'
import { type langId } from 'com.batch/message/store/message.state'
import { getMessageConfigSelector } from 'com.batch/orchestration/store/orchestration.composed.selectors'
import { type State } from 'com.batch.redux/_records'
import { senderIdentitiesSelector } from 'com.batch.redux/corelogic/selectors/sender-identity.selector'

import { getMessageIds, getMessageKey } from 'com.batch/message/models/message.helper'
import {
  EmailContentFactory,
  type EmailContentRecord,
  EmailMessageFactory,
  type EmailMessageRecord,
} from 'com.batch/message/models/message.records'
import {
  getTemplatingFromCache,
  type TemplateMode,
} from 'com.batch/templating/models/templating.utils'
import { templatingCacheSelector } from 'com.batch/templating/store/templating.selector'
import { type LoadingStatus } from 'constants/common'

type MessageIdGetterSelector<T> = (arg1: State) => (arg1: string) => T

export const getEmailMessageSelector: MessageIdGetterSelector<EmailMessageRecord> = createSelector(
  messageStateSelector,
  messageState => (messageId: string) => messageState.email.get(messageId, EmailMessageFactory())
)

export const getEmailContentSelector: MessageIdGetterSelector<
  Immutable.OrderedMap<langId, EmailContentRecord>
> = createSelector(getEmailMessageSelector, getEmail => {
  return (messageId: string) => getEmail(messageId).get('localizedContent', Immutable.OrderedMap())
})
const defaultEmailContent = EmailContentFactory()
export const getEmailContentForActiveLanguageSelector: (
  state: State
) => ({
  messageId,
  templateMode,
}: {
  messageId: string
  templateMode?: TemplateMode
}) => EmailContentRecord = createSelector(
  getEmailContentSelector,
  previewLanguageSelector,
  templatingCacheSelector,
  (getEmailContent, lang, cache) =>
    ({ messageId, templateMode = 'RAW' }) => {
      const data = getEmailContent(messageId)
      const emailContent = data.get(lang, defaultEmailContent)
      if (templateMode === 'RAW') {
        return emailContent
      }
      return EmailContentFactory({
        fromEmail: emailContent.fromEmail,
        name: emailContent.name,
        subject: getTemplatingFromCache({
          cache,
          messageId,
          langId: lang,
          field: 'subject',
          defaultValue: emailContent.subject ?? '',
          enriched: templateMode === 'TEMPLATED_HTML',
        }),
        html: getTemplatingFromCache({
          cache,
          messageId,
          langId: lang,
          field: 'html',
          defaultValue: emailContent.html ?? '',
          enriched: true,
        }),
        replyTo: getTemplatingFromCache({
          cache,
          messageId,
          langId: lang,
          field: 'replyTo',
          defaultValue: emailContent.replyTo ?? '',
          enriched: templateMode === 'TEMPLATED_HTML',
        }),
      })
    }
)

export const getEmailContentLoadingStateSelector: MessageIdGetterSelector<LoadingStatus> =
  createSelector(getEmailContentForActiveLanguageSelector, getData => (messageId: string) => {
    const content = getData({ messageId })
    return content.loadingState
  })

/**
 * Tells if all the required fields for an email automation are filled or not.
 * @return {boolean} true if all the required fields are filled, false either
 */
export const getAreRequiredEmailFieldsFilledSelector: MessageIdGetterSelector<boolean> =
  createSelector(getEmailContentForActiveLanguageSelector, getData => (messageId: string) => {
    const content = getData({ messageId })
    return Boolean(content.fromEmail) && Boolean(content.subject) && Boolean(content.name)
  })

export const getContentHasUpdatedSenderIdentitySelector: MessageIdGetterSelector<boolean> =
  createSelector(
    getEmailContentForActiveLanguageSelector,
    senderIdentitiesSelector,
    (getContent, senderIdentities) => (messageId: string) => {
      const content = getContent({ messageId })
      const selectedSenderIdentity = senderIdentities.find(
        option => option.id === content?.senderIdentityId
      )
      if (!selectedSenderIdentity) {
        return false
      }

      return (
        content?.name !== selectedSenderIdentity.sendingName ||
        content?.fromEmail !==
          `${selectedSenderIdentity.sendingPrefix}@${selectedSenderIdentity.sendingDomain}`
      )
    }
  )

export const getContentHasDeletedSenderIdentitySelector: MessageIdGetterSelector<boolean> =
  createSelector(
    getEmailContentForActiveLanguageSelector,
    senderIdentitiesSelector,
    (getContent, senderIdentities) => (messageId: string) => {
      const content = getContent({ messageId })
      if (!content?.senderIdentityId || content?.senderIdentityId === -1) {
        return false
      }
      const selectedSenderIdentity = senderIdentities.find(
        option => option.id === content.senderIdentityId
      )
      return !selectedSenderIdentity
    }
  )

export const getSelectEmailIsUploading: MessageIdGetterSelector<boolean> = createSelector(
  getEmailContentForActiveLanguageSelector,
  getContent => (messageId: string) => getContent({ messageId })?.isEmailUploading ?? false
)

type getErrorsForMessage = (arg1: {
  stepMessageNodeId: string | null | undefined
}) => Map<string, Array<string>>
export const getEmailErrorsSelector: (arg1: State) => getErrorsForMessage = createSelector(
  getEmailMessageSelector,
  getMessageConfigSelector,
  getContentHasUpdatedSenderIdentitySelector,
  getContentHasDeletedSenderIdentitySelector,
  (getMessage, getConfig, getContentHasUpdatedSenderIdentity, getContentHasDeletedSenderIdentity) =>
    ({ stepMessageNodeId }) => {
      const config = getConfig({ stepMessageNodeId })
      const abEnabled = config.experiment.enabled
      const messageTypedIdList = getMessageIds(config)
      const errors: Array<[string, Array<string>]> = []

      messageTypedIdList.forEach((messageId, index) => {
        const message = getMessage(messageId)
        const localizedContent = message.get('localizedContent', Immutable.Map())
        const languages = getLangFromContent(localizedContent, config.multilanguageEnabled)
        const contentHasUpdatedSenderIdentity = getContentHasUpdatedSenderIdentity(messageId)
        const contentHasDeletedSenderIdentity = getContentHasDeletedSenderIdentity(messageId)
        languages.forEach(lang => {
          const contentForLang = localizedContent.get(lang.value, EmailContentFactory())
          const localErrors: Array<string> = []
          if (!contentForLang.fromEmail || !contentForLang.name || !contentForLang.senderIdentityId)
            localErrors.push('Sender email is missing')
          if (contentForLang.fromEmail && !isEmail(contentForLang.fromEmail))
            localErrors.push('Sender email is invalid')
          if (!contentForLang.subject) localErrors.push('Subject is missing')
          if (!contentForLang.html) localErrors.push('HTML is missing')
          if (contentHasUpdatedSenderIdentity)
            localErrors.push('The sender has recently been modified, please select again.')
          if (contentHasDeletedSenderIdentity)
            localErrors.push('The sender has recently been deleted, please select again.')
          if (
            contentForLang.replyTo &&
            !isEmail(contentForLang.replyTo) &&
            !textUsesTemplating(contentForLang.replyTo)
          )
            localErrors.push('Reply to is invalid')
          if (localErrors.length > 0)
            errors.push([
              getMessageKey(lang) + (abEnabled ? ` ${getVariantLabel(index)}` : ''),
              localErrors,
            ])
        })
      })
      return Immutable.Map(errors)
    }
)
