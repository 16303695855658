import Immutable from 'immutable'
import * as React from 'react'
import { useDispatch, useSelector } from 'com.batch.common/react-redux'
import styled from 'styled-components'

import { BoxSection } from 'components/common/box'
import { Grid } from 'components/common/grid'
import { Icon } from 'components/common/svg-icon'
import { Select } from 'components/form'
import { SelectValueContainer, SelectField } from 'components/form/fields/select/select.styles'
import * as colors from 'components/styled/tokens/colors'

import { orchestrationStateSelector } from 'com.batch/orchestration/store/orchestration.selectors'
import { currentProjectPlatformsSelector } from 'com.batch.redux/app'

import { updatePushMessageRecordTokenModeAction } from 'com.batch/push/usecases/update-push-content'
import { Tooltip } from 'com.batch/shared/ui/component/tooltip'

export const PushTokenDropdown = (): React.ReactElement => {
  const dispatch = useDispatch()
  const orchestration = useSelector(orchestrationStateSelector)

  const projectPlatforms = useSelector(currentProjectPlatformsSelector)
  const hasWebpush = React.useMemo(() => projectPlatforms.has('webpush'), [projectPlatforms])

  const filterPushTokens = React.useMemo(
    () => orchestration.campaign.messageConfig.pushSettings.filterPushTokens,
    [orchestration.campaign.messageConfig.pushSettings.filterPushTokens]
  )

  const onPushTokenChange = React.useCallback(
    option => {
      if (!option) return
      dispatch(updatePushMessageRecordTokenModeAction({ tokenMode: option.value }))
    },
    [dispatch]
  )

  const isAllOptionOrIsImportedOptionWithWebpushEnabled = React.useCallback(
    option => option.value === 'all' || (hasWebpush && option.value === 'imported'),
    [hasWebpush]
  )

  const setTooltip = React.useCallback(option => {
    switch (option.value) {
      case 'all':
        return 'Targeting both collected & imported tokens together may lead to confusion in your analytics. We highly recommend creating 2 separate orchestrations.'
      case 'imported':
        return 'Imported tokens are not available for web push. Messages will not be sent to Web if this option is selected.'
      default:
        return ''
    }
  }, [])
  const optionFormatter = React.useCallback(
    (option, { context }) => {
      if (context === 'value') {
        return option.label
      }
      return (
        <Tooltip
          tooltip={
            isAllOptionOrIsImportedOptionWithWebpushEnabled(option) ? setTooltip(option) : undefined
          }
          placement="right"
          offset={[0, 20]}
          width={320}
        >
          <div>
            <div
              style={{
                display: 'flex',
                justifyContent: 'space-between',
              }}
            >
              <div>
                <div>{option.label}</div>
                <div
                  style={{
                    whiteSpace: 'break-spaces',
                    fontWeight: 400,
                    fontSize: '14px',
                  }}
                >
                  {option.description}
                </div>
              </div>
              <div
                style={{
                  display: 'flex',
                  margin: 'auto 0',
                  marginRight: '10px',
                }}
              >
                {isAllOptionOrIsImportedOptionWithWebpushEnabled(option) && (
                  <Icon icon="warning-triangle" color="none" size={16} />
                )}
              </div>
            </div>
          </div>
        </Tooltip>
      )
    },
    [isAllOptionOrIsImportedOptionWithWebpushEnabled, setTooltip]
  )

  const pushTokenTargetingOptions = Immutable.List<{
    value: string
    label: string
    description: string
  }>().push(
    ...[
      {
        value: 'collected',
        label: 'Collected tokens only',
        description: 'Tokens already detected by our SDKs',
      },
      {
        value: 'imported',
        label: 'Imported tokens only',
        description: 'Manually imported tokens, not yet detected by our SDKs',
      },
      {
        value: 'all',
        label: 'Both collected & imported tokens',
        description: 'Not available from Dashboard',
      },
    ]
  )

  const pushTokenTargetingOptionsFiltered = React.useMemo(() => {
    if (filterPushTokens !== 'all') {
      return pushTokenTargetingOptions.filter(option => option.value !== 'all')
    }

    return pushTokenTargetingOptions
  }, [pushTokenTargetingOptions, filterPushTokens])

  const optionToString = React.useCallback(option => option?.label ?? '', [])

  return (
    <BoxSection
      style={{
        padding: '0 0 12px 0',
        marginLeft: '20px',
        marginRight: '20px',
        borderBottom: `1px solid ${colors.stroke}`,
      }}
    >
      <Grid template="10px 120px 180px" style={{ marginTop: '16px' }}>
        <Icon icon="push" />
        <label>Filter push tokens</label>
        <PushTokenSelect
          options={pushTokenTargetingOptionsFiltered}
          optionToString={optionToString}
          onChange={onPushTokenChange}
          style={{
            height: '20px',
            width: hasWebpush ? '426px' : '376px',
            paddingLeft: 0,
            boxShadow: 'none',
          }}
          optionMenuStyle={{
            width: hasWebpush ? '436px' : '386px',
            padding: '6px',
          }}
          optionMenuHeight={56}
          optionFormatter={optionFormatter}
          value={pushTokenTargetingOptionsFiltered.find(
            option => option.value === filterPushTokens
          )}
        />
      </Grid>
    </BoxSection>
  )
}

export const PushTokenSelect = styled(Select)`
  background-color: transparent !important;

  ${SelectField} {
    width: fit-content;

    button {
      path {
        fill: ${colors.textLight};
      }
    }

    :hover button path {
      fill: ${colors.text};
    }
  }
  ${SelectValueContainer} {
    ${({ theme }) =>
      !theme.disabledMode &&
      `
    color: ${colors.textLight};

    :hover {
      color: ${colors.text};
    }
  `}
  }
`
