import { type DispatchBoundFn, type ReduxAction } from 'com.batch.redux/_records'
import { buildTemplatingCachekey } from '../models/templating.utils'
import { textUsesTemplating } from 'com.batch.common/utils'
import { fetchTemplating } from './fetch-templating'
import { templatingCacheSelector } from '../store/templating.selector'
import Immutable from 'immutable'
import { TemplatingFactory } from '../models/templating.records'

export type TEMPLATING_UNSET_ACTION = ReduxAction<'TEMPLATING_UNSET', string>

export const setTemplating =
  ({
    messageId,
    langId,
    field,
    value,
    valueIsHtml,
    isInstant = false,
  }: {
    messageId: string
    langId: string
    field: string
    value: string
    valueIsHtml: boolean
    isInstant?: boolean
  }): DispatchBoundFn<void> =>
  (dispatch, getState) => {
    const key = buildTemplatingCachekey({ messageId, langId, field })
    const cache = templatingCacheSelector(getState())
    if (textUsesTemplating(value)) {
      // do not retrigger if not changed
      if (cache.get(key, TemplatingFactory()).value !== value) {
        return dispatch(
          fetchTemplating(
            Immutable.Map([[key, TemplatingFactory({ value, valueIsHtml })]]),
            isInstant
          )
        )
      }
    } else {
      if (cache.has(key))
        dispatch<TEMPLATING_UNSET_ACTION>({
          type: 'TEMPLATING_UNSET',
          payload: key,
        })
    }
  }
