import { type Set } from 'immutable'

import { request } from 'com.batch.common/request'
import { generateUrl } from 'com.batch.common/router'

import { type PushPlatforms } from './types/push.message.types'
import { type SendTestPushResponseType } from './types/send-test-push.types'

import { type SendTestRecipientId } from '../usecases/send-test-push'

import {
  type PushContentRecord,
  type PushSettingsRecord,
} from 'com.batch/message/models/message.records'

export const sendTestPushRequest = ({
  projectKey,
  push,
  settings: { priority, customPayload, androidOverride, iosOverride },
  platforms,
  recipientId: { kind, id },
}: {
  projectKey: string
  push: PushContentRecord
  settings: PushSettingsRecord
  platforms: Set<ProjectPlatforms>
  recipientId: SendTestRecipientId
}): Promise<SendTestPushResponseType> => {
  const pushPlatforms: Array<PushPlatforms> = []
  if (platforms.has('ios')) pushPlatforms.push('PUSH_PLATFORM_IOS')
  if (platforms.has('android')) pushPlatforms.push('PUSH_PLATFORM_ANDROID')
  if (platforms.has('webpush')) pushPlatforms.push('PUSH_PLATFORM_WEB')
  // @TODO : remettre la perso
  return request.post<SendTestPushResponseType>(
    generateUrl('api_grpc_omnichannel_transmission_send_test', { methodName: 'Send' }),
    {
      projectKey: {
        textual: {
          text: projectKey,
        },
      },
      [kind as string]: id,
      platforms: pushPlatforms,
      pushMessageContentTemplate: {
        content: {
          title: push.pushTitle,
          body: push.pushBody,
          pushType: push.pushType,
          pushPriority: priority,
          picture: push.pushPicture ? { url: push.pushPicture } : null,
          deepLink: push.deeplink,
          customPayload,
        },
        androidOverride: {
          customPayload: androidOverride.customPayload,
          collapseKey: androidOverride.collapseKey,
          icon: push.pushIcon ? { url: push.pushIcon } : null,
        },
        iosOverride: {
          customPayload: iosOverride.customPayload,
          deepLink: push.iosDeeplink,
          iosVideo: push.iosVideo ? { url: push.iosVideo } : null,
          iosAudio: push.iosAudio ? { url: push.iosAudio } : null,
        },
        webPushOverride: {
          deepLink: push.webpushDeeplink,
          icon: push.pushIcon ? { url: push.pushIcon } : null,
        },
      },
    }
  )
}
