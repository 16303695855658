import * as React from 'react'

import { useWidthObserver } from 'components/_hooks'
import { Icon } from 'components/common/svg-icon'
import { Tooltip } from 'com.batch/shared/ui/component/tooltip'

import { OptionContainer } from './checkbox.styles'

type CheckboxProps = {
  label?: React.ReactNode
  name?: string
  id?: string
  size?: number
  className?: string
  activeColor?: string
  handleIndeterminate?: boolean
  checked?: boolean | null | undefined
  disabled?: boolean
  style?: {
    [key: string]: number | string
  }
  tooltip?: string
  disabledTooltip?: boolean
  onChange?: () => void
  ariaLabel?: string
  ariaLabelledBy?: string
}

const Checkbox = ({
  label,
  checked,
  onChange,
  activeColor,
  handleIndeterminate = false,
  size,
  style,
  tooltip,
  disabledTooltip = false,
  disabled = false,
  ariaLabel,
  ariaLabelledBy,
  ...props
}: CheckboxProps): React.ReactElement => {
  const labelId = React.useId()
  const _onChange = React.useCallback(
    e => {
      if (e.currentTarget) e.currentTarget.blur()
      if (onChange) onChange()
    },
    [onChange]
  )

  const spanRef = React.useRef<HTMLSpanElement>(null)

  // just used to trigger a component update when width changes, so the isTooltipEmpty checks can re-evaluate
  useWidthObserver(spanRef, 0)

  const indeterminate = handleIndeterminate && (typeof checked === 'undefined' || checked === null)
  const tooltipContent = React.useMemo(() => (tooltip ? tooltip : label ?? ''), [tooltip, label])

  return (
    <Tooltip
      tooltip={
        !disabledTooltip &&
        Boolean(tooltip) &&
        (spanRef?.current?.scrollWidth ?? 0) > (spanRef?.current?.clientWidth ?? 0) &&
        tooltipContent
      }
      placement="right"
      width={198}
    >
      <OptionContainer activeColor={activeColor} disabled={disabled} size={size} style={style}>
        <input
          {...props}
          checked={!!checked}
          disabled={disabled}
          type="checkbox"
          onChange={_onChange}
          aria-checked={!!checked}
          aria-labelledby={label ? labelId : ariaLabelledBy}
          {...(!label && !ariaLabelledBy && ariaLabel ? { 'aria-label': ariaLabel } : {})}
        />
        {indeterminate ? (
          <Icon icon="tiret" thickness={2} className="styled-option-novalue" />
        ) : (
          <Icon icon="check" thickness={2} className="styled-option-symbol" />
        )}
        {label && (
          <span ref={spanRef} className="styled-option-label" id={labelId}>
            {label}
          </span>
        )}
      </OptionContainer>
    </Tooltip>
  )
}

export { Checkbox }
