import * as React from 'react'
import styled, { css, keyframes } from 'styled-components'

import { type availableIcons, Icon } from 'components/common/svg-icon'
import { Tooltip } from 'com.batch/shared/ui/component/tooltip'
import { colors, schemes } from 'components/styled/tokens'

import { percentage } from 'com.batch.common/utils'

import { type SdkActionChoiceRecord } from 'com.batch.redux/content.records'
import { type SdkFeatureState } from 'com.batch.redux/sdk.selector'

const OptionGrid = styled.div`
  display: grid;
  grid-template-columns: ${(props: { isMenu?: boolean }) =>
    props.isMenu ? '44px 1fr' : '26px 1fr'};
  align-items: center;
  color: ${colors.textNeutral};
`

const OptionIcon = styled.div`
  color: ${colors.textNeutral} !important;
  padding-left: 1px;
  .js-sprite {
    color: crimson;
    display: inline-block;
    line-height: 0;
    margin-bottom: -2px;
    width: 24px;
    height: 24px;
    background-size: 108px 548px;
    background-image: url(/medias/img/cta_sprite.png);
    background-repeat: no-repeat;
    background-position: ${(props: {
      isMenu?: boolean
      isSelected?: boolean
      dropdownSprite: [number, number, number, number]
    }) =>
      props.isSelected
        ? `${props.dropdownSprite[2]}px ${props.dropdownSprite[3]}px`
        : `${props.dropdownSprite[0]}px ${props.dropdownSprite[1]}px`};
  }
  ${(props: {
    isMenu?: boolean
    isSelected?: boolean
    dropdownSprite: [number, number, number, number]
  }) =>
    props.isMenu &&
    css`
      text-align: center;
    `}
`
export const OptionText = styled.div`
  color: ${colors.textNeutral};
  font-weight: 500;
`
export const OptionDesc = styled.div`
  color: ${colors.textLight};
  font-weight: 400;
`

const AnimationSDKLoading = keyframes`
  0% {
    background: ${schemes.darklucent['30']};
  }
  50% {
    background: ${schemes.darklucent['10']};
  }
  100% {
    background: ${schemes.darklucent['30']};
  }
`

type SdkVersionProps = {
  state: 'warn' | 'danger' | 'loading'
  isInMenu?: boolean
}
export const SdkVersion = styled.span<SdkVersionProps>`
  display: inline-flex;
  height: 16px;
  padding: 0 4px 0 3px;
  margin-left: 7px;
  font-size: 11px;
  font-weight: 600;
  line-height: 16px;
  color: ${p => (p.state === 'loading' ? 'transparent' : schemes.lightlucent['100'])};
  background: ${p =>
    p.state === 'loading'
      ? schemes.darklucent['20']
      : p.state === 'danger'
        ? colors.fillDanger
        : colors.fillWarning};
  border-radius: 4px;

  ${props =>
    props.state === 'loading' &&
    css`
      animation-name: ${AnimationSDKLoading};
      animation-duration: 2s;
      animation-iteration-count: infinite;
    `}
`

export const SdkActionOptionFormater = (
  action: SdkActionChoiceRecord | null | undefined,
  opt: {
    context: 'menu' | 'value'
  }
): React.ReactElement | null => {
  const { context } = opt
  if (!action) return null
  const ttw =
    action.sdkState.ratio < 0.01
      ? `This action is not available in your current SDK version. Update your Batch SDK version (${action.sdkState.public} or above) to use this action in your campaign.`
      : `This action is available for SDK version ${
          action.sdkState.public
        } or above (around ${percentage(
          action.sdkState.ratio,
          0
        )}  of the userbase). Users with a version below won't be targeted in your campaign.`
  return (
    <Tooltip
      tooltip={
        !(
          context === 'value' ||
          action.sdkState.state === 'ok' ||
          action.sdkState.state === 'loading'
        ) && ttw
      }
      placement="right"
      width={220}
    >
      <OptionGrid
        isMenu={context === 'menu'}
        style={action.value === 'batch.dismiss' ? { borderTop: 'none' } : {}}
      >
        <OptionIcon isMenu={context === 'menu'} dropdownSprite={action.dropdownSprite}>
          {context === 'menu' ? (
            <span className="js-sprite" />
          ) : (
            <Icon icon={action.icon} thickness={1.3} style={{ marginLeft: 3 }} />
          )}
        </OptionIcon>
        <div style={{ padding: context === 'value' ? 'auto' : '7px 0' }}>
          <OptionText>
            {action.label}
            {action.sdkState.state !== 'ok' && action.sdkState.public !== '1.7' && (
              <Tooltip tooltip={context !== 'menu' && ttw} width={220} placement="right">
                <SdkVersion isInMenu={context === 'menu'} state={action.sdkState.state}>
                  {action.sdkState.public}
                </SdkVersion>
              </Tooltip>
            )}
          </OptionText>
          {context === 'menu' && <OptionDesc>{action.description}</OptionDesc>}
        </div>
      </OptionGrid>
    </Tooltip>
  )
}

export const SdkSecondaryOptionFormater = (
  action:
    | {
        modal: 'EVENT' | 'ADD_TAG' | 'REMOVE_TAG'
        label: string
        icon: availableIcons
        dropdownSprite: [number, number, number, number]
        description: string
        sdkState: SdkFeatureState
      }
    | null
    | undefined,
  {
    context,
  }: {
    context: 'menu' | 'value'
  }
): React.ReactElement | null => {
  if (!action) return null
  const ttw =
    action.sdkState.ratio < 0.01
      ? `This action is not available in your current SDK version. Update your Batch SDK version (${action.sdkState.public} or above) to use this action in your campaign.`
      : `This action is available for SDK version ${
          action.sdkState.public
        } or above (around ${percentage(
          action.sdkState.ratio,
          0
        )}  of the userbase). Users with a version below won't be targeted in your campaign.`
  return (
    <Tooltip
      tooltip={
        !(
          context === 'value' ||
          action.sdkState.state === 'ok' ||
          action.sdkState.state === 'loading'
        ) && ttw
      }
      placement="right"
      width={220}
    >
      <OptionGrid
        isMenu={context === 'menu'}
        style={action.modal === 'EVENT' ? { borderTop: 'none' } : {}}
      >
        <OptionIcon isMenu={context === 'menu'} dropdownSprite={action.dropdownSprite}>
          {context === 'value' ? (
            <Icon icon={action.icon} thickness={1.3} />
          ) : (
            <span className="js-sprite" />
          )}
        </OptionIcon>
        <div style={{ padding: context === 'value' ? 'auto' : '7px 0' }}>
          <OptionText>
            {action.label}{' '}
            {action.sdkState.state !== 'ok' && (
              <Tooltip tooltip={context !== 'menu' && ttw} width={220} placement="right">
                <SdkVersion state={action.sdkState.state}>SDK {action.sdkState.public}</SdkVersion>
              </Tooltip>
            )}
          </OptionText>
          {context === 'menu' && <OptionDesc>{action.description}</OptionDesc>}
        </div>
      </OptionGrid>
    </Tooltip>
  )
}

export const ActionContainer = styled.div`
  margin-bottom: 16px;
  color: ${colors.textNeutral};
  font-weight: 500;
`
export const ActionConfig = styled.div`
  border-top: 1px solid #fff;
  background: ${schemes.darklucent['10']};
  border-radius: 0 0 4px 4px;
  padding: 10px;
  &:empty {
    display: none;
  }
`

export const AdditionalContainer = styled.div`
  background: ${schemes.darklucent['10']};
  border-radius: 4px;
  padding: 4px 12px 4px 12px;
  margin-top: 8px;
  display: grid;
  height: 36px;
  align-items: center;
  grid-template-columns: 24px 1fr 55px 25px;
`
