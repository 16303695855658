import Immutable, { type List, type Set } from 'immutable'
import * as React from 'react'
import { useDispatch } from 'com.batch.common/react-redux'

import { Button } from 'components/common/button'
import { Icon } from 'components/common/svg-icon'
import { TableRow, TableCell, TableCellActions } from 'components/common/table'
import { Tooltip } from 'com.batch/shared/ui/component/tooltip'

import { percentage, numberFormat, capitalize } from 'com.batch.common/utils'

import { fetchNotifications } from 'com.batch.redux/dataCampaign'
import { type DayDataRecord, PushWithVariantFactory } from 'com.batch.redux/dataCampaign.records'

type NotificationRowProps = {
  mode: 'category' | 'period' | 'campaign' | 'transactional'
  data: DayDataRecord
  appId: number
  companyId: number
  all: List<DayDataRecord>
  loaded: Set<string>
}

export const NotificationRow = ({
  mode,
  data,
  all,
  appId,
  companyId,
  loaded,
}: NotificationRowProps): React.ReactElement => {
  const dispatch = useDispatch()

  const [loading, setLoading] = React.useState(false)
  const [expanded, setExpanded] = React.useState(false)
  const byCat = React.useMemo(() => {
    switch (mode) {
      case 'period':
        return all.filter(pd => pd.id.kind === 'category' && pd.period.isSame(data.period))
      case 'category':
        return all.filter(pd => {
          return (
            pd.id.kind === (data.id.value === 'marketing' ? 'token' : 'groupId') &&
            pd.period.isSame(data.period)
          )
        })
      default:
        return Immutable.List()
    }
  }, [all, data.id, data.period, mode])
  const openRow = React.useCallback(() => {
    if (
      mode === 'category' &&
      !loading &&
      !loaded.has(data.period.format('YYYYMMDD') + '_' + data.id.value)
    ) {
      setLoading(true)
      dispatch(
        fetchNotifications({
          from: data.period,
          to: data.period,
          category: data.id.value === 'marketing' ? 'marketing' : 'transactional',
        })
      ).then(
        () => {
          setLoading(false)
          setExpanded(true)
        },
        () => setLoading(false)
      )
    } else {
      setExpanded(true)
    }
  }, [data.id.value, data.period, dispatch, loaded, loading, mode])

  const push = React.useMemo(() => data.push ?? PushWithVariantFactory(), [data.push])
  const expandable =
    (push.total.sent > 0 || data.errors.size > 0) && (mode === 'period' || mode === 'category')
  const closeRow = React.useCallback(() => setExpanded(false), [])
  return (
    <React.Fragment>
      <TableRow style={{ backgroundColor: mode === 'category' ? '#F8F8FA' : '#FFF' }}>
        <TableCell>
          {mode === 'category' && <Icon icon="parent" style={{ marginRight: 10 }} size={14} />}
          {mode === 'period' && data.period.format('MMMM DD')}
          {mode === 'category' && capitalize(data.id.value)}
          {mode === 'transactional' && data.id.value}
          {mode === 'campaign' && (
            <a href={`/${companyId ?? ''}/apps/${appId}/campaigns/push/${data.id.value}/review`}>
              {data.id?.campaignInfo?.name ? data.id.campaignInfo.name : 'Unknown campaign'}
            </a>
          )}
        </TableCell>
        <Tooltip
          tooltip={
            push.total.sent > 0 && (
              <div style={{ textAlign: 'left' }}>
                <p>Opt-ins: {numberFormat(push.total.sentNotifOn)}</p>
                <p>
                  Opt-outs or imported: {numberFormat(push.total.sent - push.total.sentNotifOn)}
                </p>
              </div>
            )
          }
        >
          <TableCell align="right">{numberFormat(push.total.sent)}</TableCell>
        </Tooltip>
        <Tooltip
          tooltip={
            push.total.open > 0 && (
              <div style={{ textAlign: 'left' }}>
                <p>Direct: {numberFormat(push.total.open)}</p>
                <p>Influenced: {numberFormat(push.total.influencedOpen)}</p>
              </div>
            )
          }
        >
          <TableCell align="right">
            {numberFormat(push.total.openAlg)}{' '}
            <span className="expandable__rate">{percentage(push.total.openRate, 2)}</span>
          </TableCell>
        </Tooltip>

        <TableCell align="right">{numberFormat(push.total.reengaged)}</TableCell>
        <Tooltip
          tooltip={
            data.errors.size > 0 && (
              <div style={{ textAlign: 'left' }}>
                {Immutable.List()
                  .push(...data.errors)
                  .map(([err, count]: [any, any]) => (
                    <p key={err}>
                      {err}: {numberFormat(count)}
                    </p>
                  ))}
              </div>
            )
          }
        >
          <TableCell align="right">
            {numberFormat(data.errors.reduce((acc, current) => current + acc, 0))}
          </TableCell>
        </Tooltip>

        {expandable && (
          <TableCellActions>
            <Button isLoading={loading} kind="inline" onClick={!expanded ? openRow : closeRow}>
              <Icon icon={expanded ? 'chevron-up' : 'chevron-down'} />
            </Button>
          </TableCellActions>
        )}
      </TableRow>
      {expanded &&
        byCat.map((d: DayDataRecord, i) => (
          <NotificationRow
            key={i}
            data={d}
            appId={appId}
            companyId={companyId}
            loaded={loaded}
            mode={
              mode === 'period' ? 'category' : d.id.kind === 'token' ? 'campaign' : 'transactional'
            }
            all={all}
          />
        ))}
    </React.Fragment>
  )
}
