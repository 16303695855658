import * as React from 'react'

import { Icon } from 'components/common/svg-icon'
import { Tooltip } from 'com.batch/shared/ui/component/tooltip'

import { percentage } from 'com.batch.common/utils'

import { type OrchestrationAnalyticsDateRangeRecord } from 'com.batch/orchestration-analytics/store/orchestration-analytics.state'

import { TrendContainer } from 'com.batch/shared/ui/component/trend/trend.styles'

type TrendTooltipProps = {
  metricContent: React.ReactNode
  dateRange: OrchestrationAnalyticsDateRangeRecord
}
export const TrendTooltip = ({
  metricContent,
  dateRange,
}: TrendTooltipProps): React.ReactElement => {
  const { from, to, diff } = React.useMemo(
    () => ({
      from: dateRange.from,
      to: dateRange.to,
      diff: dateRange.to.diff(dateRange.from, 'day') + 1,
    }),
    [dateRange]
  )

  return (
    <div style={{ textAlign: 'left', whiteSpace: 'nowrap' }}>
      <p>Compared to the previous period</p>
      <p>
        {from.subtract(diff, 'day').format('DD/MM/YYYY')} -{' '}
        {to.subtract(diff, 'day').format('DD/MM/YYYY')}: {metricContent}
      </p>
    </div>
  )
}

type TrendProps = {
  trend: number
  isGrowthUnhealthy?: boolean
  tooltip?: React.ReactNode
  showTrendValue?: boolean
}
export const Trend = ({
  trend,
  isGrowthUnhealthy,
  tooltip,
  showTrendValue = true,
}: TrendProps): React.ReactElement => {
  const color = React.useMemo(() => {
    switch (true) {
      case trend > 0:
        return isGrowthUnhealthy ? 'danger' : 'success'
      case trend < 0:
        return isGrowthUnhealthy ? 'success' : 'danger'
      default:
        return 'neutral'
    }
  }, [isGrowthUnhealthy, trend])
  const icon = React.useMemo(() => (trend >= 0 ? 'trending-up' : 'trending-down'), [trend])

  const formattedRate = React.useMemo(() => {
    if (trend === Infinity) return '∞%'
    else if (Math.abs(trend) < 0.001 && trend > 0) return '<0.1%'
    else if (trend > 9.99) return '999+%'
    return percentage(trend)
  }, [trend])

  return (
    <Tooltip tooltip={tooltip} placement="bottom" style={{ overflow: 'visible' }}>
      <TrendContainer $color={color}>
        <Icon icon={icon} size={14} />
        {showTrendValue && <span>{formattedRate}</span>}
      </TrendContainer>
    </Tooltip>
  )
}
