import styled from 'styled-components'
import { schemes, colors, texts } from 'components/styled/tokens'
import { Trigger } from '@radix-ui/react-tooltip'
import { animated } from '@react-spring/web'

export const TooltipTitle = styled.h6`
  margin: 0 0 4px 0;
  font: ${texts.label.font};
  color: ${colors.textContrast};
`
export const TooltipParagraph = styled.p`
  margin: 0;
  font: ${texts.base.font};
  color: ${colors.textContrast};
`
export const TooltipLegend = styled.p`
  margin: 0;
  font: ${texts.base.font};
  color: ${colors.textContrastLight};
`
export const TooltipSeparator = styled.div`
  width: 100%;
  height: 1px;
  margin: 8px 0 8px 0;
  background-color: ${colors.strokeContrast};
`

// / SPECIFIC TO RADIX
type TooltipContentProps = {
  $padding?: number
  $width?: number
}
export const TooltipContent = styled(animated.div)<TooltipContentProps>`
  z-index: 1;
  width: ${p => (p.$width ? `${p.$width}px` : 'fit-content')};
  padding: ${p => p.$padding}px;
  font: ${texts.base.font};
  border-radius: 8px;
  color: ${colors.textContrast};
  background-color: ${schemes.darklucent['90']};
  transform-origin: var(--radix-tooltip-content-transform-origin);
`

export const TooltipTrigger = styled(Trigger)`
  width: auto;
  /* width: fit-content; */
`
