import * as React from 'react'
import { useDispatch } from 'com.batch.common/react-redux'
import { ThemeContext } from 'styled-components'

import { Banner } from 'components/common/banner'
import {
  Box,
  BoxHeader,
  HeaderBoxTitle,
  BoxBody,
  BoxFooter,
  HeaderBoxActions,
} from 'components/common/box'
import { Button, PermissionButton } from 'components/common/button'
import { confirm } from 'components/common/confirm'
import { Grid } from 'components/common/grid'
import { UploadImage, InputWrapper } from 'components/form'
import { Empty, EmptyTitle, EmptyText } from 'components/styled/empty'
import { LinkDoc } from 'components/styled/text'

import { useIsCurrentUserAllowedTo } from 'components/_hooks'
import { type PushConfigRecord, type AppRecord } from 'com.batch.redux/_records'
import { setWebpushIcons } from 'com.batch.redux/app.action'

import { TYPE_ICON } from 'constants/push-settings/icons'
import { Tooltip } from 'com.batch/shared/ui/component/tooltip'

type SettingsWebPushIconsProps = {
  config: PushConfigRecord
  app: AppRecord
}

export const SettingsWebPushIcons: React.ComponentType<SettingsWebPushIconsProps> = React.memo(
  ({ config, app }: SettingsWebPushIconsProps) => {
    const dispatch = useDispatch()

    // ====================== Theme
    const theme = React.useContext(ThemeContext)

    // ====================== Components states
    const [defaultIcon, setDefaultIcon] = React.useState<string>(config.safariWebsiteIcon || '')
    const [smallIcon, setSmallIcon] = React.useState<string>(config.androidSmallIcon || '')
    const [isEditing, setIsEditing] = React.useState<boolean>(false)

    // ====================== Components constants
    const noIcons = !config.androidSmallIcon && !config.safariWebsiteIcon
    const safariSettingUpIsStarted =
      Boolean(config.safariWebsiteName) && config.safariWebsites.size !== 0

    // ====================== Callbacks

    const dispatchActionIcons = React.useCallback(async () => {
      try {
        await dispatch(
          setWebpushIcons({
            app,
            safariWebsiteIconUrl: defaultIcon ? defaultIcon : null,
            androidSmallIconUrl: smallIcon ? smallIcon : null,
          })
        )
        setIsEditing(false)
      } catch {
        setIsEditing(true)
      }
    }, [app, dispatch, defaultIcon, smallIcon])

    const submitIcons = React.useCallback(async () => {
      if ((config.safariWebsiteIcon ?? '') !== defaultIcon) {
        confirm({
          title: 'Confirm icon settings?',
          message: (
            <React.Fragment>
              On Safari, the default icon can only be set once per user, and future update will only
              apply to future subscribers.
            </React.Fragment>
          ),
          confirm: 'Yes, Confirm it',
        })
          .then(() => {
            dispatchActionIcons()
          })
          .catch(() => {})
      } else {
        dispatchActionIcons()
      }
    }, [config, defaultIcon, dispatchActionIcons])

    const isAllowed = useIsCurrentUserAllowedTo(['app', 'push:config:write'])

    const onSetEditing = React.useCallback(
      (bool: true) => () => {
        setIsEditing(bool)
      },
      [setIsEditing]
    )

    const onClickCancel = React.useCallback(() => {
      setDefaultIcon(config.safariWebsiteIcon)
      setSmallIcon(config.androidSmallIcon)
      setIsEditing(false)
    }, [config.safariWebsiteIcon, config.androidSmallIcon])

    const onUploadDefaultIcon = React.useCallback(
      (url: string) => setDefaultIcon(url),
      [setDefaultIcon]
    )
    const onRemoveDefaultIcon = React.useCallback(() => setDefaultIcon(''), [setDefaultIcon])

    const onUploadSmallIcon = React.useCallback((url: string) => setSmallIcon(url), [setSmallIcon])
    const onRemoveSmallIcon = React.useCallback(() => setSmallIcon(''), [setSmallIcon])

    // ====================== Render
    return (
      <React.Fragment>
        <Box>
          <BoxHeader>
            <HeaderBoxTitle title="Web push icons" />
            <HeaderBoxActions>
              <LinkDoc
                href="https://doc.batch.com/web/getting-started/prerequisites#icons-management"
                intent="action"
                target="_blank"
              >
                Help
              </LinkDoc>
            </HeaderBoxActions>
          </BoxHeader>
          <BoxBody>
            {(theme.isLoading || isEditing || !noIcons) && (
              <Grid margin={[20]} gap={22} template="minmax(100px, 1fr) minmax(100px, 1fr)">
                <InputWrapper
                  label="Default icon"
                  hintPlacement="right"
                  hint={
                    isEditing && (
                      <div style={{ width: 244, textAlign: 'left' }}>
                        Must be a 256x256pixels PNG image. Mandatory for Safari.
                      </div>
                    )
                  }
                  htmlFor="default-icon"
                >
                  <UploadImage
                    id="default-icon"
                    app={app}
                    isEditing={isEditing}
                    value={defaultIcon ?? ''}
                    message="For macOS, Windows, Android"
                    minImgSize={256}
                    maxImgSize={256}
                    uploadRecommendations="Required size of 256x256 pixels"
                    uploadFile={onUploadDefaultIcon}
                    removeFile={onRemoveDefaultIcon}
                    type={TYPE_ICON.DEFAULT}
                    allowedFiles={['image/png']}
                  />
                </InputWrapper>
                <InputWrapper
                  label="Small icon"
                  hintPlacement="right"
                  hint={
                    isEditing && (
                      <div style={{ width: 244, textAlign: 'left' }}>
                        Must be a 96x96pixels PNG image. It will ask as a mask, meaning that it will
                        only use the alpha channel of your image and color the resulting shape.
                      </div>
                    )
                  }
                  style={{ marginTop: 0 }}
                  htmlFor="small-icon"
                >
                  <UploadImage
                    id="small-icon"
                    app={app}
                    isEditing={isEditing}
                    value={smallIcon ?? ''}
                    message="For Android web push"
                    maxImgSize={96}
                    uploadRecommendations="Required size of 96x96 pixels"
                    uploadFile={onUploadSmallIcon}
                    removeFile={onRemoveSmallIcon}
                    type={TYPE_ICON.SMALL}
                    allowedFiles={['image/png']}
                  />
                </InputWrapper>
              </Grid>
            )}
            {noIcons && !isEditing && !theme.isLoading && (
              <Empty style={{ position: 'relative', height: 172 }}>
                <EmptyTitle>No icons saved</EmptyTitle>
                <EmptyText>Add a new icons to display</EmptyText>
              </Empty>
            )}
          </BoxBody>
          <BoxFooter isEditable={isEditing}>
            {isEditing ? (
              <React.Fragment>
                <PermissionButton kind="inline" onClick={onClickCancel} isAllowed={isAllowed}>
                  Cancel
                </PermissionButton>
                <Tooltip
                  tooltip={
                    (config.safariWebsiteIcon !== null && defaultIcon === '') ||
                    (config.androidSmallIcon !== null && smallIcon === '')
                      ? "You can't delete icons, only replace."
                      : undefined
                  }
                  placement="bottom"
                >
                  <PermissionButton
                    intent="action"
                    kind="primary"
                    disabled={
                      (defaultIcon === '' && smallIcon === '') ||
                      (config.safariWebsiteIcon !== null && defaultIcon === '') ||
                      (config.androidSmallIcon !== null && smallIcon === '')
                    }
                    onClick={submitIcons}
                    isAllowed={isAllowed}
                    notAllowedMessage="This feature requires higher permissions level"
                  >
                    Confirm icon set
                  </PermissionButton>
                </Tooltip>
              </React.Fragment>
            ) : (
              <Button type="button" kind="primary" intent="action" onClick={onSetEditing(true)}>
                {noIcons ? 'Configure icons' : 'Edit icon set'}
              </Button>
            )}
          </BoxFooter>
        </Box>
        {safariSettingUpIsStarted && !config.safariWebsiteIcon && (
          <Banner
            kind="global"
            intent="danger"
            title="A default icon is mandatory to complete Safari support"
          />
        )}
      </React.Fragment>
    )
  }
)
