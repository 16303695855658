/* eslint-disable react/jsx-no-bind */
import { type List } from 'immutable'
import * as React from 'react'
import { useSelector, useDispatch } from 'components/console/react-redux'
import { useLocation, useNavigate, NavLink } from 'react-router-dom'

import { Avatar } from 'components/common/avatar'
import { ButtonNavLink, ButtonLink } from 'components/common/button'
import { Grid } from 'components/common/grid'
import { Loader } from 'components/common/loader/loader'
import { Pager } from 'components/common/pager'
import { Icon } from 'components/common/svg-icon'
import {
  Table,
  TableHeader,
  TableCellHeader,
  TableBody,
  TableCell,
  TableFooter,
  TableRow,
} from 'components/common/table/index'
import { Tooltip } from 'com.batch/shared/ui/component/tooltip'
import { FilterSearch } from 'components/filter'

import { numberFormat } from 'com.batch.common/utils'

import { CompanyPicker } from '../company/company-picker'
import { Title, Subtitle } from '../console.style'
import { createProjectAppsSelector } from '../redux/app'
import { type State } from '../redux/console.records'
import { projectsSelector, setPage, setFilter } from '../redux/project'
import { type ProjectRecord } from 'com.batch.redux/project.records'

export const ProjectList = (): React.ReactElement => {
  // ====================== REDUX STATES
  const projects: List<ProjectRecord> = useSelector(projectsSelector)
  const count: number = useSelector((state: State) => state.project.count)
  const page: number = useSelector((state: State) => state.project.page)
  const nbPerPage: number = useSelector((state: State) => state.project.nbPerPage)
  const loading: boolean = useSelector((state: State) => state.project.loading)
  const filters = useSelector((state: State) => state.project.filters)
  const getAppsForProjectKey = useSelector(createProjectAppsSelector)
  // ====================== COMPONENT CONSTANTS
  const dispatch = useDispatch()
  const location = useLocation()
  const navigate = useNavigate()
  const params = React.useMemo(() => new URLSearchParams(location.search), [location.search])

  // ====================== CALLBACKS
  const onCompanyFilterChange = React.useCallback(
    c => {
      navigate({
        search: `?page=1&query=${encodeURIComponent(filters.query)}&companyId=${c ? c.id : ''}`,
      })
    },
    [filters.query, navigate]
  )
  const onQueryChange = React.useCallback(
    f =>
      navigate({
        search: `?page=1&query=${encodeURIComponent(f)}`,
      }),
    [navigate]
  )
  const onPageChange = React.useCallback(
    p =>
      navigate({
        search: `?page=${p}&query=${filters.query}&companyId=${filters.companyId ?? ''}`,
      }),
    [filters.companyId, filters.query, navigate]
  )

  // ====================== COMPONENT USE EFFECT
  React.useEffect(() => {
    const targetPage = params.has('page') ? parseInt(params.get('page') ?? '1') : 1
    const targetFilter = params.get('query') ?? ''
    const targetFilterCompany =
      params.has('companyId') && !!params.get('companyId')
        ? parseInt(params.get('companyId') ?? '')
        : null
    if (targetPage !== page) {
      dispatch(setPage(targetPage))
    }

    if (targetFilter !== filters.query || targetFilterCompany !== filters.companyId) {
      dispatch(setFilter(filters.set('query', targetFilter).set('companyId', targetFilterCompany)))
    }
  }, [location, dispatch, params, page, filters])

  // ====================== RENDER
  return (
    <React.Fragment>
      <Grid template="1fr 400px 210px">
        <Grid template="185px 1fr" alignItems="end">
          <Title>All projects</Title>
          <Subtitle>
            {numberFormat(count)} project{count > 1 ? 's' : ''}
          </Subtitle>
        </Grid>

        <CompanyPicker
          isClearable
          placeholder="Filter by company"
          companyId={filters.companyId}
          setCompany={onCompanyFilterChange}
        />
        <FilterSearch
          style={{ marginLeft: 2 }}
          placeholder="Filter by name or ID or project key..."
          value={filters.query}
          onChange={onQueryChange}
          expandable={false}
        />
      </Grid>
      <br />
      <Loader loading={loading} overlay>
        <Table template="90px 1fr 188px 240px">
          <TableHeader>
            <TableCellHeader>Id</TableCellHeader>
            <TableCellHeader>Name</TableCellHeader>
            <TableCellHeader>Channels</TableCellHeader>
            <TableCellHeader>Actions</TableCellHeader>
          </TableHeader>

          <TableBody>
            {projects.map(project => {
              const apps = getAppsForProjectKey(project.projectKey)
              const hasAtLeastOneCepChannel =
                project.emailConfigured || project.smsConfigured || project.anyPushConfigured
              return (
                <TableRow key={project.id}>
                  <TableCell>
                    <NavLink to={`/console/projects/${project.projectKey}`}>{project.id}</NavLink>
                  </TableCell>
                  <TableCell>
                    <Avatar
                      style={{ marginRight: 12 }}
                      url={project.iconUrl}
                      placeholder={project.name}
                      color={'#1c2e43'}
                    />
                    <NavLink to={`/console/projects/${project.projectKey}`}>{project.name}</NavLink>
                  </TableCell>
                  <TableCell>
                    {apps.map(app => (
                      <Tooltip key={app.id} tooltip={app.id + ' - ' + app.name}>
                        <ButtonNavLink to={`/console/apps/${app.id}`}>
                          <Icon icon={app.platform || 'add'} />
                        </ButtonNavLink>
                      </Tooltip>
                    ))}
                    {project.emailConfigured && (
                      <ButtonNavLink to={`/console/projects/${project.projectKey}`}>
                        <Icon icon="mail" />
                      </ButtonNavLink>
                    )}
                    {project.smsConfigured && (
                      <ButtonNavLink to={`/console/projects/${project.projectKey}`}>
                        <Icon icon="sms" />
                      </ButtonNavLink>
                    )}
                    {project.anyPushConfigured && (
                      <ButtonNavLink to={`/console/projects/${project.projectKey}`}>
                        <Icon icon="push" />
                      </ButtonNavLink>
                    )}
                  </TableCell>
                  <TableCell>
                    <ButtonNavLink
                      style={{ marginRight: 10 }}
                      kind="primary"
                      addOn="prefix"
                      intent="action"
                      to={`/console/projects/${project.projectKey}`}
                    >
                      <Icon icon="edit" />
                      Details
                    </ButtonNavLink>

                    <ButtonLink
                      href={
                        hasAtLeastOneCepChannel
                          ? `/${project.companyId}/projects/${project.id}/analytics`
                          : `/${project.companyId}/projects/${project.id}/apps/${apps.first()?.id}`
                      }
                      target="_blank"
                      kind="secondary"
                      intent="action"
                      addOn="suffix"
                    >
                      Dashboard
                      <Icon icon="external" />
                    </ButtonLink>
                  </TableCell>
                </TableRow>
              )
            })}
          </TableBody>
          <TableFooter>
            {projects.size > 0 && (
              <Pager
                total={count}
                page={page}
                nbPerPage={nbPerPage}
                // selectPage={p => dispatch(companyActions.setPage(p))}
                selectPage={onPageChange}
                style={{ margin: '20px 0 10px 0' }}
              />
            )}
          </TableFooter>
        </Table>

        <div style={{ textAlign: 'center', color: '#8f959e' }}>
          <small>
            listing {count} project{count > 0 ? 's' : ''}
          </small>
        </div>
      </Loader>
    </React.Fragment>
  )
}
