import Immutable, { type Map } from 'immutable'
import { createSelector } from 'reselect'

import { getVariantLabel } from 'com.batch.common/utils'

import { getPushContentError, getPushSettingsErrors } from './push.selector.helper'

import {
  getLangFromContent,
  type MessageIdGetterSelector,
  previewLanguageSelector,
} from 'com.batch/message/store/message.selector'
import { getMessageConfigSelector } from 'com.batch/orchestration/store/orchestration.composed.selectors'
import { type State } from 'com.batch.redux/_records'

import { getMessageIds, getMessageKey } from 'com.batch/message/models/message.helper'
import {
  PushMessageFactory,
  PushContentFactory,
  type PushMessageRecord,
  type PushContentRecord,
  type PushSettingsRecord,
} from 'com.batch/message/models/message.records'
import { templatingCacheSelector } from 'com.batch/templating/store/templating.selector'
import {
  getTemplatingFromCache,
  type TemplateMode,
} from 'com.batch/templating/models/templating.utils'

export const getPushMessageSelector =
  (state: State): ((arg1: string) => PushMessageRecord) =>
  messageId =>
    state.message.push.get(messageId, PushMessageFactory())

export const getPushSettingsSelector: (
  state: State
) => (arg1: { stepMessageNodeId: string | null | undefined }) => PushSettingsRecord =
  createSelector(
    getMessageConfigSelector,
    getMessageConfig =>
      ({ stepMessageNodeId }) =>
        getMessageConfig({ stepMessageNodeId }).pushSettings
  )

export const getPushContentSelector: MessageIdGetterSelector<Map<string, PushContentRecord>> =
  createSelector(
    getPushMessageSelector,
    getPushMessage => (messageId: string) =>
      getPushMessage(messageId).get('localizedContent', Immutable.Map())
  )

export const getPushContentForActiveLanguageSelector: (
  state: State
) => ({
  messageId,
  templateMode,
}: {
  messageId: string
  templateMode?: TemplateMode
}) => PushContentRecord = createSelector(
  getPushContentSelector,
  previewLanguageSelector,
  templatingCacheSelector,
  (getData, langId, templating) =>
    ({ messageId, templateMode = 'RAW' }) => {
      const data = getData(messageId)
      const pushContent = data.get(langId ?? 'default', PushContentFactory())
      if (templateMode === 'RAW') {
        return pushContent
      }
      return PushContentFactory({
        pushTitle: getTemplatingFromCache({
          cache: templating,
          messageId,
          langId,
          field: 'pushTitle',
          defaultValue: pushContent.pushTitle,
          enriched: templateMode === 'TEMPLATED_HTML',
        }),

        pushBody: getTemplatingFromCache({
          cache: templating,
          messageId,
          langId,
          field: 'pushBody',
          defaultValue: pushContent.pushBody,
          enriched: templateMode === 'TEMPLATED_HTML',
        }),

        pushPicture: getTemplatingFromCache({
          cache: templating,
          messageId,
          langId,
          field: 'pushPicture',
          defaultValue: pushContent.pushPicture,
        }),

        pushIcon: getTemplatingFromCache({
          cache: templating,
          messageId,
          langId,
          field: 'pushIcon',
          defaultValue: pushContent.pushIcon,
        }),

        attachmentKind: pushContent.attachmentKind,

        pushType: pushContent.pushType,

        deeplink: getTemplatingFromCache({
          cache: templating,
          messageId,
          langId,
          field: 'deeplink',
          defaultValue: pushContent.deeplink,
        }),

        androidDeeplink: getTemplatingFromCache({
          cache: templating,
          messageId,
          langId,
          field: 'androidDeeplink',
          defaultValue: pushContent.androidDeeplink,
        }),

        iosDeeplink: getTemplatingFromCache({
          cache: templating,
          messageId,
          langId,
          field: 'iosDeeplink',
          defaultValue: pushContent.iosDeeplink,
        }),

        webpushDeeplink: getTemplatingFromCache({
          cache: templating,
          messageId,
          langId,
          field: 'webpushDeeplink',
          defaultValue: pushContent.webpushDeeplink,
        }),

        iosVideo: getTemplatingFromCache({
          cache: templating,
          messageId,
          langId,
          field: 'iosVideo',
          defaultValue: pushContent.iosVideo,
        }),

        iosAudio: getTemplatingFromCache({
          cache: templating,
          messageId,
          langId,
          field: 'iosAudio',
          defaultValue: pushContent.iosAudio,
        }),
      })
    }
)

type getErrorsForMessage = (arg1: {
  stepMessageNodeId: string | null | undefined
}) => Map<string, Array<string>>
export const getPushErrorsSelector: (state: State) => getErrorsForMessage = createSelector(
  getPushMessageSelector,
  getMessageConfigSelector,
  (getMessage, getConfig) =>
    ({ stepMessageNodeId }) => {
      const config = getConfig({ stepMessageNodeId })
      const settings = config.pushSettings
      const abEnabled = config.experiment.enabled
      const messageTypedIdList = getMessageIds(config)
      const settingsErrors = getPushSettingsErrors(settings)
      const contentErrors: Array<[string, Array<string>]> = []
      messageTypedIdList.forEach((messageId, index) => {
        const message = getMessage(messageId)
        const localizedContent = message.get('localizedContent', Immutable.Map())
        const languages = getLangFromContent(localizedContent, config.multilanguageEnabled)
        languages.forEach(lang => {
          const contentForLang = localizedContent.get(lang.value, PushContentFactory())
          const localErrors = getPushContentError(contentForLang)
          if (localErrors.length > 0)
            contentErrors.push([
              getMessageKey(lang) + (abEnabled ? ` ${getVariantLabel(index)}` : ''),
              localErrors,
            ])
        })
      })
      const settingsErrorsArray: Array<[string, Array<string>]> =
        settingsErrors.length > 0 ? [['Settings', settingsErrors]] : []

      return Immutable.Map([...settingsErrorsArray, ...contentErrors])
    }
)
