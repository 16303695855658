import * as React from 'react'
import { useTheme } from 'styled-components'

import { Checkbox } from 'components/form'

import { SegmentLabel } from './styles'

import { type ClusterRecord } from 'com.batch.redux/_records'
import { Tooltip } from 'com.batch/shared/ui/component/tooltip'

type SegmentProps = {
  segment: ClusterRecord
  checked: boolean
  onChange: (arg1: boolean) => void
}

export const Segment = ({ checked, segment, onChange }: SegmentProps): React.ReactElement => {
  const theme = useTheme()
  const handleOnChange = React.useCallback(() => onChange(!checked), [checked, onChange])

  return (
    <Tooltip tooltip={segment.desc} width={244} offset={[0, 10]}>
      <SegmentLabel color={segment.color} checked={checked}>
        <Checkbox
          disabled={theme?.disabledMode}
          checked={checked}
          onChange={handleOnChange}
          activeColor={segment.color}
          ariaLabelledBy={`segment-${segment.code}`}
        />
        <span id={`segment-${segment.code}`}>{segment.name}</span>
      </SegmentLabel>
    </Tooltip>
  )
}
