import * as React from 'react'
import { useDispatch } from 'com.batch.common/react-redux'

import { useToggle } from 'components/_hooks/use-toggle'
import { useDropdown, Button, ButtonLink, DropdownMenu } from 'components/common/button'
import { confirm } from 'components/common/confirm'
import { ConfirmHighlight } from 'components/common/confirm.styles'
import { Icon } from 'components/common/svg-icon'
import { Tooltip } from 'com.batch/shared/ui/component/tooltip'
import { HorizontalDivider } from 'components/styled/utils'

import { pluralize } from 'com.batch.common/utils'

import { showToast } from 'com.batch.redux/toaster'

import { type SegmentRecord } from 'com.batch/segments/models/segment.records'
import { deleteSegment } from 'com.batch/segments/usecases/delete-segment'
import { getSegmentsExport } from 'com.batch/segments/usecases/get-segments-export'
import { useCurrentCompanyHasFeature } from 'components/_hooks'

export const DropdownSegment = ({
  segment,
  hasDeletePermission,
  runningOrchestrationCount,
  automationsUrl,
  campaignsUrl,
  userIsAdmin,
}: {
  segment: SegmentRecord
  hasDeletePermission: boolean
  automationsUrl: string
  campaignsUrl: string
  runningOrchestrationCount: number
  userIsAdmin: boolean
}): React.ReactElement => {
  const segmentExportFeature = useCurrentCompanyHasFeature('segment-export')
  const { triggerProps, dropdownProps, closeDropdown } = useDropdown({
    placement: 'left-start',
  })

  const loadingExportState = useToggle()
  const dispatch = useDispatch()

  const copySegmentName = React.useCallback(() => {
    navigator.clipboard.writeText(segment.name)
    dispatch(showToast({ kind: 'success', message: 'Segment code copied to clipboard' }))
    closeDropdown()
  }, [segment.name, dispatch, closeDropdown])
  const onDelete = React.useCallback(() => {
    closeDropdown()
    confirm({
      sensitive: true,
      message: (
        <div>
          <article style={{ marginBottom: '10px' }}>
            This will delete the segment <ConfirmHighlight>{segment.displayName}</ConfirmHighlight>.
            <br />
          </article>
          <article>
            It will continue to be linked to your finished orchestrations but you will not be able
            to use it anymore.
          </article>
        </div>
      ),
      title: 'Delete this segment?',
    }).then(
      () =>
        dispatch(deleteSegment(segment)).catch(e => {
          // temporary error handling
          console.log('err', e)
        }),
      () => {}
    )
  }, [dispatch, segment, closeDropdown])

  const exportProfilesAssociatedToSegment = React.useCallback(async () => {
    if (loadingExportState.value === true) {
      return
    }

    loadingExportState.toggle()
    dispatch(getSegmentsExport({ segment }))
      .then(() => {
        loadingExportState.toggle()
        closeDropdown()
      })
      .catch(() => {
        loadingExportState.toggle()
        showToast({ kind: 'error', message: 'An error occurred while exporting profiles' })
      })
  }, [loadingExportState, dispatch, segment, closeDropdown])

  return (
    <React.Fragment>
      <Button {...triggerProps}>
        <Icon icon="options" />
      </Button>
      <DropdownMenu {...dropdownProps}>
        {segment.automationCount > 0 && (
          <ButtonLink target="_blank" href={automationsUrl} style={{ display: 'flex' }}>
            <Icon icon="automation" style={{ marginRight: 8 }} />
            View {pluralize('automation', segment.automationCount)}
          </ButtonLink>
        )}
        {segment.campaignCount > 0 && (
          <ButtonLink target="_blank" href={campaignsUrl} style={{ display: 'flex' }}>
            <Icon icon="push" style={{ marginRight: 8 }} />
            View {pluralize('campaign', segment.campaignCount)}
          </ButtonLink>
        )}
        {userIsAdmin && segmentExportFeature && (
          <Tooltip
            placement="left"
            width={173}
            tooltip="The export will be emailed to you when it’s complete"
          >
            <Button
              onClick={exportProfilesAssociatedToSegment}
              style={{ display: 'flex' }}
              disabled={loadingExportState.value === true}
            >
              <Icon
                icon={loadingExportState.value ? 'spinner' : 'download'}
                style={{ marginRight: 8 }}
              />
              Export profiles
            </Button>
          </Tooltip>
        )}
        <Button onClick={copySegmentName} style={{ display: 'flex' }}>
          <Icon icon="copy" style={{ marginRight: 8 }} />
          Copy segment code
        </Button>
        <HorizontalDivider style={{ marginBottom: 4 }} />
        <Tooltip
          tooltip={
            runningOrchestrationCount !== 0 &&
            'Your segment is currently used in running orchestration(s).'
          }
          placement="bottom"
        >
          <div>
            <Button
              onClick={onDelete}
              style={{ display: 'flex' }}
              intent="danger"
              disabled={!hasDeletePermission || runningOrchestrationCount > 0}
            >
              <Icon icon="delete" style={{ marginRight: 8 }} />
              Delete
            </Button>
          </div>
        </Tooltip>
      </DropdownMenu>
    </React.Fragment>
  )
}
