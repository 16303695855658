import * as React from 'react'
import { useDispatch } from 'com.batch.common/react-redux'

import { Button } from 'components/common/button'
import { confirm } from 'components/common/confirm'
import { Tooltip } from 'com.batch/shared/ui/component/tooltip'

import { dayjs } from 'com.batch.common/dayjs.custom'
import { capitalize } from 'com.batch.common/utils'

import { getConfirmWordings } from './confirm-wording'

import { type PartialOrchestrationRecord } from 'com.batch/orchestration-list/models/partial-orchestration.records'
import { changeOrchestrationState } from 'com.batch/orchestration-list/usecases/change-orchestration-state'
import { OrchestrationState } from 'constants/common'

type OrchestrationMenuChangeStateProps = {
  partialOrchestration: PartialOrchestrationRecord
  closeDropdown: () => void
}

export const OrchestrationMenuChangeState = ({
  partialOrchestration,
  closeDropdown,
}: OrchestrationMenuChangeStateProps): React.ReactElement => {
  const dispatch = useDispatch()
  const start = partialOrchestration.oneTime?.sendDate ?? partialOrchestration.recurring?.start
  const wordings = React.useMemo(() => {
    return getConfirmWordings({ partialOrchestration })
  }, [partialOrchestration])

  const campaignWord = React.useMemo(() => {
    return !partialOrchestration.oneTime ? 'automation' : 'campaign'
  }, [partialOrchestration.oneTime])

  const stateRunWord = React.useMemo(
    () => (!partialOrchestration.oneTime ? 'launch' : 'run'),
    [partialOrchestration.oneTime]
  )

  const stopConfirmModal = React.useCallback(() => {
    closeDropdown()
    confirm({
      ...wordings.stop,
      sensitive: true,
    })
      .then(() => {
        dispatch(
          changeOrchestrationState(partialOrchestration.token, OrchestrationState.STOPPED)
        ).catch(() => {})
      })
      .catch(() => {})
  }, [closeDropdown, wordings.stop, dispatch, partialOrchestration.token])

  const runConfirmModal = React.useCallback(() => {
    closeDropdown()
    confirm({ ...wordings.run })
      .then(() => {
        dispatch(
          changeOrchestrationState(partialOrchestration.token, OrchestrationState.RUNNING)
        ).catch(() => {})
      })
      .catch(() => {})
  }, [closeDropdown, wordings.run, dispatch, partialOrchestration.token])
  return (
    <React.Fragment>
      {partialOrchestration.state === OrchestrationState.RUNNING && (
        <Button onClick={stopConfirmModal}>Stop {campaignWord}</Button>
      )}
      {(partialOrchestration.state === OrchestrationState.STOPPED ||
        partialOrchestration.state === OrchestrationState.DRAFT) && (
        <Tooltip
          placement="left"
          tooltip={partialOrchestration.incomplete && `The ${campaignWord} is incomplete`}
        >
          <div>
            <Button
              onClick={runConfirmModal}
              disabled={Boolean(
                partialOrchestration.incomplete ||
                  (partialOrchestration.state === OrchestrationState.DRAFT &&
                    start &&
                    start.isBefore(dayjs.utc()))
              )}
            >
              {capitalize(stateRunWord)} {campaignWord}
            </Button>
          </div>
        </Tooltip>
      )}
    </React.Fragment>
  )
}
