import * as React from 'react'
import { getConsent } from 'components/common/page-tracker'

export const useHasMarketingCookies = (): boolean => {
  const [hasMarketingCookies, setHasMarketingCookies] = React.useState<any>(null)

  React.useEffect(() => {
    if (hasMarketingCookies === null) {
      getConsent()
        .then(r => setHasMarketingCookies(r.includes('MARKETING')))
        .catch(() => setHasMarketingCookies(false))
    }
  }, [hasMarketingCookies])

  return hasMarketingCookies
}
