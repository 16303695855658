/* eslint-disable react/jsx-no-bind */
import Immutable, { type Map } from 'immutable'
import * as React from 'react'
import { useDispatch, useSelector } from 'components/console/react-redux'
import { Link, useNavigate } from 'react-router-dom'
import { ThemeProvider } from 'styled-components'

import { Dropdown, Button, useBlurOnClickCallback } from 'components/common/button'
import { confirm } from 'components/common/confirm'
import { FlexLine, FlexLineItem } from 'components/common/flexline'
import { Grid } from 'components/common/grid'
import { Hint } from 'com.batch/shared/ui/component/hint'
import { Icon } from 'components/common/svg-icon'
import { Table, TableBody } from 'components/common/table/index'
import { Tooltip } from 'com.batch/shared/ui/component/tooltip'
import { EntityLog } from 'components/console/entity-log'
import {
  Copy,
  Form,
  InputWrapper,
  Input,
  FormActions,
  Checkbox,
  SelectMulti,
} from 'components/form'
import * as colorsLegacy from 'components/styled/colors'
import { schemes, colors } from 'components/styled/tokens'

import { generateUrl } from 'com.batch.common/router'

import { CompanyPermission } from './company-permission'
import { CompanyPermissionPopin } from './company-permission-popin'
import { deleteConfirm } from './delete-confirm'
import { OnboardingCircle } from './onboarding-circle'

import {
  NoCompany,
  Subtitle,
  Title,
  Section,
  SectionTitle,
  DataBoxContainer,
  DataBox,
} from '../console.style'
import { type State } from '../redux/console.records'
import { persistUser, disable2FA, deleteUser } from '../redux/user'
import { CompanyFactory, type CompanyRecord } from 'com.batch.redux/_records'
import { languages, regions } from 'com.batch.redux/lang-region'
import {
  CompanyUserPermissionsFactory,
  type UserRecord,
  type CompanyUserPermissionsRecord,
} from 'com.batch.redux/user.records'

type UserFormProps = {
  user: UserRecord
}

const companiesSelector = (state: State) => state.company.entities
export const UserForm = ({ user }: UserFormProps): React.ReactElement => {
  const companies = useSelector(companiesSelector)
  const dispatch = useDispatch()
  const [loading, setLoading] = React.useState<boolean>(false)
  const [sendMail, setSendMail] = React.useState<boolean>(true)
  const [data, setData] = React.useState<UserRecord>(user)
  const [newCompanyOpened, setNewCompanyOpened] = React.useState<boolean>(false)
  const [errors, setErrors] = React.useState<Map<string, string>>(Immutable.Map())
  const navigate = useNavigate()
  // refresh on new data
  React.useEffect(() => {
    setData(user)
  }, [user])

  const onSubmit = () => {
    const err: [string, string][] = []
    if (!data.email) err.push(['email', 'Please enter a valid email address.'])
    if (data.roles.size > 0 && data.email.indexOf('@batch.com') === -1)
      err.push(['email', 'Only @batch.com account can have console access.'])
    if (data.companiesPermissions.size !== 1) err.push(['companies', 'You need to pick a company.'])
    if (!data.lastName) err.push(['lastName', 'Enter a name for this user.'])
    if (!data.firstName)
      err.push(['firstName', 'Enter a firstname (le prénom, Cédric) for this user.'])
    if (err.length === 0) {
      setLoading(true)
      setErrors(Immutable.Map())
      dispatch(persistUser(data, sendMail)).then(
        updatedUser => {
          if (!user.id && !!updatedUser.id) {
            navigate(`/console/users/${updatedUser.id}`)
          } else {
            setLoading(false)
          }
        },
        err => {
          setErrors(Immutable.Map(err.map(e => [e.field, e.message])))
          setLoading(false)
        }
      )
    } else {
      setErrors(Immutable.Map(err))
    }
  }

  const onDelete = () => {
    deleteConfirm(user).then(
      () => {
        dispatch(deleteUser(user)).then(
          () => {
            window.location.href = '/console/users'
          },
          () => alert('unable to delete')
        )
      },
      () => {}
    )
  }

  const onDisable2FA = () => {
    confirm({
      confirm: 'Yes, this is a legit and verified request',
      title: 'Are you sure you want to disable 2FA for this user',
      sensitive: true,
      message: (
        <React.Fragment>
          <p>Disabling 2FA requires a proof of the user identity.</p>
          <p>
            Did you receive a copy of an ID proof copy for{' '}
            <strong>
              {user.firstName} {user.lastName}
            </strong>{' '}
            ?
          </p>
        </React.Fragment>
      ),
    }).then(
      () => dispatch(disable2FA(user)),
      () => {}
    )
  }

  const confirmToken = user.tokens.get('register-confirm')
  const confirmUrl = confirmToken
    ? generateUrl('register_confirm', { confirmationToken: confirmToken })
    : ''

  const inviteToken = user.tokens.get('company-invite')
  const inviteUrl = inviteToken
    ? generateUrl('register_join', { confirmationToken: inviteToken })
    : ''

  const passwordResetToken = user.tokens.get('password-reset')
  const passwordResetUrl = passwordResetToken
    ? generateUrl('password_reset_confirm', { token: passwordResetToken })
    : ''

  const tokenUrls = [
    [confirmUrl, 'This user needs to confirm his account by clicking this link.'],
    [inviteUrl, 'This user needs to confirm his invitation by clicking this link.'],
    [passwordResetUrl, 'This user needs to reset his password by clicking this link.'],
  ].filter(([url]: [any]) => !!url)

  const openCompanyOnblur = useBlurOnClickCallback(() => {
    setNewCompanyOpened(true)
  }, [])

  return (
    <ThemeProvider theme={{ kind: 'capture' }}>
      {newCompanyOpened && (
        <CompanyPermissionPopin
          company={null}
          permissions={CompanyUserPermissionsFactory()}
          updatePermissions={(
            company: CompanyRecord,
            permissions: CompanyUserPermissionsRecord
          ) => {
            setData(
              data.set(
                'companiesPermissions',
                data.companiesPermissions.set(company.id, permissions)
              )
            )
          }}
          user={user}
          opened={true}
          close={() => setNewCompanyOpened(false)}
        />
      )}
      <FlexLine
        style={{ alignItems: 'baseline', borderBottom: '1px solid #e9e9e9', paddingBottom: 15 }}
      >
        <FlexLineItem>
          <Link to="/console/users">
            <Title>Users</Title>
          </Link>
        </FlexLineItem>
        <FlexLineItem style={{ paddingBottom: 2 }}>
          <Icon icon="chevron-right" color={colors.textLight} />
        </FlexLineItem>
        <FlexLineItem>
          <Subtitle>{user.id ? user.id : 'Create'}</Subtitle>
        </FlexLineItem>
        {!!user.id && (
          <FlexLineItem>
            <OnboardingCircle step={user.onboardingStep} />
          </FlexLineItem>
        )}
        <FlexLineItem grow={1}>&nbsp;</FlexLineItem>
        <FlexLineItem>
          <Dropdown label="View">
            <Button
              onClick={() => {
                window.open(
                  `https://app.intercom.io/apps/ekaza78m/users/show?email=${user.email}`,
                  '_blank'
                )
              }}
            >
              View on Intercom
            </Button>
            {data.companiesPermissions.keySeq().map((companyId: number) => {
              return (
                <Button
                  key={companyId}
                  onClick={() => {
                    window.open(`/console/users?companyId=${companyId}`, '_blank')
                  }}
                >
                  Users from {companies.get(companyId, CompanyFactory()).name}
                </Button>
              )
            })}
          </Dropdown>
        </FlexLineItem>
        <FlexLineItem>
          <Dropdown label="Action">
            <Button disabled={!user.securedBy2FA} onClick={onDisable2FA}>
              Disable 2FA
            </Button>
            <Button intent="danger" onClick={onDelete}>
              Delete user
            </Button>
          </Dropdown>
        </FlexLineItem>
      </FlexLine>
      <FlexLine sameHeight>
        <FlexLineItem grow={1}>
          <Section>
            <SectionTitle>Edit informations</SectionTitle>
            {tokenUrls.map(([url, message]: [any, any]) => (
              <React.Fragment key={url}>
                <div
                  style={{
                    background: '#f28c4f',
                    color: '#fff',
                    lineHeight: 1.6,
                    marginBottom: 15,
                    padding: 15,
                    borderRadius: 5,
                  }}
                >
                  <p style={{ marginBottom: 6, marginTop: -6 }}>{message}</p>
                  <Copy value={new URL(url, document.location.origin).toString()} />
                </div>
              </React.Fragment>
            ))}
            <Form onSubmit={onSubmit}>
              <InputWrapper label="Console access">
                <span>
                  <Checkbox
                    label="Admin"
                    checked={data.roles.includes('ROLE_ADMIN')}
                    onChange={() =>
                      setData(
                        data.set(
                          'roles',
                          data.roles.size === 1 && !data.roles.includes('ROLE_ADMIN_STATS')
                            ? Immutable.OrderedSet()
                            : Immutable.OrderedSet(['ROLE_ADMIN'])
                        )
                      )
                    }
                  />
                </span>
                &nbsp;&nbsp;&nbsp;&nbsp;
                <Checkbox
                  label="Stats page for TV"
                  checked={data.roles.includes('ROLE_ADMIN_STATS')}
                  onChange={() =>
                    setData(
                      data.set(
                        'roles',
                        data.roles.size === 1 && !data.roles.includes('ROLE_ADMIN')
                          ? Immutable.OrderedSet()
                          : Immutable.OrderedSet(['ROLE_ADMIN_STATS'])
                      )
                    )
                  }
                />
              </InputWrapper>
              <InputWrapper label="Email Address" htmlFor="email" feedback={errors.get('email')}>
                <Input
                  id="email"
                  type="email"
                  placeholder="Email"
                  invalid={errors.has('email')}
                  value={data.email}
                  onChange={evt => setData(data.set('email', evt.target.value))}
                />
              </InputWrapper>
              <InputWrapper>
                <Grid template="1fr 1fr" alignItems="start">
                  <div>
                    <InputWrapper
                      label="First name"
                      htmlFor="first"
                      feedback={errors.get('firstname')}
                    >
                      <Input
                        id="first"
                        placeholder="Firstname"
                        invalid={errors.has('firstname')}
                        value={data.firstName}
                        onChange={evt => setData(data.set('firstName', evt.target.value))}
                      />
                    </InputWrapper>
                  </div>
                  <div>
                    <InputWrapper label="Name" htmlFor="name" feedback={errors.get('name')}>
                      <Input
                        id="name"
                        placeholder="Name"
                        invalid={errors.has('name')}
                        value={data.lastName}
                        onChange={evt => setData(data.set('lastName', evt.target.value))}
                      />
                    </InputWrapper>
                  </div>
                </Grid>
              </InputWrapper>
              <InputWrapper
                label="External ID"
                htmlFor="external-id"
                hint="The External ID is sent to the various Segment integrations (e.g. Intercom) instead of the classic ID."
                hintMinSize={200}
              >
                <Copy value={data.externalId} />
              </InputWrapper>
              <InputWrapper
                feedback={errors.get('companies')}
                label={
                  <FlexLine grow>
                    <FlexLineItem grow={1}>Companies</FlexLineItem>
                    <FlexLineItem>
                      <Button
                        kind="inline"
                        addOn="suffix"
                        style={{ height: 28 }}
                        type="button"
                        disabled={data.companiesPermissions.size > 0}
                        onClick={openCompanyOnblur}
                      >
                        Add a company
                        <Icon icon="add" />
                      </Button>
                    </FlexLineItem>
                  </FlexLine>
                }
              >
                {data.companiesPermissions.size === 0 ? (
                  <NoCompany>
                    <div>You need to pick a company.</div>
                  </NoCompany>
                ) : (
                  <Table
                    template="repeat(3, 1fr) 99px"
                    style={{ borderTop: `1px solid ${colors.stroke}` }}
                  >
                    <TableBody>
                      {data.companiesPermissions.keySeq().map((companyId: number) => {
                        return (
                          <CompanyPermission
                            key={companyId}
                            user={data}
                            companies={companies}
                            companyId={companyId}
                            updateUser={setData}
                          />
                        )
                      })}
                    </TableBody>
                  </Table>
                )}
              </InputWrapper>
              <InputWrapper
                label="Restricted languages"
                htmlFor="restrictedLanguages"
                hint="User with a restricted language will not be able to view campaign targeting an other language,
                  unless it is a draft campaign with no language targeting.
                  When creating or updating a campaign, he will have to pick one of his restricted languages."
                hintMinSize={300}
              >
                <SelectMulti
                  id="restrictedLanguages"
                  onChange={opts =>
                    setData(
                      data.set(
                        'restrictedLanguages',
                        opts ? opts.map(opt => opt.value).toSet() : Immutable.Set()
                      )
                    )
                  }
                  optionToString={lang => lang?.label ?? ''}
                  options={languages}
                  value={languages.filter(language => data.restrictedLanguages.has(language.value))}
                  isClearable
                />
              </InputWrapper>
              <InputWrapper
                label="Restricted regions"
                htmlFor="restrictedRegions"
                hint="User with a restricted region will not be able to view campaign targeting an other region,
                  unless it is a draft campaign with no region targeting.
                  When creating or updating a campaign, he will have to pick one of his restricted regions."
                hintMinSize={300}
              >
                <SelectMulti
                  id="restrictedRegions"
                  onChange={opts =>
                    setData(
                      data.set(
                        'restrictedRegions',
                        opts ? opts.map(opt => opt.value).toSet() : Immutable.Set()
                      )
                    )
                  }
                  options={regions}
                  optionToString={reg => reg?.label ?? ''}
                  value={regions.filter(regions => data.restrictedRegions.has(regions.value))}
                  isClearable
                />
              </InputWrapper>
              {!user.id && (
                <InputWrapper>
                  <Checkbox
                    label={
                      <React.Fragment>
                        Send an invitation email{' '}
                        <Hint style={{ padding: 6 }}>
                          If you don't select this option, you'll have to provide the invitation
                          link to the user yourself.
                        </Hint>
                      </React.Fragment>
                    }
                    checked={sendMail}
                    onChange={() => setSendMail(!sendMail)}
                  />
                </InputWrapper>
              )}
              <FormActions style={{ marginTop: 24 }}>
                <Button
                  kind="primary"
                  type="submit"
                  disabled={user === data || !data.firstName || !data.lastName}
                  intent="action"
                  isLoading={loading}
                >
                  {user.id ? 'Update' : 'Create'}
                </Button>
              </FormActions>
            </Form>
          </Section>
        </FlexLineItem>
        {!user.id ? (
          <FlexLineItem width={500}>&nbsp;</FlexLineItem>
        ) : (
          <FlexLineItem
            width={500}
            style={{
              backgroundColor: schemes.grayscale['01'],
              borderLeft: `1px solid ${colors.stroke}`,
              borderRight: `1px solid ${colors.stroke}`,
            }}
          >
            <DataBoxContainer>
              <DataBox spacing="18px">
                <label>2FA</label>
                {user.securedBy2FA ? 'Enabled' : 'Disabled'}
                <Icon
                  style={{ marginLeft: '5px' }}
                  size={12}
                  icon={user.securedBy2FA ? 'lock' : 'unlock'}
                  color={user.securedBy2FA ? colorsLegacy.good.shade.s4 : colors.textDanger}
                />
              </DataBox>
              <Tooltip
                placement="bottom"
                tooltip={user.createdAt ? user.createdAt.format('DD/MM/YY hh:mm:ss [UTC]Z') : 'N/A'}
              >
                <DataBox spacing="18px">
                  <label>Registered&nbsp;on</label>
                  {user.createdAt ? user.createdAt.format('DD/MM/YY') : 'N/A'}
                </DataBox>
              </Tooltip>
              <Tooltip
                placement="bottom"
                tooltip={
                  user.lastLogin ? user.lastLogin.format('DD/MM/YY hh:mm:ss [UTC]Z') : 'Never'
                }
              >
                <DataBox spacing="18px">
                  <label>Last&nbsp;login</label>
                  {user.lastLogin ? user.lastLogin.format('DD/MM/YY') : 'Never'}
                </DataBox>
              </Tooltip>
              <Tooltip
                placement="bottom"
                tooltip={
                  user.lastAccess ? user.lastAccess.format('DD/MM/YY hh:mm:ss [UTC]Z') : 'Never'
                }
              >
                <DataBox spacing="18px">
                  <label>Last&nbsp;access</label>
                  {user.lastAccess ? user.lastAccess.format('DD/MM/YY') : 'Never'}
                </DataBox>
              </Tooltip>
            </DataBoxContainer>

            <EntityLog logs={user.logs} />
          </FlexLineItem>
        )}
      </FlexLine>
    </ThemeProvider>
  )
}
