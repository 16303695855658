import { type DispatchBoundFn, type ReduxAction } from 'com.batch.redux/_records'
import { type TemplatingRecord } from 'com.batch/templating/models/templating.records'
import { buildTemplatingCachekey } from 'com.batch/templating/models/templating.utils'
import { type TEMPLATING_FETCH_SUCCESS_ACTION } from 'com.batch/templating/usecases/fetch-templating'
import Immutable from 'immutable'

export type AddLanguageAction = ReduxAction<
  'ADD_LANGUAGE',
  {
    messageId: string
    lang: string
    channel: ChannelUntilCleanup
  }
>
export const addLanguage =
  (payload: {
    messageId: string
    lang: string
    channel: ChannelUntilCleanup
  }): DispatchBoundFn<void> =>
  (dispatch, getState) => {
    const state = getState()
    const templatingCache = state.messageTemplating.entities
    const langId = 'default'
    const messageId = payload.messageId
    dispatch<AddLanguageAction>({ type: 'ADD_LANGUAGE', payload })
    /* when we add a language, we copy data from default language : 
        - push : attachementURl
        - sms: smsMessage
        - email: subject, html & replyTo
      so when there are templating, we need to also copy this
    */
    const templatingKeysToDuplicate = [
      buildTemplatingCachekey({
        messageId: messageId,
        langId,
        field: 'pushPicture',
      }),
      buildTemplatingCachekey({
        messageId: messageId,
        langId,
        field: 'pushIcon',
      }),
      buildTemplatingCachekey({
        messageId: messageId,
        langId,
        field: 'smsMessage',
      }),
      buildTemplatingCachekey({
        messageId: messageId,
        langId,
        field: 'subject',
      }),
      buildTemplatingCachekey({
        messageId: messageId,
        langId,
        field: 'replyTo',
      }),
      buildTemplatingCachekey({
        messageId: messageId,
        langId,
        field: 'html',
      }),
    ]
    const duplicatedCacheMap = templatingKeysToDuplicate.reduce((acc, key) => {
      const templating = templatingCache.get(key)
      if (templating) {
        acc = acc.set(key.replace('default', payload.lang), templating)
      }
      return acc
    }, Immutable.Map<string, TemplatingRecord>())
    if (duplicatedCacheMap.size > 0)
      dispatch<TEMPLATING_FETCH_SUCCESS_ACTION>({
        type: 'TEMPLATING_FETCH_SUCCESS',
        payload: duplicatedCacheMap,
      })
  }

export type RemoveLanguageAction = ReduxAction<
  'REMOVE_LANGUAGE',
  {
    messageId: string
    lang: string
    channel: ChannelUntilCleanup
  }
>
export const removeLanguage = (payload: {
  messageId: string
  lang: string
  channel: ChannelUntilCleanup
}): RemoveLanguageAction => {
  return { type: 'REMOVE_LANGUAGE', payload }
}

export type ClearLanguagesAction = ReduxAction<
  'CLEAR_LANGUAGES',
  {
    stepMessageNodeId: string | null | undefined
  }
>
export const clearLanguages = (payload: {
  stepMessageNodeId: string | null | undefined
}): ClearLanguagesAction => {
  return { type: 'CLEAR_LANGUAGES', payload }
}

export type SetActiveLanguageAction = ReduxAction<'SET_ACTIVE_LANGUAGE', string>
export const setActiveLanguage = (lang: string): SetActiveLanguageAction => {
  return { type: 'SET_ACTIVE_LANGUAGE', payload: lang }
}

type ActivateMultiLanguagePayload = {
  stepMessageNodeId: string | null | undefined
}
export type ActivateMultiLanguageAction = ReduxAction<
  'ACTIVATE_MULTILANGUAGE',
  ActivateMultiLanguagePayload
>
export const activateMultiLanguage = ({
  stepMessageNodeId,
}: {
  stepMessageNodeId: string | null | undefined
}): ActivateMultiLanguageAction => {
  return {
    type: 'ACTIVATE_MULTILANGUAGE',
    payload: { stepMessageNodeId },
  }
}

type DeactivateMultiLanguagePayload = {
  stepMessageNodeId: string | null | undefined
}
export type DeactivateMultiLanguageAction = ReduxAction<
  'DEACTIVATE_MULTILANGUAGE',
  DeactivateMultiLanguagePayload
>
export const deactivateMultiLanguage = ({
  stepMessageNodeId,
}: {
  stepMessageNodeId: string | null | undefined
}): DeactivateMultiLanguageAction => {
  return {
    type: 'DEACTIVATE_MULTILANGUAGE',
    payload: { stepMessageNodeId },
  }
}
