import * as React from 'react'
import { useTheme } from 'styled-components'

import { useClickOutsideOnExistingRefs, usePopper } from 'components/_hooks'
import { Button } from 'components/common/button'
import { type availableIcons, Icon } from 'components/common/svg-icon'
import { Tooltip } from 'com.batch/shared/ui/component/tooltip'

import {
  LayerBarButtonAdd,
  LayerBarButtonContainer,
  LayerBarDropdownOptions,
} from './layerbar.styles'

interface LayerBarButtonType {
  label: string
  icon: React.ReactNode
  required?: boolean
  addable?: boolean
  $rightAdd?: number
  active?: boolean
  onClick?: () => void
  embedded?: boolean
}

export const LayerBarButton = React.forwardRef<HTMLButtonElement, LayerBarButtonType>(
  (props: LayerBarButtonType, ref) => (
    <LayerBarButtonContainer
      embedded={!!props.embedded}
      active={props.active}
      addable={props.addable}
      $rightAdd={props.$rightAdd}
      onClick={props.onClick}
      ref={ref}
    >
      {props.addable && <LayerBarButtonAdd />}
      {props.icon}
      <span>
        {props.label}
        {props.required && <Icon icon="required" />}
      </span>
    </LayerBarButtonContainer>
  )
)

type LayerBarDropdownOptionType = {
  label: string
  prefix?: availableIcons
  action: () => any
  disabled?: boolean
  loading?: boolean
  active?: boolean
  isDefault?: boolean
}
interface LayerBarDropdownType extends LayerBarButtonType {
  options: LayerBarDropdownOptionType[]
  defaultOption?: LayerBarDropdownOptionType
}

const LayerBarDropdownOption: React.ComponentType<LayerBarDropdownOptionType> = (
  option: LayerBarDropdownOptionType
) => {
  const buttonRef = React.useRef<HTMLButtonElement>(null)
  const [isOverflowing, setIsOverflowing] = React.useState(false)

  React.useEffect(() => {
    if (buttonRef.current && buttonRef.current.scrollWidth > 200) {
      setIsOverflowing(true)
    }
  }, [buttonRef])

  return (
    <Tooltip
      tooltip={
        !(!isOverflowing || option.isDefault) && (
          <span style={{ whiteSpace: 'nowrap' }}>{option.label}</span>
        )
      }
      placement="top"
    >
      <Button
        intent="neutral"
        addOn="prefix"
        onClick={option.action}
        disabled={option.disabled || option.loading}
        isActive={option.active}
        ref={buttonRef}
      >
        {option.prefix && <Icon icon={option.prefix} />}
        <span
          style={isOverflowing ? { width: 150, overflow: 'hidden', textOverflow: 'ellipsis' } : {}}
        >
          {option.label}
        </span>
        {option.loading && <Icon icon="spinner" className="styled-icon-loading" />}
        {option.active && <Icon icon="close" className="styled-icon-active" />}
      </Button>
    </Tooltip>
  )
}

export const LayerBarDropdown: React.ComponentType<LayerBarDropdownType> = ({
  defaultOption,
  ...props
}: LayerBarDropdownType) => {
  const [opened, setOpened] = React.useState(false)
  const { draggingState } = useTheme()

  const [triggerRef, popperRef] = usePopper({
    placement: 'right-start',
    modifiers: [
      {
        name: 'offset',
        options: { offset: [0, 13] },
      },
    ],
  })
  useClickOutsideOnExistingRefs(() => {
    if (!opened) return
    setOpened(false)
  }, [popperRef])

  React.useEffect(() => {
    if (draggingState === 'UPLOADING') {
      setOpened(false)
    }
  }, [draggingState])

  const layerBarButtonOnClick = React.useCallback(() => {
    if (props.onClick) props.onClick()
    setOpened(prev => !prev)
  }, [setOpened, props])

  return (
    <React.Fragment>
      <LayerBarButton
        embedded={!!props.embedded}
        label={props.label}
        icon={props.icon}
        active={props.active}
        $rightAdd={props.$rightAdd}
        addable={props.addable}
        onClick={layerBarButtonOnClick}
        ref={triggerRef}
      />
      <LayerBarDropdownOptions opened={opened} ref={popperRef}>
        {props.options.map((o, i) => (
          <LayerBarDropdownOption {...o} key={i} />
        ))}
        {defaultOption ? (
          <div className="styled-default-option">
            <LayerBarDropdownOption {...defaultOption} />
          </div>
        ) : null}
      </LayerBarDropdownOptions>
    </React.Fragment>
  )
}
