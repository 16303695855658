import * as React from 'react'

import { Switch } from 'components/common/button'
import Hint from 'com.batch/shared/ui/component/hint'
import { Icon } from 'components/common/svg-icon'

import { type FieldRecord } from 'com.batch.redux/theme.records'

import { createOnKeyDownHandler } from 'com.batch/shared/ui/create-handler-onkeydown'

type FieldProps = {
  field: FieldRecord
  toggle: () => void
  select: () => void
  tooltip?: string
}

const Field = ({ field, select, tooltip = '', toggle }: FieldProps): React.ReactElement => {
  const onChange = React.useCallback(() => {
    toggle()
  }, [toggle])

  return (
    <div
      className="itemline"
      key={field.id}
      onClick={select}
      onKeyDown={createOnKeyDownHandler(select)}
      role="button"
      tabIndex={0}
    >
      {field.id !== 'general' && (
        <Switch disabled={!field.removable} isActive={!field.hidden} onChange={onChange} />
      )}
      <div className="itemline__image">{field.icon && <Icon icon={field.icon} size={12} />}</div>
      <div className="itemline__text">
        {field.label}
        {tooltip && <Hint>{tooltip}</Hint>}
      </div>
    </div>
  )
}

export default Field
