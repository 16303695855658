import * as React from 'react'
import { useSelector } from 'com.batch.common/react-redux'

import { filters } from 'components/campaign/campaign-list-utils'
import { Filter } from 'components/campaign/filters/filter'
import { Tooltip } from 'com.batch/shared/ui/component/tooltip'
import { Checkbox } from 'components/form'

import {
  type CampaignActiveFiltersRecord,
  type CampaignFilterRecord,
  type State,
} from 'com.batch.redux/_records'
import { currentAppSelector } from 'com.batch.redux/app'

type SourceFilterProps = {
  localActiveFilters: CampaignActiveFiltersRecord
  onChange: (arg1?: any) => void
  isAutomation: boolean
}

const hasEditorialDashboardSelector = (state: State) => state.company.hasEditorialDashboard

export const SourceFilter = ({
  localActiveFilters,
  onChange,
  isAutomation,
}: SourceFilterProps): React.ReactElement => {
  const hasEditorialDashboard = useSelector(hasEditorialDashboardSelector)
  const app = useSelector(currentAppSelector)
  const createOnChange = React.useCallback(
    (source: CampaignFilterRecord) => () => onChange(source),
    [onChange]
  )
  return (
    <Filter isEmbedded title="Sources">
      {filters
        .filter(f => f.category === 'sources')
        .filter(source => source.name !== 'editorial' || (hasEditorialDashboard && !isAutomation))
        .map(source => (
          <Tooltip
            key={source.name}
            tooltip={
              !(source.name !== 'apiPublic' || app.features.has('api-marketing')) &&
              'Upgrade your plan to create API campaigns.'
            }
            placement="left"
          >
            <div>
              <Checkbox
                key={source.name}
                name={source.name}
                label={source.label}
                checked={localActiveFilters.commons.has(source)}
                className={`sources_${source.label.toLocaleLowerCase().trim()}`}
                onChange={createOnChange(source)}
                disabled={source.name === 'apiPublic' && !app.features.has('api-marketing')}
              />
            </div>
          </Tooltip>
        ))}
    </Filter>
  )
}
