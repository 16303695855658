import { animated, useSpring } from '@react-spring/web'
import * as React from 'react'
import { useSelector } from 'com.batch.common/react-redux'

import { type StateToggler } from 'components/_hooks'

import { getEmailContentForActiveLanguageSelector } from 'com.batch/email/store/email-automation.selector'

import {
  IFrameContainer,
  IFramePreviewSwitchContainer,
} from 'com.batch/email/ui/components/email-preview/email-preview.styles'
import { EmailPreviewToolbar } from 'com.batch/email/ui/components/email-preview-toolbar'
import { PreviewSwitch } from 'com.batch/email/ui/components/preview-switch'

type EmailPreviewProps = {
  sendTestEmailPopinState?: StateToggler
  dynamicContentPopinState?: StateToggler
  showToolbar?: boolean
  editTemplate?: () => void
  messageId: string
  campaignFormMode?: boolean
}
export const EmailPreview = ({
  sendTestEmailPopinState,
  dynamicContentPopinState,
  showToolbar = false,
  editTemplate,
  messageId,
  campaignFormMode,
}: EmailPreviewProps): React.ReactElement => {
  const getContent = useSelector(getEmailContentForActiveLanguageSelector)
  const content = React.useMemo(
    () => getContent({ messageId, templateMode: 'TEMPLATED_HTML' }),
    [getContent, messageId]
  )
  const iFrameRef = React.useRef<HTMLIFrameElement>(null)

  const [preview, setPreview] = React.useState<'MOBILE' | 'DESKTOP'>('DESKTOP')

  const springProps = useSpring({
    width: '100%',
    maxWidth: preview === 'MOBILE' ? '380px' : campaignFormMode ? '1066px' : '830px',
  })
  const url = React.useMemo(() => {
    const blobHtml = new Blob([content.html ?? ''], {
      type: 'text/html',
    })
    return URL.createObjectURL(blobHtml)
  }, [content.html])

  /**
   * Renvoie les events mouseup et mousedown au parent de l'iframe.
   * Pour par exemple trigger les dismiss de Dropdown
   */
  const bubbleMouseEvents = React.useCallback(() => {
    iFrameRef.current?.contentWindow?.document.addEventListener('mouseup', () => {
      iFrameRef.current?.contentWindow?.parent?.document.dispatchEvent(new Event('mouseup'))
    })

    iFrameRef.current?.contentWindow?.document.addEventListener('mousedown', () => {
      iFrameRef.current?.contentWindow?.parent?.document.dispatchEvent(new Event('mousedown'))
    })
  }, [])

  const onLoadIFrame = React.useCallback(() => {
    bubbleMouseEvents()
    // Ajoute du padding AU MAIL pour éviter que la toolbar ne cache le contenu du mail
    if (iFrameRef.current?.contentWindow)
      iFrameRef.current.contentWindow.document.getElementsByTagName('body')[0].style.paddingBottom =
        '50px'
  }, [bubbleMouseEvents])

  // On passe par un effect car si on passe par la prop `src` de l'iframe, au changement de langue ça ajoute dans l'historique de navigation et affecte précédent
  React.useEffect(() => {
    if (iFrameRef.current?.contentWindow) {
      iFrameRef.current.contentWindow.location.replace(url)
    }
  }, [url])

  if (!content.html) {
    return (
      <IFrameContainer>
        <img
          src="/medias/img/canvas/email-preview.svg"
          style={{ width: '100%', height: '100%' }}
          alt="Skeleton of an email (as placeholder)"
        />
      </IFrameContainer>
    )
  }

  return (
    <IFramePreviewSwitchContainer>
      <IFrameContainer>
        <animated.iframe
          sandbox="allow-same-origin"
          ref={iFrameRef}
          title="Email preview"
          style={{
            ...springProps,
            border: 'none',
            height: '100%',
          }}
          onLoad={onLoadIFrame}
          data-testid="email-preview-iframe"
        />
        {showToolbar &&
          !!sendTestEmailPopinState &&
          !!dynamicContentPopinState &&
          !!editTemplate && (
            <EmailPreviewToolbar
              sendTestEmailPopinState={sendTestEmailPopinState}
              dynamicContentPopinState={dynamicContentPopinState}
              editTemplate={editTemplate}
              messageId={messageId}
            />
          )}
      </IFrameContainer>
      <PreviewSwitch preview={preview} setPreview={setPreview} />
    </IFramePreviewSwitchContainer>
  )
}
