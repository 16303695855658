import * as React from 'react'

import { Skeleton } from 'components/common/empty-states'
import { Icon } from 'components/common/svg-icon'
import { Tooltip } from 'com.batch/shared/ui/component/tooltip'

import { getSubscriptionMessageByChannel } from 'com.batch/profile/infra/formats/get-subscription-messages'
import { getSubscriptionTimeDetails } from 'com.batch/profile/infra/formats/get-subscription-time-details'
import { type SubscriptionStatus } from 'com.batch/profile/models/profile.records'
import { ProfileTooltip } from 'com.batch/profile/ui/components/profile-hero/profile-hero.styles'
import { SubscriptionBlockContainer } from 'com.batch/profile/ui/components/profile-hero/subscription-block/subscription-block.styles'
import { type TransmissionType } from 'com.batch/shared/models/transmission-type'

type Props = {
  channel: 'sms' | 'email' | 'push'
  subscriptionName: TransmissionType
  subscriptionStatus: SubscriptionStatus
  lastEmailOpened?: string | null | undefined
  lastEmailClicked?: string | null | undefined
  contact?: string | null | undefined
  isLoading?: boolean
  isOnlyImportedPlatforms?: boolean
}

export const SubscriptionBlock = ({
  channel = 'email',
  subscriptionName,
  subscriptionStatus,
  lastEmailOpened,
  lastEmailClicked,
  contact,
  isLoading = false,
  isOnlyImportedPlatforms = false,
}: Props): React.ReactElement => {
  const subscriptionMessage = React.useMemo(
    () =>
      getSubscriptionMessageByChannel(channel)(
        subscriptionName,
        subscriptionStatus,
        contact,
        isOnlyImportedPlatforms
      ),
    [channel, subscriptionName, subscriptionStatus, isOnlyImportedPlatforms, contact]
  )
  const subscriptionTimeDetails = React.useMemo(
    () =>
      channel === 'email'
        ? getSubscriptionTimeDetails(subscriptionName, lastEmailClicked, lastEmailOpened)
        : null,
    [channel, subscriptionName, lastEmailClicked, lastEmailOpened]
  )

  const RenderSubscription = React.useMemo(
    () => (
      <SubscriptionBlockContainer
        subscriptionStatus={isOnlyImportedPlatforms ? 'IMPORTED' : subscriptionMessage.status}
      >
        <Skeleton w={subscriptionName === 'MARKETING' ? 62 : 84} h={13} style={{ marginTop: 6 }}>
          {subscriptionName === 'MARKETING' ? 'Marketing' : 'Transactional'}
        </Skeleton>
        <Skeleton w={15} h={13} style={{ marginTop: 6, marginBottom: 2 }}>
          <Icon icon={subscriptionMessage.icon} />
        </Skeleton>
      </SubscriptionBlockContainer>
    ),
    [subscriptionName, subscriptionMessage, isOnlyImportedPlatforms]
  )

  if (isLoading) return RenderSubscription
  return (
    <Tooltip
      tooltip={
        <ProfileTooltip $minWidth={235}>
          <div className="profile-tooltip-title">{subscriptionMessage.title}</div>
          <div className="profile-tooltip-message">{subscriptionMessage.message}</div>
          {subscriptionTimeDetails && subscriptionMessage.status === 'SUBSCRIBED' && (
            <React.Fragment>
              <div style={{ marginTop: 8, marginBottom: 4 }}>{subscriptionTimeDetails.opened}</div>
              <div>{subscriptionTimeDetails.clicked}</div>
            </React.Fragment>
          )}
        </ProfileTooltip>
      }
      placement="bottom-start"
      width={260}
    >
      {RenderSubscription}
    </Tooltip>
  )
}
