/* eslint-disable react/jsx-no-bind */
import { type OrderedSet } from 'immutable'
import * as React from 'react'

import { PreviewModeTemplate } from 'components/campaign/preview/preview-mode-template'
import { PreviewModeToggle } from 'components/campaign/preview/preview-mode-toggle'
import { Button, Dropdown } from 'components/common/button'
import { Icon } from 'components/common/svg-icon'
import { Tooltip } from 'com.batch/shared/ui/component/tooltip'

import {
  PreviewToolbarContainer,
  PreviewToolbarItem,
  BrowserLabel,
  ExpandButton,
} from './preview-toolbar.styles'

type PreviewToolbarProps = {
  style?: any
  vertical?: boolean
  installId: string | null | undefined
  installIds: OrderedSet<string>
  changePreferedInstall: (installId?: string) => any
  toggleFullScreen: () => void
  installLoading: boolean
  hasLanding: boolean
  dropUp?: boolean
  disableFullscreen?: boolean
  isFullScreen?: boolean
  showIframeReloadButton?: boolean
  isWeb: boolean
  isPush: boolean
  previewMode: 'normal' | 'expanded' | 'landing'
  previewWeb: string
  updatePreviewMode: (previewMode: 'normal' | 'expanded' | 'landing') => void
  updatePreviewWeb: (previewWeb: string) => void
}

type Option = {
  name: string
  platform: string | null | undefined
  value: string
}
const PreviewWebOptions: Array<Option> = [
  { name: 'Chrome', platform: 'Windows', value: 'chrome-win' },
  { name: 'Chrome', platform: 'macOS', value: 'chrome-mac' },
  { name: 'Firefox', platform: 'Windows', value: 'firefox-win' },
  { name: 'Firefox', platform: 'macOS', value: 'firefox-mac' },
  { name: 'Android', platform: null, value: 'android' },
  { name: 'Safari', platform: 'macOS', value: 'safari' },
]

const findWebPreviewLabel = (value: string): Option | null | undefined => {
  return PreviewWebOptions.reduce((prev, current) => {
    return current.value === value ? current : prev
  })
}

const PreviewToolbar = ({
  style,
  vertical,
  installId,
  installIds,
  showIframeReloadButton,
  changePreferedInstall,
  toggleFullScreen,
  hasLanding,
  installLoading,
  isWeb,
  isPush,
  previewMode,
  disableFullscreen,
  isFullScreen,
  previewWeb,
  updatePreviewWeb,
  updatePreviewMode,
}: PreviewToolbarProps): React.ReactElement => {
  const nextInstall = () => {
    const nexts = installIds.skipUntil(v => v === installId).filter(v => v !== installId)
    return nexts.size > 0 ? nexts.first() : installIds.first()
  }
  const goToNextInstall = () => {
    changePreferedInstall(nextInstall())
  }
  const prevInstall = () => {
    const prevs = installIds.takeWhile(v => v !== installId).filter(v => v !== installId)
    return prevs.size > 0 ? prevs.last() : installIds.last()
  }
  const goToPrevInstall = () => {
    changePreferedInstall(prevInstall())
  }
  const computedStyle = { ...style, justifyContent: 'center' }
  const currentWebOption = findWebPreviewLabel(previewWeb)
  const indice = installIds.toArray().indexOf(nextInstall() ?? '')

  return (
    <PreviewToolbarContainer style={computedStyle} vertical={vertical}>
      {isWeb && !isFullScreen && (
        <PreviewToolbarItem>
          <Dropdown
            offset={[0, 0]}
            placement="bottom"
            label={
              currentWebOption
                ? `${currentWebOption.name} ${
                    currentWebOption.platform ? `(${currentWebOption.platform})` : ''
                  }`
                : ''
            }
          >
            {PreviewWebOptions.map(opt => (
              <Button
                key={opt.value}
                isActive={opt.value === previewWeb}
                onClick={() => {
                  updatePreviewWeb(opt.value)
                  window.dispatchEvent(new Event('resize'))
                }}
              >
                <BrowserLabel>
                  {opt.name} {opt.platform && <span>({opt.platform})</span>}
                </BrowserLabel>
              </Button>
            ))}
          </Dropdown>
        </PreviewToolbarItem>
      )}
      {isPush && (!isWeb || previewWeb === 'android') && (
        <PreviewToolbarItem>
          <PreviewModeToggle
            vertical={vertical}
            mode={previewMode}
            update={updatePreviewMode}
            hasLanding={hasLanding}
          />
        </PreviewToolbarItem>
      )}
      {installIds.size > 0 && (
        <PreviewToolbarItem>
          <PreviewModeTemplate
            loading={installLoading}
            next={goToNextInstall}
            prev={goToPrevInstall}
            indice={indice}
          />
        </PreviewToolbarItem>
      )}
      {!disableFullscreen &&
        !isFullScreen &&
        (!isWeb || (currentWebOption && currentWebOption.platform === null)) && (
          <PreviewToolbarItem>
            <Tooltip tooltip="Fullscreen" placement="top">
              <div>
                <ExpandButton onClick={toggleFullScreen}>
                  <Icon icon="fullscreen" size={18} />
                </ExpandButton>
              </div>
            </Tooltip>
          </PreviewToolbarItem>
        )}
      {showIframeReloadButton && (
        <PreviewToolbarItem>
          <Button
            addOn="prefix"
            kind="inline"
            style={{ marginLeft: 10 }}
            onClick={() => {
              const iframe = document.getElementById(
                'js-iframe-preview-webview'
              ) as HTMLIFrameElement
              if (iframe) {
                // je pense que c'est pour reload, je sais pas pourquoi on fait comme ça...
                // eslint-disable-next-line no-self-assign
                iframe.src = iframe.src
                iframe.blur()
              }
            }}
          >
            <Icon icon="reload" />
            Reload URL preview
          </Button>
        </PreviewToolbarItem>
      )}
    </PreviewToolbarContainer>
  )
}
export default PreviewToolbar

PreviewToolbar.displayName = 'PreviewToolbar'
