import Immutable, { type RecordOf } from 'immutable'
import { LoadingStatus } from 'constants/common'
type TemplatingProps = {
  fetchingState: LoadingStatus
  value: string
  valueIsHtml: boolean
  templatedString: string
  richTemplatedString: string
}
export const TemplatingFactory = Immutable.Record<TemplatingProps>({
  fetchingState: LoadingStatus.INIT,
  value: '',
  valueIsHtml: false,
  templatedString: '',
  richTemplatedString: '',
})

export type TemplatingRecord = RecordOf<TemplatingProps>
