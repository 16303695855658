import * as React from 'react'

import { Input } from 'components/form'

type AppPickerProps = {
  placeholder?: string
}

export const OrchestrationPicker = ({
  placeholder = 'Enter an orchestration ID',
}: AppPickerProps): React.ReactElement => {
  return (
    <form method="GET" action="/console/orchestration" target="_blank">
      <Input name="q" placeholder={placeholder} style={{ width: '190px' }} />
    </form>
  )
}
