import Immutable, { type List, type Set } from 'immutable'
import * as React from 'react'
import { useDispatch, useSelector } from 'com.batch.common/react-redux'
import { useParams } from 'react-router-dom'

import { Content } from 'components/styled/blocs'

import { EmailAnalytics } from './email-analytics'
import { PushAnalytics } from './push-analytics'
import { SmsAnalytics } from './sms-analytics'

import { DateRangeFilter } from '../filters/date-range-filter'
import { FilterBar } from '../filters/filter-bar'
import { LanguageFilter } from '../filters/language-filter'
import { StepFilter } from '../filters/step-filter'
import { StepsAnalytics } from '../steps-analytics/steps-analytics'
import { globallyConfiguredLanguagesSelector } from 'com.batch/message/store/message.selector'
import { getMessageConfigSelector } from 'com.batch/orchestration/store/orchestration.composed.selectors'
import { orchestrationStateSelector } from 'com.batch/orchestration/store/orchestration.selectors'
import { orchestrationAnalyticsMapSelector } from 'com.batch/orchestration-analytics/store/orchestration-analytics.selector'
import {
  OrchestrationAnalyticsDateRangeFactory,
  OrchestrationAnalyticsFactory,
} from 'com.batch/orchestration-analytics/store/orchestration-analytics.state'

import { type MessageConfigRecord } from 'com.batch/message/models/message.records'
import {
  type OrchestrationStatsByStepRecord,
  OrchestrationStepFactory,
  type OrchestrationStepRecord,
  StepStatsFactory,
} from 'com.batch/orchestration-analytics/models/orchestration-stats-by-step.record'
import { ABReportBlock } from 'com.batch/orchestration-analytics/ui/components/a-b-report-block/a-b-report-block'
import { useGetAnalyticsFilters } from 'com.batch/orchestration-analytics/ui/hooks/use-get-analytics-filters'
import { useUpdateFiltersUrl } from 'com.batch/orchestration-analytics/ui/hooks/use-update-filters-url'
import { journeyTreeSelector } from 'com.batch/orchestration-journey/models/journey.selectors'
import { useGetSchedulingTypeFromUrl } from 'com.batch/orchestration-list/ui/hooks/use-scheduling-type-from-url'
import { restoreOrchestrationAnalyticsFilters } from 'com.batch/orchestration-analytics/ui/helpers/restore-orchestration-analytics-filters'
import { restoreAnalyticsFiltersAndDateRange } from 'com.batch/orchestration-analytics/usecases/analytics-filters'
import { OrchestrationState } from 'constants/common'

export const OrchestrationAnalytics = (): React.ReactElement => {
  const dispatch = useDispatch()
  const { token } = useParams()
  const schedulingType = useGetSchedulingTypeFromUrl()
  const {
    channels: channelsFromOrchestration,
    campaign,
    state: campaignState,
  } = useSelector(orchestrationStateSelector)
  const { nodesMap } = useSelector(journeyTreeSelector)
  const analyticsMap = useSelector(orchestrationAnalyticsMapSelector)
  const { filters, dateRange } = useGetAnalyticsFilters(token)
  const configuredLanguages = useSelector(globallyConfiguredLanguagesSelector)
  const statsByStep: OrchestrationStatsByStepRecord | null | undefined = React.useMemo(
    () =>
      (token
        ? analyticsMap.get(token, OrchestrationAnalyticsFactory())
        : OrchestrationAnalyticsFactory()
      ).get('steps'),
    [analyticsMap, token]
  )
  const getMessageConfig = useSelector(getMessageConfigSelector)
  const config: MessageConfigRecord = React.useMemo(
    () => getMessageConfig({ stepMessageNodeId: null }),
    [getMessageConfig]
  )
  const hasABTesting = React.useMemo(() => config.experiment.enabled, [config.experiment.enabled])

  React.useEffect(() => {
    if (token) {
      const { filters: restoredFilters, dateRange: restoredDateRange } =
        restoreOrchestrationAnalyticsFilters()
      const defaultDateRange =
        schedulingType === 'automations'
          ? restoredDateRange ??
            [OrchestrationState.STOPPED, OrchestrationState.COMPLETED].includes(campaignState)
            ? null
            : OrchestrationAnalyticsDateRangeFactory()
          : null
      dispatch(
        restoreAnalyticsFiltersAndDateRange({
          token: token,
          filters: restoredFilters,
          dateRange: defaultDateRange,
        })
      )
    }
  }, [dispatch, token, schedulingType, campaignState])

  useUpdateFiltersUrl({ filters, dateRange, context: schedulingType })

  const { allSteps, existingSteps } = React.useMemo(() => {
    const existingSteps: List<OrchestrationStepRecord> = nodesMap.reduce((acc, node) => {
      if (node.type !== 'MESSAGE') return acc
      const step =
        statsByStep?.find((step: OrchestrationStepRecord) => step.step === node.id) ??
        OrchestrationStepFactory({
          step: node.id,
          channel: node.messageConfig.channel,
          stats: StepStatsFactory(),
        })
      return acc.push(step)
    }, Immutable.List())
    const deletedSteps: List<OrchestrationStepRecord> =
      // On activera l'affichage des steps supprimées même temps que les filtres
      statsByStep ? statsByStep.filter(step => !nodesMap.has(step.step)) : Immutable.List()
    return {
      allSteps: Immutable.List<OrchestrationStepRecord>().concat(existingSteps, deletedSteps),
      existingSteps,
    }
  }, [nodesMap, statsByStep])

  const channelsFromSteps: Set<ChannelUntilCleanup> = React.useMemo(() => {
    const stepFilter = filters.get('step')
    return allSteps
      .filter(step => (!stepFilter ? true : stepFilter.includes(step.step)))
      .reduce((acc, step) => (step.channel ? acc.add(step.channel) : acc), Immutable.Set())
  }, [allSteps, filters])
  const channels = React.useMemo(
    () => (campaign.sendType === 'trigger' ? channelsFromSteps : channelsFromOrchestration),
    [campaign.sendType, channelsFromOrchestration, channelsFromSteps]
  )

  const { showStepReport, showStepFilter } = React.useMemo(
    () => ({
      showStepReport: allSteps.size > 1,
      showStepFilter: existingSteps.size > 1,
    }),
    [allSteps, existingSteps]
  )
  const hideFilterBar = React.useMemo(
    () => !showStepFilter && configuredLanguages.size < 2 && schedulingType === 'campaigns',
    [showStepFilter, configuredLanguages, schedulingType]
  )

  const filterCount = React.useMemo(() => {
    let count = 0
    if (schedulingType !== 'campaigns') count++
    if (showStepFilter) count++
    if (configuredLanguages.size > 1) count++
    return count
  }, [configuredLanguages.size, schedulingType, showStepFilter])

  const isClearFiltersDisabled = React.useMemo(() => {
    return !dateRange && filters.isEmpty()
  }, [dateRange, filters])

  return (
    <React.Fragment>
      {!hideFilterBar && (
        <FilterBar
          token={token}
          filterCount={filterCount}
          isClearButtonDisabled={isClearFiltersDisabled}
        >
          {schedulingType !== 'campaigns' && (
            <DateRangeFilter token={token} style={{ width: '100%' }} />
          )}
          <div style={{ display: 'grid', gridAutoFlow: 'column', gap: 5, flexGrow: 1 }}>
            {showStepFilter && <StepFilter />}
            {configuredLanguages.size > 1 && (
              <LanguageFilter
                token={token}
                languages={configuredLanguages.toList()}
                context={schedulingType}
              />
            )}
          </div>
        </FilterBar>
      )}

      <Content
        style={{
          overflow: 'visible',
          padding: '40px 26px 0',
          display: 'flex',
          flexDirection: 'column',
        }}
      >
        {showStepReport && (
          <div style={{ order: 25 }}>
            <StepsAnalytics steps={allSteps} />
          </div>
        )}
        <div style={{ order: 50 }}>
          {channels.has('email') && <EmailAnalytics />}
          {channels.has('push') && <PushAnalytics />}
          {channels.has('sms') && <SmsAnalytics />}
        </div>
        {hasABTesting && (
          <div style={{ order: config.experiment.winnerVariantId ? 65 : 15 }}>
            <ABReportBlock />
          </div>
        )}
      </Content>
    </React.Fragment>
  )
}
