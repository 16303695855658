import Immutable, { type List } from 'immutable'
import * as React from 'react'
import { useSelector, useDispatch } from 'com.batch.common/react-redux'

import { Button } from 'components/common/button'
import { FlexLine, FlexLineItem } from 'components/common/flexline'
import { Popin } from 'components/common/popin/popin'
import { WrapOverlay } from 'components/common/wrap-overlay'
import { Select } from 'components/form'
import { QueryBuilder } from 'components/query/query-builder-event'
import { Timezone } from 'components/styled/text'

import { Icon } from '../common/svg-icon'
import { type AttributeRecord } from 'com.batch.redux/_records'
import { actions } from 'com.batch.redux/query/query'
import { getInvalidConditionIds } from 'com.batch.redux/query/query.api'
import {
  getAPIQueryForIdSelector,
  getQueryRecordForIdSelector,
} from 'com.batch.redux/query/query.selector'
import { Tooltip } from 'com.batch/shared/ui/component/tooltip'

type PushWhenTriggerEventProps = {
  events: List<AttributeRecord>
  eventId: string
  queryId: string
  isEmailAutomation: boolean
  onQueryChange: (query: any) => void
  onEventChange: (arg1: string) => void
}

const EventOptionFormatter = (attr: AttributeRecord) => (
  <div>
    {attr.label}
    {attr.id.substring(0, 3) === 'ue.' && <Timezone style={{ marginLeft: 8 }}>User</Timezone>}
  </div>
)

const optionToString = (opt?: AttributeRecord | null) => {
  return opt?.label ?? ''
}

export const PushWhenTriggerEvent = ({
  events,
  eventId,
  queryId,
  isEmailAutomation,
  onQueryChange,
  onEventChange,
}: PushWhenTriggerEventProps): React.ReactElement => {
  // ------------- redux

  const dispatch = useDispatch()
  const getAPIQueryForId = useSelector(getAPIQueryForIdSelector)
  const getQueryRecordForId = useSelector(getQueryRecordForIdSelector)
  const query = React.useMemo(() => getQueryRecordForId(queryId), [getQueryRecordForId, queryId])
  const resetQuery = React.useCallback(
    () => dispatch(actions.resetQuery({ queryId })),
    [dispatch, queryId]
  )
  // ------------- local state
  const [qbIsOpen, setQbIsOpen] = React.useState(false)
  const [touched, setTouched] = React.useState<boolean>(false)
  const [queryErrors, setQueryErrors] = React.useState(Immutable.Set<string>())
  // ------------- derived
  const event = React.useMemo(() => events.find(e => e.id === eventId), [events, eventId])

  const setTouchedTrue = React.useCallback(() => setTouched(true), [])

  const close = React.useCallback(() => {
    setQbIsOpen(false)
  }, [setQbIsOpen])

  // we need to allow non persistent edit, so we can validate before really setting our filter / cancel on close
  // so we use a temp QueryRecord, stored in trigger_edit, and we swap using T_QUERY_COPY
  const open = React.useCallback(() => {
    dispatch(actions.copyQuery({ queryId, targetId: 'trigger_edit' }))
    setQueryErrors(Immutable.Set())
    setTouched(false)
    setQbIsOpen(true)
  }, [queryId, dispatch])

  const saveAndClose = React.useCallback(() => {
    onQueryChange(getAPIQueryForId('trigger_edit'))
    dispatch(actions.copyQuery({ queryId: 'trigger_edit', targetId: queryId }))
    setQbIsOpen(false)
  }, [dispatch, queryId, onQueryChange, getAPIQueryForId])

  const validateSaveAndClose = React.useCallback(() => {
    const errorsIds = getInvalidConditionIds(getQueryRecordForId('trigger_edit'))
    const hasError = errorsIds.size > 0
    if (hasError) {
      setTouched(false)
      setQueryErrors(errorsIds)
    } else {
      saveAndClose()
    }
  }, [getQueryRecordForId, saveAndClose])

  const handleEventChange = React.useCallback(
    (
      event: {
        id?: string
      } | null
    ) => {
      onEventChange(event?.id ?? '')
      resetQuery()
    },
    [onEventChange, resetQuery]
  )
  const resetEventAndQuery = React.useCallback(() => handleEventChange(null), [handleEventChange])
  return (
    <React.Fragment>
      {qbIsOpen && (
        <Popin opened={true} close={close} style={{ width: '1000px', padding: 0 }}>
          <QueryBuilder
            queryId="trigger_edit"
            setTouched={setTouchedTrue}
            touched={touched}
            eventId={eventId}
            errors={queryErrors}
            isEmailAutomation={isEmailAutomation}
            closeModal={close}
            validateSaveAndClose={validateSaveAndClose}
          />
        </Popin>
      )}
      <WrapOverlay
        overlay={
          query.error && (
            <Tooltip
              width={400}
              tooltip={
                <div style={{ textAlign: 'left' }}>
                  <p style={{ marginBottom: 10 }}>
                    When parsing this event, we got the following error: {query.error}
                  </p>
                  <p>
                    If you have replicated this orchestration from another app, the event or its
                    attributes might differ, and you should reset and pick another one.
                  </p>
                </div>
              }
            >
              <Button
                addOn="prefix"
                kind="secondary"
                intent="danger"
                onClick={resetEventAndQuery}
                style={{ marginTop: 10 }}
              >
                <Icon icon="delete" />
                Error parsing. Click to reset.
              </Button>
            </Tooltip>
          )
        }
      >
        <FlexLine mb style={{ marginBottom: 14, flexDirection: 'row' }}>
          <FlexLineItem grow={1}>
            <Select
              id={queryId}
              optionFormatter={EventOptionFormatter}
              options={events}
              optionToString={optionToString}
              isSearchable={events.size > 5}
              placeholder="Pick an event"
              value={event}
              onChange={handleEventChange}
            />
          </FlexLineItem>
          <FlexLineItem>
            <Button
              intent={query.conditions.size === 0 ? 'neutral' : 'action'}
              addOn="prefix"
              disabled={events.size === 0 || !event || event.allowedKeys.size === 0}
              kind="inline"
              style={{ width: 108, paddingLeft: 12 }}
              onClick={open}
            >
              <Icon icon="filter" />
              {query.conditions.size === 0 ? 'Add filters' : 'Edit filters'}
            </Button>
          </FlexLineItem>
        </FlexLine>
      </WrapOverlay>
    </React.Fragment>
  )
}
