import * as React from 'react'

import Hint from 'com.batch/shared/ui/component/hint'
import { Editor } from 'components/form'

import { Input } from './input'
import { ValidatedInputContainer } from './validated-input-styles'

type ValidatedInputProps = {
  forcedFocus?: boolean
  disabled?: boolean
  monospaced?: boolean
  name: string
  onChange: (value: string) => any
  placeholder?: string
  rows?: number
  touched: boolean
  type: 'text' | 'template' | 'html'
  valid: boolean
  value: string
  warning?: React.ReactNode
}
type WrappedValidatedInputProps = {
  label?: string
  hint?: string
}

export const ValidatedInput = ({
  forcedFocus,
  monospaced,
  name,
  onChange,
  rows,
  touched,
  type,
  valid,
  ...rest
}: ValidatedInputProps): React.ReactElement => {
  const classes = ['form-control', 'v']
  if (touched) classes.push('ng-touched')
  if (!valid && touched) classes.push('ng-invalid')
  if (monospaced) classes.push('form-control--fixed')
  const props = {
    ...rest,
    className: classes.join(' '),
    forcedFocus: !!forcedFocus,
  }

  const handleOnChange = React.useCallback(
    (evt: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
      onChange(evt.target.value)
    },
    [onChange]
  )

  switch (type) {
    case 'text':
      if (rows && rows > 1) {
        return <textarea {...props} name={name} rows={rows} onChange={handleOnChange} />
      } else {
        return <Input {...props} id={name} type="text" onChange={handleOnChange} />
      }
    default:
      return (
        <ValidatedInputContainer>
          <Editor
            onChange={onChange}
            style={{ minHeight: rows ? rows * 36 : 36 * 3 }}
            id={name}
            value={props.value}
            placeholder={props.placeholder}
            forcedFocus={!!forcedFocus}
          />
        </ValidatedInputContainer>
      )
  }
}

export const WrappedValidatedInput = (
  props: ValidatedInputProps & WrappedValidatedInputProps
): React.ReactElement => {
  const { label, hint, ...rest } = props
  return (
    <div className="form-group">
      {label && (
        <label htmlFor={props.name}>
          {label} {hint && <Hint>{hint}</Hint>}
        </label>
      )}
      <ValidatedInput {...rest} />
    </div>
  )
}
