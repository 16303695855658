import { type HtmlEditorConfig } from '../models/html-editor-config'
import { type DispatchBoundFn } from 'com.batch.redux/_records'

import { type EmailMessageRecord } from 'com.batch/message/models/message.records'
import { setTemplating } from 'com.batch/templating/usecases/set-templating'

export type UpdateEmailContentAction = {
  type: 'UPDATE_EMAIL_CONTENT'
  payload: {
    messageId: string
    lang: string
    field: 'subject' | 'html'
    value: string
    htmlEditorConfig?: HtmlEditorConfig
  }
}

export const updateEmailContent = ({
  messageId,
  lang,
  field,
  value,
  htmlEditorConfig,
  isInstant,
}: {
  messageId: string
  lang: string
  field: 'subject' | 'html' | 'replyTo'
  value: string | null | undefined
  htmlEditorConfig?: HtmlEditorConfig | null | undefined
  isInstant: boolean
}): DispatchBoundFn<void> => {
  return dispatch => {
    dispatch({
      type: 'UPDATE_EMAIL_CONTENT',
      payload: {
        messageId,
        lang,
        field,
        value,
        htmlEditorConfig,
      },
    })
    dispatch(
      setTemplating({
        messageId,
        field,
        value: value ?? '',
        langId: lang,
        valueIsHtml: field === 'html',
        isInstant,
      })
    )
  }
}

export type UpdateEmailRecordAction = {
  type: 'UPDATE_EMAIL_RECORD'
  payload: {
    messageId: string
    email: EmailMessageRecord
  }
}

export const updateEmailRecord = ({
  messageId,
  emailRecord,
}: {
  messageId: string
  emailRecord: EmailMessageRecord
}): UpdateEmailRecordAction => ({
  type: 'UPDATE_EMAIL_RECORD',
  payload: { messageId, email: emailRecord },
})
