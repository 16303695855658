import { useLexicalComposerContext } from '@lexical/react/LexicalComposerContext'
import { $getNearestBlockElementAncestorOrThrow } from '@lexical/utils'
import { $createTextNode, $getRoot, $getSelection, $isRangeSelection, $isTextNode } from 'lexical'
import * as React from 'react'

import { Button } from 'components/common/button'
import { trackEvent, TrackingContext } from 'components/common/page-tracker'
import { Icon } from 'components/common/svg-icon'
import { Tooltip } from 'com.batch/shared/ui/component/tooltip'
import { $createTagNode } from 'components/editor/tag-node'
import { TemplateWizard } from 'components/template/template-wizard'

import { type EditorProps } from './editor'

type ModalTemplateProps = Omit<EditorProps, 'placeholder' | 'onChange' | 'value'> & {
  placeholder?: string
  onChange: (newValue: string) => any
}

export function ModalTemplatePlugin(props: ModalTemplateProps): React.ReactElement {
  const [editor] = useLexicalComposerContext()
  const { eventLocation, eventPlatform } = React.useContext(TrackingContext)
  const [open, setOpened] = React.useState(false)

  // ------------MODAL
  const openModal = React.useCallback(() => {
    setOpened(true)
    // focus editor to avoid losing the selection
    editor.focus()

    trackEvent('TEMPLATE_WIZARD', {
      location: eventLocation,
      field: formatFieldForTracking(props.id),
      platform: eventPlatform,
    })
  }, [editor, eventLocation, eventPlatform, props.id])

  const closeModal = React.useCallback(() => {
    setOpened(false)
  }, [])

  const formatFieldForTracking = (field: string) => {
    const arr = field.split('-')
    if (arr.length > 0) return arr[0]
    return field
  }

  const appendText = React.useCallback(
    (text: string) => {
      editor.update(() => {
        const selection = $getSelection()
        if ($isRangeSelection(selection)) {
          const nodes = selection?.getNodes()
          // we cheat here and just consider the first text node ; we will split it in two and insert the new node in the middle
          const targetNode = nodes.find($isTextNode)
          const textNodeContent: string = targetNode?.getTextContent() ?? ''
          if (targetNode) {
            const parentBlock = $getNearestBlockElementAncestorOrThrow(targetNode)
            const position = parentBlock.getChildren().indexOf(targetNode)
            parentBlock.splice(position, 1, [
              $createTextNode(textNodeContent.substring(0, selection.anchor.offset)),
              $createTagNode(text),
              $createTextNode(textNodeContent.substring(selection.anchor.offset)),
            ])
          } else {
            $getRoot().splice(0, 1, [$createTagNode(text)])
          }
        } else {
          $getRoot().splice(0, 1, [$createTagNode(text)])
        }
      })
      trackEvent('TEMPLATE_WIZARD_ADD', {
        location: eventLocation,
        field: formatFieldForTracking(props.id),
        platform: eventPlatform,
      })
    },
    [editor, eventLocation, eventPlatform, props.id]
  )

  return (
    <React.Fragment>
      <div style={{ position: 'absolute', top: 3, right: 3, zIndex: 2 }}>
        <Tooltip tooltip="Use Custom Data to add Dynamic Content to your message" width={220}>
          <Button
            type="button"
            kind="inline"
            onClick={openModal}
            style={{ width: 30, height: 30 }}
            tabIndex={-1}
            aria-label="Open template modal"
          >
            <Icon icon="macros" />
          </Button>
        </Tooltip>
      </div>

      <TemplateWizard
        key={String(open)}
        appendText={appendText}
        attributes={props.attributes}
        close={closeModal}
        contextEventId={props.contextEventId}
        opened={open}
      />
    </React.Fragment>
  )
}
