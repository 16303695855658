import * as React from 'react'
import { useDispatch, useSelector } from 'com.batch.common/react-redux'

import { getEmailContentForActiveLanguageSelector } from 'com.batch/email/store/email-automation.selector'
import { previewLanguageSelector } from 'com.batch/message/store/message.selector'
import {
  inlineEditorConfigSelector,
  isFieldActiveSelector,
} from 'com.batch/message-builder/store/inline-editor.selector'
import { getPushContentForActiveLanguageSelector } from 'com.batch/push/store/push.selector'
import { getSmsContentForActiveLanguageSelector } from 'com.batch/sms/store/sms.selector'

import { updateEmailContent } from 'com.batch/email/usecases/update-email-content'
import { type MessageBuilderEditableField } from 'com.batch/message-builder/models/message-builder-field'
import { InlineEditor } from 'com.batch/message-builder/ui/components/inline-editor/inline-editor'
import {
  blurInlineEditorAction,
  setInlineEditor,
} from 'com.batch/message-builder/usecases/manage-inline-editor'
import { updatePushMessageContent } from 'com.batch/push/usecases/update-push-content'
import { updateSmsContent } from 'com.batch/sms/usecases/update-sms-content'
import { type TemplateMode } from 'com.batch/templating/models/templating.utils'

type EditableFieldProps = {
  messageId: string
  field: 'smsMessage' | 'pushTitle' | 'pushBody' | 'subject' | 'replyTo'
  preventAllCariageReturn?: boolean
  inlinePersonalizationButton?: boolean
  label?: string
}

export const EditableField = ({
  messageId,
  field,
  preventAllCariageReturn,
  inlinePersonalizationButton,
  label,
}: EditableFieldProps): React.ReactElement => {
  const dispatch = useDispatch()
  const inlineEditorConfig = useSelector(inlineEditorConfigSelector)
  const lang = useSelector(previewLanguageSelector)
  const isActive = useSelector(isFieldActiveSelector(field))
  const getSmsContent = useSelector(getSmsContentForActiveLanguageSelector)
  const getPushContent = useSelector(getPushContentForActiveLanguageSelector)
  const getEmailContent = useSelector(getEmailContentForActiveLanguageSelector)

  const onBlur = React.useCallback(
    selectionTuple => {
      dispatch(blurInlineEditorAction({ field, variant: 'a', selection: selectionTuple }))
    },
    [dispatch, field]
  )

  const setEditing = React.useCallback(
    (editing: MessageBuilderEditableField, caret: number) => {
      const newConfig = inlineEditorConfig
        .set('field', editing)
        .set('variant', 'a')
        .set('selection', [caret, caret])

      dispatch(setInlineEditor(newConfig))
    },
    [inlineEditorConfig, dispatch]
  )

  const params: { messageId: string; templateMode: TemplateMode } = React.useMemo(
    () => ({ messageId, templateMode: isActive ? 'RAW' : 'TEMPLATED' }), // @todo: switch to TEMPLATED_HTML when design is ready
    [messageId, isActive]
  )
  const pushContent = React.useMemo(() => getPushContent(params), [getPushContent, params])
  const smsContent = React.useMemo(() => getSmsContent(params), [getSmsContent, params])
  const emailContent = React.useMemo(() => getEmailContent(params), [getEmailContent, params])

  const onFocus = React.useCallback(() => {
    setEditing(field, 0)
  }, [field, setEditing])

  const onActivateAtPosition = React.useCallback(
    (position: number) => {
      let value: string | null | undefined
      switch (field) {
        case 'smsMessage':
          value = smsContent.get(field)
          break
        case 'pushTitle':
        case 'pushBody':
          value = pushContent.get(field)
          break
        case 'subject':
        case 'replyTo':
          value = emailContent.get(field)
          break
      }
      setEditing(field, value ? position : 0)
    },
    [setEditing, field, smsContent, pushContent, emailContent]
  )

  const fieldValue = React.useMemo(() => {
    switch (field) {
      case 'smsMessage':
        return smsContent.get(field)
      case 'pushTitle':
      case 'pushBody':
        return pushContent.get(field)
      case 'subject':
      case 'replyTo':
        return emailContent.get(field) ?? ''
      default:
        return ''
    }
  }, [field, smsContent, pushContent, emailContent])

  const updateContent = React.useCallback(
    (value: string) => {
      if (value === fieldValue) return
      switch (field) {
        // SMS Fields
        case 'smsMessage':
          dispatch(
            updateSmsContent({
              messageId,
              lang,
              field,
              value,
            })
          )
          break
        // Push fields
        case 'pushTitle':
        case 'pushBody':
          dispatch(
            updatePushMessageContent({
              messageId,
              lang,
              field,
              value,
            })
          )
          break
        case 'subject':
        case 'replyTo':
          dispatch(
            updateEmailContent({
              messageId,
              lang,
              field,
              value,
              isInstant: false,
            })
          )
          break
        default:
          return
      }
    },
    [field, lang, dispatch, messageId, fieldValue]
  )

  const placeholder = React.useMemo(() => {
    switch (field) {
      case 'pushTitle':
        return 'Click to add title'
      case 'subject':
        return 'Email subject'
      case 'replyTo':
        return 'support@yourcompany.com (optional)'
      default:
        return 'Click to add text'
    }
  }, [field])

  return (
    <InlineEditor
      messageId={messageId}
      selection={inlineEditorConfig.selection}
      onBlur={onBlur}
      canUseTemplating
      field={field}
      onFocus={onFocus}
      variant="a"
      isActive={isActive}
      onActivateAtPos={onActivateAtPosition}
      onChange={updateContent}
      placeholder={placeholder}
      value={fieldValue}
      preventAllCariageReturn={preventAllCariageReturn}
      inlinePersonalizationButton={inlinePersonalizationButton}
      label={label}
      hasCharCount={field === 'subject'}
    />
  )
}
