import { type Map, type Set } from 'immutable'

import { type langId } from 'com.batch/message/store/message.state'

import {
  type PushSettingsRecord,
  type PushContentRecord,
} from 'com.batch/message/models/message.records'
import { formatFilterPushTokens } from 'com.batch/orchestration/infra/formats/push-token-audience.target'
import {
  type OrchestrationPush,
  type PushPlatforms,
} from 'com.batch/push/infra/types/push.message.types'

export const formatPushMessage = ({
  settings,
  messages,
  multilanguageEnabled,
  platforms,
}: {
  settings: PushSettingsRecord
  messages: Map<langId, PushContentRecord>
  multilanguageEnabled: boolean
  platforms: Set<ProjectPlatforms>
}): OrchestrationPush => {
  const ttl = settings.ttlEnabled && settings.ttl.valid ? `${settings.ttl.seconds}s` : undefined
  const priority = settings.priority ?? 'PUSH_PRIORITY_UNSPECIFIED'

  const pushPlatforms: Array<PushPlatforms> = []
  if (platforms.has('ios')) pushPlatforms.push('PUSH_PLATFORM_IOS')
  if (platforms.has('android')) pushPlatforms.push('PUSH_PLATFORM_ANDROID')
  if (platforms.has('webpush')) pushPlatforms.push('PUSH_PLATFORM_WEB')

  return {
    priority,
    ttl,
    pushPlatforms,
    filterPushTokens: formatFilterPushTokens(settings.filterPushTokens),
    localizedPush: (multilanguageEnabled
      ? messages
      : messages.filter((_, lang) => lang === 'default')
    )
      .map((push, language) => {
        return {
          ...(language && language !== 'default' ? { language } : {}),
          pushMessageContentTemplate: {
            content: {
              title: push.pushTitle,
              body: push.pushBody,
              picture: push.pushPicture ? { url: push.pushPicture } : undefined,
              customPayload: settings.customPayload,
              deepLink: push.deeplink,
            },
            androidOverride: {
              deepLink: push.androidDeeplink,
              icon: push.pushIcon ? { url: push.pushIcon } : undefined,
              collapseKey: settings.androidOverride.collapseKey,
              customPayload: settings.androidOverride.customPayload,
            },
            iosOverride: {
              deepLink: push.iosDeeplink,
              iosVideo: push.iosVideo ? { url: push.iosVideo } : undefined,
              iosAudio: push.iosAudio ? { url: push.iosAudio } : undefined,
              customPayload: settings.iosOverride.customPayload,
            },
            webPushOverride: {
              deepLink: push.webpushDeeplink,
              icon: push.pushIcon ? { url: push.pushIcon } : undefined,
            },
          },
        }
      })
      .toArray()
      .map(kv => kv[1]),
  }
}
