import * as React from 'react'
import { useDispatch, useSelector } from 'com.batch.common/react-redux'

import { useAction } from 'components/_hooks'
import { CampaignFormActions } from 'components/campaign/campaign-form-actions'
import { TextReview } from 'components/campaign/form/text-review'
import InAppSpecificReview from 'components/campaign/inapp-specific-review'
import { ReviewLabels } from 'components/campaign/review-labels'
import { Alert } from 'components/common/alert'
import { Box, BoxBody, BoxHeader, HeaderBoxActions, HeaderBoxTitle } from 'components/common/box'
import { Switch } from 'components/common/button'
import { confirm } from 'components/common/confirm'
import { ConfirmHighlight } from 'components/common/confirm.styles'
import { FlexLine, FlexLineItem } from 'components/common/flexline'
import ReviewItem from 'components/targeting/review-item'
import { ReviewTargeting } from 'components/targeting/review-targeting'

import { computeRepeatWord, pluralizeAndKformat } from 'com.batch.common/utils'

import { PushSpecificReview } from '../push-specific-review'
import { type AppRecord, type CampaignRecord, type Variant } from 'com.batch.redux/_records'
import { currentAppCanPush } from 'com.batch.redux/app'
import { archive, saveCampaign, updateState } from 'com.batch.redux/campaign.action'
import {
  campaignRequireThemeSelector,
  contentSelector,
  pickedLanguagesSelector,
} from 'com.batch.redux/campaign.selector'
import { rawEstimateSelector } from 'com.batch.redux/targeting.selector'
import { customErrorsSelector } from 'com.batch.redux/targeting.selector.composed'
import { InAppVariantsThemeSelector } from 'com.batch.redux/theme.selector'
import { AbTestedInAppFactory } from 'com.batch.redux/content.records'
import { OrchestrationState } from 'constants/common/orchestration-state'

type FormReviewProps = {
  app: AppRecord
  campaign: CampaignRecord
  openErrors: () => any
}

const StateSwitch = ({
  state,
  updateState,
}: {
  state: OrchestrationState
  updateState: (arg1: OrchestrationState) => void
}) => {
  const canSetState = state === OrchestrationState.STOPPED || state === OrchestrationState.RUNNING
  const handleUpdateState = React.useCallback(
    (isActive: boolean) =>
      updateState(isActive ? OrchestrationState.RUNNING : OrchestrationState.STOPPED),
    [updateState]
  )
  return canSetState ? (
    <Switch isActive={state === OrchestrationState.RUNNING} onChange={handleUpdateState}>
      {state}
    </Switch>
  ) : state === OrchestrationState.COMPLETED ? (
    <span className="muted">COMPLETED</span>
  ) : (
    <span />
  )
}

export const FormReview: React.ComponentType<FormReviewProps> = React.memo<FormReviewProps>(
  ({ app, campaign, openErrors }: FormReviewProps) => {
    // ====================== STATE
    const state = campaign.state
    const type = campaign.type

    // ====================== REDUX
    const dispatch = useDispatch()
    const canPush = useSelector(currentAppCanPush)
    const estimate = useSelector(rawEstimateSelector)

    const { campaignName, pushSettings, push, inapp } = useSelector(contentSelector)
    const variantsThemes = useSelector(InAppVariantsThemeSelector)
    const languages = useSelector(pickedLanguagesSelector)
    const customErrors = useSelector(customErrorsSelector)
    const campaignRequireTheme = useSelector(campaignRequireThemeSelector)

    const canArchive =
      state !== OrchestrationState.NEW && !campaign.devOnly && (canPush || type === 'in-app')
    const canDraft = state === OrchestrationState.NEW || state === OrchestrationState.DRAFT
    const canUpdate =
      !canDraft &&
      state !== OrchestrationState.COMPLETED &&
      !campaign.devOnly &&
      (canPush || type === 'in-app')

    const confirmRunningCampaign = React.useCallback(() => {
      const variants = ['a', 'b']
      let warningWebiewNonSecure: React.ReactElement | null = null
      variants.forEach((variant: Variant) => {
        const webviewURL = inapp.get(app.defaultLanguageId ?? 'default', AbTestedInAppFactory()).a
          .webviewUrl
        if (
          campaignRequireTheme &&
          variantsThemes[variant]?.payloadVars.kind === 'webview' &&
          webviewURL.indexOf('http://') !== -1
        ) {
          warningWebiewNonSecure = (
            <div style={{ color: '#ED8545', marginTop: 10, fontWeight: '600' }}>
              You are using an insecure URL for your webview. Please be aware that an unsecure URL
              may create problems.
            </div>
          )
        }
      })

      return confirm({
        title: 'Run this campaign?',
        confirm: 'Yes, save & run',
        message: (
          <React.Fragment>
            <p>
              You are about to save and run the <ConfirmHighlight>{campaignName}</ConfirmHighlight>
              campaign{' '}
              {estimate && (
                <React.Fragment>
                  {' '}
                  and target{' '}
                  <ConfirmHighlight>
                    {pluralizeAndKformat('token', estimate.matching.count)}
                  </ConfirmHighlight>{' '}
                  out of
                  <ConfirmHighlight>
                    {pluralizeAndKformat('total token', estimate.all.count)}
                  </ConfirmHighlight>{' '}
                </React.Fragment>
              )}
              .
            </p>
            <p>
              {(() => {
                const sendType = campaign.sendType

                if (sendType === 'trigger') {
                  return (
                    <React.Fragment>
                      It will be triggered by an{' '}
                      <ConfirmHighlight>
                        {campaign.triggerConfig.enterEvent.substring(
                          campaign.triggerConfig.enterEvent.indexOf('.') + 1
                        )}
                      </ConfirmHighlight>{' '}
                      event
                    </React.Fragment>
                  )
                } else if (sendType === 'now' || !campaign.start) {
                  return (
                    <React.Fragment>
                      It will start <ConfirmHighlight>right now</ConfirmHighlight>. A started
                      campaign cannot be stopped
                    </React.Fragment>
                  )
                } else if (sendType === 'scheduled' || sendType === 'recurring') {
                  return (
                    <React.Fragment>
                      It will start on{' '}
                      <ConfirmHighlight>
                        {campaign.start && campaign.start.format('dddd, MMMM D, YYYY')} at{' '}
                        {campaign.start && campaign.start.format('HH:mm')}
                      </ConfirmHighlight>
                      {sendType === 'recurring' && (
                        <React.Fragment>
                          {' '}
                          every{' '}
                          <ConfirmHighlight>
                            {computeRepeatWord(
                              campaign.repeatUnit,
                              campaign.repeatFrequency,
                              campaign.start
                            )}
                          </ConfirmHighlight>
                        </React.Fragment>
                      )}
                      {campaign.tzAware ? " in the user's timezone" : ' UTC'}
                    </React.Fragment>
                  )
                }
              })()}
              .
            </p>
            {warningWebiewNonSecure}
            {}
          </React.Fragment>
        ),
      })
    }, [
      campaignName,
      estimate,
      inapp,
      app.defaultLanguageId,
      campaignRequireTheme,
      variantsThemes,
      campaign.sendType,
      campaign.start,
      campaign.triggerConfig.enterEvent,
      campaign.repeatUnit,
      campaign.repeatFrequency,
      campaign.tzAware,
    ])

    const saveIfNoError = React.useCallback(
      (running?: boolean) => {
        if (customErrors.size > 0) {
          openErrors()
        } else if (running) {
          confirmRunningCampaign().then(
            () => {
              dispatch(saveCampaign(true))
            },
            () => {}
          )
        } else {
          dispatch(saveCampaign())
        }
      },
      [confirmRunningCampaign, customErrors.size, openErrors, dispatch]
    )

    const saveAsDraft = React.useCallback(() => saveIfNoError(false), [saveIfNoError])
    const save = React.useCallback(() => saveIfNoError(true), [saveIfNoError])
    const saveAndKeep = React.useCallback(() => saveIfNoError(), [saveIfNoError])
    const archiveBound = useAction(archive)
    const footerProps = {
      loading: campaign.saving,
      validPushConfig: canPush,
      canViewData: state === OrchestrationState.COMPLETED,
      dataUrl: `${campaign.token}/analytics`,
      canArchive,
      canDraft,
      canUpdate,
      invalid: false,
      save: save,
      saveAndKeep: saveAndKeep,
      saveAsDraft: saveAsDraft,
      archive: archiveBound,
      isArchived: campaign.archived,
      campaignRequireTheme,
      hasTheme: Boolean(variantsThemes.a) && Boolean(variantsThemes.b),
    } as const

    const handleUpdateState = React.useCallback(
      (st: OrchestrationState) => {
        if (
          campaign.state === OrchestrationState.STOPPED &&
          campaign.sendType === 'trigger' &&
          (campaign.stats.loading || campaign.stats.trigger.exited.stop >= 0)
        ) {
          confirm({
            title: 'Run again this campaign?',
            message: (
              <React.Fragment>
                <p>
                  This campaign was running before. If you run it again, users who were in a waiting
                  state might receive the notification the next time they fire any event.
                </p>
                <p>
                  We always advise to create new campaign, for easier troubleshooting and better
                  analytics.
                </p>
              </React.Fragment>
            ),
            confirm: 'Yes, run',
          }).then(
            () => {
              dispatch(updateState(OrchestrationState.RUNNING))
            },
            () => {}
          )
        } else {
          dispatch(updateState(st))
        }
      },
      [
        dispatch,
        campaign.sendType,
        campaign.state,
        campaign.stats.loading,
        campaign.stats.trigger.exited.stop,
      ]
    )

    return (
      <Box style={{ zIndex: 'auto' }}>
        <BoxHeader>
          <HeaderBoxTitle title={type === 'in-app' ? 'Review your In-App' : 'Review'} />
          <HeaderBoxActions>
            <StateSwitch updateState={handleUpdateState} state={state} />
          </HeaderBoxActions>
        </BoxHeader>
        <BoxBody>
          {campaign.devOnly && (
            <Alert icon="danger" kind="warning">
              This campaign has been created via the API on the <strong>DEV</strong> api key and did
              only push <strong>DEV</strong> installs. You need to use the API update route to make
              any modification.
            </Alert>
          )}
          <FlexLine sameHeight style={{ marginBottom: 0 }}>
            <FlexLineItem grow={1} style={{ paddingRight: 0 }}>
              <ReviewTargeting />
              {campaign.pickedLabels.size > 0 && (
                <ReviewItem kind="labels">
                  <ReviewLabels labels={campaign.pickedLabels} />
                </ReviewItem>
              )}

              {app.platform === 'webpush' && type === 'push' && (
                <ReviewItem kind="Config">
                  <span>&nbsp;Collapse key:</span>
                  {pushSettings.hasCollapseKey ? (
                    <code>{pushSettings.collapseKey}</code>
                  ) : (
                    <em>Disabled</em>
                  )}
                  {pushSettings.hasExpiration && <span>&nbsp;Expiration:</span>}
                  {pushSettings.hasExpiration && <code>{pushSettings.expiration} hours</code>}
                </ReviewItem>
              )}
              {app.platform === 'android' && type === 'push' && (
                <ReviewItem kind="GCM">
                  <span>Priority:</span>
                  <code>{pushSettings.priority || 'HIGH'}</code>
                  <span>&nbsp;Collapse key:</span>
                  {pushSettings.hasCollapseKey ? (
                    <code>{pushSettings.collapseKey}</code>
                  ) : (
                    <em>Disabled</em>
                  )}
                  {pushSettings.hasExpiration && <span>Expiration:</span>}
                  {pushSettings.hasExpiration && <code>{pushSettings.expiration} hours</code>}
                </ReviewItem>
              )}
              {app.platform === 'ios' && type === 'push' && (
                <ReviewItem kind="APNS">
                  <span>Priority:</span>
                  <code>{pushSettings.priority || 'HIGH'}</code>
                  {pushSettings.hasExpiration && <span>Expiration:</span>}
                  {pushSettings.hasExpiration && <code>{pushSettings.expiration} hours</code>}
                </ReviewItem>
              )}
            </FlexLineItem>
            <FlexLineItem
              grow={1}
              container
              bl
              style={{
                width: '42%',
                minWidth: 350,
                padding: 30,
              }}
            >
              <TextReview
                hasLanding={campaignRequireTheme}
                isPush={type === 'push'}
                variantsThemes={variantsThemes}
                abtesting={campaign.abtesting}
                payload={pushSettings.payload}
                languages={languages}
                pushContent={push}
                inappContent={inapp}
              />
            </FlexLineItem>
          </FlexLine>
          {type === 'in-app' && <InAppSpecificReview inapp={campaign} />}
          {type === 'push' && <PushSpecificReview />}
        </BoxBody>
        <CampaignFormActions {...footerProps} />
      </Box>
    )
  }
)
FormReview.displayName = 'FormReview'
