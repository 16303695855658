import * as React from 'react'
import { IntercomButton, IntercomUnreadBadge } from 'com.batch/shared/ui/intercom-button.styles'
import { Icon } from 'components/common/svg-icon'
import { animated, useSpring } from '@react-spring/web'

export const Intercom = () => {
  const [unreadCount, setUnreadCount] = React.useState<number>(0)
  const [isIntercomOpen, setIsIntercomOpen] = React.useState(false)
  const isIntercomAvailable = React.useMemo(() => Boolean(window.Intercom), [])

  React.useEffect(() => {
    if (!isIntercomAvailable) return
    const handleUnreadCountChange = (count: number) => {
      setUnreadCount(count)
    }

    const handleShow = () => {
      setIsIntercomOpen(true)
    }

    const handleHide = () => {
      setIsIntercomOpen(false)
    }

    window.Intercom('onUnreadCountChange', handleUnreadCountChange)
    window.Intercom('onShow', handleShow)
    window.Intercom('onHide', handleHide)

    return () => {
      window.Intercom('offUnreadCountChange', handleUnreadCountChange)
      window.Intercom('offShow', handleShow)
      window.Intercom('offHide', handleHide)
    }
  }, [isIntercomAvailable])

  const springProps = useSpring({
    width: isIntercomOpen ? '0px' : '44px',
    paddingLeft: isIntercomOpen ? '7px' : '0px',
    config: { friction: 20, tension: 190 },
  })

  const iconSpringProps = useSpring({
    overflow: isIntercomOpen ? 'hidden' : 'visible',
    config: { friction: 20, tension: 190 },
  })

  const AnimatedIntercomButton = animated(IntercomButton)
  const AnimatedIcon = animated(Icon)

  return isIntercomAvailable ? (
    <AnimatedIntercomButton id="batch-intercom-btn" style={springProps}>
      {unreadCount > 0 && <IntercomUnreadBadge />}
      <AnimatedIcon icon="chat-bubble" style={iconSpringProps} size={16} />
    </AnimatedIntercomButton>
  ) : null
}
