import styled, { css } from 'styled-components'

import { HintIcon } from 'com.batch/shared/ui/component/hint'
import { IconContainer } from 'components/common/svg-icon'
import { colors, texts } from 'components/styled/tokens'

export const MetricContainer = styled.div`
  display: flex;

  ${props =>
    props.theme.metricAlign === 'row' &&
    css`
      flex-direction: row;
      align-items: center;
    `}

  ${props =>
    props.theme.metricAlign === 'column' &&
    css`
      flex-direction: column;
      & > div:first-of-type {
        display: flex;
        align-items: baseline;
      }
    `}
    ${HintIcon} {
    padding: 0;
    margin: 4px;
  }
`

export const MetricTitle = styled.h1`
  color: ${colors.text};

  ${props =>
    props.theme.metricSize === 'XL' &&
    css`
      ${texts.metricXl};
    `}

  ${props =>
    props.theme.metricSize === 'M' &&
    css`
      ${texts.metric};
    `}

    ${props =>
    props.theme.metricAlign === 'column' &&
    css`
      margin-bottom: 2px;
    `}
`

export const MetricSubtitle = styled.span`
  font-weight: 400;
  color: ${colors.textLight};

  ${IconContainer} {
    margin: 0 4px;
  }

  ${props =>
    props.theme.metricAlign === 'row' &&
    css`
      margin-left: 6px;
    `};
`

type MetricLegendProps = {
  $color: string
}
export const MetricLegend = styled.span<MetricLegendProps>`
  display: inline-block;
  height: 14px;
  width: 14px;
  background-color: ${props => props.$color};
  border-radius: 4px;
  margin: 4px;
`
