import { type Dayjs } from 'dayjs'
import Immutable, { type List } from 'immutable'
import * as React from 'react'
import { useDispatch, useSelector } from 'com.batch.common/react-redux'
import { Route, Routes, useLocation } from 'react-router-dom'

import { Box, BoxBody, BoxHeader, BoxTitle } from 'components/common/box'
import { ButtonNavLink } from 'components/common/button'
import { Skeleton, GlobalErrorOverlayProps, Wrapper } from 'components/common/empty-states'
import { LinkDocumentation } from 'components/styled/text'

import { dayjs } from 'com.batch.common/dayjs.custom'

import { useGetReportTypeFromUrl } from './use-get-report-type-from-url'

import { orchestrationStateSelector } from 'com.batch/orchestration/store/orchestration.selectors'
import {
  bounceByDaysRangeSelector,
  orchestrationAnalyticsMapSelector,
} from 'com.batch/orchestration-analytics/store/orchestration-analytics.selector'

import { bounceCatToReasons } from 'com.batch/orchestration-analytics/infra/formats/bounce-cat-to-reasons'
import { type EmailProviderRecord } from 'com.batch/orchestration-analytics/models/orchestration-stats-by-provider.record'
import {
  type BounceCategoryRecord,
  BounceCategoryFactory,
} from 'com.batch/orchestration-analytics/models/orchestration-stats.record'
import { BounceByDaysChart } from 'com.batch/orchestration-analytics/ui/components/bounce-by-days-chart/bounce-by-days-chart'
import { BouncePerMailboxProvider } from 'com.batch/orchestration-analytics/ui/components/bounce-per-mailbox-provider/bounce-per-mailbox-provider'
import { BounceReasons } from 'com.batch/orchestration-analytics/ui/components/bounce-reasons/bounce-reasons'
import { BounceCategory } from 'com.batch/orchestration-analytics/ui/components/bounce-report-block/bounce-category'
import {
  BounceBlockHeaderContainer,
  BounceStatsRow,
  SinceDate,
} from 'com.batch/orchestration-analytics/ui/components/bounce-report-block/bounce-report-block.styles'
import { useGetAnalyticsFilters } from 'com.batch/orchestration-analytics/ui/hooks/use-get-analytics-filters'
import { fetchOrchestrationDynamicStats } from 'com.batch/orchestration-analytics/usecases/fetch-dynamic-stats'
import { useGetSchedulingTypeFromUrl } from 'com.batch/orchestration-list/ui/hooks/use-scheduling-type-from-url'
import { LoadingStatus } from 'constants/common'
import { OrchestrationAnalyticsFactory } from 'com.batch/orchestration-analytics/store/orchestration-analytics.state'
import { statConfigs } from 'com.batch/orchestration-analytics/usecases/fetch-dynamic-stats.helper'

const emptyCategories: List<BounceCategoryRecord> = Immutable.List([
  BounceCategoryFactory({
    name: 'HARD',
    count: 0,
    rate: 0,
    classifications: Immutable.List(),
  }),
  BounceCategoryFactory({
    name: 'SOFT',
    count: 0,
    rate: 0,
    classifications: Immutable.List(),
  }),
  BounceCategoryFactory({
    name: 'BLOCK',
    count: 0,
    rate: 0,
    classifications: Immutable.List(),
  }),
])

type BounceReportBlockProps = {
  bounceCategories: List<BounceCategoryRecord> | null | undefined
  providers: List<EmailProviderRecord> | null | undefined
  token?: string
}
export const BounceReportBlock = ({
  bounceCategories,
  providers,
  token,
}: BounceReportBlockProps): React.ReactElement | null => {
  const dispatch = useDispatch()
  const schedulingType = useGetSchedulingTypeFromUrl()
  const reportType = useGetReportTypeFromUrl()

  const orchestration = useSelector(orchestrationStateSelector)
  const analyticsMap = useSelector(orchestrationAnalyticsMapSelector)
  const { campaign, loadingState: orchestrationLoadingState } = orchestration
  const { filters, dateRange } = useGetAnalyticsFilters(token)
  const getBounceByDaysRange = useSelector(bounceByDaysRangeSelector)
  const bounces = React.useMemo(
    () => (token ? getBounceByDaysRange(token) : []),
    [getBounceByDaysRange, token]
  )

  const [daysLoadingState, classificationsLoadingState, providersLoadingState] = React.useMemo(
    () => [
      token ? analyticsMap.get(token)?.daysLoadingState ?? LoadingStatus.INIT : LoadingStatus.ERROR,
      token
        ? analyticsMap.get(token)?.classificationsLoadingState ?? LoadingStatus.INIT
        : LoadingStatus.ERROR,
      token
        ? analyticsMap.get(token)?.providersLoadingState ?? LoadingStatus.INIT
        : LoadingStatus.ERROR,
    ],
    [analyticsMap, token]
  )

  const [dateOnView, setDateOnView] = React.useState<Dayjs | null | undefined>(null)

  const selectedBounce = React.useMemo(
    () => bounces.find(f => dateOnView && f.date.isSame(dateOnView, 'day')) || null,
    [bounces, dateOnView]
  )

  const location = useLocation()
  const activeTab = React.useMemo(() => {
    if (location.pathname.match('reasons')) return 'REASONS'
    else if (location.pathname.match('mailbox')) return 'MAILBOX'
    else return 'METRICS'
  }, [location])

  const reasons = React.useMemo(() => {
    if (!bounceCategories) return []
    return bounceCatToReasons(bounceCategories).sort((a, b) => (a.count > b.count ? -1 : 1))
  }, [bounceCategories])

  const bounceCount = React.useMemo(
    () =>
      (token
        ? analyticsMap.get(token, OrchestrationAnalyticsFactory())
        : OrchestrationAnalyticsFactory()
      )
        .get('channels')
        .get('email')
        .get('stats')
        ?.get('bounce').value ?? 0,
    [analyticsMap, token]
  )

  const recurrence = React.useMemo(() => {
    let from: Dayjs | null = null
    let to: Dayjs | null = dayjs.utc()

    if (dateRange) {
      from = dateRange.from
      to = dateRange.to
    } else {
      const bouncesDates: Array<Dayjs> = bounces.map(bounce => bounce.date)
      if (bouncesDates.length > 0) {
        from = dayjs.min(bouncesDates) as Dayjs
        to = dayjs.max(bouncesDates) as Dayjs
      }
    }

    if (!from) return 'DAILY'

    const diff = to.diff(from, 'day')
    switch (true) {
      case diff < 31:
        return 'DAILY'
      case diff < 77:
        return 'WEEKLY'
      default:
        return 'MONTHLY'
    }
  }, [dateRange, bounces])

  // Selon le type d'orchestration ou la plage de date sélectionnée, le wording diffère pour l'affichage de la temporalité
  const sinceDateLabel = React.useMemo(() => {
    let defaultDateLabel = ''
    if (campaign.sendType === 'recurring') {
      switch (campaign.recurrent.recurrence) {
        case 'DAILY':
          defaultDateLabel = '- daily recurring'
          break
        case 'WEEKLY':
          defaultDateLabel = '- weekly recurring'
          break
        case 'MONTHLY':
          defaultDateLabel = '- monthly recurring'
          break
        default:
          defaultDateLabel = ''
      }
    } else if (campaign.sendType === 'trigger') {
      defaultDateLabel = `- since launch ${
        orchestration.triggerSettings.start?.format('(MMM Do, YYYY)') ?? ''
      }`
    } else if (campaign.sendType === 'scheduled') {
      defaultDateLabel = ` campaign sent on ${
        orchestration.campaign.oneTime.sendDate?.format('MMM Do, YYYY') ?? ''
      }`
    }

    if (dateRange) {
      const from = dateRange.from
      const to = dateRange.to
      const diff = to.diff(from, 'day')

      if (selectedBounce) {
        const start =
          diff < 77
            ? selectedBounce.date.startOf('isoWeek').format('MMM Do, YYYY')
            : selectedBounce.date.startOf('month').format('MMM Do, YYYY')
        const end = selectedBounce.date
          .endOf(diff < 77 ? 'isoWeek' : 'month')
          .format('MMM Do, YYYY')
        return (
          <SinceDate>
            {diff < 31
              ? `- on ${selectedBounce.date.format('MMM Do, YYYY')}`
              : `- from ${start} to ${end}`}
          </SinceDate>
        )
      }
      return <SinceDate>{defaultDateLabel}</SinceDate>
    } else if (campaign.sendType === 'recurring') {
      return (
        <SinceDate>
          {selectedBounce
            ? campaign.recurrent.recurrence === 'DAILY'
              ? `- on ${selectedBounce.date.format('MMM Do, YYYY')}`
              : `- from ${
                  campaign.recurrent.recurrence === 'WEEKLY'
                    ? selectedBounce.date.startOf('week').add(1, 'day').format('MMM Do, YYYY')
                    : selectedBounce.date.startOf('month').format('MMM Do, YYYY')
                } to ${selectedBounce.date
                  .endOf(campaign.recurrent.recurrence === 'WEEKLY' ? 'week' : 'month')
                  .format('MMM Do, YYYY')}`
            : defaultDateLabel}
        </SinceDate>
      )
    } else if (campaign.sendType === 'trigger') {
      return (
        <SinceDate>
          {selectedBounce
            ? `- on ${selectedBounce.date.format('MMM Do, YYYY')}`
            : `${defaultDateLabel}`}
        </SinceDate>
      )
    } else if (campaign.sendType === 'scheduled') {
      return <SinceDate>- {defaultDateLabel}</SinceDate>
    }
    return null
  }, [
    campaign.recurrent.recurrence,
    campaign.sendType,
    orchestration.campaign.oneTime.sendDate,
    orchestration.triggerSettings.start,
    selectedBounce,
    dateRange,
  ])

  const displayedCategories = React.useMemo(() => {
    if (classificationsLoadingState === LoadingStatus.LOADING) return Immutable.List()
    return (
      bounceCategories && bounceCategories.size > 0
        ? bounceCategories.filter(category => ['BLOCK', 'HARD', 'SOFT'].includes(category.name))
        : emptyCategories
    ).sort((a, b) => {
      // Hard > Soft > Block
      const order = { HARD: 3, SOFT: 3, BLOCK: 2, UNDETERMINED: 4 }
      return order[a.name] - order[b.name]
    })
  }, [bounceCategories, classificationsLoadingState])

  const displayedProviders = React.useMemo(
    () => providers ?? Immutable.List<EmailProviderRecord>(),
    [providers]
  )

  const [isLoading, isOverlayShown] = React.useMemo(() => {
    switch (reportType) {
      case 'reasons':
        return [
          classificationsLoadingState === LoadingStatus.LOADING,
          classificationsLoadingState === LoadingStatus.ERROR,
        ]
      case 'mailbox':
        return [
          providersLoadingState === LoadingStatus.LOADING,
          providersLoadingState === LoadingStatus.ERROR,
        ]
      default:
        return [
          daysLoadingState === LoadingStatus.LOADING,
          daysLoadingState === LoadingStatus.ERROR,
        ]
    }
  }, [reportType, daysLoadingState, classificationsLoadingState, providersLoadingState])

  React.useEffect(() => {
    if (
      token &&
      orchestrationLoadingState === LoadingStatus.LOADED &&
      campaign.sendType !== 'scheduled'
    ) {
      const tokens = Immutable.List([token])
      dispatch(
        fetchOrchestrationDynamicStats({
          tokens,
          filters,
          dateRange,
          config: statConfigs.orchestrationStatsByDay,
        })
      ).catch(() => {})
    }
  }, [dispatch, token, orchestrationLoadingState, campaign.sendType, filters, dateRange])

  return (
    <Wrapper
      isLoading={isLoading}
      isEmpty={false}
      isOverlayShown={isOverlayShown}
      overlayProps={
        isOverlayShown
          ? GlobalErrorOverlayProps
          : {
              status: 'empty',
              title: `No links for this ${
                schedulingType === 'campaigns' ? 'campaign' : 'automation'
              }`,
              description: '',
            }
      }
      boxed
    >
      <Box
        style={{
          width: 704,
          marginBottom: 0,
          display: 'flex',
          height: 'min-content',
          flexDirection: 'column',
        }}
      >
        <BoxHeader style={{ padding: '0 10px 0 20px' }}>
          <BounceBlockHeaderContainer>
            <BoxTitle>
              Bounces
              <Skeleton w={149} h={22} style={{ marginLeft: 6 }}>
                {sinceDateLabel}
              </Skeleton>
            </BoxTitle>
            <div style={{ display: 'flex', gap: 8 }}>
              <ButtonNavLink to={''} end>
                Metrics
              </ButtonNavLink>
              <ButtonNavLink
                to={'reasons'}
                {...(classificationsLoadingState === LoadingStatus.LOADING || bounceCount === 0
                  ? { disabled: true }
                  : { end: true })}
              >
                Reasons
              </ButtonNavLink>
              <ButtonNavLink
                to={'mailbox'}
                {...(providersLoadingState === LoadingStatus.LOADING || bounceCount === 0
                  ? { disabled: true }
                  : { end: true })}
              >
                Mailbox
              </ButtonNavLink>
            </div>
          </BounceBlockHeaderContainer>
        </BoxHeader>
        <BoxBody
          style={{
            height: 391,
            display: 'flex',
            flexDirection: 'column',
            padding: activeTab === 'METRICS' ? '20px 0 6px' : 0,
          }}
        >
          <Routes>
            <Route
              path={'/'}
              element={
                <React.Fragment>
                  <BounceByDaysChart
                    bounces={bounces}
                    setDateOnView={setDateOnView}
                    loadingState={daysLoadingState}
                    sendType={campaign.sendType}
                    recurrence={recurrence}
                    totalBounces={bounceCount}
                    dateRangeFilter={dateRange}
                  />

                  <BounceStatsRow>
                    <div>
                      {displayedCategories?.map((category: BounceCategoryRecord) => (
                        <BounceCategory
                          key={category.name}
                          category={category}
                          selectedBounce={selectedBounce}
                        />
                      ))}
                    </div>
                    <LinkDocumentation
                      target="_blank"
                      href="https://help.batch.com/en/articles/8058036-email-analytics-glossary#h_73ec8500eb"
                      intent="action"
                      style={{ marginLeft: 'auto', marginTop: 7 }}
                    >
                      Help
                    </LinkDocumentation>
                  </BounceStatsRow>
                </React.Fragment>
              }
            />
            <Route path={'/reasons'} element={<BounceReasons reasons={reasons} />} />
            <Route
              path={'/mailbox'}
              element={<BouncePerMailboxProvider providers={displayedProviders} />}
            />
          </Routes>
        </BoxBody>
      </Box>
    </Wrapper>
  )
}
