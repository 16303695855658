import Immutable from 'immutable'

import {
  SmsMessageFactory,
  SmsContentFactory,
  type SmsContentRecord,
  type SmsMessageRecord,
} from 'com.batch/message/models/message.records'
import { type SmsContent } from 'com.batch/sms/infra/types/sms.message.types'
import { countSmsMessage } from 'com.batch/sms/usecases/count-sms-message'
import { validateSmsFields } from 'com.batch/sms/usecases/validate-sms-fields'

export const parseSms = (
  raw: SmsContent,
  isMarketing: boolean
): {
  message: SmsMessageRecord
  contentFullyEmpty: boolean
  contentInvalid: boolean
} => {
  let contentInvalid = false
  let contentFullyEmpty = false
  const tuplesLangValueArray: Array<[string, SmsContentRecord]> = (raw?.localizedSms ?? []).map(
    ({ language, region, message }) => {
      const lang = language && region ? `${language}-${region}` : language ? language : 'default'
      const smsRecord = SmsContentFactory({
        smsMessage: message?.content?.text ?? '',
      })
      if (!smsRecord.smsMessage) {
        contentFullyEmpty = true
      }

      const { parts } = countSmsMessage({
        message: smsRecord.smsMessage,
        countStop: isMarketing,
      })

      if (
        validateSmsFields({
          message: smsRecord.smsMessage,
          parts,
        }).size > 0
      ) {
        contentInvalid = true
      }
      return [lang, smsRecord]
    }
  )
  return {
    message: SmsMessageFactory({
      localizedContent: Immutable.OrderedMap(tuplesLangValueArray),
    }),
    contentFullyEmpty,
    contentInvalid,
  }
}
