import * as React from 'react'

import {
  Button,
  ButtonNavLink,
  DropdownMenu,
  useDropdown,
  useBlurOnClickCallback,
} from 'components/common/button'
import { Grid } from 'components/common/grid'
import { Icon } from 'components/common/svg-icon'
import { TableRow, TableCell, TableCellActions } from 'components/common/table'
import { Tag } from 'components/common/tag'
import { Tooltip } from 'com.batch/shared/ui/component/tooltip'

import { colors } from 'components/styled/tokens'

import { ucFirst } from 'com.batch.common/utils'

import { InvitationTag, UserIdentifier, UserName, UserEmail } from './team.styles'
import { UserAvatar } from './user-avatar'
import {
  getAllowedAppsCountOnCompanyForUser,
  getPermissionsForUserAndCompany,
  permissionNameFormatter,
  ROLES,
} from './utils'

import { type CompanyRecord } from 'com.batch.redux/_records'
import { type UserRecord } from 'com.batch.redux/user.records'

type UserRowProps = {
  appsLoading: boolean
  user: UserRecord
  appsCount: number
  editUri: string
  canUseAppRestriction: boolean
  company: CompanyRecord
  isSelf: boolean
  onDelete: () => any
  onResend?: () => any
}

function UserRowRaw({
  appsLoading,
  user,
  company,
  isSelf,
  onDelete,
  canUseAppRestriction,
  appsCount,
  editUri,
  onResend,
}: UserRowProps) {
  const userPerm = getPermissionsForUserAndCompany(user, company)
  const allowedCount = getAllowedAppsCountOnCompanyForUser({ user, company, appsCount })
  const { triggerProps, dropdownProps, closeDropdown } = useDropdown({ placement: 'left-start' })
  const onDeleteBlur = useBlurOnClickCallback(() => {
    closeDropdown()
    onDelete()
  }, [onDelete, closeDropdown])
  return (
    <TableRow key={user.id} style={{ height: 62 }}>
      <TableCell>
        <Grid template="24px minmax(0, 1fr)" style={{ overflow: 'visible', height: 46 }}>
          <UserAvatar user={user} />
          <UserIdentifier>
            {user.isInvite ? (
              <Tooltip
                placement="right"
                width={240}
                tooltip={
                  <div style={{ textAlign: 'left' }}>
                    The account is awaiting confirmation.
                    {user.lastInvite && (
                      <React.Fragment>
                        <br />
                        Last invite sent on {user.lastInvite.local().format('DD/MM/YY HH:mm')}
                      </React.Fragment>
                    )}
                  </div>
                }
              >
                <InvitationTag style={{ marginBottom: 2 }}>Invite</InvitationTag>
              </Tooltip>
            ) : (
              <UserName>
                {ucFirst(user.firstName)} {ucFirst(user.lastName)}
              </UserName>
            )}

            <UserEmail>{user.email}</UserEmail>
          </UserIdentifier>
        </Grid>
      </TableCell>
      <TableCell>
        {user.securedBy2FA ? (
          '2FA'
        ) : !user.onboardingStep.has('password') ? (
          <span style={{ color: colors.textDisabled }}>&mdash;</span>
        ) : (
          'Standard'
        )}
      </TableCell>
      <TableCell>
        <div>
          {ROLES.filter(
            r =>
              userPerm.has(r.id) &&
              !r.autoGrantedWith.reduce((acc, current) => acc || userPerm.has(current), false)
          ).map((p, k) => (
            <Tag type="label" key={k}>
              {permissionNameFormatter(p.id)}
            </Tag>
          ))}
        </div>
      </TableCell>
      {canUseAppRestriction && (
        <TableCell>
          {appsLoading ? (
            <React.Fragment>&mdash;</React.Fragment>
          ) : allowedCount === appsCount && appsCount > 0 ? (
            <React.Fragment>All&nbsp;({allowedCount})</React.Fragment>
          ) : allowedCount === 0 ? (
            <span style={{ color: colors.textDisabled }}>&mdash;</span>
          ) : (
            allowedCount
          )}
        </TableCell>
      )}
      <TableCellActions>
        {isSelf ? (
          <React.Fragment>
            <Tooltip tooltip="You can't edit your own permissions." placement="left">
              <span>
                <Button disabled style={{ height: 28, width: 28, marginRight: 10 }}>
                  <Icon icon="edit" />
                </Button>
              </span>
            </Tooltip>
            <Tooltip tooltip="You can't delete your own profile." placement="left">
              <span>
                <Button disabled kind="inline" style={{ width: 28, height: 28 }}>
                  <Icon icon="delete" />
                </Button>
              </span>
            </Tooltip>
          </React.Fragment>
        ) : (
          <React.Fragment>
            <ButtonNavLink to={editUri} style={{ height: 28, width: 28 }}>
              <Icon icon="edit" />
            </ButtonNavLink>
            <Button {...triggerProps} isLoading={user.loading} style={{ height: 28, width: 28 }}>
              <Icon icon="options" />
            </Button>
            <DropdownMenu {...dropdownProps}>
              {user.isInvite ? (
                <React.Fragment>
                  <Button onClick={onResend}>Resend invite</Button>
                  <Button intent="danger" onClick={onDeleteBlur}>
                    Remove
                  </Button>
                </React.Fragment>
              ) : (
                <Button intent="danger" onClick={onDeleteBlur}>
                  Delete
                </Button>
              )}
            </DropdownMenu>
          </React.Fragment>
        )}
      </TableCellActions>
    </TableRow>
  )
}

export const UserRow = React.memo<UserRowProps>(UserRowRaw) as React.ComponentType<UserRowProps>
