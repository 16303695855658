import Immutable, { type Set } from 'immutable'
import * as React from 'react'
import { useTheme } from 'styled-components'

import { Separator } from 'components/app/custom-data/custom-data.styles'
import { Button } from 'components/common/button/button.styles'
import { DropdownMenu, useDropdown } from 'components/common/button/dropdown'
import { type availableIcons, Icon } from 'components/common/svg-icon'
import { Tooltip } from 'com.batch/shared/ui/component/tooltip'
import { Checkbox } from 'components/form/fields/checkbox'
import { colors } from 'components/styled/tokens'

import { SelectPlatformToggle, PlatformsIconsWrapper } from './select-platform.styles'

export const icons: { [key: string]: availableIcons } = {
  ios: 'ios',
  android: 'android',
  webpush: 'webpush-fill',
}

export const formatPlatformName = (platform: ProjectPlatforms): string => {
  switch (platform) {
    case 'ios':
      return 'iOS'
    case 'android':
      return 'Android'
    default:
      return 'Web'
  }
}

export const getIconProps = (platform: ProjectPlatforms) => {
  switch (platform) {
    case 'webpush':
      return {
        size: 15,
        style: { marginTop: -3 },
      }
    case 'android':
      return {
        size: 14,
      }
    case 'ios':
      return {
        size: 15,
        style: { marginTop: -1 },
      }
    default:
      return {}
  }
}

type PlatformsIconsProps = {
  platforms: Set<ProjectPlatforms>
  hasPushIcon?: boolean
}
export const PlatformsIcons = ({
  platforms,
  hasPushIcon = false,
}: PlatformsIconsProps): React.ReactElement => (
  <PlatformsIconsWrapper style={{ display: 'flex', gap: 5, alignItems: 'center' }}>
    {hasPushIcon && (
      <React.Fragment>
        <Icon size={16} color={colors.textAction} icon="push" style={{ marginBottom: 4 }} />
        <Separator style={{ margin: '0 2px 0 0' }} />
      </React.Fragment>
    )}
    {platforms.toList().map(platform => {
      const { size, style } = getIconProps(platform)
      return (
        <Icon
          size={size}
          color={colors.textAction}
          icon={icons[platform]}
          style={style}
          key={`icon-${platform}`}
        />
      )
    })}
  </PlatformsIconsWrapper>
)

type SelectPlatformProps = {
  platforms: Set<ProjectPlatforms>
  disabledPlatforms?: Set<ProjectPlatforms>
  onChange: (arg1: Set<ProjectPlatforms>) => void
  value: Set<ProjectPlatforms>
  isDisabled?: boolean
  tooltip?: React.ReactNode
  showTooltip?: boolean
  hasPushIcon?: boolean
  platformTooltips?: { [key in ProjectPlatforms]: string } | object
}
export const SelectPlatform = ({
  platforms,
  disabledPlatforms = Immutable.Set(),
  value,
  isDisabled = false,
  onChange,
  tooltip,
  showTooltip = true,
  hasPushIcon,
  platformTooltips = {},
}: SelectPlatformProps): React.ReactElement => {
  const isOptionSelected = React.useCallback((platform: any) => value.includes(platform), [value])
  const theme = useTheme()
  const computedDisabled = React.useMemo(() => {
    return Boolean(isDisabled || theme?.disabledMode)
  }, [isDisabled, theme?.disabledMode])

  const isOptionDisabled = React.useCallback(
    opt => (isOptionSelected(opt) && value.size === 1) || disabledPlatforms.has(opt),
    [isOptionSelected, value, disabledPlatforms]
  )
  const onPlatformChange = React.useCallback(
    (opt: ProjectPlatforms) => () => {
      let updatedSet = value
      if (updatedSet.has(opt)) updatedSet = updatedSet.delete(opt)
      else updatedSet = updatedSet.add(opt)
      onChange(updatedSet)
    },
    [value, onChange]
  )
  const optionFormatter = React.useCallback(
    opt => {
      let color
      if (opt !== 'android') color = colors.text
      if (!isOptionSelected(opt)) color = colors.textLight
      if (isOptionSelected(opt) && value.size === 1) color = colors.textDisabled

      return (
        <Tooltip tooltip={platformTooltips[opt]} placement="right" offset={[10, 0]}>
          <div style={{ display: 'flex', alignItems: 'center', gap: 8 }}>
            <Checkbox
              ariaLabelledBy={`platform-option-${opt}`}
              checked={isOptionSelected(opt)}
              onChange={onPlatformChange(opt)}
              disabled={isOptionDisabled(opt)}
            />
            <p
              style={{
                display: 'flex',
                flexGrow: 1,
                justifyContent: 'space-between',
                alignItems: 'center',
              }}
              id={`platform-option-${opt}`}
            >
              {formatPlatformName(opt)}
              <Icon icon={opt} color={color} />
            </p>
          </div>
        </Tooltip>
      )
    },
    [isOptionSelected, value, onPlatformChange, isOptionDisabled, platformTooltips]
  )

  const { dropdownProps, triggerProps } = useDropdown({ forcedWidth: 140 })

  return computedDisabled ? (
    <PlatformsIcons platforms={platforms} hasPushIcon={hasPushIcon} />
  ) : (
    <React.Fragment>
      <Tooltip tooltip={!(!tooltip || !showTooltip || dropdownProps.isOpen) && tooltip} width={238}>
        <SelectPlatformToggle {...triggerProps} isActive={dropdownProps.isOpen}>
          <div style={{ display: 'flex', gap: 5, alignItems: 'center' }}>
            {hasPushIcon && (
              <React.Fragment>
                <Icon size={16} color={colors.textAction} icon="push" />
                <Separator style={{ margin: '0 2px 0 0', height: 16 }} />
              </React.Fragment>
            )}
            {platforms.map(platform => {
              const { size, style } = getIconProps(platform)
              return (
                <Icon
                  icon={icons[platform]}
                  size={size}
                  color={colors.textAction}
                  style={{
                    opacity: isOptionSelected(platform) ? 1 : 0.3,
                    ...style,
                  }}
                  key={`platform-option-icon-${platform}`}
                />
              )
            })}
            <Icon icon="select" style={{ marginLeft: 2 }} color={colors.textLight} />
          </div>
        </SelectPlatformToggle>
      </Tooltip>
      <DropdownMenu {...dropdownProps}>
        {platforms.map(p => (
          <Button as="label" disabled={isOptionDisabled(p)} key={`platform-option-${p}`}>
            {optionFormatter(p)}
          </Button>
        ))}
      </DropdownMenu>
    </React.Fragment>
  )
}
