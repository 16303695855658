import React from 'react'
import { type List } from 'immutable'
import {
  AudienceAttribute,
  SegmentsCollectionAttribute,
  ClickedMessage,
  LastEmailClick,
  LastEmailClickMarketing,
  LastEmailMarketingOpen,
  LastEmailOpen,
  OpenedMessage,
  SentMessage,
  IsPushOptin,
} from 'com.batch.redux/attribute.api'
import { type QueryAttributeRecord } from 'com.batch.redux/query/query.records'

export const useSortAndFilterPickableAttributes = (
  attributes: List<QueryAttributeRecord>,
  hasRetargeting: boolean
) => {
  return React.useMemo(
    () => sortAndFilterAttributes(attributes, hasRetargeting),
    [attributes, hasRetargeting]
  )
}

const sortAndFilterAttributes = (
  attributes: List<QueryAttributeRecord>,
  hasRetargeting: boolean
) => {
  return attributes
    .sort((a, b) => (a.label.toLocaleLowerCase() < b.label.toLocaleLowerCase() ? -1 : 1))
    .filter(
      attr =>
        ![SegmentsCollectionAttribute.id, AudienceAttribute.id, IsPushOptin.id].includes(
          attr.api
        ) && !hiddenAttributesDependingOnRetargetingFeature(hasRetargeting).includes(attr.api)
    )
}

const hiddenAttributesDependingOnRetargetingFeature = (hasRetargeting: boolean) => {
  return hasRetargeting
    ? [LastEmailClick.id, LastEmailOpen.id, LastEmailClickMarketing.id, LastEmailMarketingOpen.id]
    : [SentMessage.id, OpenedMessage.id, ClickedMessage.id, LastEmailClick.id, LastEmailOpen.id]
}
