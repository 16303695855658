import * as React from 'react'

import { Grid } from 'components/common/grid'
import { Tooltip } from 'com.batch/shared/ui/component/tooltip'

import { formatAudienceType } from 'com.batch.common/utils'

import { type CustomAudienceRecord } from 'com.batch.redux/_records'

export const OptionAudience = (
  option: CustomAudienceRecord,
  {
    context,
  }: {
    context: 'value' | 'menu'
  }
): React.ReactNode => {
  return context === 'value' ? (
    option.description ? (
      <Tooltip tooltip={option.name}>
        <span>{option.description}</span>
      </Tooltip>
    ) : (
      option.name
    )
  ) : (
    <Grid template="1fr auto">
      <span>{option.description || option.name}</span>
      <div style={{ textAlign: 'right' }}>
        <em>{formatAudienceType(option.type)}</em>
      </div>
    </Grid>
  )
}
