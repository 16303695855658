import Immutable from 'immutable'
import * as React from 'react'

import { useUserHasPermission } from 'components/_hooks'
import { Button, PermissionButton, Switch } from 'components/common/button'
import { Code } from 'components/common/code'
import { confirm } from 'components/common/confirm'
import { Icon } from 'components/common/svg-icon'
import { TableCell, TableCellActions, TableRow } from 'components/common/table'
import { Tooltip } from 'com.batch/shared/ui/component/tooltip'
import { Input, Select } from 'components/form'
import { colors } from 'components/styled/tokens'

import { humanizeDayjs } from 'com.batch.common/utils'

import { AllowedAttributeList, TestIcon } from './custom-data.styles'

import { type AttributeRecord } from 'com.batch.redux/_records'
import { type UserRecord } from 'com.batch.redux/user.records'
import { ThemeProvider } from 'styled-components'

type AttributeEditorProps = {
  user: UserRecord
  attribute: AttributeRecord
  editable: boolean
  isEditing: boolean
  editLine: () => any
  stopEditLine: () => any
  save: (a: AttributeRecord) => Promise<{
    attribute: AttributeRecord
  }>
  isAttr: boolean
  isEvent: boolean
}

type MyCustomOptions = {
  label: string
  value: string
}

const pickableTypes = Immutable.List<MyCustomOptions>([
  { value: 'BOOLEAN', label: 'BOOLEAN' },
  { value: 'DATE', label: 'DATE' },
  { value: 'FLOAT', label: 'FLOAT' },
  { value: 'INTEGER', label: 'INTEGER' },
  { value: 'POSITION', label: 'POSITION' },
  { value: 'STRING', label: 'STRING' },
  { value: 'VERSION', label: 'VERSION' },
  { value: 'URL', label: 'URL' },
])

const AttributeEditor = ({
  user,
  attribute,
  isEditing,
  editLine,
  stopEditLine,
  editable,
  save,
  isAttr,
  isEvent,
}: AttributeEditorProps): React.ReactElement => {
  const [localAttribute, setLocalAttribute] = React.useState<AttributeRecord>(attribute)
  const [saving, setSaving] = React.useState<boolean>(false)

  const userAllowedTopUpdate = useUserHasPermission(user, ['app', 'custom-data:write'])

  const inputNameRef = React.useRef<HTMLInputElement & HTMLTextAreaElement>(null)

  const update: boolean =
    localAttribute.name !== attribute.name ||
    localAttribute.overridenType !== attribute.overridenType ||
    localAttribute.hidden !== attribute.hidden

  React.useEffect(() => {
    if (!isEditing && update) setLocalAttribute(attribute)
    if (isEditing && !!inputNameRef.current) {
      inputNameRef.current.focus()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isEditing])

  let lastUpdate: string = ''
  let lastUpdateTooltipMsg: React.ReactNode = ''

  if (attribute.lastUpdate) {
    lastUpdate = humanizeDayjs({ date: attribute.lastUpdate })

    lastUpdateTooltipMsg = (
      <React.Fragment>
        {!!attribute.lastUpdate && attribute.lastUpdate.format('DD/MM/YYYY')}{' '}
        <span style={{ fontWeight: 300 }}>
          at {!!attribute.lastUpdate && attribute.lastUpdate.format('HH:mm:ss')} UTC
        </span>
      </React.Fragment>
    )
  }

  const savingAttribute = React.useCallback(() => {
    setSaving(true)

    save(localAttribute).then(res => {
      setLocalAttribute(res.attribute)
      setSaving(false)
      stopEditLine()
    })
  }, [localAttribute, save, stopEditLine])

  const handleSubmit = React.useCallback(
    (event: React.FormEvent) => {
      event.preventDefault()

      if (localAttribute.overridenType !== attribute.overridenType) {
        confirm({
          title: 'Override attribute type',
          message: (
            <React.Fragment>
              <p>
                Please be aware that we won’t do any type conversion, and only devices sending the
                data in the specified type will be targeted.{' '}
              </p>
              <br />
              <p>
                The option to update the type of an attribute is only there to freeze the targeting
                form of the dashboard, in the case multiple versions of your app use different
                types.
              </p>
            </React.Fragment>
          ),
        }).then(
          () => savingAttribute(),
          () => {}
        )
      } else {
        savingAttribute()
      }
    },
    [localAttribute, attribute, savingAttribute]
  )

  const handleOnClickClose = React.useCallback(() => {
    setLocalAttribute(attribute)
    stopEditLine()
  }, [attribute, stopEditLine])

  const handleOnClickSwitch = React.useCallback(() => {
    if (!isEditing) editLine()
    setLocalAttribute(localAttribute.set('hidden', !localAttribute.hidden))
  }, [editLine, isEditing, localAttribute])

  const handleOnChangeType = React.useCallback(
    (forcedType?: MyCustomOptions | null) => {
      if (forcedType) {
        setLocalAttribute(localAttribute.set('overridenType', forcedType.value))
      }
    },
    [localAttribute]
  )

  const handleOnChangeName = React.useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      setLocalAttribute(
        localAttribute.set(
          'name',
          event.target.value.length === 0 ? null : event.target.value.trim()
        )
      )
    },
    [localAttribute]
  )

  const AllowedAttribute = (
    <AllowedAttributeList>
      {localAttribute.allowedKeys.map(attr => (
        <div key={attr.hashCode()}>Attribute {attr.name}</div>
      ))}
    </AllowedAttributeList>
  )

  const filterOptionToString = React.useCallback(
    (opt: MyCustomOptions | undefined | null) => opt?.label ?? '',
    []
  )

  const optionFormatter = React.useCallback(
    (opt: MyCustomOptions) =>
      opt.value === localAttribute.lastReceivedType ? opt.label + '*' : opt.label,
    [localAttribute.lastReceivedType]
  )

  return (
    <TableRow
      as={isEditing ? 'form' : 'div'}
      isEditing={isEditing}
      className="fix-active-indicator"
      onSubmit={handleSubmit}
    >
      <ThemeProvider theme={{ kind: 'capture', size: 'small' }}>
        <TableCell kind="toggle">
          <Switch
            isActive={!localAttribute.hidden}
            onChange={handleOnClickSwitch}
            disabled={!userAllowedTopUpdate}
          />

          <div title={attribute.name || attribute.cleanId}>
            {isEditing ? (
              <Input
                type="text"
                ref={inputNameRef}
                placeholder={attribute.cleanId}
                defaultValue={localAttribute.name ?? undefined}
                onChange={handleOnChangeName}
              />
            ) : (
              attribute.name || attribute.cleanId
            )}
          </div>
        </TableCell>

        <TableCell>
          <Code>{attribute.cleanId}</Code>
        </TableCell>

        {isAttr && (
          <TableCell kind="input">
            {isEditing ? (
              <Select
                style={{ width: 140, minHeight: 28 }}
                optionToString={filterOptionToString}
                value={pickableTypes.find(e =>
                  localAttribute.overridenType
                    ? e.value === localAttribute.overridenType
                    : e.value === attribute.lastReceivedType
                )}
                onChange={handleOnChangeType}
                options={pickableTypes}
                optionFormatter={optionFormatter}
              />
            ) : (
              <span style={{ color: colors.textLight, letterSpacing: '-0.01em' }}>
                {attribute.type}
                <span>{localAttribute.lastReceivedType === localAttribute.type && '*'}</span>
              </span>
            )}
          </TableCell>
        )}

        {isEvent && (
          <TableCell style={attribute.allowedKeys.size === 0 ? { color: colors.textDisabled } : {}}>
            {attribute.allowedKeys.size > 0 ? (
              <React.Fragment>
                {attribute.allowedKeys.size}
                <Tooltip tooltip={AllowedAttribute} placement="right">
                  <TestIcon icon="details" />
                </Tooltip>
              </React.Fragment>
            ) : (
              '—'
            )}
          </TableCell>
        )}

        <TableCell>
          <Tooltip tooltip={lastUpdateTooltipMsg} placement="right">
            <span style={{ cursor: 'pointer' }}>{lastUpdate}</span>
          </Tooltip>
        </TableCell>
      </ThemeProvider>

      <TableCellActions>
        {isEditing ? (
          <React.Fragment>
            <Button
              type="submit"
              kind="inline"
              style={{ width: 50 }}
              intent="action"
              isLoading={saving}
              disabled={!update || saving}
            >
              Save
            </Button>
            <Button type="button" kind="inline" disabled={saving} onClick={handleOnClickClose}>
              <Icon icon="close" />
            </Button>
          </React.Fragment>
        ) : (
          <PermissionButton
            type="button"
            kind="inline"
            intent="neutral"
            disabled={!editable}
            onClick={editLine}
            isAllowed={userAllowedTopUpdate}
          >
            <Icon icon="edit" />
          </PermissionButton>
        )}
      </TableCellActions>
    </TableRow>
  )
}

export default AttributeEditor
