import Immutable, { type Set, type List } from 'immutable'
import * as React from 'react'

import { Filter } from 'components/campaign/filters/filter'
import { FilterSelectMulti } from 'components/filter'
import { CampaignStateIcon } from 'components/project/campaign/common/campaign-state-icon'
import { OrchestrationState } from 'constants/common/orchestration-state'

const optToString = (opt?: OrchestrationState | null) => opt ?? ''

type StatesFilterProps = {
  value: Set<OrchestrationState>
  onChange: (arg1: Set<OrchestrationState>) => void
  isAutomation: boolean
}

export const StatesFilter = ({
  value,
  onChange,
  isAutomation,
}: StatesFilterProps): React.ReactElement => {
  const formatOptionWord = React.useCallback(
    (option: OrchestrationState) => {
      switch (option) {
        case OrchestrationState.DRAFT:
          return 'Draft'
        case OrchestrationState.RUNNING:
          return !isAutomation ? 'Planned' : 'Running'
        case OrchestrationState.STOPPED:
          return 'Stopped'
        default:
          return 'Completed'
      }
    },
    [isAutomation]
  )
  const formatTerm = React.useCallback(
    (count: number) => (count === 1 ? formatOptionWord(value.first()) : `${count} statuses`),
    [formatOptionWord, value]
  )
  const onSelect = React.useCallback(
    (values: List<OrchestrationState>) => {
      onChange(values.toSet())
    },
    [onChange]
  )
  const formatOption = React.useCallback(
    (option: OrchestrationState) => {
      return (
        <span style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
          {formatOptionWord(option)}
          <CampaignStateIcon
            state={option}
            schedulingType={isAutomation ? 'automations' : 'campaigns'}
          />
        </span>
      )
    },
    [formatOptionWord, isAutomation]
  )
  return (
    <Filter title="Status">
      <FilterSelectMulti
        options={Immutable.List(
          Object.values(OrchestrationState).filter(state => state !== OrchestrationState.NEW)
        )}
        value={value.toList()}
        onChange={onSelect}
        placeholder="All statuses"
        term={formatTerm}
        isSearchable={false}
        optionToString={optToString}
        optionFormatter={formatOption}
      />
    </Filter>
  )
}
