import { type List, type Map } from 'immutable'

import { parseBounceByDays } from '../infra/parses/parse-bounce-by-days'
import { parseBounceCategories } from '../infra/parses/parse-bounce-categories'
import { parseStatsByChannel } from '../infra/parses/parse-stats-by-channel'
import { parseStatsByPeriod } from '../infra/parses/parse-stats-by-period'
import { parseStatsByProvider } from '../infra/parses/parse-stats-by-provider'
import { parseStatsByStep } from '../infra/parses/parse-stats-by-step'
import { type BounceDetailRecord } from '../models/bounce-detail-record'
import { type EmailProviderRecord } from '../models/orchestration-stats-by-provider.record'
import { type OrchestrationStatsByStepRecord } from '../models/orchestration-stats-by-step.record'
import {
  type OrchestrationStatsRecord,
  type BounceCategoryRecord,
} from '../models/orchestration-stats.record'
import { type PeriodicAnalyticsRecord } from '../store/orchestration-analytics.state'

import { parseStatsByVariant } from 'com.batch/orchestration-analytics/infra/parses/parse-stats-by-variant'
import { type OrchestrationStatsByVariantRecord } from 'com.batch/orchestration-analytics/models/orchestration-stats-by-variant.record'
import {
  parseOrchestrationCompletedStatsToListStatsState,
  parseOrchestrationOngoingStatsToListStatsState,
} from 'com.batch/orchestration-list/infra/parses/parse-orchestration-stats-to-list-stats-state'
import { type OrchestrationListStatsStateRecord } from 'com.batch/orchestration-list/models/orchestration-list-stats-state.records'
import { type OrchestrationStatsDynamicDimension } from 'com.batch/shared/infra/types/grpc-stats-service'

export type DynamicDimension =
  | 'channel'
  | 'bounce_classification'
  | 'day'
  | 'week'
  | 'month'
  | 'mailbox_provider'
  | 'step'
  | 'content_language'
  | 'orchestration'
  | 'ab_testing_variant'
  | 'ab_testing_status'
  | 'platform'
  | 'label'

export type FilterDimensions = Array<{
  dimensionName: DynamicDimension
  dimensionValues: Array<string>
}>
type GroupByConfig = {
  groupDimensionNames: Array<DynamicDimension>
  includeFilterDimensions?: FilterDimensions
}

export type PlatformFilterDimensionValue = 'IOS' | 'ANDROID' | 'WEBPUSH'

type GroupBy = 'bounceClassification' | 'day' | 'mailboxProvider' | 'variant'
const groupBy: {
  [key in GroupBy]: GroupByConfig
} = {
  bounceClassification: {
    groupDimensionNames: ['bounce_classification'],
    includeFilterDimensions: [
      {
        dimensionName: 'channel',
        dimensionValues: ['email'],
      },
    ],
  },
  day: {
    groupDimensionNames: ['day'],
    includeFilterDimensions: [
      {
        dimensionName: 'channel',
        dimensionValues: ['email'],
      },
    ],
  },
  mailboxProvider: {
    groupDimensionNames: ['mailbox_provider'],
    includeFilterDimensions: [
      {
        dimensionName: 'channel',
        dimensionValues: ['email'],
      },
    ],
  },
  variant: {
    groupDimensionNames: ['ab_testing_variant'],
    includeFilterDimensions: [
      {
        dimensionName: 'ab_testing_status',
        dimensionValues: ['ONGOING'],
      },
    ],
  },
}

type availableMetrics =
  | 'sent'
  | 'delivered'
  | 'uniqueClick'
  | 'bounce'
  | 'click'
  | 'open'
  | 'uniqueOpen'
  | 'uniqueMpp'
  | 'unsubscribe'
  | 'uniqueUnsubscribe'
  | 'hardBounce'
  | 'softBounce'
  | 'blockBounce'
  | 'lastSent'

export type StatConfig<T> = {
  actionName: string
  groupBy: GroupByConfig
  onlyIncludeMetricNames?: Array<availableMetrics>
  parser: (res: Array<OrchestrationStatsDynamicDimension> | null) => T
}
const commonChannel: StatConfig<OrchestrationStatsRecord> = {
  groupBy: {
    groupDimensionNames: ['channel'],
  },
  parser: parseStatsByChannel,
  actionName: '',
}
const orchestrationStatsForEmail: StatConfig<OrchestrationStatsRecord> = {
  ...commonChannel,
  actionName: 'FETCH_ORCHESTRATION_STATS_EMAIL',
}
const orchestrationStatsForSms: StatConfig<OrchestrationStatsRecord> = {
  ...commonChannel,
  actionName: 'FETCH_ORCHESTRATION_STATS_SMS',
}
const orchestrationStatsForPush: StatConfig<OrchestrationStatsRecord> = {
  ...commonChannel,
  actionName: 'FETCH_ORCHESTRATION_STATS_PUSH',
}
const orchestrationStatsByClassification: StatConfig<List<BounceCategoryRecord>> = {
  actionName: 'FETCH_ORCHESTRATION_STATS_BY_BOUNCE_CLASSIFICATION',
  groupBy: groupBy.bounceClassification,
  parser: parseBounceCategories,
}
const orchestrationStatsBouncesByDay: StatConfig<Map<string, BounceDetailRecord>> = {
  actionName: 'FETCH_ORCHESTRATION_BOUNCES_BY_DAY',
  groupBy: groupBy.day,
  parser: parseBounceByDays,
  onlyIncludeMetricNames: ['bounce', 'hardBounce', 'softBounce', 'blockBounce'],
}
const orchestrationStatsByProvider: StatConfig<List<EmailProviderRecord>> = {
  actionName: 'FETCH_ORCHESTRATION_STATS_BY_PROVIDER',
  groupBy: groupBy.mailboxProvider,
  parser: parseStatsByProvider,
  onlyIncludeMetricNames: [
    'sent',
    'delivered',
    'uniqueClick',
    'bounce',
    'uniqueOpen',
    'uniqueMpp',
    'unsubscribe',
    'hardBounce',
    'softBounce',
    'blockBounce',
  ],
}
const orchestrationStatsByStep: StatConfig<OrchestrationStatsByStepRecord> = {
  actionName: 'FETCH_ORCHESTRATION_STATS_BY_STEP',
  groupBy: {
    groupDimensionNames: ['step', 'channel'],
  },
  onlyIncludeMetricNames: [
    'sent',
    'delivered',
    'click',
    'uniqueClick',
    'bounce',
    'open',
    'uniqueOpen',
    'unsubscribe',
    'uniqueUnsubscribe',
  ],
  parser: parseStatsByStep,
}
const orchestrationListStatsLastPeriodWithTrend: StatConfig<
  Map<string, OrchestrationListStatsStateRecord>
> = {
  actionName: 'FETCH_ORCHESTRATIONS_LIST_DATA_LAST_PERIOD_WITH_TREND',
  onlyIncludeMetricNames: ['sent', 'lastSent'],
  groupBy: {
    groupDimensionNames: ['orchestration'],
  },
  parser: parseOrchestrationOngoingStatsToListStatsState,
}
const orchestrationListStatsAllTime: StatConfig<Map<string, OrchestrationListStatsStateRecord>> = {
  onlyIncludeMetricNames: ['sent', 'lastSent'],
  groupBy: {
    groupDimensionNames: ['orchestration'],
  },
  parser: parseOrchestrationCompletedStatsToListStatsState,
  actionName: 'FETCH_ORCHESTRATIONS_LIST_DATA_ALL_TIME',
}
const orchestrationStatsByVariant: StatConfig<OrchestrationStatsByVariantRecord> = {
  actionName: 'FETCH_ORCHESTRATION_STATS_BY_VARIANT',
  groupBy: groupBy.variant,
  parser: parseStatsByVariant,
}
type OrcStatsbyPeriod = StatConfig<Map<ChannelUntilCleanup, PeriodicAnalyticsRecord>>
const orchestrationStatsByChannelAndDay: OrcStatsbyPeriod = {
  actionName: 'FETCH_ORCHESTRATION_STATS_BY_CHANNEL_AND_PERIOD',
  groupBy: {
    groupDimensionNames: ['channel', 'day'],
  },
  parser: parseStatsByPeriod('day'),
}
const orchestrationStatsByChannelAndWeek: OrcStatsbyPeriod = {
  actionName: 'FETCH_ORCHESTRATION_STATS_BY_CHANNEL_AND_PERIOD',
  groupBy: {
    groupDimensionNames: ['channel', 'week'],
  },
  parser: parseStatsByPeriod('week'),
}
const orchestrationStatsByChannelAndMonth: OrcStatsbyPeriod = {
  actionName: 'FETCH_ORCHESTRATION_STATS_BY_CHANNEL_AND_PERIOD',
  groupBy: {
    groupDimensionNames: ['channel', 'month'],
  },
  parser: parseStatsByPeriod('month'),
}
export const statConfigs = {
  orchestrationStatsForEmail,
  orchestrationStatsForSms,
  orchestrationStatsForPush,
  orchestrationStatsByClassification,
  orchestrationStatsByDay: orchestrationStatsBouncesByDay,
  orchestrationStatsByProvider,
  orchestrationStatsByStep,
  orchestrationListStatsOngoing: orchestrationListStatsLastPeriodWithTrend,
  orchestrationListStatsCompleted: orchestrationListStatsAllTime,
  orchestrationStatsByVariant,
  orchestrationStatsByChannelAndDay,
  orchestrationStatsByChannelAndWeek,
  orchestrationStatsByChannelAndMonth,
}

// ACTIONS
export type FetchDynamicStatsActionCommon = {
  payload: {
    tokens?: List<string>
    projectKey?: string
  }
}
type FetchDynamicStatsFailureActionCommon = {
  payload: {
    error: {
      message: string
    }
    aborted: boolean
    tokens?: List<string>
    projectKey?: string
  }
}

// FetchOrchestrationStatsEmail
export type FetchOrchestrationStatsEmailAction = {
  type: 'FETCH_ORCHESTRATION_STATS_EMAIL'
} & FetchDynamicStatsActionCommon
export type FetchOrchestrationStatsEmailSuccessAction = {
  type: 'FETCH_ORCHESTRATION_STATS_EMAIL_SUCCESS'
  payload: {
    tokens?: List<string>
    projectKey?: string
    parsed: OrchestrationStatsRecord
  }
}
export type FetchOrchestrationStatsEmailFailureAction = {
  type: 'FETCH_ORCHESTRATION_STATS_EMAIL_FAILURE'
} & FetchDynamicStatsFailureActionCommon
export type FetchOrchestrationStatsEmailActions =
  | FetchOrchestrationStatsEmailAction
  | FetchOrchestrationStatsEmailSuccessAction
  | FetchOrchestrationStatsEmailFailureAction

// FetchOrchestrationStatsSms
export type FetchOrchestrationStatsSmsAction = {
  type: 'FETCH_ORCHESTRATION_STATS_SMS'
} & FetchDynamicStatsActionCommon
export type FetchOrchestrationStatsSmsSuccessAction = {
  type: 'FETCH_ORCHESTRATION_STATS_SMS_SUCCESS'
  payload: {
    tokens?: List<string>
    projectKey?: string
    parsed: OrchestrationStatsRecord
  }
}
export type FetchOrchestrationStatsSmsFailureAction = {
  type: 'FETCH_ORCHESTRATION_STATS_SMS_FAILURE'
} & FetchDynamicStatsFailureActionCommon
export type FetchOrchestrationStatsSmsActions =
  | FetchOrchestrationStatsSmsAction
  | FetchOrchestrationStatsSmsSuccessAction
  | FetchOrchestrationStatsSmsFailureAction

// FetchOrchestrationStatsPush
export type FetchOrchestrationStatsPushAction = {
  type: 'FETCH_ORCHESTRATION_STATS_PUSH'
} & FetchDynamicStatsActionCommon
export type FetchOrchestrationStatsPushSuccessAction = {
  type: 'FETCH_ORCHESTRATION_STATS_PUSH_SUCCESS'
  payload: {
    tokens?: List<string>
    projectKey?: string
    parsed: OrchestrationStatsRecord
  }
}
export type FetchOrchestrationStatsPushFailureAction = {
  type: 'FETCH_ORCHESTRATION_STATS_PUSH_FAILURE'
} & FetchDynamicStatsFailureActionCommon
export type FetchOrchestrationStatsPushActions =
  | FetchOrchestrationStatsPushAction
  | FetchOrchestrationStatsPushSuccessAction
  | FetchOrchestrationStatsPushFailureAction

// FetchOrchestrationStatsByClassification
export type FetchOrchestrationStatsByClassificationAction = {
  type: 'FETCH_ORCHESTRATION_STATS_BY_BOUNCE_CLASSIFICATION'
} & FetchDynamicStatsActionCommon
export type FetchOrchestrationStatsByClassificationSuccessAction = {
  type: 'FETCH_ORCHESTRATION_STATS_BY_BOUNCE_CLASSIFICATION_SUCCESS'
  payload: {
    tokens?: List<string>
    projectKey?: string
    parsed: List<BounceCategoryRecord>
  }
}
export type FetchOrchestrationStatsByClassificationFailureAction = {
  type: 'FETCH_ORCHESTRATION_STATS_BY_BOUNCE_CLASSIFICATION_FAILURE'
} & FetchDynamicStatsFailureActionCommon
export type FetchOrchestrationStatsByClassificationActions =
  | FetchOrchestrationStatsByClassificationAction
  | FetchOrchestrationStatsByClassificationSuccessAction
  | FetchOrchestrationStatsByClassificationFailureAction

// FetchOrchestrationStatsByDay
export type FetchOrchestrationStatsByDayAction = {
  type: 'FETCH_ORCHESTRATION_BOUNCES_BY_DAY'
} & FetchDynamicStatsActionCommon
export type FetchOrchestrationStatsByDaySuccessAction = {
  type: 'FETCH_ORCHESTRATION_BOUNCES_BY_DAY_SUCCESS'
  payload: {
    tokens?: List<string>
    projectKey?: string
    parsed: Map<string, BounceDetailRecord>
  }
}
export type FetchOrchestrationStatsByDayFailureAction = {
  type: 'FETCH_ORCHESTRATION_BOUNCES_BY_DAY_FAILURE'
} & FetchDynamicStatsFailureActionCommon
export type FetchOrchestrationStatsByDayActions =
  | FetchOrchestrationStatsByDayAction
  | FetchOrchestrationStatsByDaySuccessAction
  | FetchOrchestrationStatsByDayFailureAction

// FetchOrchestrationStatsByProvider
export type FetchOrchestrationStatsByProviderAction = {
  type: 'FETCH_ORCHESTRATION_STATS_BY_PROVIDER'
} & FetchDynamicStatsActionCommon
export type FetchOrchestrationStatsByProviderSuccessAction = {
  type: 'FETCH_ORCHESTRATION_STATS_BY_PROVIDER_SUCCESS'
  payload: {
    tokens?: List<string>
    projectKey?: string
    parsed: List<EmailProviderRecord>
  }
}
export type FetchOrchestrationStatsByProviderFailureAction = {
  type: 'FETCH_ORCHESTRATION_STATS_BY_PROVIDER_FAILURE'
} & FetchDynamicStatsFailureActionCommon
export type FetchOrchestrationStatsByProviderActions =
  | FetchOrchestrationStatsByProviderAction
  | FetchOrchestrationStatsByProviderSuccessAction
  | FetchOrchestrationStatsByProviderFailureAction

// FetchOrchestrationStatsByStep
export type FetchOrchestrationStatsByStepAction = {
  type: 'FETCH_ORCHESTRATION_STATS_BY_STEP'
} & FetchDynamicStatsActionCommon
export type FetchOrchestrationStatsByStepSuccessAction = {
  type: 'FETCH_ORCHESTRATION_STATS_BY_STEP_SUCCESS'
  payload: {
    tokens?: List<string>
    projectKey?: string
    parsed: OrchestrationStatsByStepRecord
  }
}
export type FetchOrchestrationStatsByStepFailureAction = {
  type: 'FETCH_ORCHESTRATION_STATS_BY_STEP_FAILURE'
} & FetchDynamicStatsFailureActionCommon

export type FetchOrchestrationStatsByStepActions =
  | FetchOrchestrationStatsByStepAction
  | FetchOrchestrationStatsByStepSuccessAction
  | FetchOrchestrationStatsByStepFailureAction

// FetchOrchestrationStatsByVariant
export type FetchOrchestrationStatsByVariantAction = {
  type: 'FETCH_ORCHESTRATION_STATS_BY_VARIANT'
} & FetchDynamicStatsActionCommon
export type FetchOrchestrationStatsByVariantSuccessAction = {
  type: 'FETCH_ORCHESTRATION_STATS_BY_VARIANT_SUCCESS'
  payload: {
    tokens?: List<string>
    projectKey?: string
    parsed: OrchestrationStatsByVariantRecord
  }
}
export type FetchOrchestrationStatsByVariantFailureAction = {
  type: 'FETCH_ORCHESTRATION_STATS_BY_VARIANT_FAILURE'
} & FetchDynamicStatsFailureActionCommon

export type FetchOrchestrationStatsByVariantActions =
  | FetchOrchestrationStatsByVariantAction
  | FetchOrchestrationStatsByVariantSuccessAction
  | FetchOrchestrationStatsByVariantFailureAction

// FetchOrchestrationListStats

export type FetchOrchestrationsListOngoingAction = {
  type: 'FETCH_ORCHESTRATIONS_LIST_DATA_LAST_PERIOD_WITH_TREND'
} & FetchDynamicStatsActionCommon

export type FetchOrchestrationsListOngoingSuccessAction = {
  type: 'FETCH_ORCHESTRATIONS_LIST_DATA_LAST_PERIOD_WITH_TREND_SUCCESS'
} & {
  payload: {
    tokens?: List<string>
    projectKey?: string
    parsed: Map<string, OrchestrationListStatsStateRecord>
  }
}

export type FetchOrchestrationsListOngoingFailureAction = {
  type: 'FETCH_ORCHESTRATIONS_LIST_DATA_LAST_PERIOD_WITH_TREND_FAILURE'
} & FetchDynamicStatsFailureActionCommon

export type FetchOrchestrationsListCompletedAction = {
  type: 'FETCH_ORCHESTRATIONS_LIST_DATA_ALL_TIME'
} & FetchDynamicStatsActionCommon

export type FetchOrchestrationsListCompletedSuccessAction = {
  type: 'FETCH_ORCHESTRATIONS_LIST_DATA_ALL_TIME_SUCCESS'
} & {
  payload: {
    tokens?: List<string>
    projectKey?: string
    parsed: Map<string, OrchestrationListStatsStateRecord>
  }
}

export type FetchOrchestrationsListCompletedFailureAction = {
  type: 'FETCH_ORCHESTRATIONS_LIST_DATA_ALL_TIME_FAILURE'
} & FetchDynamicStatsFailureActionCommon

export type FetchOrchestrationsDataActions =
  | FetchOrchestrationsListOngoingAction
  | FetchOrchestrationsListOngoingSuccessAction
  | FetchOrchestrationsListOngoingFailureAction
  | FetchOrchestrationsListCompletedAction
  | FetchOrchestrationsListCompletedSuccessAction
  | FetchOrchestrationsListCompletedFailureAction

// FetchOrchestrationStatsByChannelAndPeriod
export type FetchOrchestrationStatsByChannelAndPeriodAction = {
  type: 'FETCH_ORCHESTRATION_STATS_BY_CHANNEL_AND_PERIOD'
} & FetchDynamicStatsActionCommon
export type FetchOrchestrationStatsByChannelAndPeriodSuccessAction = {
  type: 'FETCH_ORCHESTRATION_STATS_BY_CHANNEL_AND_PERIOD_SUCCESS'
  payload: {
    tokens?: List<string>
    projectKey?: string
    parsed: Map<ChannelUntilCleanup, PeriodicAnalyticsRecord>
  }
}
export type FetchOrchestrationStatsByChannelAndPeriodFailureAction = {
  type: 'FETCH_ORCHESTRATION_STATS_BY_CHANNEL_AND_PERIOD_FAILURE'
} & FetchDynamicStatsFailureActionCommon

export type FetchOrchestrationStatsByChannelAndPeriodActions =
  | FetchOrchestrationStatsByChannelAndPeriodAction
  | FetchOrchestrationStatsByChannelAndPeriodSuccessAction
  | FetchOrchestrationStatsByChannelAndPeriodFailureAction
